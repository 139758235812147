import { OnboardingContent } from "../../../components/organisms/onboarding/OnboardingContent";
export const ONBOARDING_CONTENTS: OnboardingContent[] = [
  {
    img: {
      src: `/img/onboarding/onboarding-1.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`,
      altText: "page 1",
    },
    title: "あなたの環境行動を\nグリーンスコアとして可視化",
    text: "電気使用量の実績や環境行動の振り返り結果に基づき、\n独自ロジックによってスコア化します。",
  },
  {
    img: {
      src: `/img/onboarding/onboarding-2.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`,
      altText: "page 2",
    },
    title: "ご家庭の電気使用量を確認",
    text: "電力データを連携することで、電気使用量の推移や過去との比較、概算の電気料金を確認できます。",
  },
  {
    img: {
      src: `/img/onboarding/onboarding-3.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`,
      altText: "page 3",
    },
    title: "脱炭素アクションを選んで実践",
    text: "様々な脱炭素アクションから取組みたいものを選んで設定できます。",
  },
  {
    img: {
      src: `/img/onboarding/onboarding-4.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`,
      altText: "page 4",
    },
    title: "脱炭素アクションを振り返り",
    text: "振り返りによるグリーンスコアの変化で、\n行動の成果を実感。",
  },
  // {
  //   img: {
  //     src: `/img/onboarding/onboarding-5.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`,
  //     altText: "page 5",
  //   },
  //   title: "毎月の結果をレポートで確認",
  //   text: "レポートで月のスコア推移やアクション実施状況を確認。CO2の削減量は、グリーンステージの杉の木でチェック。",
  // },
];

export const LABELS = {
  nextButton: "次へ",
  finishButton: "始める",
  skipLink: "スキップ",
  firstPageText: "あなたの脱炭素行動をサポート",
  firstPageButtonText: "はじめる",
  endPageTitle: "まずは診断からスタート",
  endPageButtonText: "いまのあなたの環境行動を\n 診断してみましょう。",
  accountBottom: "アカウント登録",
};

const IS_DONE_TUTORIAL_KEY = "Greenscore-Tutorial-Done";
const IS_ONCE_TUTORIAL_KEY = "Greenscore-Tutorial-Once";
const DONE_TUTORIAL_VALUE_PREFIX =
  "" + process.env.NEXT_PUBLIC_DONE_TUTORIAL_VALUE_PREFIX;
const DELIMITER = ":";
/**
 * チュートリアル実施済の場合、trueを返却する
 * @returns
 */
export const isDoneTutorial = (): boolean => {
  if (typeof window !== "undefined" && window.localStorage) {
    let isDoneTutorial = false;
    const doneTutorialValue = window.localStorage.getItem(IS_DONE_TUTORIAL_KEY);
    if (doneTutorialValue) {
      const prefix = doneTutorialValue.split(DELIMITER)[0];
      // チュートリアルが更新されていない場合は、実施済とする
      isDoneTutorial = prefix >= DONE_TUTORIAL_VALUE_PREFIX;
    }
    return isDoneTutorial;
  }
  return true;
};
/**
 * チュートリアル実施済を保存する
 */
export const saveDoneTutorial = () => {
  if (typeof window !== "undefined" && window.localStorage)
    window.localStorage.setItem(
      IS_DONE_TUTORIAL_KEY,
      DONE_TUTORIAL_VALUE_PREFIX + DELIMITER + new Date().getTime()
    );
};
