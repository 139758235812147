import { api } from './';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  float8: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "zerocame.area_master" */
  delete_zerocame_area_master?: Maybe<Zerocame_Area_Master_Mutation_Response>;
  /** delete single row from the table: "zerocame.area_master" */
  delete_zerocame_area_master_by_pk?: Maybe<Zerocame_Area_Master>;
  /** delete data from the table: "zerocame.bingo_user_actions" */
  delete_zerocame_bingo_user_actions?: Maybe<Zerocame_Bingo_User_Actions_Mutation_Response>;
  /** delete single row from the table: "zerocame.bingo_user_actions" */
  delete_zerocame_bingo_user_actions_by_pk?: Maybe<Zerocame_Bingo_User_Actions>;
  /** delete data from the table: "zerocame.bingo_users" */
  delete_zerocame_bingo_users?: Maybe<Zerocame_Bingo_Users_Mutation_Response>;
  /** delete single row from the table: "zerocame.bingo_users" */
  delete_zerocame_bingo_users_by_pk?: Maybe<Zerocame_Bingo_Users>;
  /** delete data from the table: "zerocame.consent_application_input" */
  delete_zerocame_consent_application_input?: Maybe<Zerocame_Consent_Application_Input_Mutation_Response>;
  /** delete single row from the table: "zerocame.consent_application_input" */
  delete_zerocame_consent_application_input_by_pk?: Maybe<Zerocame_Consent_Application_Input>;
  /** delete data from the table: "zerocame.consent_application_result" */
  delete_zerocame_consent_application_result?: Maybe<Zerocame_Consent_Application_Result_Mutation_Response>;
  /** delete single row from the table: "zerocame.consent_application_result" */
  delete_zerocame_consent_application_result_by_pk?: Maybe<Zerocame_Consent_Application_Result>;
  /** delete data from the table: "zerocame.consent_application_zeroca_analysis" */
  delete_zerocame_consent_application_zeroca_analysis?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis_Mutation_Response>;
  /** delete single row from the table: "zerocame.consent_application_zeroca_analysis" */
  delete_zerocame_consent_application_zeroca_analysis_by_pk?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** delete data from the table: "zerocame.greenscore" */
  delete_zerocame_greenscore?: Maybe<Zerocame_Greenscore_Mutation_Response>;
  /** delete data from the table: "zerocame.greenscore_action_review" */
  delete_zerocame_greenscore_action_review?: Maybe<Zerocame_Greenscore_Action_Review_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_action_review" */
  delete_zerocame_greenscore_action_review_by_pk?: Maybe<Zerocame_Greenscore_Action_Review>;
  /** delete data from the table: "zerocame.greenscore_action_review_detail" */
  delete_zerocame_greenscore_action_review_detail?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_action_review_detail" */
  delete_zerocame_greenscore_action_review_detail_by_pk?: Maybe<Zerocame_Greenscore_Action_Review_Detail>;
  /** delete single row from the table: "zerocame.greenscore" */
  delete_zerocame_greenscore_by_pk?: Maybe<Zerocame_Greenscore>;
  /** delete data from the table: "zerocame.greenscore_calc" */
  delete_zerocame_greenscore_calc?: Maybe<Zerocame_Greenscore_Calc_Mutation_Response>;
  /** delete data from the table: "zerocame.greenscore_calc_action" */
  delete_zerocame_greenscore_calc_action?: Maybe<Zerocame_Greenscore_Calc_Action_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_calc_action" */
  delete_zerocame_greenscore_calc_action_by_pk?: Maybe<Zerocame_Greenscore_Calc_Action>;
  /** delete single row from the table: "zerocame.greenscore_calc" */
  delete_zerocame_greenscore_calc_by_pk?: Maybe<Zerocame_Greenscore_Calc>;
  /** delete data from the table: "zerocame.greenscore_calc_energy" */
  delete_zerocame_greenscore_calc_energy?: Maybe<Zerocame_Greenscore_Calc_Energy_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_calc_energy" */
  delete_zerocame_greenscore_calc_energy_by_pk?: Maybe<Zerocame_Greenscore_Calc_Energy>;
  /** delete data from the table: "zerocame.greenscore_challenge_code_master" */
  delete_zerocame_greenscore_challenge_code_master?: Maybe<Zerocame_Greenscore_Challenge_Code_Master_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_challenge_code_master" */
  delete_zerocame_greenscore_challenge_code_master_by_pk?: Maybe<Zerocame_Greenscore_Challenge_Code_Master>;
  /** delete data from the table: "zerocame.greenscore_co2_emission" */
  delete_zerocame_greenscore_co2_emission?: Maybe<Zerocame_Greenscore_Co2_Emission_Mutation_Response>;
  /** delete data from the table: "zerocame.greenscore_co2_emission_action" */
  delete_zerocame_greenscore_co2_emission_action?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_co2_emission_action" */
  delete_zerocame_greenscore_co2_emission_action_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Action>;
  /** delete single row from the table: "zerocame.greenscore_co2_emission" */
  delete_zerocame_greenscore_co2_emission_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission>;
  /** delete data from the table: "zerocame.greenscore_co2_emission_energy_bm" */
  delete_zerocame_greenscore_co2_emission_energy_bm?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_co2_emission_energy_bm" */
  delete_zerocame_greenscore_co2_emission_energy_bm_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** delete data from the table: "zerocame.greenscore_co2_emission_energy_dr" */
  delete_zerocame_greenscore_co2_emission_energy_dr?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_co2_emission_energy_dr" */
  delete_zerocame_greenscore_co2_emission_energy_dr_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** delete data from the table: "zerocame.greenscore_init_hearing" */
  delete_zerocame_greenscore_init_hearing?: Maybe<Zerocame_Greenscore_Init_Hearing_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_init_hearing" */
  delete_zerocame_greenscore_init_hearing_by_pk?: Maybe<Zerocame_Greenscore_Init_Hearing>;
  /** delete data from the table: "zerocame.greenscore_mission_achievement" */
  delete_zerocame_greenscore_mission_achievement?: Maybe<Zerocame_Greenscore_Mission_Achievement_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_mission_achievement" */
  delete_zerocame_greenscore_mission_achievement_by_pk?: Maybe<Zerocame_Greenscore_Mission_Achievement>;
  /** delete data from the table: "zerocame.greenscore_referral_code" */
  delete_zerocame_greenscore_referral_code?: Maybe<Zerocame_Greenscore_Referral_Code_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_referral_code" */
  delete_zerocame_greenscore_referral_code_by_pk?: Maybe<Zerocame_Greenscore_Referral_Code>;
  /** delete data from the table: "zerocame.greenscore_referral_result" */
  delete_zerocame_greenscore_referral_result?: Maybe<Zerocame_Greenscore_Referral_Result_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_referral_result" */
  delete_zerocame_greenscore_referral_result_by_pk?: Maybe<Zerocame_Greenscore_Referral_Result>;
  /** delete data from the table: "zerocame.greenscore_user_actions" */
  delete_zerocame_greenscore_user_actions?: Maybe<Zerocame_Greenscore_User_Actions_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_user_actions" */
  delete_zerocame_greenscore_user_actions_by_pk?: Maybe<Zerocame_Greenscore_User_Actions>;
  /** delete data from the table: "zerocame.greenscore_users" */
  delete_zerocame_greenscore_users?: Maybe<Zerocame_Greenscore_Users_Mutation_Response>;
  /** delete single row from the table: "zerocame.greenscore_users" */
  delete_zerocame_greenscore_users_by_pk?: Maybe<Zerocame_Greenscore_Users>;
  /** delete data from the table: "zerocame.jma_area_master" */
  delete_zerocame_jma_area_master?: Maybe<Zerocame_Jma_Area_Master_Mutation_Response>;
  /** delete single row from the table: "zerocame.jma_area_master" */
  delete_zerocame_jma_area_master_by_pk?: Maybe<Zerocame_Jma_Area_Master>;
  /** delete data from the table: "zerocame.jma_meteorological_daily" */
  delete_zerocame_jma_meteorological_daily?: Maybe<Zerocame_Jma_Meteorological_Daily_Mutation_Response>;
  /** delete single row from the table: "zerocame.jma_meteorological_daily" */
  delete_zerocame_jma_meteorological_daily_by_pk?: Maybe<Zerocame_Jma_Meteorological_Daily>;
  /** delete data from the table: "zerocame.mst_co2_emission_coefficient" */
  delete_zerocame_mst_co2_emission_coefficient?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_co2_emission_coefficient" */
  delete_zerocame_mst_co2_emission_coefficient_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** delete data from the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  delete_zerocame_mst_co2_emission_monthly_benchmark?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  delete_zerocame_mst_co2_emission_monthly_benchmark_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** delete data from the table: "zerocame.mst_electric_company" */
  delete_zerocame_mst_electric_company?: Maybe<Zerocame_Mst_Electric_Company_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_electric_company" */
  delete_zerocame_mst_electric_company_by_pk?: Maybe<Zerocame_Mst_Electric_Company>;
  /** delete data from the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  delete_zerocame_mst_energyscore_benchmark_coefficient?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  delete_zerocame_mst_energyscore_benchmark_coefficient_by_pk?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** delete data from the table: "zerocame.mst_energyscore_daily_benchmark" */
  delete_zerocame_mst_energyscore_daily_benchmark?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_energyscore_daily_benchmark" */
  delete_zerocame_mst_energyscore_daily_benchmark_by_pk?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** delete data from the table: "zerocame.mst_energyscore_difference_ratio" */
  delete_zerocame_mst_energyscore_difference_ratio?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_energyscore_difference_ratio" */
  delete_zerocame_mst_energyscore_difference_ratio_by_pk?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** delete data from the table: "zerocame.mst_energyscore_user_attribute" */
  delete_zerocame_mst_energyscore_user_attribute?: Maybe<Zerocame_Mst_Energyscore_User_Attribute_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_energyscore_user_attribute" */
  delete_zerocame_mst_energyscore_user_attribute_by_pk?: Maybe<Zerocame_Mst_Energyscore_User_Attribute>;
  /** delete data from the table: "zerocame.mst_prefecture_electric_company" */
  delete_zerocame_mst_prefecture_electric_company?: Maybe<Zerocame_Mst_Prefecture_Electric_Company_Mutation_Response>;
  /** delete single row from the table: "zerocame.mst_prefecture_electric_company" */
  delete_zerocame_mst_prefecture_electric_company_by_pk?: Maybe<Zerocame_Mst_Prefecture_Electric_Company>;
  /** delete data from the table: "zerocame.personal_electricity_data_30_minuites" */
  delete_zerocame_personal_electricity_data_30_minuites?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Mutation_Response>;
  /** delete single row from the table: "zerocame.personal_electricity_data_30_minuites" */
  delete_zerocame_personal_electricity_data_30_minuites_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** delete data from the table: "zerocame.personal_electricity_data_daily" */
  delete_zerocame_personal_electricity_data_daily?: Maybe<Zerocame_Personal_Electricity_Data_Daily_Mutation_Response>;
  /** delete single row from the table: "zerocame.personal_electricity_data_daily" */
  delete_zerocame_personal_electricity_data_daily_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_Daily>;
  /** delete data from the table: "zerocame.type_greenscore_detail_by_category" */
  delete_zerocame_type_greenscore_detail_by_category?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Mutation_Response>;
  /** delete single row from the table: "zerocame.type_greenscore_detail_by_category" */
  delete_zerocame_type_greenscore_detail_by_category_by_pk?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** delete data from the table: "zerocame.user_actions" */
  delete_zerocame_user_actions?: Maybe<Zerocame_User_Actions_Mutation_Response>;
  /** delete single row from the table: "zerocame.user_actions" */
  delete_zerocame_user_actions_by_pk?: Maybe<Zerocame_User_Actions>;
  /** delete data from the table: "zerocame.user_electricity_data_per_day" */
  delete_zerocame_user_electricity_data_per_day?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Mutation_Response>;
  /** delete single row from the table: "zerocame.user_electricity_data_per_day" */
  delete_zerocame_user_electricity_data_per_day_by_pk?: Maybe<Zerocame_User_Electricity_Data_Per_Day>;
  /** delete data from the table: "zerocame.users" */
  delete_zerocame_users?: Maybe<Zerocame_Users_Mutation_Response>;
  /** delete single row from the table: "zerocame.users" */
  delete_zerocame_users_by_pk?: Maybe<Zerocame_Users>;
  /** insert data into the table: "zerocame.area_master" */
  insert_zerocame_area_master?: Maybe<Zerocame_Area_Master_Mutation_Response>;
  /** insert a single row into the table: "zerocame.area_master" */
  insert_zerocame_area_master_one?: Maybe<Zerocame_Area_Master>;
  /** insert data into the table: "zerocame.bingo_user_actions" */
  insert_zerocame_bingo_user_actions?: Maybe<Zerocame_Bingo_User_Actions_Mutation_Response>;
  /** insert a single row into the table: "zerocame.bingo_user_actions" */
  insert_zerocame_bingo_user_actions_one?: Maybe<Zerocame_Bingo_User_Actions>;
  /** insert data into the table: "zerocame.bingo_users" */
  insert_zerocame_bingo_users?: Maybe<Zerocame_Bingo_Users_Mutation_Response>;
  /** insert a single row into the table: "zerocame.bingo_users" */
  insert_zerocame_bingo_users_one?: Maybe<Zerocame_Bingo_Users>;
  /** insert data into the table: "zerocame.consent_application_input" */
  insert_zerocame_consent_application_input?: Maybe<Zerocame_Consent_Application_Input_Mutation_Response>;
  /** insert a single row into the table: "zerocame.consent_application_input" */
  insert_zerocame_consent_application_input_one?: Maybe<Zerocame_Consent_Application_Input>;
  /** insert data into the table: "zerocame.consent_application_result" */
  insert_zerocame_consent_application_result?: Maybe<Zerocame_Consent_Application_Result_Mutation_Response>;
  /** insert a single row into the table: "zerocame.consent_application_result" */
  insert_zerocame_consent_application_result_one?: Maybe<Zerocame_Consent_Application_Result>;
  /** insert data into the table: "zerocame.consent_application_zeroca_analysis" */
  insert_zerocame_consent_application_zeroca_analysis?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis_Mutation_Response>;
  /** insert a single row into the table: "zerocame.consent_application_zeroca_analysis" */
  insert_zerocame_consent_application_zeroca_analysis_one?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** insert data into the table: "zerocame.greenscore" */
  insert_zerocame_greenscore?: Maybe<Zerocame_Greenscore_Mutation_Response>;
  /** insert data into the table: "zerocame.greenscore_action_review" */
  insert_zerocame_greenscore_action_review?: Maybe<Zerocame_Greenscore_Action_Review_Mutation_Response>;
  /** insert data into the table: "zerocame.greenscore_action_review_detail" */
  insert_zerocame_greenscore_action_review_detail?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_action_review_detail" */
  insert_zerocame_greenscore_action_review_detail_one?: Maybe<Zerocame_Greenscore_Action_Review_Detail>;
  /** insert a single row into the table: "zerocame.greenscore_action_review" */
  insert_zerocame_greenscore_action_review_one?: Maybe<Zerocame_Greenscore_Action_Review>;
  /** insert data into the table: "zerocame.greenscore_calc" */
  insert_zerocame_greenscore_calc?: Maybe<Zerocame_Greenscore_Calc_Mutation_Response>;
  /** insert data into the table: "zerocame.greenscore_calc_action" */
  insert_zerocame_greenscore_calc_action?: Maybe<Zerocame_Greenscore_Calc_Action_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_calc_action" */
  insert_zerocame_greenscore_calc_action_one?: Maybe<Zerocame_Greenscore_Calc_Action>;
  /** insert data into the table: "zerocame.greenscore_calc_energy" */
  insert_zerocame_greenscore_calc_energy?: Maybe<Zerocame_Greenscore_Calc_Energy_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_calc_energy" */
  insert_zerocame_greenscore_calc_energy_one?: Maybe<Zerocame_Greenscore_Calc_Energy>;
  /** insert a single row into the table: "zerocame.greenscore_calc" */
  insert_zerocame_greenscore_calc_one?: Maybe<Zerocame_Greenscore_Calc>;
  /** insert data into the table: "zerocame.greenscore_challenge_code_master" */
  insert_zerocame_greenscore_challenge_code_master?: Maybe<Zerocame_Greenscore_Challenge_Code_Master_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_challenge_code_master" */
  insert_zerocame_greenscore_challenge_code_master_one?: Maybe<Zerocame_Greenscore_Challenge_Code_Master>;
  /** insert data into the table: "zerocame.greenscore_co2_emission" */
  insert_zerocame_greenscore_co2_emission?: Maybe<Zerocame_Greenscore_Co2_Emission_Mutation_Response>;
  /** insert data into the table: "zerocame.greenscore_co2_emission_action" */
  insert_zerocame_greenscore_co2_emission_action?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_co2_emission_action" */
  insert_zerocame_greenscore_co2_emission_action_one?: Maybe<Zerocame_Greenscore_Co2_Emission_Action>;
  /** insert data into the table: "zerocame.greenscore_co2_emission_energy_bm" */
  insert_zerocame_greenscore_co2_emission_energy_bm?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_co2_emission_energy_bm" */
  insert_zerocame_greenscore_co2_emission_energy_bm_one?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** insert data into the table: "zerocame.greenscore_co2_emission_energy_dr" */
  insert_zerocame_greenscore_co2_emission_energy_dr?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_co2_emission_energy_dr" */
  insert_zerocame_greenscore_co2_emission_energy_dr_one?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** insert a single row into the table: "zerocame.greenscore_co2_emission" */
  insert_zerocame_greenscore_co2_emission_one?: Maybe<Zerocame_Greenscore_Co2_Emission>;
  /** insert data into the table: "zerocame.greenscore_init_hearing" */
  insert_zerocame_greenscore_init_hearing?: Maybe<Zerocame_Greenscore_Init_Hearing_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_init_hearing" */
  insert_zerocame_greenscore_init_hearing_one?: Maybe<Zerocame_Greenscore_Init_Hearing>;
  /** insert data into the table: "zerocame.greenscore_mission_achievement" */
  insert_zerocame_greenscore_mission_achievement?: Maybe<Zerocame_Greenscore_Mission_Achievement_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_mission_achievement" */
  insert_zerocame_greenscore_mission_achievement_one?: Maybe<Zerocame_Greenscore_Mission_Achievement>;
  /** insert a single row into the table: "zerocame.greenscore" */
  insert_zerocame_greenscore_one?: Maybe<Zerocame_Greenscore>;
  /** insert data into the table: "zerocame.greenscore_referral_code" */
  insert_zerocame_greenscore_referral_code?: Maybe<Zerocame_Greenscore_Referral_Code_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_referral_code" */
  insert_zerocame_greenscore_referral_code_one?: Maybe<Zerocame_Greenscore_Referral_Code>;
  /** insert data into the table: "zerocame.greenscore_referral_result" */
  insert_zerocame_greenscore_referral_result?: Maybe<Zerocame_Greenscore_Referral_Result_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_referral_result" */
  insert_zerocame_greenscore_referral_result_one?: Maybe<Zerocame_Greenscore_Referral_Result>;
  /** insert data into the table: "zerocame.greenscore_user_actions" */
  insert_zerocame_greenscore_user_actions?: Maybe<Zerocame_Greenscore_User_Actions_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_user_actions" */
  insert_zerocame_greenscore_user_actions_one?: Maybe<Zerocame_Greenscore_User_Actions>;
  /** insert data into the table: "zerocame.greenscore_users" */
  insert_zerocame_greenscore_users?: Maybe<Zerocame_Greenscore_Users_Mutation_Response>;
  /** insert a single row into the table: "zerocame.greenscore_users" */
  insert_zerocame_greenscore_users_one?: Maybe<Zerocame_Greenscore_Users>;
  /** insert data into the table: "zerocame.jma_area_master" */
  insert_zerocame_jma_area_master?: Maybe<Zerocame_Jma_Area_Master_Mutation_Response>;
  /** insert a single row into the table: "zerocame.jma_area_master" */
  insert_zerocame_jma_area_master_one?: Maybe<Zerocame_Jma_Area_Master>;
  /** insert data into the table: "zerocame.jma_meteorological_daily" */
  insert_zerocame_jma_meteorological_daily?: Maybe<Zerocame_Jma_Meteorological_Daily_Mutation_Response>;
  /** insert a single row into the table: "zerocame.jma_meteorological_daily" */
  insert_zerocame_jma_meteorological_daily_one?: Maybe<Zerocame_Jma_Meteorological_Daily>;
  /** insert data into the table: "zerocame.mst_co2_emission_coefficient" */
  insert_zerocame_mst_co2_emission_coefficient?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_co2_emission_coefficient" */
  insert_zerocame_mst_co2_emission_coefficient_one?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** insert data into the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  insert_zerocame_mst_co2_emission_monthly_benchmark?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  insert_zerocame_mst_co2_emission_monthly_benchmark_one?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** insert data into the table: "zerocame.mst_electric_company" */
  insert_zerocame_mst_electric_company?: Maybe<Zerocame_Mst_Electric_Company_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_electric_company" */
  insert_zerocame_mst_electric_company_one?: Maybe<Zerocame_Mst_Electric_Company>;
  /** insert data into the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  insert_zerocame_mst_energyscore_benchmark_coefficient?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  insert_zerocame_mst_energyscore_benchmark_coefficient_one?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** insert data into the table: "zerocame.mst_energyscore_daily_benchmark" */
  insert_zerocame_mst_energyscore_daily_benchmark?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_energyscore_daily_benchmark" */
  insert_zerocame_mst_energyscore_daily_benchmark_one?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** insert data into the table: "zerocame.mst_energyscore_difference_ratio" */
  insert_zerocame_mst_energyscore_difference_ratio?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_energyscore_difference_ratio" */
  insert_zerocame_mst_energyscore_difference_ratio_one?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** insert data into the table: "zerocame.mst_energyscore_user_attribute" */
  insert_zerocame_mst_energyscore_user_attribute?: Maybe<Zerocame_Mst_Energyscore_User_Attribute_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_energyscore_user_attribute" */
  insert_zerocame_mst_energyscore_user_attribute_one?: Maybe<Zerocame_Mst_Energyscore_User_Attribute>;
  /** insert data into the table: "zerocame.mst_prefecture_electric_company" */
  insert_zerocame_mst_prefecture_electric_company?: Maybe<Zerocame_Mst_Prefecture_Electric_Company_Mutation_Response>;
  /** insert a single row into the table: "zerocame.mst_prefecture_electric_company" */
  insert_zerocame_mst_prefecture_electric_company_one?: Maybe<Zerocame_Mst_Prefecture_Electric_Company>;
  /** insert data into the table: "zerocame.personal_electricity_data_30_minuites" */
  insert_zerocame_personal_electricity_data_30_minuites?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Mutation_Response>;
  /** insert a single row into the table: "zerocame.personal_electricity_data_30_minuites" */
  insert_zerocame_personal_electricity_data_30_minuites_one?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** insert data into the table: "zerocame.personal_electricity_data_daily" */
  insert_zerocame_personal_electricity_data_daily?: Maybe<Zerocame_Personal_Electricity_Data_Daily_Mutation_Response>;
  /** insert a single row into the table: "zerocame.personal_electricity_data_daily" */
  insert_zerocame_personal_electricity_data_daily_one?: Maybe<Zerocame_Personal_Electricity_Data_Daily>;
  /** insert data into the table: "zerocame.type_greenscore_detail_by_category" */
  insert_zerocame_type_greenscore_detail_by_category?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Mutation_Response>;
  /** insert a single row into the table: "zerocame.type_greenscore_detail_by_category" */
  insert_zerocame_type_greenscore_detail_by_category_one?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** insert data into the table: "zerocame.user_actions" */
  insert_zerocame_user_actions?: Maybe<Zerocame_User_Actions_Mutation_Response>;
  /** insert a single row into the table: "zerocame.user_actions" */
  insert_zerocame_user_actions_one?: Maybe<Zerocame_User_Actions>;
  /** insert data into the table: "zerocame.user_electricity_data_per_day" */
  insert_zerocame_user_electricity_data_per_day?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Mutation_Response>;
  /** insert a single row into the table: "zerocame.user_electricity_data_per_day" */
  insert_zerocame_user_electricity_data_per_day_one?: Maybe<Zerocame_User_Electricity_Data_Per_Day>;
  /** insert data into the table: "zerocame.users" */
  insert_zerocame_users?: Maybe<Zerocame_Users_Mutation_Response>;
  /** insert a single row into the table: "zerocame.users" */
  insert_zerocame_users_one?: Maybe<Zerocame_Users>;
  /** update data of the table: "zerocame.area_master" */
  update_zerocame_area_master?: Maybe<Zerocame_Area_Master_Mutation_Response>;
  /** update single row of the table: "zerocame.area_master" */
  update_zerocame_area_master_by_pk?: Maybe<Zerocame_Area_Master>;
  /** update multiples rows of table: "zerocame.area_master" */
  update_zerocame_area_master_many?: Maybe<Array<Maybe<Zerocame_Area_Master_Mutation_Response>>>;
  /** update data of the table: "zerocame.bingo_user_actions" */
  update_zerocame_bingo_user_actions?: Maybe<Zerocame_Bingo_User_Actions_Mutation_Response>;
  /** update single row of the table: "zerocame.bingo_user_actions" */
  update_zerocame_bingo_user_actions_by_pk?: Maybe<Zerocame_Bingo_User_Actions>;
  /** update multiples rows of table: "zerocame.bingo_user_actions" */
  update_zerocame_bingo_user_actions_many?: Maybe<Array<Maybe<Zerocame_Bingo_User_Actions_Mutation_Response>>>;
  /** update data of the table: "zerocame.bingo_users" */
  update_zerocame_bingo_users?: Maybe<Zerocame_Bingo_Users_Mutation_Response>;
  /** update single row of the table: "zerocame.bingo_users" */
  update_zerocame_bingo_users_by_pk?: Maybe<Zerocame_Bingo_Users>;
  /** update multiples rows of table: "zerocame.bingo_users" */
  update_zerocame_bingo_users_many?: Maybe<Array<Maybe<Zerocame_Bingo_Users_Mutation_Response>>>;
  /** update data of the table: "zerocame.consent_application_input" */
  update_zerocame_consent_application_input?: Maybe<Zerocame_Consent_Application_Input_Mutation_Response>;
  /** update single row of the table: "zerocame.consent_application_input" */
  update_zerocame_consent_application_input_by_pk?: Maybe<Zerocame_Consent_Application_Input>;
  /** update multiples rows of table: "zerocame.consent_application_input" */
  update_zerocame_consent_application_input_many?: Maybe<Array<Maybe<Zerocame_Consent_Application_Input_Mutation_Response>>>;
  /** update data of the table: "zerocame.consent_application_result" */
  update_zerocame_consent_application_result?: Maybe<Zerocame_Consent_Application_Result_Mutation_Response>;
  /** update single row of the table: "zerocame.consent_application_result" */
  update_zerocame_consent_application_result_by_pk?: Maybe<Zerocame_Consent_Application_Result>;
  /** update multiples rows of table: "zerocame.consent_application_result" */
  update_zerocame_consent_application_result_many?: Maybe<Array<Maybe<Zerocame_Consent_Application_Result_Mutation_Response>>>;
  /** update data of the table: "zerocame.consent_application_zeroca_analysis" */
  update_zerocame_consent_application_zeroca_analysis?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis_Mutation_Response>;
  /** update single row of the table: "zerocame.consent_application_zeroca_analysis" */
  update_zerocame_consent_application_zeroca_analysis_by_pk?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** update multiples rows of table: "zerocame.consent_application_zeroca_analysis" */
  update_zerocame_consent_application_zeroca_analysis_many?: Maybe<Array<Maybe<Zerocame_Consent_Application_Zeroca_Analysis_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore" */
  update_zerocame_greenscore?: Maybe<Zerocame_Greenscore_Mutation_Response>;
  /** update data of the table: "zerocame.greenscore_action_review" */
  update_zerocame_greenscore_action_review?: Maybe<Zerocame_Greenscore_Action_Review_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_action_review" */
  update_zerocame_greenscore_action_review_by_pk?: Maybe<Zerocame_Greenscore_Action_Review>;
  /** update data of the table: "zerocame.greenscore_action_review_detail" */
  update_zerocame_greenscore_action_review_detail?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_action_review_detail" */
  update_zerocame_greenscore_action_review_detail_by_pk?: Maybe<Zerocame_Greenscore_Action_Review_Detail>;
  /** update multiples rows of table: "zerocame.greenscore_action_review_detail" */
  update_zerocame_greenscore_action_review_detail_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Action_Review_Detail_Mutation_Response>>>;
  /** update multiples rows of table: "zerocame.greenscore_action_review" */
  update_zerocame_greenscore_action_review_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Action_Review_Mutation_Response>>>;
  /** update single row of the table: "zerocame.greenscore" */
  update_zerocame_greenscore_by_pk?: Maybe<Zerocame_Greenscore>;
  /** update data of the table: "zerocame.greenscore_calc" */
  update_zerocame_greenscore_calc?: Maybe<Zerocame_Greenscore_Calc_Mutation_Response>;
  /** update data of the table: "zerocame.greenscore_calc_action" */
  update_zerocame_greenscore_calc_action?: Maybe<Zerocame_Greenscore_Calc_Action_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_calc_action" */
  update_zerocame_greenscore_calc_action_by_pk?: Maybe<Zerocame_Greenscore_Calc_Action>;
  /** update multiples rows of table: "zerocame.greenscore_calc_action" */
  update_zerocame_greenscore_calc_action_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Calc_Action_Mutation_Response>>>;
  /** update single row of the table: "zerocame.greenscore_calc" */
  update_zerocame_greenscore_calc_by_pk?: Maybe<Zerocame_Greenscore_Calc>;
  /** update data of the table: "zerocame.greenscore_calc_energy" */
  update_zerocame_greenscore_calc_energy?: Maybe<Zerocame_Greenscore_Calc_Energy_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_calc_energy" */
  update_zerocame_greenscore_calc_energy_by_pk?: Maybe<Zerocame_Greenscore_Calc_Energy>;
  /** update multiples rows of table: "zerocame.greenscore_calc_energy" */
  update_zerocame_greenscore_calc_energy_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Calc_Energy_Mutation_Response>>>;
  /** update multiples rows of table: "zerocame.greenscore_calc" */
  update_zerocame_greenscore_calc_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Calc_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_challenge_code_master" */
  update_zerocame_greenscore_challenge_code_master?: Maybe<Zerocame_Greenscore_Challenge_Code_Master_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_challenge_code_master" */
  update_zerocame_greenscore_challenge_code_master_by_pk?: Maybe<Zerocame_Greenscore_Challenge_Code_Master>;
  /** update multiples rows of table: "zerocame.greenscore_challenge_code_master" */
  update_zerocame_greenscore_challenge_code_master_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Challenge_Code_Master_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_co2_emission" */
  update_zerocame_greenscore_co2_emission?: Maybe<Zerocame_Greenscore_Co2_Emission_Mutation_Response>;
  /** update data of the table: "zerocame.greenscore_co2_emission_action" */
  update_zerocame_greenscore_co2_emission_action?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_co2_emission_action" */
  update_zerocame_greenscore_co2_emission_action_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Action>;
  /** update multiples rows of table: "zerocame.greenscore_co2_emission_action" */
  update_zerocame_greenscore_co2_emission_action_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Co2_Emission_Action_Mutation_Response>>>;
  /** update single row of the table: "zerocame.greenscore_co2_emission" */
  update_zerocame_greenscore_co2_emission_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission>;
  /** update data of the table: "zerocame.greenscore_co2_emission_energy_bm" */
  update_zerocame_greenscore_co2_emission_energy_bm?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_co2_emission_energy_bm" */
  update_zerocame_greenscore_co2_emission_energy_bm_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** update multiples rows of table: "zerocame.greenscore_co2_emission_energy_bm" */
  update_zerocame_greenscore_co2_emission_energy_bm_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_co2_emission_energy_dr" */
  update_zerocame_greenscore_co2_emission_energy_dr?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_co2_emission_energy_dr" */
  update_zerocame_greenscore_co2_emission_energy_dr_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** update multiples rows of table: "zerocame.greenscore_co2_emission_energy_dr" */
  update_zerocame_greenscore_co2_emission_energy_dr_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Mutation_Response>>>;
  /** update multiples rows of table: "zerocame.greenscore_co2_emission" */
  update_zerocame_greenscore_co2_emission_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Co2_Emission_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_init_hearing" */
  update_zerocame_greenscore_init_hearing?: Maybe<Zerocame_Greenscore_Init_Hearing_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_init_hearing" */
  update_zerocame_greenscore_init_hearing_by_pk?: Maybe<Zerocame_Greenscore_Init_Hearing>;
  /** update multiples rows of table: "zerocame.greenscore_init_hearing" */
  update_zerocame_greenscore_init_hearing_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Init_Hearing_Mutation_Response>>>;
  /** update multiples rows of table: "zerocame.greenscore" */
  update_zerocame_greenscore_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_mission_achievement" */
  update_zerocame_greenscore_mission_achievement?: Maybe<Zerocame_Greenscore_Mission_Achievement_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_mission_achievement" */
  update_zerocame_greenscore_mission_achievement_by_pk?: Maybe<Zerocame_Greenscore_Mission_Achievement>;
  /** update multiples rows of table: "zerocame.greenscore_mission_achievement" */
  update_zerocame_greenscore_mission_achievement_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Mission_Achievement_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_referral_code" */
  update_zerocame_greenscore_referral_code?: Maybe<Zerocame_Greenscore_Referral_Code_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_referral_code" */
  update_zerocame_greenscore_referral_code_by_pk?: Maybe<Zerocame_Greenscore_Referral_Code>;
  /** update multiples rows of table: "zerocame.greenscore_referral_code" */
  update_zerocame_greenscore_referral_code_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Referral_Code_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_referral_result" */
  update_zerocame_greenscore_referral_result?: Maybe<Zerocame_Greenscore_Referral_Result_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_referral_result" */
  update_zerocame_greenscore_referral_result_by_pk?: Maybe<Zerocame_Greenscore_Referral_Result>;
  /** update multiples rows of table: "zerocame.greenscore_referral_result" */
  update_zerocame_greenscore_referral_result_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Referral_Result_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_user_actions" */
  update_zerocame_greenscore_user_actions?: Maybe<Zerocame_Greenscore_User_Actions_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_user_actions" */
  update_zerocame_greenscore_user_actions_by_pk?: Maybe<Zerocame_Greenscore_User_Actions>;
  /** update multiples rows of table: "zerocame.greenscore_user_actions" */
  update_zerocame_greenscore_user_actions_many?: Maybe<Array<Maybe<Zerocame_Greenscore_User_Actions_Mutation_Response>>>;
  /** update data of the table: "zerocame.greenscore_users" */
  update_zerocame_greenscore_users?: Maybe<Zerocame_Greenscore_Users_Mutation_Response>;
  /** update single row of the table: "zerocame.greenscore_users" */
  update_zerocame_greenscore_users_by_pk?: Maybe<Zerocame_Greenscore_Users>;
  /** update multiples rows of table: "zerocame.greenscore_users" */
  update_zerocame_greenscore_users_many?: Maybe<Array<Maybe<Zerocame_Greenscore_Users_Mutation_Response>>>;
  /** update data of the table: "zerocame.jma_area_master" */
  update_zerocame_jma_area_master?: Maybe<Zerocame_Jma_Area_Master_Mutation_Response>;
  /** update single row of the table: "zerocame.jma_area_master" */
  update_zerocame_jma_area_master_by_pk?: Maybe<Zerocame_Jma_Area_Master>;
  /** update multiples rows of table: "zerocame.jma_area_master" */
  update_zerocame_jma_area_master_many?: Maybe<Array<Maybe<Zerocame_Jma_Area_Master_Mutation_Response>>>;
  /** update data of the table: "zerocame.jma_meteorological_daily" */
  update_zerocame_jma_meteorological_daily?: Maybe<Zerocame_Jma_Meteorological_Daily_Mutation_Response>;
  /** update single row of the table: "zerocame.jma_meteorological_daily" */
  update_zerocame_jma_meteorological_daily_by_pk?: Maybe<Zerocame_Jma_Meteorological_Daily>;
  /** update multiples rows of table: "zerocame.jma_meteorological_daily" */
  update_zerocame_jma_meteorological_daily_many?: Maybe<Array<Maybe<Zerocame_Jma_Meteorological_Daily_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_co2_emission_coefficient" */
  update_zerocame_mst_co2_emission_coefficient?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_co2_emission_coefficient" */
  update_zerocame_mst_co2_emission_coefficient_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** update multiples rows of table: "zerocame.mst_co2_emission_coefficient" */
  update_zerocame_mst_co2_emission_coefficient_many?: Maybe<Array<Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  update_zerocame_mst_co2_emission_monthly_benchmark?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  update_zerocame_mst_co2_emission_monthly_benchmark_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** update multiples rows of table: "zerocame.mst_co2_emission_monthly_benchmark" */
  update_zerocame_mst_co2_emission_monthly_benchmark_many?: Maybe<Array<Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_electric_company" */
  update_zerocame_mst_electric_company?: Maybe<Zerocame_Mst_Electric_Company_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_electric_company" */
  update_zerocame_mst_electric_company_by_pk?: Maybe<Zerocame_Mst_Electric_Company>;
  /** update multiples rows of table: "zerocame.mst_electric_company" */
  update_zerocame_mst_electric_company_many?: Maybe<Array<Maybe<Zerocame_Mst_Electric_Company_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  update_zerocame_mst_energyscore_benchmark_coefficient?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  update_zerocame_mst_energyscore_benchmark_coefficient_by_pk?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** update multiples rows of table: "zerocame.mst_energyscore_benchmark_coefficient" */
  update_zerocame_mst_energyscore_benchmark_coefficient_many?: Maybe<Array<Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_energyscore_daily_benchmark" */
  update_zerocame_mst_energyscore_daily_benchmark?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_energyscore_daily_benchmark" */
  update_zerocame_mst_energyscore_daily_benchmark_by_pk?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** update multiples rows of table: "zerocame.mst_energyscore_daily_benchmark" */
  update_zerocame_mst_energyscore_daily_benchmark_many?: Maybe<Array<Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_energyscore_difference_ratio" */
  update_zerocame_mst_energyscore_difference_ratio?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_energyscore_difference_ratio" */
  update_zerocame_mst_energyscore_difference_ratio_by_pk?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** update multiples rows of table: "zerocame.mst_energyscore_difference_ratio" */
  update_zerocame_mst_energyscore_difference_ratio_many?: Maybe<Array<Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_energyscore_user_attribute" */
  update_zerocame_mst_energyscore_user_attribute?: Maybe<Zerocame_Mst_Energyscore_User_Attribute_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_energyscore_user_attribute" */
  update_zerocame_mst_energyscore_user_attribute_by_pk?: Maybe<Zerocame_Mst_Energyscore_User_Attribute>;
  /** update multiples rows of table: "zerocame.mst_energyscore_user_attribute" */
  update_zerocame_mst_energyscore_user_attribute_many?: Maybe<Array<Maybe<Zerocame_Mst_Energyscore_User_Attribute_Mutation_Response>>>;
  /** update data of the table: "zerocame.mst_prefecture_electric_company" */
  update_zerocame_mst_prefecture_electric_company?: Maybe<Zerocame_Mst_Prefecture_Electric_Company_Mutation_Response>;
  /** update single row of the table: "zerocame.mst_prefecture_electric_company" */
  update_zerocame_mst_prefecture_electric_company_by_pk?: Maybe<Zerocame_Mst_Prefecture_Electric_Company>;
  /** update multiples rows of table: "zerocame.mst_prefecture_electric_company" */
  update_zerocame_mst_prefecture_electric_company_many?: Maybe<Array<Maybe<Zerocame_Mst_Prefecture_Electric_Company_Mutation_Response>>>;
  /** update data of the table: "zerocame.personal_electricity_data_30_minuites" */
  update_zerocame_personal_electricity_data_30_minuites?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Mutation_Response>;
  /** update single row of the table: "zerocame.personal_electricity_data_30_minuites" */
  update_zerocame_personal_electricity_data_30_minuites_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** update multiples rows of table: "zerocame.personal_electricity_data_30_minuites" */
  update_zerocame_personal_electricity_data_30_minuites_many?: Maybe<Array<Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Mutation_Response>>>;
  /** update data of the table: "zerocame.personal_electricity_data_daily" */
  update_zerocame_personal_electricity_data_daily?: Maybe<Zerocame_Personal_Electricity_Data_Daily_Mutation_Response>;
  /** update single row of the table: "zerocame.personal_electricity_data_daily" */
  update_zerocame_personal_electricity_data_daily_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_Daily>;
  /** update multiples rows of table: "zerocame.personal_electricity_data_daily" */
  update_zerocame_personal_electricity_data_daily_many?: Maybe<Array<Maybe<Zerocame_Personal_Electricity_Data_Daily_Mutation_Response>>>;
  /** update data of the table: "zerocame.type_greenscore_detail_by_category" */
  update_zerocame_type_greenscore_detail_by_category?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Mutation_Response>;
  /** update single row of the table: "zerocame.type_greenscore_detail_by_category" */
  update_zerocame_type_greenscore_detail_by_category_by_pk?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** update multiples rows of table: "zerocame.type_greenscore_detail_by_category" */
  update_zerocame_type_greenscore_detail_by_category_many?: Maybe<Array<Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Mutation_Response>>>;
  /** update data of the table: "zerocame.user_actions" */
  update_zerocame_user_actions?: Maybe<Zerocame_User_Actions_Mutation_Response>;
  /** update single row of the table: "zerocame.user_actions" */
  update_zerocame_user_actions_by_pk?: Maybe<Zerocame_User_Actions>;
  /** update multiples rows of table: "zerocame.user_actions" */
  update_zerocame_user_actions_many?: Maybe<Array<Maybe<Zerocame_User_Actions_Mutation_Response>>>;
  /** update data of the table: "zerocame.user_electricity_data_per_day" */
  update_zerocame_user_electricity_data_per_day?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Mutation_Response>;
  /** update single row of the table: "zerocame.user_electricity_data_per_day" */
  update_zerocame_user_electricity_data_per_day_by_pk?: Maybe<Zerocame_User_Electricity_Data_Per_Day>;
  /** update multiples rows of table: "zerocame.user_electricity_data_per_day" */
  update_zerocame_user_electricity_data_per_day_many?: Maybe<Array<Maybe<Zerocame_User_Electricity_Data_Per_Day_Mutation_Response>>>;
  /** update data of the table: "zerocame.users" */
  update_zerocame_users?: Maybe<Zerocame_Users_Mutation_Response>;
  /** update single row of the table: "zerocame.users" */
  update_zerocame_users_by_pk?: Maybe<Zerocame_Users>;
  /** update multiples rows of table: "zerocame.users" */
  update_zerocame_users_many?: Maybe<Array<Maybe<Zerocame_Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Area_MasterArgs = {
  where: Zerocame_Area_Master_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Area_Master_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Bingo_User_ActionsArgs = {
  where: Zerocame_Bingo_User_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Bingo_User_Actions_By_PkArgs = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Bingo_UsersArgs = {
  where: Zerocame_Bingo_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Bingo_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Consent_Application_InputArgs = {
  where: Zerocame_Consent_Application_Input_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Consent_Application_Input_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Consent_Application_ResultArgs = {
  where: Zerocame_Consent_Application_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Consent_Application_Result_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Consent_Application_Zeroca_AnalysisArgs = {
  where: Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Consent_Application_Zeroca_Analysis_By_PkArgs = {
  consent_info_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_GreenscoreArgs = {
  where: Zerocame_Greenscore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Action_ReviewArgs = {
  where: Zerocame_Greenscore_Action_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Action_Review_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Action_Review_DetailArgs = {
  where: Zerocame_Greenscore_Action_Review_Detail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Action_Review_Detail_By_PkArgs = {
  greenscore_action_id: Scalars['String'];
  review_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_CalcArgs = {
  where: Zerocame_Greenscore_Calc_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Calc_ActionArgs = {
  where: Zerocame_Greenscore_Calc_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Calc_Action_By_PkArgs = {
  greenscore_action_id: Scalars['String'];
  greenscore_calc_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Calc_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Calc_EnergyArgs = {
  where: Zerocame_Greenscore_Calc_Energy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Calc_Energy_By_PkArgs = {
  greenscore_calc_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Challenge_Code_MasterArgs = {
  where: Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Challenge_Code_Master_By_PkArgs = {
  challenge_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_EmissionArgs = {
  where: Zerocame_Greenscore_Co2_Emission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_Emission_ActionArgs = {
  where: Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_Emission_Action_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
  year_month: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_Emission_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_Emission_Energy_BmArgs = {
  where: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_Emission_Energy_Bm_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_Emission_Energy_DrArgs = {
  where: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Co2_Emission_Energy_Dr_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Init_HearingArgs = {
  where: Zerocame_Greenscore_Init_Hearing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Init_Hearing_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  hearing_no: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Mission_AchievementArgs = {
  where: Zerocame_Greenscore_Mission_Achievement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Mission_Achievement_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  challenge_id: Scalars['String'];
  mission_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Referral_CodeArgs = {
  where: Zerocame_Greenscore_Referral_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Referral_Code_By_PkArgs = {
  referral_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Referral_ResultArgs = {
  where: Zerocame_Greenscore_Referral_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Referral_Result_By_PkArgs = {
  referred_auth0_user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_User_ActionsArgs = {
  where: Zerocame_Greenscore_User_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_User_Actions_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_UsersArgs = {
  where: Zerocame_Greenscore_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Greenscore_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Jma_Area_MasterArgs = {
  where: Zerocame_Jma_Area_Master_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Jma_Area_Master_By_PkArgs = {
  block_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Jma_Meteorological_DailyArgs = {
  where: Zerocame_Jma_Meteorological_Daily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Jma_Meteorological_Daily_By_PkArgs = {
  block_code: Scalars['String'];
  date: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Co2_Emission_CoefficientArgs = {
  where: Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Co2_Emission_Coefficient_By_PkArgs = {
  regist_no: Scalars['String'];
  year_month: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Co2_Emission_Monthly_BenchmarkArgs = {
  where: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Co2_Emission_Monthly_Benchmark_By_PkArgs = {
  attribute_no: Scalars['String'];
  year_month: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Electric_CompanyArgs = {
  where: Zerocame_Mst_Electric_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Electric_Company_By_PkArgs = {
  regist_no: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_Benchmark_CoefficientArgs = {
  where: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_Benchmark_Coefficient_By_PkArgs = {
  attribute_item: Scalars['String'];
  attribute_value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_Daily_BenchmarkArgs = {
  where: Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_Daily_Benchmark_By_PkArgs = {
  attribute_no: Scalars['String'];
  date: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_Difference_RatioArgs = {
  where: Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_Difference_Ratio_By_PkArgs = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_User_AttributeArgs = {
  where: Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Energyscore_User_Attribute_By_PkArgs = {
  no: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Prefecture_Electric_CompanyArgs = {
  where: Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Mst_Prefecture_Electric_Company_By_PkArgs = {
  pref_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Personal_Electricity_Data_30_MinuitesArgs = {
  where: Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Personal_Electricity_Data_30_Minuites_By_PkArgs = {
  consumer_spcfc_id: Scalars['String'];
  get_ymd: Scalars['String'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
  time_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Personal_Electricity_Data_DailyArgs = {
  where: Zerocame_Personal_Electricity_Data_Daily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Personal_Electricity_Data_Daily_By_PkArgs = {
  consumer_spcfc_id: Scalars['String'];
  get_ymd: Scalars['String'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Type_Greenscore_Detail_By_CategoryArgs = {
  where: Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Type_Greenscore_Detail_By_Category_By_PkArgs = {
  category_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_User_ActionsArgs = {
  where: Zerocame_User_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_User_Actions_By_PkArgs = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_User_Electricity_Data_Per_DayArgs = {
  where: Zerocame_User_Electricity_Data_Per_Day_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_User_Electricity_Data_Per_Day_By_PkArgs = {
  consent_application_result_id: Scalars['Int'];
  get_ymd: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_UsersArgs = {
  where: Zerocame_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Zerocame_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Area_MasterArgs = {
  objects: Array<Zerocame_Area_Master_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Area_Master_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Area_Master_OneArgs = {
  object: Zerocame_Area_Master_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Area_Master_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Bingo_User_ActionsArgs = {
  objects: Array<Zerocame_Bingo_User_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Bingo_User_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Bingo_User_Actions_OneArgs = {
  object: Zerocame_Bingo_User_Actions_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Bingo_User_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Bingo_UsersArgs = {
  objects: Array<Zerocame_Bingo_Users_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Bingo_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Bingo_Users_OneArgs = {
  object: Zerocame_Bingo_Users_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Bingo_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Consent_Application_InputArgs = {
  objects: Array<Zerocame_Consent_Application_Input_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Input_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Consent_Application_Input_OneArgs = {
  object: Zerocame_Consent_Application_Input_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Input_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Consent_Application_ResultArgs = {
  objects: Array<Zerocame_Consent_Application_Result_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Consent_Application_Result_OneArgs = {
  object: Zerocame_Consent_Application_Result_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Consent_Application_Zeroca_AnalysisArgs = {
  objects: Array<Zerocame_Consent_Application_Zeroca_Analysis_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Consent_Application_Zeroca_Analysis_OneArgs = {
  object: Zerocame_Consent_Application_Zeroca_Analysis_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_GreenscoreArgs = {
  objects: Array<Zerocame_Greenscore_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Action_ReviewArgs = {
  objects: Array<Zerocame_Greenscore_Action_Review_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Action_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Action_Review_DetailArgs = {
  objects: Array<Zerocame_Greenscore_Action_Review_Detail_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Action_Review_Detail_OneArgs = {
  object: Zerocame_Greenscore_Action_Review_Detail_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Action_Review_OneArgs = {
  object: Zerocame_Greenscore_Action_Review_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Action_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_CalcArgs = {
  objects: Array<Zerocame_Greenscore_Calc_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Calc_ActionArgs = {
  objects: Array<Zerocame_Greenscore_Calc_Action_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Calc_Action_OneArgs = {
  object: Zerocame_Greenscore_Calc_Action_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Calc_EnergyArgs = {
  objects: Array<Zerocame_Greenscore_Calc_Energy_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_Energy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Calc_Energy_OneArgs = {
  object: Zerocame_Greenscore_Calc_Energy_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_Energy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Calc_OneArgs = {
  object: Zerocame_Greenscore_Calc_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Challenge_Code_MasterArgs = {
  objects: Array<Zerocame_Greenscore_Challenge_Code_Master_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Challenge_Code_Master_OneArgs = {
  object: Zerocame_Greenscore_Challenge_Code_Master_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_EmissionArgs = {
  objects: Array<Zerocame_Greenscore_Co2_Emission_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_Emission_ActionArgs = {
  objects: Array<Zerocame_Greenscore_Co2_Emission_Action_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_Emission_Action_OneArgs = {
  object: Zerocame_Greenscore_Co2_Emission_Action_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_Emission_Energy_BmArgs = {
  objects: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_Emission_Energy_Bm_OneArgs = {
  object: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_Emission_Energy_DrArgs = {
  objects: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_Emission_Energy_Dr_OneArgs = {
  object: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Co2_Emission_OneArgs = {
  object: Zerocame_Greenscore_Co2_Emission_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Co2_Emission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Init_HearingArgs = {
  objects: Array<Zerocame_Greenscore_Init_Hearing_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Init_Hearing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Init_Hearing_OneArgs = {
  object: Zerocame_Greenscore_Init_Hearing_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Init_Hearing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Mission_AchievementArgs = {
  objects: Array<Zerocame_Greenscore_Mission_Achievement_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Mission_Achievement_OneArgs = {
  object: Zerocame_Greenscore_Mission_Achievement_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_OneArgs = {
  object: Zerocame_Greenscore_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Referral_CodeArgs = {
  objects: Array<Zerocame_Greenscore_Referral_Code_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Referral_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Referral_Code_OneArgs = {
  object: Zerocame_Greenscore_Referral_Code_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Referral_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Referral_ResultArgs = {
  objects: Array<Zerocame_Greenscore_Referral_Result_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Referral_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Referral_Result_OneArgs = {
  object: Zerocame_Greenscore_Referral_Result_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Referral_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_User_ActionsArgs = {
  objects: Array<Zerocame_Greenscore_User_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_User_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_User_Actions_OneArgs = {
  object: Zerocame_Greenscore_User_Actions_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_User_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_UsersArgs = {
  objects: Array<Zerocame_Greenscore_Users_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Greenscore_Users_OneArgs = {
  object: Zerocame_Greenscore_Users_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Greenscore_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Jma_Area_MasterArgs = {
  objects: Array<Zerocame_Jma_Area_Master_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Jma_Area_Master_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Jma_Area_Master_OneArgs = {
  object: Zerocame_Jma_Area_Master_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Jma_Area_Master_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Jma_Meteorological_DailyArgs = {
  objects: Array<Zerocame_Jma_Meteorological_Daily_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Jma_Meteorological_Daily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Jma_Meteorological_Daily_OneArgs = {
  object: Zerocame_Jma_Meteorological_Daily_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Jma_Meteorological_Daily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Co2_Emission_CoefficientArgs = {
  objects: Array<Zerocame_Mst_Co2_Emission_Coefficient_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Co2_Emission_Coefficient_OneArgs = {
  object: Zerocame_Mst_Co2_Emission_Coefficient_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Co2_Emission_Monthly_BenchmarkArgs = {
  objects: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Co2_Emission_Monthly_Benchmark_OneArgs = {
  object: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Electric_CompanyArgs = {
  objects: Array<Zerocame_Mst_Electric_Company_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Electric_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Electric_Company_OneArgs = {
  object: Zerocame_Mst_Electric_Company_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Electric_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_Benchmark_CoefficientArgs = {
  objects: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_Benchmark_Coefficient_OneArgs = {
  object: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_Daily_BenchmarkArgs = {
  objects: Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_Daily_Benchmark_OneArgs = {
  object: Zerocame_Mst_Energyscore_Daily_Benchmark_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_Difference_RatioArgs = {
  objects: Array<Zerocame_Mst_Energyscore_Difference_Ratio_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_Difference_Ratio_OneArgs = {
  object: Zerocame_Mst_Energyscore_Difference_Ratio_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_User_AttributeArgs = {
  objects: Array<Zerocame_Mst_Energyscore_User_Attribute_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Energyscore_User_Attribute_OneArgs = {
  object: Zerocame_Mst_Energyscore_User_Attribute_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Prefecture_Electric_CompanyArgs = {
  objects: Array<Zerocame_Mst_Prefecture_Electric_Company_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Mst_Prefecture_Electric_Company_OneArgs = {
  object: Zerocame_Mst_Prefecture_Electric_Company_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Personal_Electricity_Data_30_MinuitesArgs = {
  objects: Array<Zerocame_Personal_Electricity_Data_30_Minuites_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Personal_Electricity_Data_30_Minuites_OneArgs = {
  object: Zerocame_Personal_Electricity_Data_30_Minuites_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Personal_Electricity_Data_DailyArgs = {
  objects: Array<Zerocame_Personal_Electricity_Data_Daily_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Personal_Electricity_Data_Daily_OneArgs = {
  object: Zerocame_Personal_Electricity_Data_Daily_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Type_Greenscore_Detail_By_CategoryArgs = {
  objects: Array<Zerocame_Type_Greenscore_Detail_By_Category_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Type_Greenscore_Detail_By_Category_OneArgs = {
  object: Zerocame_Type_Greenscore_Detail_By_Category_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_User_ActionsArgs = {
  objects: Array<Zerocame_User_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_User_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_User_Actions_OneArgs = {
  object: Zerocame_User_Actions_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_User_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_User_Electricity_Data_Per_DayArgs = {
  objects: Array<Zerocame_User_Electricity_Data_Per_Day_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_User_Electricity_Data_Per_Day_OneArgs = {
  object: Zerocame_User_Electricity_Data_Per_Day_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_UsersArgs = {
  objects: Array<Zerocame_Users_Insert_Input>;
  on_conflict?: InputMaybe<Zerocame_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Zerocame_Users_OneArgs = {
  object: Zerocame_Users_Insert_Input;
  on_conflict?: InputMaybe<Zerocame_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Area_MasterArgs = {
  _set?: InputMaybe<Zerocame_Area_Master_Set_Input>;
  where: Zerocame_Area_Master_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Area_Master_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Area_Master_Set_Input>;
  pk_columns: Zerocame_Area_Master_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Area_Master_ManyArgs = {
  updates: Array<Zerocame_Area_Master_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Bingo_User_ActionsArgs = {
  _inc?: InputMaybe<Zerocame_Bingo_User_Actions_Inc_Input>;
  _set?: InputMaybe<Zerocame_Bingo_User_Actions_Set_Input>;
  where: Zerocame_Bingo_User_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Bingo_User_Actions_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Bingo_User_Actions_Inc_Input>;
  _set?: InputMaybe<Zerocame_Bingo_User_Actions_Set_Input>;
  pk_columns: Zerocame_Bingo_User_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Bingo_User_Actions_ManyArgs = {
  updates: Array<Zerocame_Bingo_User_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Bingo_UsersArgs = {
  _set?: InputMaybe<Zerocame_Bingo_Users_Set_Input>;
  where: Zerocame_Bingo_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Bingo_Users_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Bingo_Users_Set_Input>;
  pk_columns: Zerocame_Bingo_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Bingo_Users_ManyArgs = {
  updates: Array<Zerocame_Bingo_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_InputArgs = {
  _set?: InputMaybe<Zerocame_Consent_Application_Input_Set_Input>;
  where: Zerocame_Consent_Application_Input_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_Input_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Consent_Application_Input_Set_Input>;
  pk_columns: Zerocame_Consent_Application_Input_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_Input_ManyArgs = {
  updates: Array<Zerocame_Consent_Application_Input_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_ResultArgs = {
  _inc?: InputMaybe<Zerocame_Consent_Application_Result_Inc_Input>;
  _set?: InputMaybe<Zerocame_Consent_Application_Result_Set_Input>;
  where: Zerocame_Consent_Application_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_Result_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Consent_Application_Result_Inc_Input>;
  _set?: InputMaybe<Zerocame_Consent_Application_Result_Set_Input>;
  pk_columns: Zerocame_Consent_Application_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_Result_ManyArgs = {
  updates: Array<Zerocame_Consent_Application_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_Zeroca_AnalysisArgs = {
  _set?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Set_Input>;
  where: Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_Zeroca_Analysis_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Set_Input>;
  pk_columns: Zerocame_Consent_Application_Zeroca_Analysis_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Consent_Application_Zeroca_Analysis_ManyArgs = {
  updates: Array<Zerocame_Consent_Application_Zeroca_Analysis_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_GreenscoreArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Set_Input>;
  where: Zerocame_Greenscore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Action_ReviewArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Action_Review_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Action_Review_Set_Input>;
  where: Zerocame_Greenscore_Action_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Action_Review_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Action_Review_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Action_Review_Set_Input>;
  pk_columns: Zerocame_Greenscore_Action_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Action_Review_DetailArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Set_Input>;
  where: Zerocame_Greenscore_Action_Review_Detail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Action_Review_Detail_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Set_Input>;
  pk_columns: Zerocame_Greenscore_Action_Review_Detail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Action_Review_Detail_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Action_Review_Detail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Action_Review_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Action_Review_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Set_Input>;
  pk_columns: Zerocame_Greenscore_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_CalcArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Set_Input>;
  where: Zerocame_Greenscore_Calc_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_ActionArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Action_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Action_Set_Input>;
  where: Zerocame_Greenscore_Calc_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_Action_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Action_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Action_Set_Input>;
  pk_columns: Zerocame_Greenscore_Calc_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_Action_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Calc_Action_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Set_Input>;
  pk_columns: Zerocame_Greenscore_Calc_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_EnergyArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Set_Input>;
  where: Zerocame_Greenscore_Calc_Energy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_Energy_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Set_Input>;
  pk_columns: Zerocame_Greenscore_Calc_Energy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_Energy_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Calc_Energy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Calc_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Calc_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Challenge_Code_MasterArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Set_Input>;
  where: Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Challenge_Code_Master_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Set_Input>;
  pk_columns: Zerocame_Greenscore_Challenge_Code_Master_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Challenge_Code_Master_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Challenge_Code_Master_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_EmissionArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Set_Input>;
  where: Zerocame_Greenscore_Co2_Emission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_ActionArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Set_Input>;
  where: Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Action_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Set_Input>;
  pk_columns: Zerocame_Greenscore_Co2_Emission_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Action_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Co2_Emission_Action_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Set_Input>;
  pk_columns: Zerocame_Greenscore_Co2_Emission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Energy_BmArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Set_Input>;
  where: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Energy_Bm_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Set_Input>;
  pk_columns: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Energy_Bm_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Energy_DrArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Set_Input>;
  where: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Energy_Dr_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Set_Input>;
  pk_columns: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_Energy_Dr_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Co2_Emission_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Co2_Emission_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Init_HearingArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Set_Input>;
  where: Zerocame_Greenscore_Init_Hearing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Init_Hearing_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Inc_Input>;
  _set?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Set_Input>;
  pk_columns: Zerocame_Greenscore_Init_Hearing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Init_Hearing_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Init_Hearing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Mission_AchievementArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Set_Input>;
  where: Zerocame_Greenscore_Mission_Achievement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Mission_Achievement_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Set_Input>;
  pk_columns: Zerocame_Greenscore_Mission_Achievement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Mission_Achievement_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Mission_Achievement_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Referral_CodeArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Referral_Code_Set_Input>;
  where: Zerocame_Greenscore_Referral_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Referral_Code_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Referral_Code_Set_Input>;
  pk_columns: Zerocame_Greenscore_Referral_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Referral_Code_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Referral_Code_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Referral_ResultArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Referral_Result_Set_Input>;
  where: Zerocame_Greenscore_Referral_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Referral_Result_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Referral_Result_Set_Input>;
  pk_columns: Zerocame_Greenscore_Referral_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Referral_Result_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Referral_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_User_ActionsArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_User_Actions_Set_Input>;
  where: Zerocame_Greenscore_User_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_User_Actions_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_User_Actions_Set_Input>;
  pk_columns: Zerocame_Greenscore_User_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_User_Actions_ManyArgs = {
  updates: Array<Zerocame_Greenscore_User_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_UsersArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Users_Set_Input>;
  where: Zerocame_Greenscore_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Users_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Greenscore_Users_Set_Input>;
  pk_columns: Zerocame_Greenscore_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Greenscore_Users_ManyArgs = {
  updates: Array<Zerocame_Greenscore_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Jma_Area_MasterArgs = {
  _set?: InputMaybe<Zerocame_Jma_Area_Master_Set_Input>;
  where: Zerocame_Jma_Area_Master_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Jma_Area_Master_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Jma_Area_Master_Set_Input>;
  pk_columns: Zerocame_Jma_Area_Master_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Jma_Area_Master_ManyArgs = {
  updates: Array<Zerocame_Jma_Area_Master_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Jma_Meteorological_DailyArgs = {
  _inc?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Inc_Input>;
  _set?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Set_Input>;
  where: Zerocame_Jma_Meteorological_Daily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Jma_Meteorological_Daily_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Inc_Input>;
  _set?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Set_Input>;
  pk_columns: Zerocame_Jma_Meteorological_Daily_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Jma_Meteorological_Daily_ManyArgs = {
  updates: Array<Zerocame_Jma_Meteorological_Daily_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Co2_Emission_CoefficientArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Set_Input>;
  where: Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Co2_Emission_Coefficient_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Set_Input>;
  pk_columns: Zerocame_Mst_Co2_Emission_Coefficient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Co2_Emission_Coefficient_ManyArgs = {
  updates: Array<Zerocame_Mst_Co2_Emission_Coefficient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Co2_Emission_Monthly_BenchmarkArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Set_Input>;
  where: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Co2_Emission_Monthly_Benchmark_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Set_Input>;
  pk_columns: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Co2_Emission_Monthly_Benchmark_ManyArgs = {
  updates: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Electric_CompanyArgs = {
  _set?: InputMaybe<Zerocame_Mst_Electric_Company_Set_Input>;
  where: Zerocame_Mst_Electric_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Electric_Company_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Mst_Electric_Company_Set_Input>;
  pk_columns: Zerocame_Mst_Electric_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Electric_Company_ManyArgs = {
  updates: Array<Zerocame_Mst_Electric_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Benchmark_CoefficientArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Set_Input>;
  where: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Benchmark_Coefficient_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Set_Input>;
  pk_columns: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Benchmark_Coefficient_ManyArgs = {
  updates: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Daily_BenchmarkArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Set_Input>;
  where: Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Daily_Benchmark_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Set_Input>;
  pk_columns: Zerocame_Mst_Energyscore_Daily_Benchmark_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Daily_Benchmark_ManyArgs = {
  updates: Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Difference_RatioArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Set_Input>;
  where: Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Difference_Ratio_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Inc_Input>;
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Set_Input>;
  pk_columns: Zerocame_Mst_Energyscore_Difference_Ratio_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_Difference_Ratio_ManyArgs = {
  updates: Array<Zerocame_Mst_Energyscore_Difference_Ratio_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_User_AttributeArgs = {
  _set?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Set_Input>;
  where: Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_User_Attribute_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Set_Input>;
  pk_columns: Zerocame_Mst_Energyscore_User_Attribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Energyscore_User_Attribute_ManyArgs = {
  updates: Array<Zerocame_Mst_Energyscore_User_Attribute_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Prefecture_Electric_CompanyArgs = {
  _set?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Set_Input>;
  where: Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Prefecture_Electric_Company_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Set_Input>;
  pk_columns: Zerocame_Mst_Prefecture_Electric_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Mst_Prefecture_Electric_Company_ManyArgs = {
  updates: Array<Zerocame_Mst_Prefecture_Electric_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Personal_Electricity_Data_30_MinuitesArgs = {
  _inc?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Inc_Input>;
  _set?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Set_Input>;
  where: Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Personal_Electricity_Data_30_Minuites_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Inc_Input>;
  _set?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Set_Input>;
  pk_columns: Zerocame_Personal_Electricity_Data_30_Minuites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Personal_Electricity_Data_30_Minuites_ManyArgs = {
  updates: Array<Zerocame_Personal_Electricity_Data_30_Minuites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Personal_Electricity_Data_DailyArgs = {
  _set?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Set_Input>;
  where: Zerocame_Personal_Electricity_Data_Daily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Personal_Electricity_Data_Daily_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Set_Input>;
  pk_columns: Zerocame_Personal_Electricity_Data_Daily_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Personal_Electricity_Data_Daily_ManyArgs = {
  updates: Array<Zerocame_Personal_Electricity_Data_Daily_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Type_Greenscore_Detail_By_CategoryArgs = {
  _inc?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Inc_Input>;
  _set?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Set_Input>;
  where: Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Type_Greenscore_Detail_By_Category_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Inc_Input>;
  _set?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Set_Input>;
  pk_columns: Zerocame_Type_Greenscore_Detail_By_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Type_Greenscore_Detail_By_Category_ManyArgs = {
  updates: Array<Zerocame_Type_Greenscore_Detail_By_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_User_ActionsArgs = {
  _inc?: InputMaybe<Zerocame_User_Actions_Inc_Input>;
  _set?: InputMaybe<Zerocame_User_Actions_Set_Input>;
  where: Zerocame_User_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_User_Actions_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_User_Actions_Inc_Input>;
  _set?: InputMaybe<Zerocame_User_Actions_Set_Input>;
  pk_columns: Zerocame_User_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_User_Actions_ManyArgs = {
  updates: Array<Zerocame_User_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_User_Electricity_Data_Per_DayArgs = {
  _inc?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Inc_Input>;
  _set?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Set_Input>;
  where: Zerocame_User_Electricity_Data_Per_Day_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_User_Electricity_Data_Per_Day_By_PkArgs = {
  _inc?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Inc_Input>;
  _set?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Set_Input>;
  pk_columns: Zerocame_User_Electricity_Data_Per_Day_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_User_Electricity_Data_Per_Day_ManyArgs = {
  updates: Array<Zerocame_User_Electricity_Data_Per_Day_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_UsersArgs = {
  _set?: InputMaybe<Zerocame_Users_Set_Input>;
  where: Zerocame_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Users_By_PkArgs = {
  _set?: InputMaybe<Zerocame_Users_Set_Input>;
  pk_columns: Zerocame_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Zerocame_Users_ManyArgs = {
  updates: Array<Zerocame_Users_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "zerocame.area_master" */
  zerocame_area_master: Array<Zerocame_Area_Master>;
  /** fetch aggregated fields from the table: "zerocame.area_master" */
  zerocame_area_master_aggregate: Zerocame_Area_Master_Aggregate;
  /** fetch data from the table: "zerocame.area_master" using primary key columns */
  zerocame_area_master_by_pk?: Maybe<Zerocame_Area_Master>;
  /** fetch data from the table: "zerocame.bingo_user_actions" */
  zerocame_bingo_user_actions: Array<Zerocame_Bingo_User_Actions>;
  /** fetch aggregated fields from the table: "zerocame.bingo_user_actions" */
  zerocame_bingo_user_actions_aggregate: Zerocame_Bingo_User_Actions_Aggregate;
  /** fetch data from the table: "zerocame.bingo_user_actions" using primary key columns */
  zerocame_bingo_user_actions_by_pk?: Maybe<Zerocame_Bingo_User_Actions>;
  /** fetch data from the table: "zerocame.bingo_users" */
  zerocame_bingo_users: Array<Zerocame_Bingo_Users>;
  /** fetch aggregated fields from the table: "zerocame.bingo_users" */
  zerocame_bingo_users_aggregate: Zerocame_Bingo_Users_Aggregate;
  /** fetch data from the table: "zerocame.bingo_users" using primary key columns */
  zerocame_bingo_users_by_pk?: Maybe<Zerocame_Bingo_Users>;
  /** fetch data from the table: "zerocame.consent_application_input" */
  zerocame_consent_application_input: Array<Zerocame_Consent_Application_Input>;
  /** fetch aggregated fields from the table: "zerocame.consent_application_input" */
  zerocame_consent_application_input_aggregate: Zerocame_Consent_Application_Input_Aggregate;
  /** fetch data from the table: "zerocame.consent_application_input" using primary key columns */
  zerocame_consent_application_input_by_pk?: Maybe<Zerocame_Consent_Application_Input>;
  /** fetch data from the table: "zerocame.consent_application_result" */
  zerocame_consent_application_result: Array<Zerocame_Consent_Application_Result>;
  /** fetch aggregated fields from the table: "zerocame.consent_application_result" */
  zerocame_consent_application_result_aggregate: Zerocame_Consent_Application_Result_Aggregate;
  /** fetch data from the table: "zerocame.consent_application_result" using primary key columns */
  zerocame_consent_application_result_by_pk?: Maybe<Zerocame_Consent_Application_Result>;
  /** fetch data from the table: "zerocame.consent_application_zeroca_analysis" */
  zerocame_consent_application_zeroca_analysis: Array<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** fetch aggregated fields from the table: "zerocame.consent_application_zeroca_analysis" */
  zerocame_consent_application_zeroca_analysis_aggregate: Zerocame_Consent_Application_Zeroca_Analysis_Aggregate;
  /** fetch data from the table: "zerocame.consent_application_zeroca_analysis" using primary key columns */
  zerocame_consent_application_zeroca_analysis_by_pk?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** execute function "zerocame.fn_current_consent_application_result" which returns "zerocame.consent_application_result" */
  zerocame_fn_current_consent_application_result: Array<Zerocame_Consent_Application_Result>;
  /** execute function "zerocame.fn_current_consent_application_result" and query aggregates on result of table type "zerocame.consent_application_result" */
  zerocame_fn_current_consent_application_result_aggregate: Zerocame_Consent_Application_Result_Aggregate;
  /** execute function "zerocame.fn_greenscore_detail_by_category" which returns "zerocame.type_greenscore_detail_by_category" */
  zerocame_fn_greenscore_detail_by_category: Array<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** execute function "zerocame.fn_greenscore_detail_by_category" and query aggregates on result of table type "zerocame.type_greenscore_detail_by_category" */
  zerocame_fn_greenscore_detail_by_category_aggregate: Zerocame_Type_Greenscore_Detail_By_Category_Aggregate;
  /** execute function "zerocame.fn_user_electricity_data_per_day" which returns "zerocame.user_electricity_data_per_day" */
  zerocame_fn_user_electricity_data_per_day: Array<Zerocame_User_Electricity_Data_Per_Day>;
  /** execute function "zerocame.fn_user_electricity_data_per_day" and query aggregates on result of table type "zerocame.user_electricity_data_per_day" */
  zerocame_fn_user_electricity_data_per_day_aggregate: Zerocame_User_Electricity_Data_Per_Day_Aggregate;
  /** fetch data from the table: "zerocame.greenscore" */
  zerocame_greenscore: Array<Zerocame_Greenscore>;
  /** fetch data from the table: "zerocame.greenscore_action_review" */
  zerocame_greenscore_action_review: Array<Zerocame_Greenscore_Action_Review>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_action_review" */
  zerocame_greenscore_action_review_aggregate: Zerocame_Greenscore_Action_Review_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_action_review" using primary key columns */
  zerocame_greenscore_action_review_by_pk?: Maybe<Zerocame_Greenscore_Action_Review>;
  /** fetch data from the table: "zerocame.greenscore_action_review_detail" */
  zerocame_greenscore_action_review_detail: Array<Zerocame_Greenscore_Action_Review_Detail>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_action_review_detail" */
  zerocame_greenscore_action_review_detail_aggregate: Zerocame_Greenscore_Action_Review_Detail_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_action_review_detail" using primary key columns */
  zerocame_greenscore_action_review_detail_by_pk?: Maybe<Zerocame_Greenscore_Action_Review_Detail>;
  /** fetch aggregated fields from the table: "zerocame.greenscore" */
  zerocame_greenscore_aggregate: Zerocame_Greenscore_Aggregate;
  /** fetch data from the table: "zerocame.greenscore" using primary key columns */
  zerocame_greenscore_by_pk?: Maybe<Zerocame_Greenscore>;
  /** fetch data from the table: "zerocame.greenscore_calc" */
  zerocame_greenscore_calc: Array<Zerocame_Greenscore_Calc>;
  /** fetch data from the table: "zerocame.greenscore_calc_action" */
  zerocame_greenscore_calc_action: Array<Zerocame_Greenscore_Calc_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_calc_action" */
  zerocame_greenscore_calc_action_aggregate: Zerocame_Greenscore_Calc_Action_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_calc_action" using primary key columns */
  zerocame_greenscore_calc_action_by_pk?: Maybe<Zerocame_Greenscore_Calc_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_calc" */
  zerocame_greenscore_calc_aggregate: Zerocame_Greenscore_Calc_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_calc" using primary key columns */
  zerocame_greenscore_calc_by_pk?: Maybe<Zerocame_Greenscore_Calc>;
  /** fetch data from the table: "zerocame.greenscore_calc_energy" */
  zerocame_greenscore_calc_energy: Array<Zerocame_Greenscore_Calc_Energy>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_calc_energy" */
  zerocame_greenscore_calc_energy_aggregate: Zerocame_Greenscore_Calc_Energy_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_calc_energy" using primary key columns */
  zerocame_greenscore_calc_energy_by_pk?: Maybe<Zerocame_Greenscore_Calc_Energy>;
  /** fetch data from the table: "zerocame.greenscore_challenge_code_master" */
  zerocame_greenscore_challenge_code_master: Array<Zerocame_Greenscore_Challenge_Code_Master>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_challenge_code_master" */
  zerocame_greenscore_challenge_code_master_aggregate: Zerocame_Greenscore_Challenge_Code_Master_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_challenge_code_master" using primary key columns */
  zerocame_greenscore_challenge_code_master_by_pk?: Maybe<Zerocame_Greenscore_Challenge_Code_Master>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission" */
  zerocame_greenscore_co2_emission: Array<Zerocame_Greenscore_Co2_Emission>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_action" */
  zerocame_greenscore_co2_emission_action: Array<Zerocame_Greenscore_Co2_Emission_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission_action" */
  zerocame_greenscore_co2_emission_action_aggregate: Zerocame_Greenscore_Co2_Emission_Action_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_action" using primary key columns */
  zerocame_greenscore_co2_emission_action_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission" */
  zerocame_greenscore_co2_emission_aggregate: Zerocame_Greenscore_Co2_Emission_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission" using primary key columns */
  zerocame_greenscore_co2_emission_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_bm" */
  zerocame_greenscore_co2_emission_energy_bm: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission_energy_bm" */
  zerocame_greenscore_co2_emission_energy_bm_aggregate: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_bm" using primary key columns */
  zerocame_greenscore_co2_emission_energy_bm_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_dr" */
  zerocame_greenscore_co2_emission_energy_dr: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission_energy_dr" */
  zerocame_greenscore_co2_emission_energy_dr_aggregate: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_dr" using primary key columns */
  zerocame_greenscore_co2_emission_energy_dr_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** fetch data from the table: "zerocame.greenscore_init_hearing" */
  zerocame_greenscore_init_hearing: Array<Zerocame_Greenscore_Init_Hearing>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_init_hearing" */
  zerocame_greenscore_init_hearing_aggregate: Zerocame_Greenscore_Init_Hearing_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_init_hearing" using primary key columns */
  zerocame_greenscore_init_hearing_by_pk?: Maybe<Zerocame_Greenscore_Init_Hearing>;
  /** fetch data from the table: "zerocame.greenscore_mission_achievement" */
  zerocame_greenscore_mission_achievement: Array<Zerocame_Greenscore_Mission_Achievement>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_mission_achievement" */
  zerocame_greenscore_mission_achievement_aggregate: Zerocame_Greenscore_Mission_Achievement_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_mission_achievement" using primary key columns */
  zerocame_greenscore_mission_achievement_by_pk?: Maybe<Zerocame_Greenscore_Mission_Achievement>;
  /** fetch data from the table: "zerocame.greenscore_referral_code" */
  zerocame_greenscore_referral_code: Array<Zerocame_Greenscore_Referral_Code>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_referral_code" */
  zerocame_greenscore_referral_code_aggregate: Zerocame_Greenscore_Referral_Code_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_referral_code" using primary key columns */
  zerocame_greenscore_referral_code_by_pk?: Maybe<Zerocame_Greenscore_Referral_Code>;
  /** fetch data from the table: "zerocame.greenscore_referral_result" */
  zerocame_greenscore_referral_result: Array<Zerocame_Greenscore_Referral_Result>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_referral_result" */
  zerocame_greenscore_referral_result_aggregate: Zerocame_Greenscore_Referral_Result_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_referral_result" using primary key columns */
  zerocame_greenscore_referral_result_by_pk?: Maybe<Zerocame_Greenscore_Referral_Result>;
  /** fetch data from the table: "zerocame.greenscore_user_actions" */
  zerocame_greenscore_user_actions: Array<Zerocame_Greenscore_User_Actions>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_user_actions" */
  zerocame_greenscore_user_actions_aggregate: Zerocame_Greenscore_User_Actions_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_user_actions" using primary key columns */
  zerocame_greenscore_user_actions_by_pk?: Maybe<Zerocame_Greenscore_User_Actions>;
  /** fetch data from the table: "zerocame.greenscore_users" */
  zerocame_greenscore_users: Array<Zerocame_Greenscore_Users>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_users" */
  zerocame_greenscore_users_aggregate: Zerocame_Greenscore_Users_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_users" using primary key columns */
  zerocame_greenscore_users_by_pk?: Maybe<Zerocame_Greenscore_Users>;
  /** fetch data from the table: "zerocame.jma_area_master" */
  zerocame_jma_area_master: Array<Zerocame_Jma_Area_Master>;
  /** fetch aggregated fields from the table: "zerocame.jma_area_master" */
  zerocame_jma_area_master_aggregate: Zerocame_Jma_Area_Master_Aggregate;
  /** fetch data from the table: "zerocame.jma_area_master" using primary key columns */
  zerocame_jma_area_master_by_pk?: Maybe<Zerocame_Jma_Area_Master>;
  /** fetch data from the table: "zerocame.jma_meteorological_daily" */
  zerocame_jma_meteorological_daily: Array<Zerocame_Jma_Meteorological_Daily>;
  /** fetch aggregated fields from the table: "zerocame.jma_meteorological_daily" */
  zerocame_jma_meteorological_daily_aggregate: Zerocame_Jma_Meteorological_Daily_Aggregate;
  /** fetch data from the table: "zerocame.jma_meteorological_daily" using primary key columns */
  zerocame_jma_meteorological_daily_by_pk?: Maybe<Zerocame_Jma_Meteorological_Daily>;
  /** fetch data from the table: "zerocame.mst_co2_emission_coefficient" */
  zerocame_mst_co2_emission_coefficient: Array<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** fetch aggregated fields from the table: "zerocame.mst_co2_emission_coefficient" */
  zerocame_mst_co2_emission_coefficient_aggregate: Zerocame_Mst_Co2_Emission_Coefficient_Aggregate;
  /** fetch data from the table: "zerocame.mst_co2_emission_coefficient" using primary key columns */
  zerocame_mst_co2_emission_coefficient_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** fetch data from the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  zerocame_mst_co2_emission_monthly_benchmark: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** fetch aggregated fields from the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  zerocame_mst_co2_emission_monthly_benchmark_aggregate: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Aggregate;
  /** fetch data from the table: "zerocame.mst_co2_emission_monthly_benchmark" using primary key columns */
  zerocame_mst_co2_emission_monthly_benchmark_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** fetch data from the table: "zerocame.mst_electric_company" */
  zerocame_mst_electric_company: Array<Zerocame_Mst_Electric_Company>;
  /** fetch aggregated fields from the table: "zerocame.mst_electric_company" */
  zerocame_mst_electric_company_aggregate: Zerocame_Mst_Electric_Company_Aggregate;
  /** fetch data from the table: "zerocame.mst_electric_company" using primary key columns */
  zerocame_mst_electric_company_by_pk?: Maybe<Zerocame_Mst_Electric_Company>;
  /** fetch data from the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  zerocame_mst_energyscore_benchmark_coefficient: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  zerocame_mst_energyscore_benchmark_coefficient_aggregate: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_benchmark_coefficient" using primary key columns */
  zerocame_mst_energyscore_benchmark_coefficient_by_pk?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** fetch data from the table: "zerocame.mst_energyscore_daily_benchmark" */
  zerocame_mst_energyscore_daily_benchmark: Array<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_daily_benchmark" */
  zerocame_mst_energyscore_daily_benchmark_aggregate: Zerocame_Mst_Energyscore_Daily_Benchmark_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_daily_benchmark" using primary key columns */
  zerocame_mst_energyscore_daily_benchmark_by_pk?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** fetch data from the table: "zerocame.mst_energyscore_difference_ratio" */
  zerocame_mst_energyscore_difference_ratio: Array<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_difference_ratio" */
  zerocame_mst_energyscore_difference_ratio_aggregate: Zerocame_Mst_Energyscore_Difference_Ratio_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_difference_ratio" using primary key columns */
  zerocame_mst_energyscore_difference_ratio_by_pk?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** fetch data from the table: "zerocame.mst_energyscore_user_attribute" */
  zerocame_mst_energyscore_user_attribute: Array<Zerocame_Mst_Energyscore_User_Attribute>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_user_attribute" */
  zerocame_mst_energyscore_user_attribute_aggregate: Zerocame_Mst_Energyscore_User_Attribute_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_user_attribute" using primary key columns */
  zerocame_mst_energyscore_user_attribute_by_pk?: Maybe<Zerocame_Mst_Energyscore_User_Attribute>;
  /** fetch data from the table: "zerocame.mst_prefecture_electric_company" */
  zerocame_mst_prefecture_electric_company: Array<Zerocame_Mst_Prefecture_Electric_Company>;
  /** fetch aggregated fields from the table: "zerocame.mst_prefecture_electric_company" */
  zerocame_mst_prefecture_electric_company_aggregate: Zerocame_Mst_Prefecture_Electric_Company_Aggregate;
  /** fetch data from the table: "zerocame.mst_prefecture_electric_company" using primary key columns */
  zerocame_mst_prefecture_electric_company_by_pk?: Maybe<Zerocame_Mst_Prefecture_Electric_Company>;
  /** fetch data from the table: "zerocame.personal_electricity_data_30_minuites" */
  zerocame_personal_electricity_data_30_minuites: Array<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** fetch aggregated fields from the table: "zerocame.personal_electricity_data_30_minuites" */
  zerocame_personal_electricity_data_30_minuites_aggregate: Zerocame_Personal_Electricity_Data_30_Minuites_Aggregate;
  /** fetch data from the table: "zerocame.personal_electricity_data_30_minuites" using primary key columns */
  zerocame_personal_electricity_data_30_minuites_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** fetch data from the table: "zerocame.personal_electricity_data_daily" */
  zerocame_personal_electricity_data_daily: Array<Zerocame_Personal_Electricity_Data_Daily>;
  /** fetch aggregated fields from the table: "zerocame.personal_electricity_data_daily" */
  zerocame_personal_electricity_data_daily_aggregate: Zerocame_Personal_Electricity_Data_Daily_Aggregate;
  /** fetch data from the table: "zerocame.personal_electricity_data_daily" using primary key columns */
  zerocame_personal_electricity_data_daily_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_Daily>;
  /** fetch data from the table: "zerocame.type_greenscore_detail_by_category" */
  zerocame_type_greenscore_detail_by_category: Array<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** fetch aggregated fields from the table: "zerocame.type_greenscore_detail_by_category" */
  zerocame_type_greenscore_detail_by_category_aggregate: Zerocame_Type_Greenscore_Detail_By_Category_Aggregate;
  /** fetch data from the table: "zerocame.type_greenscore_detail_by_category" using primary key columns */
  zerocame_type_greenscore_detail_by_category_by_pk?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** fetch data from the table: "zerocame.user_actions" */
  zerocame_user_actions: Array<Zerocame_User_Actions>;
  /** fetch aggregated fields from the table: "zerocame.user_actions" */
  zerocame_user_actions_aggregate: Zerocame_User_Actions_Aggregate;
  /** fetch data from the table: "zerocame.user_actions" using primary key columns */
  zerocame_user_actions_by_pk?: Maybe<Zerocame_User_Actions>;
  /** fetch data from the table: "zerocame.user_electricity_data_per_day" */
  zerocame_user_electricity_data_per_day: Array<Zerocame_User_Electricity_Data_Per_Day>;
  /** fetch aggregated fields from the table: "zerocame.user_electricity_data_per_day" */
  zerocame_user_electricity_data_per_day_aggregate: Zerocame_User_Electricity_Data_Per_Day_Aggregate;
  /** fetch data from the table: "zerocame.user_electricity_data_per_day" using primary key columns */
  zerocame_user_electricity_data_per_day_by_pk?: Maybe<Zerocame_User_Electricity_Data_Per_Day>;
  /** fetch data from the table: "zerocame.users" */
  zerocame_users: Array<Zerocame_Users>;
  /** fetch aggregated fields from the table: "zerocame.users" */
  zerocame_users_aggregate: Zerocame_Users_Aggregate;
  /** fetch data from the table: "zerocame.users" using primary key columns */
  zerocame_users_by_pk?: Maybe<Zerocame_Users>;
  /** fetch data from the table: "zerocame.view_now" */
  zerocame_view_now: Array<Zerocame_View_Now>;
  /** fetch aggregated fields from the table: "zerocame.view_now" */
  zerocame_view_now_aggregate: Zerocame_View_Now_Aggregate;
  /** fetch data from the table: "zerocame.view_user_electricity_data" */
  zerocame_view_user_electricity_data: Array<Zerocame_View_User_Electricity_Data>;
  /** fetch aggregated fields from the table: "zerocame.view_user_electricity_data" */
  zerocame_view_user_electricity_data_aggregate: Zerocame_View_User_Electricity_Data_Aggregate;
};


export type Query_RootZerocame_Area_MasterArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Area_Master_Bool_Exp>;
};


export type Query_RootZerocame_Area_Master_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Area_Master_Bool_Exp>;
};


export type Query_RootZerocame_Area_Master_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootZerocame_Bingo_User_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_User_Actions_Bool_Exp>;
};


export type Query_RootZerocame_Bingo_User_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_User_Actions_Bool_Exp>;
};


export type Query_RootZerocame_Bingo_User_Actions_By_PkArgs = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};


export type Query_RootZerocame_Bingo_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};


export type Query_RootZerocame_Bingo_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};


export type Query_RootZerocame_Bingo_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};


export type Query_RootZerocame_Consent_Application_InputArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Input_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};


export type Query_RootZerocame_Consent_Application_Input_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Input_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};


export type Query_RootZerocame_Consent_Application_Input_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


export type Query_RootZerocame_Consent_Application_ResultArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Query_RootZerocame_Consent_Application_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Query_RootZerocame_Consent_Application_Result_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootZerocame_Consent_Application_Zeroca_AnalysisArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};


export type Query_RootZerocame_Consent_Application_Zeroca_Analysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};


export type Query_RootZerocame_Consent_Application_Zeroca_Analysis_By_PkArgs = {
  consent_info_id: Scalars['String'];
};


export type Query_RootZerocame_Fn_Current_Consent_Application_ResultArgs = {
  args: Zerocame_Fn_Current_Consent_Application_Result_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Query_RootZerocame_Fn_Current_Consent_Application_Result_AggregateArgs = {
  args: Zerocame_Fn_Current_Consent_Application_Result_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Query_RootZerocame_Fn_Greenscore_Detail_By_CategoryArgs = {
  args: Zerocame_Fn_Greenscore_Detail_By_Category_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Query_RootZerocame_Fn_Greenscore_Detail_By_Category_AggregateArgs = {
  args: Zerocame_Fn_Greenscore_Detail_By_Category_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Query_RootZerocame_Fn_User_Electricity_Data_Per_DayArgs = {
  args: Zerocame_Fn_User_Electricity_Data_Per_Day_Args;
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Query_RootZerocame_Fn_User_Electricity_Data_Per_Day_AggregateArgs = {
  args: Zerocame_Fn_User_Electricity_Data_Per_Day_Args;
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Query_RootZerocame_GreenscoreArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Action_ReviewArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Action_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Action_Review_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootZerocame_Greenscore_Action_Review_DetailArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Action_Review_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Action_Review_Detail_By_PkArgs = {
  greenscore_action_id: Scalars['String'];
  review_id: Scalars['Int'];
};


export type Query_RootZerocame_Greenscore_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootZerocame_Greenscore_CalcArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Calc_ActionArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Calc_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Calc_Action_By_PkArgs = {
  greenscore_action_id: Scalars['String'];
  greenscore_calc_id: Scalars['Int'];
};


export type Query_RootZerocame_Greenscore_Calc_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Calc_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootZerocame_Greenscore_Calc_EnergyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Calc_Energy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Calc_Energy_By_PkArgs = {
  greenscore_calc_id: Scalars['Int'];
};


export type Query_RootZerocame_Greenscore_Challenge_Code_MasterArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Challenge_Code_Master_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Challenge_Code_Master_By_PkArgs = {
  challenge_id: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_Co2_EmissionArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_ActionArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Action_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_Co2_Emission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Energy_BmArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Energy_Bm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Energy_Bm_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Energy_DrArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Energy_Dr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Co2_Emission_Energy_Dr_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_Init_HearingArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Init_Hearing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Init_Hearing_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  hearing_no: Scalars['Int'];
};


export type Query_RootZerocame_Greenscore_Mission_AchievementArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Mission_Achievement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Mission_Achievement_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  challenge_id: Scalars['String'];
  mission_id: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_Referral_CodeArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Code_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Referral_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Code_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Referral_Code_By_PkArgs = {
  referral_code: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_Referral_ResultArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Result_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Referral_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Result_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Referral_Result_By_PkArgs = {
  referred_auth0_user_id: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_User_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_User_Actions_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_User_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_User_Actions_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_User_Actions_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
};


export type Query_RootZerocame_Greenscore_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};


export type Query_RootZerocame_Greenscore_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


export type Query_RootZerocame_Jma_Area_MasterArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
};


export type Query_RootZerocame_Jma_Area_Master_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
};


export type Query_RootZerocame_Jma_Area_Master_By_PkArgs = {
  block_code: Scalars['String'];
};


export type Query_RootZerocame_Jma_Meteorological_DailyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};


export type Query_RootZerocame_Jma_Meteorological_Daily_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};


export type Query_RootZerocame_Jma_Meteorological_Daily_By_PkArgs = {
  block_code: Scalars['String'];
  date: Scalars['String'];
};


export type Query_RootZerocame_Mst_Co2_Emission_CoefficientArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Co2_Emission_Coefficient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Co2_Emission_Coefficient_By_PkArgs = {
  regist_no: Scalars['String'];
  year_month: Scalars['String'];
};


export type Query_RootZerocame_Mst_Co2_Emission_Monthly_BenchmarkArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Co2_Emission_Monthly_Benchmark_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Co2_Emission_Monthly_Benchmark_By_PkArgs = {
  attribute_no: Scalars['String'];
  year_month: Scalars['String'];
};


export type Query_RootZerocame_Mst_Electric_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Electric_Company_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Electric_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Electric_Company_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Electric_Company_By_PkArgs = {
  regist_no: Scalars['String'];
};


export type Query_RootZerocame_Mst_Energyscore_Benchmark_CoefficientArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_Benchmark_Coefficient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_Benchmark_Coefficient_By_PkArgs = {
  attribute_item: Scalars['String'];
  attribute_value: Scalars['String'];
};


export type Query_RootZerocame_Mst_Energyscore_Daily_BenchmarkArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_Daily_Benchmark_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_Daily_Benchmark_By_PkArgs = {
  attribute_no: Scalars['String'];
  date: Scalars['String'];
};


export type Query_RootZerocame_Mst_Energyscore_Difference_RatioArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_Difference_Ratio_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_Difference_Ratio_By_PkArgs = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};


export type Query_RootZerocame_Mst_Energyscore_User_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_User_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Energyscore_User_Attribute_By_PkArgs = {
  no: Scalars['String'];
};


export type Query_RootZerocame_Mst_Prefecture_Electric_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Prefecture_Electric_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>;
};


export type Query_RootZerocame_Mst_Prefecture_Electric_Company_By_PkArgs = {
  pref_code: Scalars['String'];
};


export type Query_RootZerocame_Personal_Electricity_Data_30_MinuitesArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>;
};


export type Query_RootZerocame_Personal_Electricity_Data_30_Minuites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>;
};


export type Query_RootZerocame_Personal_Electricity_Data_30_Minuites_By_PkArgs = {
  consumer_spcfc_id: Scalars['String'];
  get_ymd: Scalars['String'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
  time_code: Scalars['String'];
};


export type Query_RootZerocame_Personal_Electricity_Data_DailyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>;
};


export type Query_RootZerocame_Personal_Electricity_Data_Daily_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>;
};


export type Query_RootZerocame_Personal_Electricity_Data_Daily_By_PkArgs = {
  consumer_spcfc_id: Scalars['String'];
  get_ymd: Scalars['String'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
};


export type Query_RootZerocame_Type_Greenscore_Detail_By_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Query_RootZerocame_Type_Greenscore_Detail_By_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Query_RootZerocame_Type_Greenscore_Detail_By_Category_By_PkArgs = {
  category_name: Scalars['String'];
};


export type Query_RootZerocame_User_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_User_Actions_Bool_Exp>;
};


export type Query_RootZerocame_User_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_User_Actions_Bool_Exp>;
};


export type Query_RootZerocame_User_Actions_By_PkArgs = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
};


export type Query_RootZerocame_User_Electricity_Data_Per_DayArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Query_RootZerocame_User_Electricity_Data_Per_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Query_RootZerocame_User_Electricity_Data_Per_Day_By_PkArgs = {
  consent_application_result_id: Scalars['Int'];
  get_ymd: Scalars['String'];
};


export type Query_RootZerocame_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Users_Bool_Exp>;
};


export type Query_RootZerocame_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Users_Bool_Exp>;
};


export type Query_RootZerocame_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


export type Query_RootZerocame_View_NowArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_Now_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_Now_Order_By>>;
  where?: InputMaybe<Zerocame_View_Now_Bool_Exp>;
};


export type Query_RootZerocame_View_Now_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_Now_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_Now_Order_By>>;
  where?: InputMaybe<Zerocame_View_Now_Bool_Exp>;
};


export type Query_RootZerocame_View_User_Electricity_DataArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Order_By>>;
  where?: InputMaybe<Zerocame_View_User_Electricity_Data_Bool_Exp>;
};


export type Query_RootZerocame_View_User_Electricity_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Order_By>>;
  where?: InputMaybe<Zerocame_View_User_Electricity_Data_Bool_Exp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "zerocame.area_master" */
  zerocame_area_master: Array<Zerocame_Area_Master>;
  /** fetch aggregated fields from the table: "zerocame.area_master" */
  zerocame_area_master_aggregate: Zerocame_Area_Master_Aggregate;
  /** fetch data from the table: "zerocame.area_master" using primary key columns */
  zerocame_area_master_by_pk?: Maybe<Zerocame_Area_Master>;
  /** fetch data from the table in a streaming manner: "zerocame.area_master" */
  zerocame_area_master_stream: Array<Zerocame_Area_Master>;
  /** fetch data from the table: "zerocame.bingo_user_actions" */
  zerocame_bingo_user_actions: Array<Zerocame_Bingo_User_Actions>;
  /** fetch aggregated fields from the table: "zerocame.bingo_user_actions" */
  zerocame_bingo_user_actions_aggregate: Zerocame_Bingo_User_Actions_Aggregate;
  /** fetch data from the table: "zerocame.bingo_user_actions" using primary key columns */
  zerocame_bingo_user_actions_by_pk?: Maybe<Zerocame_Bingo_User_Actions>;
  /** fetch data from the table in a streaming manner: "zerocame.bingo_user_actions" */
  zerocame_bingo_user_actions_stream: Array<Zerocame_Bingo_User_Actions>;
  /** fetch data from the table: "zerocame.bingo_users" */
  zerocame_bingo_users: Array<Zerocame_Bingo_Users>;
  /** fetch aggregated fields from the table: "zerocame.bingo_users" */
  zerocame_bingo_users_aggregate: Zerocame_Bingo_Users_Aggregate;
  /** fetch data from the table: "zerocame.bingo_users" using primary key columns */
  zerocame_bingo_users_by_pk?: Maybe<Zerocame_Bingo_Users>;
  /** fetch data from the table in a streaming manner: "zerocame.bingo_users" */
  zerocame_bingo_users_stream: Array<Zerocame_Bingo_Users>;
  /** fetch data from the table: "zerocame.consent_application_input" */
  zerocame_consent_application_input: Array<Zerocame_Consent_Application_Input>;
  /** fetch aggregated fields from the table: "zerocame.consent_application_input" */
  zerocame_consent_application_input_aggregate: Zerocame_Consent_Application_Input_Aggregate;
  /** fetch data from the table: "zerocame.consent_application_input" using primary key columns */
  zerocame_consent_application_input_by_pk?: Maybe<Zerocame_Consent_Application_Input>;
  /** fetch data from the table in a streaming manner: "zerocame.consent_application_input" */
  zerocame_consent_application_input_stream: Array<Zerocame_Consent_Application_Input>;
  /** fetch data from the table: "zerocame.consent_application_result" */
  zerocame_consent_application_result: Array<Zerocame_Consent_Application_Result>;
  /** fetch aggregated fields from the table: "zerocame.consent_application_result" */
  zerocame_consent_application_result_aggregate: Zerocame_Consent_Application_Result_Aggregate;
  /** fetch data from the table: "zerocame.consent_application_result" using primary key columns */
  zerocame_consent_application_result_by_pk?: Maybe<Zerocame_Consent_Application_Result>;
  /** fetch data from the table in a streaming manner: "zerocame.consent_application_result" */
  zerocame_consent_application_result_stream: Array<Zerocame_Consent_Application_Result>;
  /** fetch data from the table: "zerocame.consent_application_zeroca_analysis" */
  zerocame_consent_application_zeroca_analysis: Array<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** fetch aggregated fields from the table: "zerocame.consent_application_zeroca_analysis" */
  zerocame_consent_application_zeroca_analysis_aggregate: Zerocame_Consent_Application_Zeroca_Analysis_Aggregate;
  /** fetch data from the table: "zerocame.consent_application_zeroca_analysis" using primary key columns */
  zerocame_consent_application_zeroca_analysis_by_pk?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** fetch data from the table in a streaming manner: "zerocame.consent_application_zeroca_analysis" */
  zerocame_consent_application_zeroca_analysis_stream: Array<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** execute function "zerocame.fn_current_consent_application_result" which returns "zerocame.consent_application_result" */
  zerocame_fn_current_consent_application_result: Array<Zerocame_Consent_Application_Result>;
  /** execute function "zerocame.fn_current_consent_application_result" and query aggregates on result of table type "zerocame.consent_application_result" */
  zerocame_fn_current_consent_application_result_aggregate: Zerocame_Consent_Application_Result_Aggregate;
  /** execute function "zerocame.fn_greenscore_detail_by_category" which returns "zerocame.type_greenscore_detail_by_category" */
  zerocame_fn_greenscore_detail_by_category: Array<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** execute function "zerocame.fn_greenscore_detail_by_category" and query aggregates on result of table type "zerocame.type_greenscore_detail_by_category" */
  zerocame_fn_greenscore_detail_by_category_aggregate: Zerocame_Type_Greenscore_Detail_By_Category_Aggregate;
  /** execute function "zerocame.fn_user_electricity_data_per_day" which returns "zerocame.user_electricity_data_per_day" */
  zerocame_fn_user_electricity_data_per_day: Array<Zerocame_User_Electricity_Data_Per_Day>;
  /** execute function "zerocame.fn_user_electricity_data_per_day" and query aggregates on result of table type "zerocame.user_electricity_data_per_day" */
  zerocame_fn_user_electricity_data_per_day_aggregate: Zerocame_User_Electricity_Data_Per_Day_Aggregate;
  /** fetch data from the table: "zerocame.greenscore" */
  zerocame_greenscore: Array<Zerocame_Greenscore>;
  /** fetch data from the table: "zerocame.greenscore_action_review" */
  zerocame_greenscore_action_review: Array<Zerocame_Greenscore_Action_Review>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_action_review" */
  zerocame_greenscore_action_review_aggregate: Zerocame_Greenscore_Action_Review_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_action_review" using primary key columns */
  zerocame_greenscore_action_review_by_pk?: Maybe<Zerocame_Greenscore_Action_Review>;
  /** fetch data from the table: "zerocame.greenscore_action_review_detail" */
  zerocame_greenscore_action_review_detail: Array<Zerocame_Greenscore_Action_Review_Detail>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_action_review_detail" */
  zerocame_greenscore_action_review_detail_aggregate: Zerocame_Greenscore_Action_Review_Detail_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_action_review_detail" using primary key columns */
  zerocame_greenscore_action_review_detail_by_pk?: Maybe<Zerocame_Greenscore_Action_Review_Detail>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_action_review_detail" */
  zerocame_greenscore_action_review_detail_stream: Array<Zerocame_Greenscore_Action_Review_Detail>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_action_review" */
  zerocame_greenscore_action_review_stream: Array<Zerocame_Greenscore_Action_Review>;
  /** fetch aggregated fields from the table: "zerocame.greenscore" */
  zerocame_greenscore_aggregate: Zerocame_Greenscore_Aggregate;
  /** fetch data from the table: "zerocame.greenscore" using primary key columns */
  zerocame_greenscore_by_pk?: Maybe<Zerocame_Greenscore>;
  /** fetch data from the table: "zerocame.greenscore_calc" */
  zerocame_greenscore_calc: Array<Zerocame_Greenscore_Calc>;
  /** fetch data from the table: "zerocame.greenscore_calc_action" */
  zerocame_greenscore_calc_action: Array<Zerocame_Greenscore_Calc_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_calc_action" */
  zerocame_greenscore_calc_action_aggregate: Zerocame_Greenscore_Calc_Action_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_calc_action" using primary key columns */
  zerocame_greenscore_calc_action_by_pk?: Maybe<Zerocame_Greenscore_Calc_Action>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_calc_action" */
  zerocame_greenscore_calc_action_stream: Array<Zerocame_Greenscore_Calc_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_calc" */
  zerocame_greenscore_calc_aggregate: Zerocame_Greenscore_Calc_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_calc" using primary key columns */
  zerocame_greenscore_calc_by_pk?: Maybe<Zerocame_Greenscore_Calc>;
  /** fetch data from the table: "zerocame.greenscore_calc_energy" */
  zerocame_greenscore_calc_energy: Array<Zerocame_Greenscore_Calc_Energy>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_calc_energy" */
  zerocame_greenscore_calc_energy_aggregate: Zerocame_Greenscore_Calc_Energy_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_calc_energy" using primary key columns */
  zerocame_greenscore_calc_energy_by_pk?: Maybe<Zerocame_Greenscore_Calc_Energy>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_calc_energy" */
  zerocame_greenscore_calc_energy_stream: Array<Zerocame_Greenscore_Calc_Energy>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_calc" */
  zerocame_greenscore_calc_stream: Array<Zerocame_Greenscore_Calc>;
  /** fetch data from the table: "zerocame.greenscore_challenge_code_master" */
  zerocame_greenscore_challenge_code_master: Array<Zerocame_Greenscore_Challenge_Code_Master>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_challenge_code_master" */
  zerocame_greenscore_challenge_code_master_aggregate: Zerocame_Greenscore_Challenge_Code_Master_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_challenge_code_master" using primary key columns */
  zerocame_greenscore_challenge_code_master_by_pk?: Maybe<Zerocame_Greenscore_Challenge_Code_Master>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_challenge_code_master" */
  zerocame_greenscore_challenge_code_master_stream: Array<Zerocame_Greenscore_Challenge_Code_Master>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission" */
  zerocame_greenscore_co2_emission: Array<Zerocame_Greenscore_Co2_Emission>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_action" */
  zerocame_greenscore_co2_emission_action: Array<Zerocame_Greenscore_Co2_Emission_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission_action" */
  zerocame_greenscore_co2_emission_action_aggregate: Zerocame_Greenscore_Co2_Emission_Action_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_action" using primary key columns */
  zerocame_greenscore_co2_emission_action_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Action>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_co2_emission_action" */
  zerocame_greenscore_co2_emission_action_stream: Array<Zerocame_Greenscore_Co2_Emission_Action>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission" */
  zerocame_greenscore_co2_emission_aggregate: Zerocame_Greenscore_Co2_Emission_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission" using primary key columns */
  zerocame_greenscore_co2_emission_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_bm" */
  zerocame_greenscore_co2_emission_energy_bm: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission_energy_bm" */
  zerocame_greenscore_co2_emission_energy_bm_aggregate: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_bm" using primary key columns */
  zerocame_greenscore_co2_emission_energy_bm_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_co2_emission_energy_bm" */
  zerocame_greenscore_co2_emission_energy_bm_stream: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_dr" */
  zerocame_greenscore_co2_emission_energy_dr: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_co2_emission_energy_dr" */
  zerocame_greenscore_co2_emission_energy_dr_aggregate: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_co2_emission_energy_dr" using primary key columns */
  zerocame_greenscore_co2_emission_energy_dr_by_pk?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_co2_emission_energy_dr" */
  zerocame_greenscore_co2_emission_energy_dr_stream: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_co2_emission" */
  zerocame_greenscore_co2_emission_stream: Array<Zerocame_Greenscore_Co2_Emission>;
  /** fetch data from the table: "zerocame.greenscore_init_hearing" */
  zerocame_greenscore_init_hearing: Array<Zerocame_Greenscore_Init_Hearing>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_init_hearing" */
  zerocame_greenscore_init_hearing_aggregate: Zerocame_Greenscore_Init_Hearing_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_init_hearing" using primary key columns */
  zerocame_greenscore_init_hearing_by_pk?: Maybe<Zerocame_Greenscore_Init_Hearing>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_init_hearing" */
  zerocame_greenscore_init_hearing_stream: Array<Zerocame_Greenscore_Init_Hearing>;
  /** fetch data from the table: "zerocame.greenscore_mission_achievement" */
  zerocame_greenscore_mission_achievement: Array<Zerocame_Greenscore_Mission_Achievement>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_mission_achievement" */
  zerocame_greenscore_mission_achievement_aggregate: Zerocame_Greenscore_Mission_Achievement_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_mission_achievement" using primary key columns */
  zerocame_greenscore_mission_achievement_by_pk?: Maybe<Zerocame_Greenscore_Mission_Achievement>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_mission_achievement" */
  zerocame_greenscore_mission_achievement_stream: Array<Zerocame_Greenscore_Mission_Achievement>;
  /** fetch data from the table: "zerocame.greenscore_referral_code" */
  zerocame_greenscore_referral_code: Array<Zerocame_Greenscore_Referral_Code>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_referral_code" */
  zerocame_greenscore_referral_code_aggregate: Zerocame_Greenscore_Referral_Code_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_referral_code" using primary key columns */
  zerocame_greenscore_referral_code_by_pk?: Maybe<Zerocame_Greenscore_Referral_Code>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_referral_code" */
  zerocame_greenscore_referral_code_stream: Array<Zerocame_Greenscore_Referral_Code>;
  /** fetch data from the table: "zerocame.greenscore_referral_result" */
  zerocame_greenscore_referral_result: Array<Zerocame_Greenscore_Referral_Result>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_referral_result" */
  zerocame_greenscore_referral_result_aggregate: Zerocame_Greenscore_Referral_Result_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_referral_result" using primary key columns */
  zerocame_greenscore_referral_result_by_pk?: Maybe<Zerocame_Greenscore_Referral_Result>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_referral_result" */
  zerocame_greenscore_referral_result_stream: Array<Zerocame_Greenscore_Referral_Result>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore" */
  zerocame_greenscore_stream: Array<Zerocame_Greenscore>;
  /** fetch data from the table: "zerocame.greenscore_user_actions" */
  zerocame_greenscore_user_actions: Array<Zerocame_Greenscore_User_Actions>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_user_actions" */
  zerocame_greenscore_user_actions_aggregate: Zerocame_Greenscore_User_Actions_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_user_actions" using primary key columns */
  zerocame_greenscore_user_actions_by_pk?: Maybe<Zerocame_Greenscore_User_Actions>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_user_actions" */
  zerocame_greenscore_user_actions_stream: Array<Zerocame_Greenscore_User_Actions>;
  /** fetch data from the table: "zerocame.greenscore_users" */
  zerocame_greenscore_users: Array<Zerocame_Greenscore_Users>;
  /** fetch aggregated fields from the table: "zerocame.greenscore_users" */
  zerocame_greenscore_users_aggregate: Zerocame_Greenscore_Users_Aggregate;
  /** fetch data from the table: "zerocame.greenscore_users" using primary key columns */
  zerocame_greenscore_users_by_pk?: Maybe<Zerocame_Greenscore_Users>;
  /** fetch data from the table in a streaming manner: "zerocame.greenscore_users" */
  zerocame_greenscore_users_stream: Array<Zerocame_Greenscore_Users>;
  /** fetch data from the table: "zerocame.jma_area_master" */
  zerocame_jma_area_master: Array<Zerocame_Jma_Area_Master>;
  /** fetch aggregated fields from the table: "zerocame.jma_area_master" */
  zerocame_jma_area_master_aggregate: Zerocame_Jma_Area_Master_Aggregate;
  /** fetch data from the table: "zerocame.jma_area_master" using primary key columns */
  zerocame_jma_area_master_by_pk?: Maybe<Zerocame_Jma_Area_Master>;
  /** fetch data from the table in a streaming manner: "zerocame.jma_area_master" */
  zerocame_jma_area_master_stream: Array<Zerocame_Jma_Area_Master>;
  /** fetch data from the table: "zerocame.jma_meteorological_daily" */
  zerocame_jma_meteorological_daily: Array<Zerocame_Jma_Meteorological_Daily>;
  /** fetch aggregated fields from the table: "zerocame.jma_meteorological_daily" */
  zerocame_jma_meteorological_daily_aggregate: Zerocame_Jma_Meteorological_Daily_Aggregate;
  /** fetch data from the table: "zerocame.jma_meteorological_daily" using primary key columns */
  zerocame_jma_meteorological_daily_by_pk?: Maybe<Zerocame_Jma_Meteorological_Daily>;
  /** fetch data from the table in a streaming manner: "zerocame.jma_meteorological_daily" */
  zerocame_jma_meteorological_daily_stream: Array<Zerocame_Jma_Meteorological_Daily>;
  /** fetch data from the table: "zerocame.mst_co2_emission_coefficient" */
  zerocame_mst_co2_emission_coefficient: Array<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** fetch aggregated fields from the table: "zerocame.mst_co2_emission_coefficient" */
  zerocame_mst_co2_emission_coefficient_aggregate: Zerocame_Mst_Co2_Emission_Coefficient_Aggregate;
  /** fetch data from the table: "zerocame.mst_co2_emission_coefficient" using primary key columns */
  zerocame_mst_co2_emission_coefficient_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_co2_emission_coefficient" */
  zerocame_mst_co2_emission_coefficient_stream: Array<Zerocame_Mst_Co2_Emission_Coefficient>;
  /** fetch data from the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  zerocame_mst_co2_emission_monthly_benchmark: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** fetch aggregated fields from the table: "zerocame.mst_co2_emission_monthly_benchmark" */
  zerocame_mst_co2_emission_monthly_benchmark_aggregate: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Aggregate;
  /** fetch data from the table: "zerocame.mst_co2_emission_monthly_benchmark" using primary key columns */
  zerocame_mst_co2_emission_monthly_benchmark_by_pk?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_co2_emission_monthly_benchmark" */
  zerocame_mst_co2_emission_monthly_benchmark_stream: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
  /** fetch data from the table: "zerocame.mst_electric_company" */
  zerocame_mst_electric_company: Array<Zerocame_Mst_Electric_Company>;
  /** fetch aggregated fields from the table: "zerocame.mst_electric_company" */
  zerocame_mst_electric_company_aggregate: Zerocame_Mst_Electric_Company_Aggregate;
  /** fetch data from the table: "zerocame.mst_electric_company" using primary key columns */
  zerocame_mst_electric_company_by_pk?: Maybe<Zerocame_Mst_Electric_Company>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_electric_company" */
  zerocame_mst_electric_company_stream: Array<Zerocame_Mst_Electric_Company>;
  /** fetch data from the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  zerocame_mst_energyscore_benchmark_coefficient: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_benchmark_coefficient" */
  zerocame_mst_energyscore_benchmark_coefficient_aggregate: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_benchmark_coefficient" using primary key columns */
  zerocame_mst_energyscore_benchmark_coefficient_by_pk?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_energyscore_benchmark_coefficient" */
  zerocame_mst_energyscore_benchmark_coefficient_stream: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
  /** fetch data from the table: "zerocame.mst_energyscore_daily_benchmark" */
  zerocame_mst_energyscore_daily_benchmark: Array<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_daily_benchmark" */
  zerocame_mst_energyscore_daily_benchmark_aggregate: Zerocame_Mst_Energyscore_Daily_Benchmark_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_daily_benchmark" using primary key columns */
  zerocame_mst_energyscore_daily_benchmark_by_pk?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_energyscore_daily_benchmark" */
  zerocame_mst_energyscore_daily_benchmark_stream: Array<Zerocame_Mst_Energyscore_Daily_Benchmark>;
  /** fetch data from the table: "zerocame.mst_energyscore_difference_ratio" */
  zerocame_mst_energyscore_difference_ratio: Array<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_difference_ratio" */
  zerocame_mst_energyscore_difference_ratio_aggregate: Zerocame_Mst_Energyscore_Difference_Ratio_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_difference_ratio" using primary key columns */
  zerocame_mst_energyscore_difference_ratio_by_pk?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_energyscore_difference_ratio" */
  zerocame_mst_energyscore_difference_ratio_stream: Array<Zerocame_Mst_Energyscore_Difference_Ratio>;
  /** fetch data from the table: "zerocame.mst_energyscore_user_attribute" */
  zerocame_mst_energyscore_user_attribute: Array<Zerocame_Mst_Energyscore_User_Attribute>;
  /** fetch aggregated fields from the table: "zerocame.mst_energyscore_user_attribute" */
  zerocame_mst_energyscore_user_attribute_aggregate: Zerocame_Mst_Energyscore_User_Attribute_Aggregate;
  /** fetch data from the table: "zerocame.mst_energyscore_user_attribute" using primary key columns */
  zerocame_mst_energyscore_user_attribute_by_pk?: Maybe<Zerocame_Mst_Energyscore_User_Attribute>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_energyscore_user_attribute" */
  zerocame_mst_energyscore_user_attribute_stream: Array<Zerocame_Mst_Energyscore_User_Attribute>;
  /** fetch data from the table: "zerocame.mst_prefecture_electric_company" */
  zerocame_mst_prefecture_electric_company: Array<Zerocame_Mst_Prefecture_Electric_Company>;
  /** fetch aggregated fields from the table: "zerocame.mst_prefecture_electric_company" */
  zerocame_mst_prefecture_electric_company_aggregate: Zerocame_Mst_Prefecture_Electric_Company_Aggregate;
  /** fetch data from the table: "zerocame.mst_prefecture_electric_company" using primary key columns */
  zerocame_mst_prefecture_electric_company_by_pk?: Maybe<Zerocame_Mst_Prefecture_Electric_Company>;
  /** fetch data from the table in a streaming manner: "zerocame.mst_prefecture_electric_company" */
  zerocame_mst_prefecture_electric_company_stream: Array<Zerocame_Mst_Prefecture_Electric_Company>;
  /** fetch data from the table: "zerocame.personal_electricity_data_30_minuites" */
  zerocame_personal_electricity_data_30_minuites: Array<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** fetch aggregated fields from the table: "zerocame.personal_electricity_data_30_minuites" */
  zerocame_personal_electricity_data_30_minuites_aggregate: Zerocame_Personal_Electricity_Data_30_Minuites_Aggregate;
  /** fetch data from the table: "zerocame.personal_electricity_data_30_minuites" using primary key columns */
  zerocame_personal_electricity_data_30_minuites_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** fetch data from the table in a streaming manner: "zerocame.personal_electricity_data_30_minuites" */
  zerocame_personal_electricity_data_30_minuites_stream: Array<Zerocame_Personal_Electricity_Data_30_Minuites>;
  /** fetch data from the table: "zerocame.personal_electricity_data_daily" */
  zerocame_personal_electricity_data_daily: Array<Zerocame_Personal_Electricity_Data_Daily>;
  /** fetch aggregated fields from the table: "zerocame.personal_electricity_data_daily" */
  zerocame_personal_electricity_data_daily_aggregate: Zerocame_Personal_Electricity_Data_Daily_Aggregate;
  /** fetch data from the table: "zerocame.personal_electricity_data_daily" using primary key columns */
  zerocame_personal_electricity_data_daily_by_pk?: Maybe<Zerocame_Personal_Electricity_Data_Daily>;
  /** fetch data from the table in a streaming manner: "zerocame.personal_electricity_data_daily" */
  zerocame_personal_electricity_data_daily_stream: Array<Zerocame_Personal_Electricity_Data_Daily>;
  /** fetch data from the table: "zerocame.type_greenscore_detail_by_category" */
  zerocame_type_greenscore_detail_by_category: Array<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** fetch aggregated fields from the table: "zerocame.type_greenscore_detail_by_category" */
  zerocame_type_greenscore_detail_by_category_aggregate: Zerocame_Type_Greenscore_Detail_By_Category_Aggregate;
  /** fetch data from the table: "zerocame.type_greenscore_detail_by_category" using primary key columns */
  zerocame_type_greenscore_detail_by_category_by_pk?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** fetch data from the table in a streaming manner: "zerocame.type_greenscore_detail_by_category" */
  zerocame_type_greenscore_detail_by_category_stream: Array<Zerocame_Type_Greenscore_Detail_By_Category>;
  /** fetch data from the table: "zerocame.user_actions" */
  zerocame_user_actions: Array<Zerocame_User_Actions>;
  /** fetch aggregated fields from the table: "zerocame.user_actions" */
  zerocame_user_actions_aggregate: Zerocame_User_Actions_Aggregate;
  /** fetch data from the table: "zerocame.user_actions" using primary key columns */
  zerocame_user_actions_by_pk?: Maybe<Zerocame_User_Actions>;
  /** fetch data from the table in a streaming manner: "zerocame.user_actions" */
  zerocame_user_actions_stream: Array<Zerocame_User_Actions>;
  /** fetch data from the table: "zerocame.user_electricity_data_per_day" */
  zerocame_user_electricity_data_per_day: Array<Zerocame_User_Electricity_Data_Per_Day>;
  /** fetch aggregated fields from the table: "zerocame.user_electricity_data_per_day" */
  zerocame_user_electricity_data_per_day_aggregate: Zerocame_User_Electricity_Data_Per_Day_Aggregate;
  /** fetch data from the table: "zerocame.user_electricity_data_per_day" using primary key columns */
  zerocame_user_electricity_data_per_day_by_pk?: Maybe<Zerocame_User_Electricity_Data_Per_Day>;
  /** fetch data from the table in a streaming manner: "zerocame.user_electricity_data_per_day" */
  zerocame_user_electricity_data_per_day_stream: Array<Zerocame_User_Electricity_Data_Per_Day>;
  /** fetch data from the table: "zerocame.users" */
  zerocame_users: Array<Zerocame_Users>;
  /** fetch aggregated fields from the table: "zerocame.users" */
  zerocame_users_aggregate: Zerocame_Users_Aggregate;
  /** fetch data from the table: "zerocame.users" using primary key columns */
  zerocame_users_by_pk?: Maybe<Zerocame_Users>;
  /** fetch data from the table in a streaming manner: "zerocame.users" */
  zerocame_users_stream: Array<Zerocame_Users>;
  /** fetch data from the table: "zerocame.view_now" */
  zerocame_view_now: Array<Zerocame_View_Now>;
  /** fetch aggregated fields from the table: "zerocame.view_now" */
  zerocame_view_now_aggregate: Zerocame_View_Now_Aggregate;
  /** fetch data from the table in a streaming manner: "zerocame.view_now" */
  zerocame_view_now_stream: Array<Zerocame_View_Now>;
  /** fetch data from the table: "zerocame.view_user_electricity_data" */
  zerocame_view_user_electricity_data: Array<Zerocame_View_User_Electricity_Data>;
  /** fetch aggregated fields from the table: "zerocame.view_user_electricity_data" */
  zerocame_view_user_electricity_data_aggregate: Zerocame_View_User_Electricity_Data_Aggregate;
  /** fetch data from the table in a streaming manner: "zerocame.view_user_electricity_data" */
  zerocame_view_user_electricity_data_stream: Array<Zerocame_View_User_Electricity_Data>;
};


export type Subscription_RootZerocame_Area_MasterArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Area_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Area_Master_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Area_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Area_Master_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootZerocame_Area_Master_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Area_Master_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Area_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Bingo_User_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_Bingo_User_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_Bingo_User_Actions_By_PkArgs = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};


export type Subscription_RootZerocame_Bingo_User_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Bingo_User_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Bingo_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_Bingo_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Bingo_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Bingo_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};


export type Subscription_RootZerocame_Bingo_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Bingo_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_InputArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Input_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_Input_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Input_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_Input_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


export type Subscription_RootZerocame_Consent_Application_Input_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Consent_Application_Input_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_ResultArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_Result_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootZerocame_Consent_Application_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Consent_Application_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_Zeroca_AnalysisArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_Zeroca_Analysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};


export type Subscription_RootZerocame_Consent_Application_Zeroca_Analysis_By_PkArgs = {
  consent_info_id: Scalars['String'];
};


export type Subscription_RootZerocame_Consent_Application_Zeroca_Analysis_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};


export type Subscription_RootZerocame_Fn_Current_Consent_Application_ResultArgs = {
  args: Zerocame_Fn_Current_Consent_Application_Result_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Fn_Current_Consent_Application_Result_AggregateArgs = {
  args: Zerocame_Fn_Current_Consent_Application_Result_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Fn_Greenscore_Detail_By_CategoryArgs = {
  args: Zerocame_Fn_Greenscore_Detail_By_Category_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Subscription_RootZerocame_Fn_Greenscore_Detail_By_Category_AggregateArgs = {
  args: Zerocame_Fn_Greenscore_Detail_By_Category_Args;
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Subscription_RootZerocame_Fn_User_Electricity_Data_Per_DayArgs = {
  args: Zerocame_Fn_User_Electricity_Data_Per_Day_Args;
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Subscription_RootZerocame_Fn_User_Electricity_Data_Per_Day_AggregateArgs = {
  args: Zerocame_Fn_User_Electricity_Data_Per_Day_Args;
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Subscription_RootZerocame_GreenscoreArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Action_ReviewArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Action_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Action_Review_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootZerocame_Greenscore_Action_Review_DetailArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Action_Review_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Action_Review_Detail_By_PkArgs = {
  greenscore_action_id: Scalars['String'];
  review_id: Scalars['Int'];
};


export type Subscription_RootZerocame_Greenscore_Action_Review_Detail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Action_Review_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Action_Review_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootZerocame_Greenscore_CalcArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_ActionArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_Action_By_PkArgs = {
  greenscore_action_id: Scalars['String'];
  greenscore_calc_id: Scalars['Int'];
};


export type Subscription_RootZerocame_Greenscore_Calc_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Calc_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootZerocame_Greenscore_Calc_EnergyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_Energy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_Energy_By_PkArgs = {
  greenscore_calc_id: Scalars['Int'];
};


export type Subscription_RootZerocame_Greenscore_Calc_Energy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Calc_Energy_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Calc_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Calc_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Challenge_Code_MasterArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Challenge_Code_Master_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Challenge_Code_Master_By_PkArgs = {
  challenge_id: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Challenge_Code_Master_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_EmissionArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_ActionArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Action_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_BmArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_Bm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_Bm_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_Bm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_DrArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_Dr_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_Dr_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  year_month: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_Energy_Dr_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Co2_Emission_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Co2_Emission_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Init_HearingArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Init_Hearing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Init_Hearing_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  hearing_no: Scalars['Int'];
};


export type Subscription_RootZerocame_Greenscore_Init_Hearing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Init_Hearing_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Mission_AchievementArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Mission_Achievement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Mission_Achievement_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  challenge_id: Scalars['String'];
  mission_id: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Mission_Achievement_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Mission_Achievement_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Referral_CodeArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Code_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Referral_Code_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Code_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Referral_Code_By_PkArgs = {
  referral_code: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Referral_Code_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Referral_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Code_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Referral_ResultArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Referral_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Referral_Result_By_PkArgs = {
  referred_auth0_user_id: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Referral_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Referral_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Result_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_User_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_User_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_User_Actions_By_PkArgs = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_User_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_User_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Greenscore_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


export type Subscription_RootZerocame_Greenscore_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Greenscore_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Jma_Area_MasterArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Jma_Area_Master_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Area_Master_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Area_Master_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Jma_Area_Master_By_PkArgs = {
  block_code: Scalars['String'];
};


export type Subscription_RootZerocame_Jma_Area_Master_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Jma_Area_Master_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
};


export type Subscription_RootZerocame_Jma_Meteorological_DailyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};


export type Subscription_RootZerocame_Jma_Meteorological_Daily_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};


export type Subscription_RootZerocame_Jma_Meteorological_Daily_By_PkArgs = {
  block_code: Scalars['String'];
  date: Scalars['String'];
};


export type Subscription_RootZerocame_Jma_Meteorological_Daily_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Jma_Meteorological_Daily_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Co2_Emission_CoefficientArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Co2_Emission_Coefficient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Co2_Emission_Coefficient_By_PkArgs = {
  regist_no: Scalars['String'];
  year_month: Scalars['String'];
};


export type Subscription_RootZerocame_Mst_Co2_Emission_Coefficient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Co2_Emission_Monthly_BenchmarkArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Co2_Emission_Monthly_Benchmark_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Co2_Emission_Monthly_Benchmark_By_PkArgs = {
  attribute_no: Scalars['String'];
  year_month: Scalars['String'];
};


export type Subscription_RootZerocame_Mst_Co2_Emission_Monthly_Benchmark_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Electric_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Electric_Company_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Electric_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Electric_Company_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Electric_Company_By_PkArgs = {
  regist_no: Scalars['String'];
};


export type Subscription_RootZerocame_Mst_Electric_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Electric_Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Electric_Company_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Benchmark_CoefficientArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Benchmark_Coefficient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Benchmark_Coefficient_By_PkArgs = {
  attribute_item: Scalars['String'];
  attribute_value: Scalars['String'];
};


export type Subscription_RootZerocame_Mst_Energyscore_Benchmark_Coefficient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Daily_BenchmarkArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Daily_Benchmark_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Daily_Benchmark_By_PkArgs = {
  attribute_no: Scalars['String'];
  date: Scalars['String'];
};


export type Subscription_RootZerocame_Mst_Energyscore_Daily_Benchmark_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Difference_RatioArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Difference_Ratio_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_Difference_Ratio_By_PkArgs = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};


export type Subscription_RootZerocame_Mst_Energyscore_Difference_Ratio_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_User_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_User_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Energyscore_User_Attribute_By_PkArgs = {
  no: Scalars['String'];
};


export type Subscription_RootZerocame_Mst_Energyscore_User_Attribute_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Prefecture_Electric_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Prefecture_Electric_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Order_By>>;
  where?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>;
};


export type Subscription_RootZerocame_Mst_Prefecture_Electric_Company_By_PkArgs = {
  pref_code: Scalars['String'];
};


export type Subscription_RootZerocame_Mst_Prefecture_Electric_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>;
};


export type Subscription_RootZerocame_Personal_Electricity_Data_30_MinuitesArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>;
};


export type Subscription_RootZerocame_Personal_Electricity_Data_30_Minuites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>;
};


export type Subscription_RootZerocame_Personal_Electricity_Data_30_Minuites_By_PkArgs = {
  consumer_spcfc_id: Scalars['String'];
  get_ymd: Scalars['String'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
  time_code: Scalars['String'];
};


export type Subscription_RootZerocame_Personal_Electricity_Data_30_Minuites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>;
};


export type Subscription_RootZerocame_Personal_Electricity_Data_DailyArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>;
};


export type Subscription_RootZerocame_Personal_Electricity_Data_Daily_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>;
};


export type Subscription_RootZerocame_Personal_Electricity_Data_Daily_By_PkArgs = {
  consumer_spcfc_id: Scalars['String'];
  get_ymd: Scalars['String'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
};


export type Subscription_RootZerocame_Personal_Electricity_Data_Daily_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>;
};


export type Subscription_RootZerocame_Type_Greenscore_Detail_By_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Subscription_RootZerocame_Type_Greenscore_Detail_By_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Order_By>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Subscription_RootZerocame_Type_Greenscore_Detail_By_Category_By_PkArgs = {
  category_name: Scalars['String'];
};


export type Subscription_RootZerocame_Type_Greenscore_Detail_By_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};


export type Subscription_RootZerocame_User_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_User_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Actions_Order_By>>;
  where?: InputMaybe<Zerocame_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_User_Actions_By_PkArgs = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
};


export type Subscription_RootZerocame_User_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_User_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_User_Actions_Bool_Exp>;
};


export type Subscription_RootZerocame_User_Electricity_Data_Per_DayArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Subscription_RootZerocame_User_Electricity_Data_Per_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Order_By>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Subscription_RootZerocame_User_Electricity_Data_Per_Day_By_PkArgs = {
  consent_application_result_id: Scalars['Int'];
  get_ymd: Scalars['String'];
};


export type Subscription_RootZerocame_User_Electricity_Data_Per_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};


export type Subscription_RootZerocame_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_Users_By_PkArgs = {
  auth0_user_id: Scalars['String'];
};


export type Subscription_RootZerocame_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_Users_Bool_Exp>;
};


export type Subscription_RootZerocame_View_NowArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_Now_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_Now_Order_By>>;
  where?: InputMaybe<Zerocame_View_Now_Bool_Exp>;
};


export type Subscription_RootZerocame_View_Now_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_Now_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_Now_Order_By>>;
  where?: InputMaybe<Zerocame_View_Now_Bool_Exp>;
};


export type Subscription_RootZerocame_View_Now_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_View_Now_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_View_Now_Bool_Exp>;
};


export type Subscription_RootZerocame_View_User_Electricity_DataArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Order_By>>;
  where?: InputMaybe<Zerocame_View_User_Electricity_Data_Bool_Exp>;
};


export type Subscription_RootZerocame_View_User_Electricity_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Order_By>>;
  where?: InputMaybe<Zerocame_View_User_Electricity_Data_Bool_Exp>;
};


export type Subscription_RootZerocame_View_User_Electricity_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zerocame_View_User_Electricity_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Zerocame_View_User_Electricity_Data_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** 全国地方公共団体コードをもとにした地域マスタ */
export type Zerocame_Area_Master = {
  __typename?: 'zerocame_area_master';
  block_code: Scalars['String'];
  code: Scalars['String'];
  /** An object relationship */
  jma_area_master: Zerocame_Jma_Area_Master;
  kubun: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "zerocame.area_master" */
export type Zerocame_Area_Master_Aggregate = {
  __typename?: 'zerocame_area_master_aggregate';
  aggregate?: Maybe<Zerocame_Area_Master_Aggregate_Fields>;
  nodes: Array<Zerocame_Area_Master>;
};

/** aggregate fields of "zerocame.area_master" */
export type Zerocame_Area_Master_Aggregate_Fields = {
  __typename?: 'zerocame_area_master_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Area_Master_Max_Fields>;
  min?: Maybe<Zerocame_Area_Master_Min_Fields>;
};


/** aggregate fields of "zerocame.area_master" */
export type Zerocame_Area_Master_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Area_Master_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.area_master". All fields are combined with a logical 'AND'. */
export type Zerocame_Area_Master_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Area_Master_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Area_Master_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Area_Master_Bool_Exp>>;
  block_code?: InputMaybe<String_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  jma_area_master?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
  kubun?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.area_master" */
export enum Zerocame_Area_Master_Constraint {
  /** unique or primary key constraint on columns "code" */
  AreaMasterPkey = 'area_master_pkey'
}

/** input type for inserting data into table "zerocame.area_master" */
export type Zerocame_Area_Master_Insert_Input = {
  block_code?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  jma_area_master?: InputMaybe<Zerocame_Jma_Area_Master_Obj_Rel_Insert_Input>;
  kubun?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Area_Master_Max_Fields = {
  __typename?: 'zerocame_area_master_max_fields';
  block_code?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  kubun?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Area_Master_Min_Fields = {
  __typename?: 'zerocame_area_master_min_fields';
  block_code?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  kubun?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.area_master" */
export type Zerocame_Area_Master_Mutation_Response = {
  __typename?: 'zerocame_area_master_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Area_Master>;
};

/** on_conflict condition type for table "zerocame.area_master" */
export type Zerocame_Area_Master_On_Conflict = {
  constraint: Zerocame_Area_Master_Constraint;
  update_columns?: Array<Zerocame_Area_Master_Update_Column>;
  where?: InputMaybe<Zerocame_Area_Master_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.area_master". */
export type Zerocame_Area_Master_Order_By = {
  block_code?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  jma_area_master?: InputMaybe<Zerocame_Jma_Area_Master_Order_By>;
  kubun?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.area_master */
export type Zerocame_Area_Master_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "zerocame.area_master" */
export enum Zerocame_Area_Master_Select_Column {
  /** column name */
  BlockCode = 'block_code',
  /** column name */
  Code = 'code',
  /** column name */
  Kubun = 'kubun',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "zerocame.area_master" */
export type Zerocame_Area_Master_Set_Input = {
  block_code?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  kubun?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_area_master" */
export type Zerocame_Area_Master_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Area_Master_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Area_Master_Stream_Cursor_Value_Input = {
  block_code?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  kubun?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.area_master" */
export enum Zerocame_Area_Master_Update_Column {
  /** column name */
  BlockCode = 'block_code',
  /** column name */
  Code = 'code',
  /** column name */
  Kubun = 'kubun',
  /** column name */
  Name = 'name'
}

export type Zerocame_Area_Master_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Area_Master_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Area_Master_Bool_Exp;
};

/** 【ビンゴ】ユーザーのアクション達成状況を管理するテーブル */
export type Zerocame_Bingo_User_Actions = {
  __typename?: 'zerocame_bingo_user_actions';
  action_id: Scalars['String'];
  /** 入力１のプルダウンの選択値 */
  action_input1_pulldown?: Maybe<Scalars['String']>;
  /** 入力１のテキストの入力値 */
  action_input1_text?: Maybe<Scalars['String']>;
  /** 入力２のテキストの入力値 */
  action_input2_text?: Maybe<Scalars['String']>;
  /** 入力３のテキストの入力値 */
  action_input3_text?: Maybe<Scalars['String']>;
  action_text?: Maybe<Scalars['String']>;
  auth0_user_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_at_from_epoch: Scalars['numeric'];
  /** 電気連動アクション完了時の電力データ表示基準日 */
  electricity_start_point_date?: Maybe<Scalars['String']>;
  municipality: Scalars['String'];
};

/** aggregated selection of "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Aggregate = {
  __typename?: 'zerocame_bingo_user_actions_aggregate';
  aggregate?: Maybe<Zerocame_Bingo_User_Actions_Aggregate_Fields>;
  nodes: Array<Zerocame_Bingo_User_Actions>;
};

/** aggregate fields of "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Aggregate_Fields = {
  __typename?: 'zerocame_bingo_user_actions_aggregate_fields';
  avg?: Maybe<Zerocame_Bingo_User_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Bingo_User_Actions_Max_Fields>;
  min?: Maybe<Zerocame_Bingo_User_Actions_Min_Fields>;
  stddev?: Maybe<Zerocame_Bingo_User_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Bingo_User_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Bingo_User_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Bingo_User_Actions_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Bingo_User_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Bingo_User_Actions_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Bingo_User_Actions_Variance_Fields>;
};


/** aggregate fields of "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Bingo_User_Actions_Avg_Fields = {
  __typename?: 'zerocame_bingo_user_actions_avg_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.bingo_user_actions". All fields are combined with a logical 'AND'. */
export type Zerocame_Bingo_User_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Bingo_User_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Bingo_User_Actions_Bool_Exp>>;
  action_id?: InputMaybe<String_Comparison_Exp>;
  action_input1_pulldown?: InputMaybe<String_Comparison_Exp>;
  action_input1_text?: InputMaybe<String_Comparison_Exp>;
  action_input2_text?: InputMaybe<String_Comparison_Exp>;
  action_input3_text?: InputMaybe<String_Comparison_Exp>;
  action_text?: InputMaybe<String_Comparison_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at_from_epoch?: InputMaybe<Numeric_Comparison_Exp>;
  electricity_start_point_date?: InputMaybe<String_Comparison_Exp>;
  municipality?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.bingo_user_actions" */
export enum Zerocame_Bingo_User_Actions_Constraint {
  /** unique or primary key constraint on columns "municipality", "action_id", "auth0_user_id" */
  BingoUserActionsPkey = 'bingo_user_actions_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Inc_Input = {
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Insert_Input = {
  action_id?: InputMaybe<Scalars['String']>;
  /** 入力１のプルダウンの選択値 */
  action_input1_pulldown?: InputMaybe<Scalars['String']>;
  /** 入力１のテキストの入力値 */
  action_input1_text?: InputMaybe<Scalars['String']>;
  /** 入力２のテキストの入力値 */
  action_input2_text?: InputMaybe<Scalars['String']>;
  /** 入力３のテキストの入力値 */
  action_input3_text?: InputMaybe<Scalars['String']>;
  action_text?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
  /** 電気連動アクション完了時の電力データ表示基準日 */
  electricity_start_point_date?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Bingo_User_Actions_Max_Fields = {
  __typename?: 'zerocame_bingo_user_actions_max_fields';
  action_id?: Maybe<Scalars['String']>;
  /** 入力１のプルダウンの選択値 */
  action_input1_pulldown?: Maybe<Scalars['String']>;
  /** 入力１のテキストの入力値 */
  action_input1_text?: Maybe<Scalars['String']>;
  /** 入力２のテキストの入力値 */
  action_input2_text?: Maybe<Scalars['String']>;
  /** 入力３のテキストの入力値 */
  action_input3_text?: Maybe<Scalars['String']>;
  action_text?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_at_from_epoch?: Maybe<Scalars['numeric']>;
  /** 電気連動アクション完了時の電力データ表示基準日 */
  electricity_start_point_date?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Bingo_User_Actions_Min_Fields = {
  __typename?: 'zerocame_bingo_user_actions_min_fields';
  action_id?: Maybe<Scalars['String']>;
  /** 入力１のプルダウンの選択値 */
  action_input1_pulldown?: Maybe<Scalars['String']>;
  /** 入力１のテキストの入力値 */
  action_input1_text?: Maybe<Scalars['String']>;
  /** 入力２のテキストの入力値 */
  action_input2_text?: Maybe<Scalars['String']>;
  /** 入力３のテキストの入力値 */
  action_input3_text?: Maybe<Scalars['String']>;
  action_text?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_at_from_epoch?: Maybe<Scalars['numeric']>;
  /** 電気連動アクション完了時の電力データ表示基準日 */
  electricity_start_point_date?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Mutation_Response = {
  __typename?: 'zerocame_bingo_user_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Bingo_User_Actions>;
};

/** on_conflict condition type for table "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_On_Conflict = {
  constraint: Zerocame_Bingo_User_Actions_Constraint;
  update_columns?: Array<Zerocame_Bingo_User_Actions_Update_Column>;
  where?: InputMaybe<Zerocame_Bingo_User_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.bingo_user_actions". */
export type Zerocame_Bingo_User_Actions_Order_By = {
  action_id?: InputMaybe<Order_By>;
  action_input1_pulldown?: InputMaybe<Order_By>;
  action_input1_text?: InputMaybe<Order_By>;
  action_input2_text?: InputMaybe<Order_By>;
  action_input3_text?: InputMaybe<Order_By>;
  action_text?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_at_from_epoch?: InputMaybe<Order_By>;
  electricity_start_point_date?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.bingo_user_actions */
export type Zerocame_Bingo_User_Actions_Pk_Columns_Input = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};

/** select columns of table "zerocame.bingo_user_actions" */
export enum Zerocame_Bingo_User_Actions_Select_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  ActionInput1Pulldown = 'action_input1_pulldown',
  /** column name */
  ActionInput1Text = 'action_input1_text',
  /** column name */
  ActionInput2Text = 'action_input2_text',
  /** column name */
  ActionInput3Text = 'action_input3_text',
  /** column name */
  ActionText = 'action_text',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedAtFromEpoch = 'created_at_from_epoch',
  /** column name */
  ElectricityStartPointDate = 'electricity_start_point_date',
  /** column name */
  Municipality = 'municipality'
}

/** input type for updating data in table "zerocame.bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Set_Input = {
  action_id?: InputMaybe<Scalars['String']>;
  /** 入力１のプルダウンの選択値 */
  action_input1_pulldown?: InputMaybe<Scalars['String']>;
  /** 入力１のテキストの入力値 */
  action_input1_text?: InputMaybe<Scalars['String']>;
  /** 入力２のテキストの入力値 */
  action_input2_text?: InputMaybe<Scalars['String']>;
  /** 入力３のテキストの入力値 */
  action_input3_text?: InputMaybe<Scalars['String']>;
  action_text?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
  /** 電気連動アクション完了時の電力データ表示基準日 */
  electricity_start_point_date?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Bingo_User_Actions_Stddev_Fields = {
  __typename?: 'zerocame_bingo_user_actions_stddev_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Bingo_User_Actions_Stddev_Pop_Fields = {
  __typename?: 'zerocame_bingo_user_actions_stddev_pop_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Bingo_User_Actions_Stddev_Samp_Fields = {
  __typename?: 'zerocame_bingo_user_actions_stddev_samp_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_bingo_user_actions" */
export type Zerocame_Bingo_User_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Bingo_User_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Bingo_User_Actions_Stream_Cursor_Value_Input = {
  action_id?: InputMaybe<Scalars['String']>;
  /** 入力１のプルダウンの選択値 */
  action_input1_pulldown?: InputMaybe<Scalars['String']>;
  /** 入力１のテキストの入力値 */
  action_input1_text?: InputMaybe<Scalars['String']>;
  /** 入力２のテキストの入力値 */
  action_input2_text?: InputMaybe<Scalars['String']>;
  /** 入力３のテキストの入力値 */
  action_input3_text?: InputMaybe<Scalars['String']>;
  action_text?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
  /** 電気連動アクション完了時の電力データ表示基準日 */
  electricity_start_point_date?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Bingo_User_Actions_Sum_Fields = {
  __typename?: 'zerocame_bingo_user_actions_sum_fields';
  created_at_from_epoch?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.bingo_user_actions" */
export enum Zerocame_Bingo_User_Actions_Update_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  ActionInput1Pulldown = 'action_input1_pulldown',
  /** column name */
  ActionInput1Text = 'action_input1_text',
  /** column name */
  ActionInput2Text = 'action_input2_text',
  /** column name */
  ActionInput3Text = 'action_input3_text',
  /** column name */
  ActionText = 'action_text',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedAtFromEpoch = 'created_at_from_epoch',
  /** column name */
  ElectricityStartPointDate = 'electricity_start_point_date',
  /** column name */
  Municipality = 'municipality'
}

export type Zerocame_Bingo_User_Actions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Bingo_User_Actions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Bingo_User_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Bingo_User_Actions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Bingo_User_Actions_Var_Pop_Fields = {
  __typename?: 'zerocame_bingo_user_actions_var_pop_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Bingo_User_Actions_Var_Samp_Fields = {
  __typename?: 'zerocame_bingo_user_actions_var_samp_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Bingo_User_Actions_Variance_Fields = {
  __typename?: 'zerocame_bingo_user_actions_variance_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
};

/** ビンゴアプリ向けユーザー情報 */
export type Zerocame_Bingo_Users = {
  __typename?: 'zerocame_bingo_users';
  auth0_user_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 【削除予定】nameを使用します */
  family_name?: Maybe<Scalars['String']>;
  /** 【削除予定】nameを使用します */
  given_name?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  municipalities?: Maybe<Scalars['String']>;
  municipality: Scalars['String'];
  /** 20231204リリース後にnot nullにする */
  name?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Aggregate = {
  __typename?: 'zerocame_bingo_users_aggregate';
  aggregate?: Maybe<Zerocame_Bingo_Users_Aggregate_Fields>;
  nodes: Array<Zerocame_Bingo_Users>;
};

export type Zerocame_Bingo_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Zerocame_Bingo_Users_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Bingo_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Aggregate_Fields = {
  __typename?: 'zerocame_bingo_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Bingo_Users_Max_Fields>;
  min?: Maybe<Zerocame_Bingo_Users_Min_Fields>;
};


/** aggregate fields of "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Bingo_Users_Max_Order_By>;
  min?: InputMaybe<Zerocame_Bingo_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Bingo_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Bingo_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zerocame.bingo_users". All fields are combined with a logical 'AND'. */
export type Zerocame_Bingo_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Bingo_Users_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Bingo_Users_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  family_name?: InputMaybe<String_Comparison_Exp>;
  given_name?: InputMaybe<String_Comparison_Exp>;
  grade?: InputMaybe<String_Comparison_Exp>;
  municipalities?: InputMaybe<String_Comparison_Exp>;
  municipality?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  school?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.bingo_users" */
export enum Zerocame_Bingo_Users_Constraint {
  /** unique or primary key constraint on columns "municipality", "auth0_user_id" */
  BingoUsersPkey = 'bingo_users_pkey'
}

/** input type for inserting data into table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 【削除予定】nameを使用します */
  family_name?: InputMaybe<Scalars['String']>;
  /** 【削除予定】nameを使用します */
  given_name?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  municipalities?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  /** 20231204リリース後にnot nullにする */
  name?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Zerocame_Bingo_Users_Max_Fields = {
  __typename?: 'zerocame_bingo_users_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 【削除予定】nameを使用します */
  family_name?: Maybe<Scalars['String']>;
  /** 【削除予定】nameを使用します */
  given_name?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  municipalities?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  /** 20231204リリース後にnot nullにする */
  name?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Max_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** 【削除予定】nameを使用します */
  family_name?: InputMaybe<Order_By>;
  /** 【削除予定】nameを使用します */
  given_name?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  municipalities?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  /** 20231204リリース後にnot nullにする */
  name?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Bingo_Users_Min_Fields = {
  __typename?: 'zerocame_bingo_users_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 【削除予定】nameを使用します */
  family_name?: Maybe<Scalars['String']>;
  /** 【削除予定】nameを使用します */
  given_name?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  municipalities?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  /** 20231204リリース後にnot nullにする */
  name?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Min_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** 【削除予定】nameを使用します */
  family_name?: InputMaybe<Order_By>;
  /** 【削除予定】nameを使用します */
  given_name?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  municipalities?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  /** 20231204リリース後にnot nullにする */
  name?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Mutation_Response = {
  __typename?: 'zerocame_bingo_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Bingo_Users>;
};

/** on_conflict condition type for table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_On_Conflict = {
  constraint: Zerocame_Bingo_Users_Constraint;
  update_columns?: Array<Zerocame_Bingo_Users_Update_Column>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.bingo_users". */
export type Zerocame_Bingo_Users_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  family_name?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  grade?: InputMaybe<Order_By>;
  municipalities?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  school?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.bingo_users */
export type Zerocame_Bingo_Users_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
  municipality: Scalars['String'];
};

/** select columns of table "zerocame.bingo_users" */
export enum Zerocame_Bingo_Users_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FamilyName = 'family_name',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  Grade = 'grade',
  /** column name */
  Municipalities = 'municipalities',
  /** column name */
  Municipality = 'municipality',
  /** column name */
  Name = 'name',
  /** column name */
  School = 'school',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "zerocame.bingo_users" */
export type Zerocame_Bingo_Users_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 【削除予定】nameを使用します */
  family_name?: InputMaybe<Scalars['String']>;
  /** 【削除予定】nameを使用します */
  given_name?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  municipalities?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  /** 20231204リリース後にnot nullにする */
  name?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "zerocame_bingo_users" */
export type Zerocame_Bingo_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Bingo_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Bingo_Users_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 【削除予定】nameを使用します */
  family_name?: InputMaybe<Scalars['String']>;
  /** 【削除予定】nameを使用します */
  given_name?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  municipalities?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  /** 20231204リリース後にnot nullにする */
  name?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "zerocame.bingo_users" */
export enum Zerocame_Bingo_Users_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FamilyName = 'family_name',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  Grade = 'grade',
  /** column name */
  Municipalities = 'municipalities',
  /** column name */
  Municipality = 'municipality',
  /** column name */
  Name = 'name',
  /** column name */
  School = 'school',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Zerocame_Bingo_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Bingo_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Bingo_Users_Bool_Exp;
};

/** ユーザーの同意申込入力 */
export type Zerocame_Consent_Application_Input = {
  __typename?: 'zerocame_consent_application_input';
  auth0_user_id: Scalars['String'];
  contractor_address_postal_code: Scalars['String'];
  contractor_address_state: Scalars['String'];
  contractor_address_state_later: Scalars['String'];
  contractor_name_kana_mei: Scalars['String'];
  contractor_name_kana_sei: Scalars['String'];
  contractor_name_kanji_mei: Scalars['String'];
  contractor_name_kanji_sei: Scalars['String'];
  contractor_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 初回連携の場合、TRUE 再連携の場合、FALSE */
  is_initial_linkage: Scalars['Boolean'];
  point_address_postal_code?: Maybe<Scalars['String']>;
  point_address_state: Scalars['String'];
  point_address_state_later: Scalars['String'];
  point_contract_facility_type?: Maybe<Scalars['String']>;
  point_meter_id?: Maybe<Scalars['String']>;
  point_spply_rcvng_pwr_division?: Maybe<Scalars['String']>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: Maybe<Scalars['String']>;
  point_voltage_classification?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Aggregate = {
  __typename?: 'zerocame_consent_application_input_aggregate';
  aggregate?: Maybe<Zerocame_Consent_Application_Input_Aggregate_Fields>;
  nodes: Array<Zerocame_Consent_Application_Input>;
};

export type Zerocame_Consent_Application_Input_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_And = {
  arguments: Zerocame_Consent_Application_Input_Select_Column_Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Zerocame_Consent_Application_Input_Select_Column_Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Aggregate_Fields = {
  __typename?: 'zerocame_consent_application_input_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Consent_Application_Input_Max_Fields>;
  min?: Maybe<Zerocame_Consent_Application_Input_Min_Fields>;
};


/** aggregate fields of "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Consent_Application_Input_Max_Order_By>;
  min?: InputMaybe<Zerocame_Consent_Application_Input_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Consent_Application_Input_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Input_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zerocame.consent_application_input". All fields are combined with a logical 'AND'. */
export type Zerocame_Consent_Application_Input_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Consent_Application_Input_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Consent_Application_Input_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  contractor_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  contractor_address_state?: InputMaybe<String_Comparison_Exp>;
  contractor_address_state_later?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kana_mei?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kana_sei?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kanji_mei?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kanji_sei?: InputMaybe<String_Comparison_Exp>;
  contractor_phone_number?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_initial_linkage?: InputMaybe<Boolean_Comparison_Exp>;
  point_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  point_address_state?: InputMaybe<String_Comparison_Exp>;
  point_address_state_later?: InputMaybe<String_Comparison_Exp>;
  point_contract_facility_type?: InputMaybe<String_Comparison_Exp>;
  point_meter_id?: InputMaybe<String_Comparison_Exp>;
  point_spply_rcvng_pwr_division?: InputMaybe<String_Comparison_Exp>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: InputMaybe<String_Comparison_Exp>;
  point_voltage_classification?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.consent_application_input" */
export enum Zerocame_Consent_Application_Input_Constraint {
  /** unique or primary key constraint on columns "auth0_user_id" */
  ConsentApplicationInputPkey = 'consent_application_input_pkey'
}

/** input type for inserting data into table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  contractor_address_postal_code?: InputMaybe<Scalars['String']>;
  contractor_address_state?: InputMaybe<Scalars['String']>;
  contractor_address_state_later?: InputMaybe<Scalars['String']>;
  contractor_name_kana_mei?: InputMaybe<Scalars['String']>;
  contractor_name_kana_sei?: InputMaybe<Scalars['String']>;
  contractor_name_kanji_mei?: InputMaybe<Scalars['String']>;
  contractor_name_kanji_sei?: InputMaybe<Scalars['String']>;
  contractor_phone_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 初回連携の場合、TRUE 再連携の場合、FALSE */
  is_initial_linkage?: InputMaybe<Scalars['Boolean']>;
  point_address_postal_code?: InputMaybe<Scalars['String']>;
  point_address_state?: InputMaybe<Scalars['String']>;
  point_address_state_later?: InputMaybe<Scalars['String']>;
  point_contract_facility_type?: InputMaybe<Scalars['String']>;
  point_meter_id?: InputMaybe<Scalars['String']>;
  point_spply_rcvng_pwr_division?: InputMaybe<Scalars['String']>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: InputMaybe<Scalars['String']>;
  point_voltage_classification?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Consent_Application_Input_Max_Fields = {
  __typename?: 'zerocame_consent_application_input_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  contractor_address_postal_code?: Maybe<Scalars['String']>;
  contractor_address_state?: Maybe<Scalars['String']>;
  contractor_address_state_later?: Maybe<Scalars['String']>;
  contractor_name_kana_mei?: Maybe<Scalars['String']>;
  contractor_name_kana_sei?: Maybe<Scalars['String']>;
  contractor_name_kanji_mei?: Maybe<Scalars['String']>;
  contractor_name_kanji_sei?: Maybe<Scalars['String']>;
  contractor_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  point_address_postal_code?: Maybe<Scalars['String']>;
  point_address_state?: Maybe<Scalars['String']>;
  point_address_state_later?: Maybe<Scalars['String']>;
  point_contract_facility_type?: Maybe<Scalars['String']>;
  point_meter_id?: Maybe<Scalars['String']>;
  point_spply_rcvng_pwr_division?: Maybe<Scalars['String']>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: Maybe<Scalars['String']>;
  point_voltage_classification?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Max_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  contractor_address_postal_code?: InputMaybe<Order_By>;
  contractor_address_state?: InputMaybe<Order_By>;
  contractor_address_state_later?: InputMaybe<Order_By>;
  contractor_name_kana_mei?: InputMaybe<Order_By>;
  contractor_name_kana_sei?: InputMaybe<Order_By>;
  contractor_name_kanji_mei?: InputMaybe<Order_By>;
  contractor_name_kanji_sei?: InputMaybe<Order_By>;
  contractor_phone_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  point_address_postal_code?: InputMaybe<Order_By>;
  point_address_state?: InputMaybe<Order_By>;
  point_address_state_later?: InputMaybe<Order_By>;
  point_contract_facility_type?: InputMaybe<Order_By>;
  point_meter_id?: InputMaybe<Order_By>;
  point_spply_rcvng_pwr_division?: InputMaybe<Order_By>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: InputMaybe<Order_By>;
  point_voltage_classification?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Consent_Application_Input_Min_Fields = {
  __typename?: 'zerocame_consent_application_input_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  contractor_address_postal_code?: Maybe<Scalars['String']>;
  contractor_address_state?: Maybe<Scalars['String']>;
  contractor_address_state_later?: Maybe<Scalars['String']>;
  contractor_name_kana_mei?: Maybe<Scalars['String']>;
  contractor_name_kana_sei?: Maybe<Scalars['String']>;
  contractor_name_kanji_mei?: Maybe<Scalars['String']>;
  contractor_name_kanji_sei?: Maybe<Scalars['String']>;
  contractor_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  point_address_postal_code?: Maybe<Scalars['String']>;
  point_address_state?: Maybe<Scalars['String']>;
  point_address_state_later?: Maybe<Scalars['String']>;
  point_contract_facility_type?: Maybe<Scalars['String']>;
  point_meter_id?: Maybe<Scalars['String']>;
  point_spply_rcvng_pwr_division?: Maybe<Scalars['String']>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: Maybe<Scalars['String']>;
  point_voltage_classification?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Min_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  contractor_address_postal_code?: InputMaybe<Order_By>;
  contractor_address_state?: InputMaybe<Order_By>;
  contractor_address_state_later?: InputMaybe<Order_By>;
  contractor_name_kana_mei?: InputMaybe<Order_By>;
  contractor_name_kana_sei?: InputMaybe<Order_By>;
  contractor_name_kanji_mei?: InputMaybe<Order_By>;
  contractor_name_kanji_sei?: InputMaybe<Order_By>;
  contractor_phone_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  point_address_postal_code?: InputMaybe<Order_By>;
  point_address_state?: InputMaybe<Order_By>;
  point_address_state_later?: InputMaybe<Order_By>;
  point_contract_facility_type?: InputMaybe<Order_By>;
  point_meter_id?: InputMaybe<Order_By>;
  point_spply_rcvng_pwr_division?: InputMaybe<Order_By>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: InputMaybe<Order_By>;
  point_voltage_classification?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Mutation_Response = {
  __typename?: 'zerocame_consent_application_input_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Consent_Application_Input>;
};

/** on_conflict condition type for table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_On_Conflict = {
  constraint: Zerocame_Consent_Application_Input_Constraint;
  update_columns?: Array<Zerocame_Consent_Application_Input_Update_Column>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.consent_application_input". */
export type Zerocame_Consent_Application_Input_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  contractor_address_postal_code?: InputMaybe<Order_By>;
  contractor_address_state?: InputMaybe<Order_By>;
  contractor_address_state_later?: InputMaybe<Order_By>;
  contractor_name_kana_mei?: InputMaybe<Order_By>;
  contractor_name_kana_sei?: InputMaybe<Order_By>;
  contractor_name_kanji_mei?: InputMaybe<Order_By>;
  contractor_name_kanji_sei?: InputMaybe<Order_By>;
  contractor_phone_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  is_initial_linkage?: InputMaybe<Order_By>;
  point_address_postal_code?: InputMaybe<Order_By>;
  point_address_state?: InputMaybe<Order_By>;
  point_address_state_later?: InputMaybe<Order_By>;
  point_contract_facility_type?: InputMaybe<Order_By>;
  point_meter_id?: InputMaybe<Order_By>;
  point_spply_rcvng_pwr_division?: InputMaybe<Order_By>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: InputMaybe<Order_By>;
  point_voltage_classification?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.consent_application_input */
export type Zerocame_Consent_Application_Input_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
};

/** select columns of table "zerocame.consent_application_input" */
export enum Zerocame_Consent_Application_Input_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ContractorAddressPostalCode = 'contractor_address_postal_code',
  /** column name */
  ContractorAddressState = 'contractor_address_state',
  /** column name */
  ContractorAddressStateLater = 'contractor_address_state_later',
  /** column name */
  ContractorNameKanaMei = 'contractor_name_kana_mei',
  /** column name */
  ContractorNameKanaSei = 'contractor_name_kana_sei',
  /** column name */
  ContractorNameKanjiMei = 'contractor_name_kanji_mei',
  /** column name */
  ContractorNameKanjiSei = 'contractor_name_kanji_sei',
  /** column name */
  ContractorPhoneNumber = 'contractor_phone_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsInitialLinkage = 'is_initial_linkage',
  /** column name */
  PointAddressPostalCode = 'point_address_postal_code',
  /** column name */
  PointAddressState = 'point_address_state',
  /** column name */
  PointAddressStateLater = 'point_address_state_later',
  /** column name */
  PointContractFacilityType = 'point_contract_facility_type',
  /** column name */
  PointMeterId = 'point_meter_id',
  /** column name */
  PointSpplyRcvngPwrDivision = 'point_spply_rcvng_pwr_division',
  /** column name */
  PointSpplyRcvngPwrPntSpcfcNo = 'point_spply_rcvng_pwr_pnt__spcfc_no',
  /** column name */
  PointVoltageClassification = 'point_voltage_classification'
}

/** select "zerocame_consent_application_input_aggregate_bool_exp_bool_and_arguments_columns" columns of table "zerocame.consent_application_input" */
export enum Zerocame_Consent_Application_Input_Select_Column_Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsInitialLinkage = 'is_initial_linkage'
}

/** select "zerocame_consent_application_input_aggregate_bool_exp_bool_or_arguments_columns" columns of table "zerocame.consent_application_input" */
export enum Zerocame_Consent_Application_Input_Select_Column_Zerocame_Consent_Application_Input_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsInitialLinkage = 'is_initial_linkage'
}

/** input type for updating data in table "zerocame.consent_application_input" */
export type Zerocame_Consent_Application_Input_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  contractor_address_postal_code?: InputMaybe<Scalars['String']>;
  contractor_address_state?: InputMaybe<Scalars['String']>;
  contractor_address_state_later?: InputMaybe<Scalars['String']>;
  contractor_name_kana_mei?: InputMaybe<Scalars['String']>;
  contractor_name_kana_sei?: InputMaybe<Scalars['String']>;
  contractor_name_kanji_mei?: InputMaybe<Scalars['String']>;
  contractor_name_kanji_sei?: InputMaybe<Scalars['String']>;
  contractor_phone_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 初回連携の場合、TRUE 再連携の場合、FALSE */
  is_initial_linkage?: InputMaybe<Scalars['Boolean']>;
  point_address_postal_code?: InputMaybe<Scalars['String']>;
  point_address_state?: InputMaybe<Scalars['String']>;
  point_address_state_later?: InputMaybe<Scalars['String']>;
  point_contract_facility_type?: InputMaybe<Scalars['String']>;
  point_meter_id?: InputMaybe<Scalars['String']>;
  point_spply_rcvng_pwr_division?: InputMaybe<Scalars['String']>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: InputMaybe<Scalars['String']>;
  point_voltage_classification?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_consent_application_input" */
export type Zerocame_Consent_Application_Input_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Consent_Application_Input_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Consent_Application_Input_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  contractor_address_postal_code?: InputMaybe<Scalars['String']>;
  contractor_address_state?: InputMaybe<Scalars['String']>;
  contractor_address_state_later?: InputMaybe<Scalars['String']>;
  contractor_name_kana_mei?: InputMaybe<Scalars['String']>;
  contractor_name_kana_sei?: InputMaybe<Scalars['String']>;
  contractor_name_kanji_mei?: InputMaybe<Scalars['String']>;
  contractor_name_kanji_sei?: InputMaybe<Scalars['String']>;
  contractor_phone_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 初回連携の場合、TRUE 再連携の場合、FALSE */
  is_initial_linkage?: InputMaybe<Scalars['Boolean']>;
  point_address_postal_code?: InputMaybe<Scalars['String']>;
  point_address_state?: InputMaybe<Scalars['String']>;
  point_address_state_later?: InputMaybe<Scalars['String']>;
  point_contract_facility_type?: InputMaybe<Scalars['String']>;
  point_meter_id?: InputMaybe<Scalars['String']>;
  point_spply_rcvng_pwr_division?: InputMaybe<Scalars['String']>;
  point_spply_rcvng_pwr_pnt__spcfc_no?: InputMaybe<Scalars['String']>;
  point_voltage_classification?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.consent_application_input" */
export enum Zerocame_Consent_Application_Input_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ContractorAddressPostalCode = 'contractor_address_postal_code',
  /** column name */
  ContractorAddressState = 'contractor_address_state',
  /** column name */
  ContractorAddressStateLater = 'contractor_address_state_later',
  /** column name */
  ContractorNameKanaMei = 'contractor_name_kana_mei',
  /** column name */
  ContractorNameKanaSei = 'contractor_name_kana_sei',
  /** column name */
  ContractorNameKanjiMei = 'contractor_name_kanji_mei',
  /** column name */
  ContractorNameKanjiSei = 'contractor_name_kanji_sei',
  /** column name */
  ContractorPhoneNumber = 'contractor_phone_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsInitialLinkage = 'is_initial_linkage',
  /** column name */
  PointAddressPostalCode = 'point_address_postal_code',
  /** column name */
  PointAddressState = 'point_address_state',
  /** column name */
  PointAddressStateLater = 'point_address_state_later',
  /** column name */
  PointContractFacilityType = 'point_contract_facility_type',
  /** column name */
  PointMeterId = 'point_meter_id',
  /** column name */
  PointSpplyRcvngPwrDivision = 'point_spply_rcvng_pwr_division',
  /** column name */
  PointSpplyRcvngPwrPntSpcfcNo = 'point_spply_rcvng_pwr_pnt__spcfc_no',
  /** column name */
  PointVoltageClassification = 'point_voltage_classification'
}

export type Zerocame_Consent_Application_Input_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Consent_Application_Input_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Consent_Application_Input_Bool_Exp;
};

/** 同意申込結果 */
export type Zerocame_Consent_Application_Result = {
  __typename?: 'zerocame_consent_application_result';
  auth0_user_id: Scalars['String'];
  /** 同意申込番号 */
  consent_application_no?: Maybe<Scalars['String']>;
  /** An array relationship */
  consent_application_result_consent_application_zeroca_analyses: Array<Zerocame_Consent_Application_Zeroca_Analysis>;
  /** An aggregate relationship */
  consent_application_result_consent_application_zeroca_analyses_aggregate: Zerocame_Consent_Application_Zeroca_Analysis_Aggregate;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status: Scalars['String'];
  /** 同意情報ID */
  consent_info_id?: Maybe<Scalars['String']>;
  /** 需要家特定ID */
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: Maybe<Scalars['String']>;
  /** 契約照合結果 */
  contract_matching_result?: Maybe<Scalars['String']>;
  /** 郵便番号 */
  contractor_address_postal_code?: Maybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: Maybe<Scalars['String']>;
  /** メイ */
  contractor_name_kana_mei?: Maybe<Scalars['String']>;
  /** セイ */
  contractor_name_kana_sei?: Maybe<Scalars['String']>;
  /** 名 */
  contractor_name_kanji_mei?: Maybe<Scalars['String']>;
  /** 姓 */
  contractor_name_kanji_sei?: Maybe<Scalars['String']>;
  /** 電話番号 */
  contractor_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 最終削除時間 */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** 最終削除機能ID */
  deleted_by?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** 本人確認不備内容 */
  identification_incomplete_content?: Maybe<Scalars['String']>;
  /** 本人確認結果 */
  identification_result?: Maybe<Scalars['String']>;
  /** 削除フラグ */
  is_deleted: Scalars['Boolean'];
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: Maybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: Maybe<Scalars['String']>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: Maybe<Scalars['String']>;
  /** 地点確認結果 */
  point_confirmation_result?: Maybe<Scalars['String']>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: Maybe<Scalars['String']>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};


/** 同意申込結果 */
export type Zerocame_Consent_Application_ResultConsent_Application_Result_Consent_Application_Zeroca_AnalysesArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};


/** 同意申込結果 */
export type Zerocame_Consent_Application_ResultConsent_Application_Result_Consent_Application_Zeroca_Analyses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};

/** aggregated selection of "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Aggregate = {
  __typename?: 'zerocame_consent_application_result_aggregate';
  aggregate?: Maybe<Zerocame_Consent_Application_Result_Aggregate_Fields>;
  nodes: Array<Zerocame_Consent_Application_Result>;
};

export type Zerocame_Consent_Application_Result_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_And = {
  arguments: Zerocame_Consent_Application_Result_Select_Column_Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Zerocame_Consent_Application_Result_Select_Column_Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Aggregate_Fields = {
  __typename?: 'zerocame_consent_application_result_aggregate_fields';
  avg?: Maybe<Zerocame_Consent_Application_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Consent_Application_Result_Max_Fields>;
  min?: Maybe<Zerocame_Consent_Application_Result_Min_Fields>;
  stddev?: Maybe<Zerocame_Consent_Application_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Consent_Application_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Consent_Application_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Consent_Application_Result_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Consent_Application_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Consent_Application_Result_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Consent_Application_Result_Variance_Fields>;
};


/** aggregate fields of "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Zerocame_Consent_Application_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Consent_Application_Result_Max_Order_By>;
  min?: InputMaybe<Zerocame_Consent_Application_Result_Min_Order_By>;
  stddev?: InputMaybe<Zerocame_Consent_Application_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Zerocame_Consent_Application_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Zerocame_Consent_Application_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Zerocame_Consent_Application_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Zerocame_Consent_Application_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Zerocame_Consent_Application_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Zerocame_Consent_Application_Result_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Consent_Application_Result_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Result_On_Conflict>;
};

/** aggregate avg on columns */
export type Zerocame_Consent_Application_Result_Avg_Fields = {
  __typename?: 'zerocame_consent_application_result_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zerocame.consent_application_result". All fields are combined with a logical 'AND'. */
export type Zerocame_Consent_Application_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Consent_Application_Result_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Consent_Application_Result_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  consent_application_no?: InputMaybe<String_Comparison_Exp>;
  consent_application_result_consent_application_zeroca_analyses?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
  consent_application_result_consent_application_zeroca_analyses_aggregate?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Bool_Exp>;
  consent_application_status?: InputMaybe<String_Comparison_Exp>;
  consent_info_id?: InputMaybe<String_Comparison_Exp>;
  consumer_spcfc_id?: InputMaybe<String_Comparison_Exp>;
  contract_matching_incomplete_content?: InputMaybe<String_Comparison_Exp>;
  contract_matching_result?: InputMaybe<String_Comparison_Exp>;
  contractor_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  contractor_address_state?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kana_mei?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kana_sei?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kanji_mei?: InputMaybe<String_Comparison_Exp>;
  contractor_name_kanji_sei?: InputMaybe<String_Comparison_Exp>;
  contractor_phone_number?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identification_incomplete_content?: InputMaybe<String_Comparison_Exp>;
  identification_result?: InputMaybe<String_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  point_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  point_address_state?: InputMaybe<String_Comparison_Exp>;
  point_confirmation_incomplete_content?: InputMaybe<String_Comparison_Exp>;
  point_confirmation_result?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.consent_application_result" */
export enum Zerocame_Consent_Application_Result_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsentAppliationResultPkey = 'consent_appliation_result_pkey',
  /** unique or primary key constraint on columns "consent_application_no" */
  ConsentApplicationResultConsentApplicationNoKey = 'consent_application_result_consent_application_no_key'
}

/** input type for incrementing numeric columns in table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 同意申込番号 */
  consent_application_no?: InputMaybe<Scalars['String']>;
  consent_application_result_consent_application_zeroca_analyses?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Arr_Rel_Insert_Input>;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status?: InputMaybe<Scalars['String']>;
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Scalars['String']>;
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 契約照合結果 */
  contract_matching_result?: InputMaybe<Scalars['String']>;
  /** 郵便番号 */
  contractor_address_postal_code?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Scalars['String']>;
  /** メイ */
  contractor_name_kana_mei?: InputMaybe<Scalars['String']>;
  /** セイ */
  contractor_name_kana_sei?: InputMaybe<Scalars['String']>;
  /** 名 */
  contractor_name_kanji_mei?: InputMaybe<Scalars['String']>;
  /** 姓 */
  contractor_name_kanji_sei?: InputMaybe<Scalars['String']>;
  /** 電話番号 */
  contractor_phone_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 最終削除時間 */
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終削除機能ID */
  deleted_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** 本人確認不備内容 */
  identification_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 本人確認結果 */
  identification_result?: InputMaybe<Scalars['String']>;
  /** 削除フラグ */
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: InputMaybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Scalars['String']>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 地点確認結果 */
  point_confirmation_result?: InputMaybe<Scalars['String']>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: InputMaybe<Scalars['String']>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Consent_Application_Result_Max_Fields = {
  __typename?: 'zerocame_consent_application_result_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 同意申込番号 */
  consent_application_no?: Maybe<Scalars['String']>;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status?: Maybe<Scalars['String']>;
  /** 同意情報ID */
  consent_info_id?: Maybe<Scalars['String']>;
  /** 需要家特定ID */
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: Maybe<Scalars['String']>;
  /** 契約照合結果 */
  contract_matching_result?: Maybe<Scalars['String']>;
  /** 郵便番号 */
  contractor_address_postal_code?: Maybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: Maybe<Scalars['String']>;
  /** メイ */
  contractor_name_kana_mei?: Maybe<Scalars['String']>;
  /** セイ */
  contractor_name_kana_sei?: Maybe<Scalars['String']>;
  /** 名 */
  contractor_name_kanji_mei?: Maybe<Scalars['String']>;
  /** 姓 */
  contractor_name_kanji_sei?: Maybe<Scalars['String']>;
  /** 電話番号 */
  contractor_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 最終削除時間 */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** 最終削除機能ID */
  deleted_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** 本人確認不備内容 */
  identification_incomplete_content?: Maybe<Scalars['String']>;
  /** 本人確認結果 */
  identification_result?: Maybe<Scalars['String']>;
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: Maybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: Maybe<Scalars['String']>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: Maybe<Scalars['String']>;
  /** 地点確認結果 */
  point_confirmation_result?: Maybe<Scalars['String']>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: Maybe<Scalars['String']>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Max_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  /** 同意申込番号 */
  consent_application_no?: InputMaybe<Order_By>;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status?: InputMaybe<Order_By>;
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Order_By>;
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Order_By>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: InputMaybe<Order_By>;
  /** 契約照合結果 */
  contract_matching_result?: InputMaybe<Order_By>;
  /** 郵便番号 */
  contractor_address_postal_code?: InputMaybe<Order_By>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Order_By>;
  /** メイ */
  contractor_name_kana_mei?: InputMaybe<Order_By>;
  /** セイ */
  contractor_name_kana_sei?: InputMaybe<Order_By>;
  /** 名 */
  contractor_name_kanji_mei?: InputMaybe<Order_By>;
  /** 姓 */
  contractor_name_kanji_sei?: InputMaybe<Order_By>;
  /** 電話番号 */
  contractor_phone_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  /** 最終削除時間 */
  deleted_at?: InputMaybe<Order_By>;
  /** 最終削除機能ID */
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 本人確認不備内容 */
  identification_incomplete_content?: InputMaybe<Order_By>;
  /** 本人確認結果 */
  identification_result?: InputMaybe<Order_By>;
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: InputMaybe<Order_By>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Order_By>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: InputMaybe<Order_By>;
  /** 地点確認結果 */
  point_confirmation_result?: InputMaybe<Order_By>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: InputMaybe<Order_By>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Consent_Application_Result_Min_Fields = {
  __typename?: 'zerocame_consent_application_result_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 同意申込番号 */
  consent_application_no?: Maybe<Scalars['String']>;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status?: Maybe<Scalars['String']>;
  /** 同意情報ID */
  consent_info_id?: Maybe<Scalars['String']>;
  /** 需要家特定ID */
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: Maybe<Scalars['String']>;
  /** 契約照合結果 */
  contract_matching_result?: Maybe<Scalars['String']>;
  /** 郵便番号 */
  contractor_address_postal_code?: Maybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: Maybe<Scalars['String']>;
  /** メイ */
  contractor_name_kana_mei?: Maybe<Scalars['String']>;
  /** セイ */
  contractor_name_kana_sei?: Maybe<Scalars['String']>;
  /** 名 */
  contractor_name_kanji_mei?: Maybe<Scalars['String']>;
  /** 姓 */
  contractor_name_kanji_sei?: Maybe<Scalars['String']>;
  /** 電話番号 */
  contractor_phone_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 最終削除時間 */
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** 最終削除機能ID */
  deleted_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** 本人確認不備内容 */
  identification_incomplete_content?: Maybe<Scalars['String']>;
  /** 本人確認結果 */
  identification_result?: Maybe<Scalars['String']>;
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: Maybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: Maybe<Scalars['String']>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: Maybe<Scalars['String']>;
  /** 地点確認結果 */
  point_confirmation_result?: Maybe<Scalars['String']>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: Maybe<Scalars['String']>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Min_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  /** 同意申込番号 */
  consent_application_no?: InputMaybe<Order_By>;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status?: InputMaybe<Order_By>;
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Order_By>;
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Order_By>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: InputMaybe<Order_By>;
  /** 契約照合結果 */
  contract_matching_result?: InputMaybe<Order_By>;
  /** 郵便番号 */
  contractor_address_postal_code?: InputMaybe<Order_By>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Order_By>;
  /** メイ */
  contractor_name_kana_mei?: InputMaybe<Order_By>;
  /** セイ */
  contractor_name_kana_sei?: InputMaybe<Order_By>;
  /** 名 */
  contractor_name_kanji_mei?: InputMaybe<Order_By>;
  /** 姓 */
  contractor_name_kanji_sei?: InputMaybe<Order_By>;
  /** 電話番号 */
  contractor_phone_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  /** 最終削除時間 */
  deleted_at?: InputMaybe<Order_By>;
  /** 最終削除機能ID */
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** 本人確認不備内容 */
  identification_incomplete_content?: InputMaybe<Order_By>;
  /** 本人確認結果 */
  identification_result?: InputMaybe<Order_By>;
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: InputMaybe<Order_By>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Order_By>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: InputMaybe<Order_By>;
  /** 地点確認結果 */
  point_confirmation_result?: InputMaybe<Order_By>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: InputMaybe<Order_By>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Mutation_Response = {
  __typename?: 'zerocame_consent_application_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Consent_Application_Result>;
};

/** input type for inserting object relation for remote table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Obj_Rel_Insert_Input = {
  data: Zerocame_Consent_Application_Result_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Result_On_Conflict>;
};

/** on_conflict condition type for table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_On_Conflict = {
  constraint: Zerocame_Consent_Application_Result_Constraint;
  update_columns?: Array<Zerocame_Consent_Application_Result_Update_Column>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.consent_application_result". */
export type Zerocame_Consent_Application_Result_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  consent_application_no?: InputMaybe<Order_By>;
  consent_application_result_consent_application_zeroca_analyses_aggregate?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Order_By>;
  consent_application_status?: InputMaybe<Order_By>;
  consent_info_id?: InputMaybe<Order_By>;
  consumer_spcfc_id?: InputMaybe<Order_By>;
  contract_matching_incomplete_content?: InputMaybe<Order_By>;
  contract_matching_result?: InputMaybe<Order_By>;
  contractor_address_postal_code?: InputMaybe<Order_By>;
  contractor_address_state?: InputMaybe<Order_By>;
  contractor_name_kana_mei?: InputMaybe<Order_By>;
  contractor_name_kana_sei?: InputMaybe<Order_By>;
  contractor_name_kanji_mei?: InputMaybe<Order_By>;
  contractor_name_kanji_sei?: InputMaybe<Order_By>;
  contractor_phone_number?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deleted_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identification_incomplete_content?: InputMaybe<Order_By>;
  identification_result?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  point_address_postal_code?: InputMaybe<Order_By>;
  point_address_state?: InputMaybe<Order_By>;
  point_confirmation_incomplete_content?: InputMaybe<Order_By>;
  point_confirmation_result?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.consent_application_result */
export type Zerocame_Consent_Application_Result_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "zerocame.consent_application_result" */
export enum Zerocame_Consent_Application_Result_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ConsentApplicationNo = 'consent_application_no',
  /** column name */
  ConsentApplicationStatus = 'consent_application_status',
  /** column name */
  ConsentInfoId = 'consent_info_id',
  /** column name */
  ConsumerSpcfcId = 'consumer_spcfc_id',
  /** column name */
  ContractMatchingIncompleteContent = 'contract_matching_incomplete_content',
  /** column name */
  ContractMatchingResult = 'contract_matching_result',
  /** column name */
  ContractorAddressPostalCode = 'contractor_address_postal_code',
  /** column name */
  ContractorAddressState = 'contractor_address_state',
  /** column name */
  ContractorNameKanaMei = 'contractor_name_kana_mei',
  /** column name */
  ContractorNameKanaSei = 'contractor_name_kana_sei',
  /** column name */
  ContractorNameKanjiMei = 'contractor_name_kanji_mei',
  /** column name */
  ContractorNameKanjiSei = 'contractor_name_kanji_sei',
  /** column name */
  ContractorPhoneNumber = 'contractor_phone_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationIncompleteContent = 'identification_incomplete_content',
  /** column name */
  IdentificationResult = 'identification_result',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PointAddressPostalCode = 'point_address_postal_code',
  /** column name */
  PointAddressState = 'point_address_state',
  /** column name */
  PointConfirmationIncompleteContent = 'point_confirmation_incomplete_content',
  /** column name */
  PointConfirmationResult = 'point_confirmation_result',
  /** column name */
  Region = 'region',
  /** column name */
  SpplyRcvngPwrPntSpcfcNo = 'spply_rcvng_pwr_pnt_spcfc_no',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** select "zerocame_consent_application_result_aggregate_bool_exp_bool_and_arguments_columns" columns of table "zerocame.consent_application_result" */
export enum Zerocame_Consent_Application_Result_Select_Column_Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "zerocame_consent_application_result_aggregate_bool_exp_bool_or_arguments_columns" columns of table "zerocame.consent_application_result" */
export enum Zerocame_Consent_Application_Result_Select_Column_Zerocame_Consent_Application_Result_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 同意申込番号 */
  consent_application_no?: InputMaybe<Scalars['String']>;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status?: InputMaybe<Scalars['String']>;
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Scalars['String']>;
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 契約照合結果 */
  contract_matching_result?: InputMaybe<Scalars['String']>;
  /** 郵便番号 */
  contractor_address_postal_code?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Scalars['String']>;
  /** メイ */
  contractor_name_kana_mei?: InputMaybe<Scalars['String']>;
  /** セイ */
  contractor_name_kana_sei?: InputMaybe<Scalars['String']>;
  /** 名 */
  contractor_name_kanji_mei?: InputMaybe<Scalars['String']>;
  /** 姓 */
  contractor_name_kanji_sei?: InputMaybe<Scalars['String']>;
  /** 電話番号 */
  contractor_phone_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 最終削除時間 */
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終削除機能ID */
  deleted_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** 本人確認不備内容 */
  identification_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 本人確認結果 */
  identification_result?: InputMaybe<Scalars['String']>;
  /** 削除フラグ */
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: InputMaybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Scalars['String']>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 地点確認結果 */
  point_confirmation_result?: InputMaybe<Scalars['String']>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: InputMaybe<Scalars['String']>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Consent_Application_Result_Stddev_Fields = {
  __typename?: 'zerocame_consent_application_result_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Consent_Application_Result_Stddev_Pop_Fields = {
  __typename?: 'zerocame_consent_application_result_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Consent_Application_Result_Stddev_Samp_Fields = {
  __typename?: 'zerocame_consent_application_result_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "zerocame_consent_application_result" */
export type Zerocame_Consent_Application_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Consent_Application_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Consent_Application_Result_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 同意申込番号 */
  consent_application_no?: InputMaybe<Scalars['String']>;
  /** 同意申込状態　1：同意申込結果OK、2：同意申込結果NG、3：同意取消完了 */
  consent_application_status?: InputMaybe<Scalars['String']>;
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Scalars['String']>;
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  /** 契約照合不備内容 */
  contract_matching_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 契約照合結果 */
  contract_matching_result?: InputMaybe<Scalars['String']>;
  /** 郵便番号 */
  contractor_address_postal_code?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Scalars['String']>;
  /** メイ */
  contractor_name_kana_mei?: InputMaybe<Scalars['String']>;
  /** セイ */
  contractor_name_kana_sei?: InputMaybe<Scalars['String']>;
  /** 名 */
  contractor_name_kanji_mei?: InputMaybe<Scalars['String']>;
  /** 姓 */
  contractor_name_kanji_sei?: InputMaybe<Scalars['String']>;
  /** 電話番号 */
  contractor_phone_number?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 最終削除時間 */
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終削除機能ID */
  deleted_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** 本人確認不備内容 */
  identification_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 本人確認結果 */
  identification_result?: InputMaybe<Scalars['String']>;
  /** 削除フラグ */
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  /** 電気使用場所の郵便番号 */
  point_address_postal_code?: InputMaybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Scalars['String']>;
  /** 地点確認不備内容 */
  point_confirmation_incomplete_content?: InputMaybe<Scalars['String']>;
  /** 地点確認結果 */
  point_confirmation_result?: InputMaybe<Scalars['String']>;
  /** 地方　1：北海道、2：東北、3：関東甲信、、、9：九州、10：沖縄 */
  region?: InputMaybe<Scalars['String']>;
  /** 供給／受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Consent_Application_Result_Sum_Fields = {
  __typename?: 'zerocame_consent_application_result_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "zerocame.consent_application_result" */
export enum Zerocame_Consent_Application_Result_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ConsentApplicationNo = 'consent_application_no',
  /** column name */
  ConsentApplicationStatus = 'consent_application_status',
  /** column name */
  ConsentInfoId = 'consent_info_id',
  /** column name */
  ConsumerSpcfcId = 'consumer_spcfc_id',
  /** column name */
  ContractMatchingIncompleteContent = 'contract_matching_incomplete_content',
  /** column name */
  ContractMatchingResult = 'contract_matching_result',
  /** column name */
  ContractorAddressPostalCode = 'contractor_address_postal_code',
  /** column name */
  ContractorAddressState = 'contractor_address_state',
  /** column name */
  ContractorNameKanaMei = 'contractor_name_kana_mei',
  /** column name */
  ContractorNameKanaSei = 'contractor_name_kana_sei',
  /** column name */
  ContractorNameKanjiMei = 'contractor_name_kanji_mei',
  /** column name */
  ContractorNameKanjiSei = 'contractor_name_kanji_sei',
  /** column name */
  ContractorPhoneNumber = 'contractor_phone_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedBy = 'deleted_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationIncompleteContent = 'identification_incomplete_content',
  /** column name */
  IdentificationResult = 'identification_result',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PointAddressPostalCode = 'point_address_postal_code',
  /** column name */
  PointAddressState = 'point_address_state',
  /** column name */
  PointConfirmationIncompleteContent = 'point_confirmation_incomplete_content',
  /** column name */
  PointConfirmationResult = 'point_confirmation_result',
  /** column name */
  Region = 'region',
  /** column name */
  SpplyRcvngPwrPntSpcfcNo = 'spply_rcvng_pwr_pnt_spcfc_no',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Consent_Application_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Consent_Application_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Consent_Application_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Consent_Application_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Consent_Application_Result_Var_Pop_Fields = {
  __typename?: 'zerocame_consent_application_result_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Zerocame_Consent_Application_Result_Var_Samp_Fields = {
  __typename?: 'zerocame_consent_application_result_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Zerocame_Consent_Application_Result_Variance_Fields = {
  __typename?: 'zerocame_consent_application_result_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zerocame.consent_application_result" */
export type Zerocame_Consent_Application_Result_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** ZeroCa分析情報（電気契約者・地点） */
export type Zerocame_Consent_Application_Zeroca_Analysis = {
  __typename?: 'zerocame_consent_application_zeroca_analysis';
  /** An object relationship */
  consent_application_result?: Maybe<Zerocame_Consent_Application_Result>;
  /** 同意情報ID */
  consent_info_id: Scalars['String'];
  /** 電気契約者情報：住所 */
  contractor_address_state: Scalars['String'];
  /** 電気契約者情報：電気契約者区分 */
  contractor_division: Scalars['String'];
  /** 電気契約者情報：氏名（漢字） */
  contractor_name: Scalars['String'];
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state: Scalars['String'];
  /** 地点情報：契約種別 */
  point_contract_facility_type?: Maybe<Scalars['String']>;
  /** 地点情報：計器ID */
  point_meter_id?: Maybe<Scalars['String']>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: Maybe<Scalars['String']>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Aggregate = {
  __typename?: 'zerocame_consent_application_zeroca_analysis_aggregate';
  aggregate?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Fields>;
  nodes: Array<Zerocame_Consent_Application_Zeroca_Analysis>;
};

export type Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Bool_Exp = {
  count?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Fields = {
  __typename?: 'zerocame_consent_application_zeroca_analysis_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis_Max_Fields>;
  min?: Maybe<Zerocame_Consent_Application_Zeroca_Analysis_Min_Fields>;
};


/** aggregate fields of "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Max_Order_By>;
  min?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Consent_Application_Zeroca_Analysis_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zerocame.consent_application_zeroca_analysis". All fields are combined with a logical 'AND'. */
export type Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>>;
  consent_application_result?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
  consent_info_id?: InputMaybe<String_Comparison_Exp>;
  contractor_address_state?: InputMaybe<String_Comparison_Exp>;
  contractor_division?: InputMaybe<String_Comparison_Exp>;
  contractor_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  point_address_state?: InputMaybe<String_Comparison_Exp>;
  point_contract_facility_type?: InputMaybe<String_Comparison_Exp>;
  point_meter_id?: InputMaybe<String_Comparison_Exp>;
  point_spply_rcvng_pwr_division?: InputMaybe<String_Comparison_Exp>;
  point_voltage_classification?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.consent_application_zeroca_analysis" */
export enum Zerocame_Consent_Application_Zeroca_Analysis_Constraint {
  /** unique or primary key constraint on columns "consent_info_id" */
  ConsentApplicationZerocaAnalysisPkey = 'consent_application_zeroca_analysis_pkey'
}

/** input type for inserting data into table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Insert_Input = {
  consent_application_result?: InputMaybe<Zerocame_Consent_Application_Result_Obj_Rel_Insert_Input>;
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：電気契約者区分 */
  contractor_division?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：氏名（漢字） */
  contractor_name?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Scalars['String']>;
  /** 地点情報：契約種別 */
  point_contract_facility_type?: InputMaybe<Scalars['String']>;
  /** 地点情報：計器ID */
  point_meter_id?: InputMaybe<Scalars['String']>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: InputMaybe<Scalars['String']>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Consent_Application_Zeroca_Analysis_Max_Fields = {
  __typename?: 'zerocame_consent_application_zeroca_analysis_max_fields';
  /** 同意情報ID */
  consent_info_id?: Maybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: Maybe<Scalars['String']>;
  /** 電気契約者情報：電気契約者区分 */
  contractor_division?: Maybe<Scalars['String']>;
  /** 電気契約者情報：氏名（漢字） */
  contractor_name?: Maybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: Maybe<Scalars['String']>;
  /** 地点情報：契約種別 */
  point_contract_facility_type?: Maybe<Scalars['String']>;
  /** 地点情報：計器ID */
  point_meter_id?: Maybe<Scalars['String']>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: Maybe<Scalars['String']>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Max_Order_By = {
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Order_By>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Order_By>;
  /** 電気契約者情報：電気契約者区分 */
  contractor_division?: InputMaybe<Order_By>;
  /** 電気契約者情報：氏名（漢字） */
  contractor_name?: InputMaybe<Order_By>;
  /** 登録日時 */
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Order_By>;
  /** 地点情報：契約種別 */
  point_contract_facility_type?: InputMaybe<Order_By>;
  /** 地点情報：計器ID */
  point_meter_id?: InputMaybe<Order_By>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: InputMaybe<Order_By>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: InputMaybe<Order_By>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Consent_Application_Zeroca_Analysis_Min_Fields = {
  __typename?: 'zerocame_consent_application_zeroca_analysis_min_fields';
  /** 同意情報ID */
  consent_info_id?: Maybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: Maybe<Scalars['String']>;
  /** 電気契約者情報：電気契約者区分 */
  contractor_division?: Maybe<Scalars['String']>;
  /** 電気契約者情報：氏名（漢字） */
  contractor_name?: Maybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: Maybe<Scalars['String']>;
  /** 地点情報：契約種別 */
  point_contract_facility_type?: Maybe<Scalars['String']>;
  /** 地点情報：計器ID */
  point_meter_id?: Maybe<Scalars['String']>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: Maybe<Scalars['String']>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Min_Order_By = {
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Order_By>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Order_By>;
  /** 電気契約者情報：電気契約者区分 */
  contractor_division?: InputMaybe<Order_By>;
  /** 電気契約者情報：氏名（漢字） */
  contractor_name?: InputMaybe<Order_By>;
  /** 登録日時 */
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Order_By>;
  /** 地点情報：契約種別 */
  point_contract_facility_type?: InputMaybe<Order_By>;
  /** 地点情報：計器ID */
  point_meter_id?: InputMaybe<Order_By>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: InputMaybe<Order_By>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: InputMaybe<Order_By>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Mutation_Response = {
  __typename?: 'zerocame_consent_application_zeroca_analysis_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Consent_Application_Zeroca_Analysis>;
};

/** on_conflict condition type for table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_On_Conflict = {
  constraint: Zerocame_Consent_Application_Zeroca_Analysis_Constraint;
  update_columns?: Array<Zerocame_Consent_Application_Zeroca_Analysis_Update_Column>;
  where?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.consent_application_zeroca_analysis". */
export type Zerocame_Consent_Application_Zeroca_Analysis_Order_By = {
  consent_application_result?: InputMaybe<Zerocame_Consent_Application_Result_Order_By>;
  consent_info_id?: InputMaybe<Order_By>;
  contractor_address_state?: InputMaybe<Order_By>;
  contractor_division?: InputMaybe<Order_By>;
  contractor_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  point_address_state?: InputMaybe<Order_By>;
  point_contract_facility_type?: InputMaybe<Order_By>;
  point_meter_id?: InputMaybe<Order_By>;
  point_spply_rcvng_pwr_division?: InputMaybe<Order_By>;
  point_voltage_classification?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.consent_application_zeroca_analysis */
export type Zerocame_Consent_Application_Zeroca_Analysis_Pk_Columns_Input = {
  /** 同意情報ID */
  consent_info_id: Scalars['String'];
};

/** select columns of table "zerocame.consent_application_zeroca_analysis" */
export enum Zerocame_Consent_Application_Zeroca_Analysis_Select_Column {
  /** column name */
  ConsentInfoId = 'consent_info_id',
  /** column name */
  ContractorAddressState = 'contractor_address_state',
  /** column name */
  ContractorDivision = 'contractor_division',
  /** column name */
  ContractorName = 'contractor_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  PointAddressState = 'point_address_state',
  /** column name */
  PointContractFacilityType = 'point_contract_facility_type',
  /** column name */
  PointMeterId = 'point_meter_id',
  /** column name */
  PointSpplyRcvngPwrDivision = 'point_spply_rcvng_pwr_division',
  /** column name */
  PointVoltageClassification = 'point_voltage_classification',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Set_Input = {
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：電気契約者区分 */
  contractor_division?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：氏名（漢字） */
  contractor_name?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Scalars['String']>;
  /** 地点情報：契約種別 */
  point_contract_facility_type?: InputMaybe<Scalars['String']>;
  /** 地点情報：計器ID */
  point_meter_id?: InputMaybe<Scalars['String']>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: InputMaybe<Scalars['String']>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_consent_application_zeroca_analysis" */
export type Zerocame_Consent_Application_Zeroca_Analysis_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Consent_Application_Zeroca_Analysis_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Consent_Application_Zeroca_Analysis_Stream_Cursor_Value_Input = {
  /** 同意情報ID */
  consent_info_id?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：住所 */
  contractor_address_state?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：電気契約者区分 */
  contractor_division?: InputMaybe<Scalars['String']>;
  /** 電気契約者情報：氏名（漢字） */
  contractor_name?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 地点情報：住所 */
  point_address_state?: InputMaybe<Scalars['String']>;
  /** 地点情報：契約種別 */
  point_contract_facility_type?: InputMaybe<Scalars['String']>;
  /** 地点情報：計器ID */
  point_meter_id?: InputMaybe<Scalars['String']>;
  /** 地点情報：供給／受電区分 */
  point_spply_rcvng_pwr_division?: InputMaybe<Scalars['String']>;
  /** 地点情報：電圧分類 */
  point_voltage_classification?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.consent_application_zeroca_analysis" */
export enum Zerocame_Consent_Application_Zeroca_Analysis_Update_Column {
  /** column name */
  ConsentInfoId = 'consent_info_id',
  /** column name */
  ContractorAddressState = 'contractor_address_state',
  /** column name */
  ContractorDivision = 'contractor_division',
  /** column name */
  ContractorName = 'contractor_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  PointAddressState = 'point_address_state',
  /** column name */
  PointContractFacilityType = 'point_contract_facility_type',
  /** column name */
  PointMeterId = 'point_meter_id',
  /** column name */
  PointSpplyRcvngPwrDivision = 'point_spply_rcvng_pwr_division',
  /** column name */
  PointVoltageClassification = 'point_voltage_classification',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Consent_Application_Zeroca_Analysis_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Consent_Application_Zeroca_Analysis_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Consent_Application_Zeroca_Analysis_Bool_Exp;
};

export type Zerocame_Fn_Current_Consent_Application_Result_Args = {
  user_id?: InputMaybe<Scalars['String']>;
};

export type Zerocame_Fn_Greenscore_Detail_By_Category_Args = {
  greenscore_id?: InputMaybe<Scalars['Int']>;
};

export type Zerocame_Fn_User_Electricity_Data_Per_Day_Args = {
  date_from?: InputMaybe<Scalars['String']>;
  date_to?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** グリーンスコア */
export type Zerocame_Greenscore = {
  __typename?: 'zerocame_greenscore';
  auth0_user_id: Scalars['String'];
  calc_id_action?: Maybe<Scalars['Int']>;
  calc_id_energy?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_saved: Scalars['Boolean'];
  score_sum: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Zerocame_Users;
};

/** 振り返り */
export type Zerocame_Greenscore_Action_Review = {
  __typename?: 'zerocame_greenscore_action_review';
  auth0_user_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** 1：ユーザー操作、2：スコア集計処理 */
  created_by: Scalars['String'];
  /** An object relationship */
  greenscore?: Maybe<Zerocame_Greenscore>;
  /** An array relationship */
  greenscore_action_review_details: Array<Zerocame_Greenscore_Action_Review_Detail>;
  /** An aggregate relationship */
  greenscore_action_review_details_aggregate: Zerocame_Greenscore_Action_Review_Detail_Aggregate;
  /** An array relationship */
  greenscore_calc_actions: Array<Zerocame_Greenscore_Calc_Action>;
  /** An aggregate relationship */
  greenscore_calc_actions_aggregate: Zerocame_Greenscore_Calc_Action_Aggregate;
  /** 0：スコア集計未実施、1：スコア集計中、2：スコア集計完了 */
  greenscore_calc_status: Scalars['String'];
  greenscore_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Zerocame_Users;
};


/** 振り返り */
export type Zerocame_Greenscore_Action_ReviewGreenscore_Action_Review_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};


/** 振り返り */
export type Zerocame_Greenscore_Action_ReviewGreenscore_Action_Review_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};


/** 振り返り */
export type Zerocame_Greenscore_Action_ReviewGreenscore_Calc_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};


/** 振り返り */
export type Zerocame_Greenscore_Action_ReviewGreenscore_Calc_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};

/** aggregated selection of "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Aggregate = {
  __typename?: 'zerocame_greenscore_action_review_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Action_Review_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Action_Review>;
};

/** aggregate fields of "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_action_review_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Action_Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Action_Review_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Action_Review_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Action_Review_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Action_Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Action_Review_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Action_Review_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Action_Review_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Action_Review_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Action_Review_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Action_Review_Avg_Fields = {
  __typename?: 'zerocame_greenscore_action_review_avg_fields';
  greenscore_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_action_review". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Action_Review_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  greenscore?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
  greenscore_action_review_details?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
  greenscore_action_review_details_aggregate?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Aggregate_Bool_Exp>;
  greenscore_calc_actions?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
  greenscore_calc_actions_aggregate?: InputMaybe<Zerocame_Greenscore_Calc_Action_Aggregate_Bool_Exp>;
  greenscore_calc_status?: InputMaybe<String_Comparison_Exp>;
  greenscore_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Zerocame_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_action_review" */
export enum Zerocame_Greenscore_Action_Review_Constraint {
  /** unique or primary key constraint on columns "id" */
  GreenscoreActionReviewPkey = 'greenscore_action_review_pkey'
}

/** 振り返り明細 */
export type Zerocame_Greenscore_Action_Review_Detail = {
  __typename?: 'zerocame_greenscore_action_review_detail';
  created_at: Scalars['timestamptz'];
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation: Scalars['Int'];
  greenscore_action_id: Scalars['String'];
  /** An object relationship */
  greenscore_action_review: Zerocame_Greenscore_Action_Review;
  review_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Aggregate = {
  __typename?: 'zerocame_greenscore_action_review_detail_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Action_Review_Detail>;
};

export type Zerocame_Greenscore_Action_Review_Detail_Aggregate_Bool_Exp = {
  count?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Greenscore_Action_Review_Detail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Action_Review_Detail_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Aggregate_Order_By = {
  avg?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Max_Order_By>;
  min?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Min_Order_By>;
  stddev?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Sum_Order_By>;
  var_pop?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Var_Samp_Order_By>;
  variance?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Greenscore_Action_Review_Detail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_On_Conflict>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Avg_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_avg_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Avg_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_action_review_detail". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Action_Review_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  evaluation?: InputMaybe<Int_Comparison_Exp>;
  greenscore_action_id?: InputMaybe<String_Comparison_Exp>;
  greenscore_action_review?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
  review_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_action_review_detail" */
export enum Zerocame_Greenscore_Action_Review_Detail_Constraint {
  /** unique or primary key constraint on columns "review_id", "greenscore_action_id" */
  GreenscoreActionReviewDetailPkey = 'greenscore_action_review_detail_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Inc_Input = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Scalars['Int']>;
  review_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Scalars['Int']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  greenscore_action_review?: InputMaybe<Zerocame_Greenscore_Action_Review_Obj_Rel_Insert_Input>;
  review_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Max_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Int']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  review_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Min_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Int']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  review_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Mutation_Response = {
  __typename?: 'zerocame_greenscore_action_review_detail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Action_Review_Detail>;
};

/** on_conflict condition type for table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_On_Conflict = {
  constraint: Zerocame_Greenscore_Action_Review_Detail_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Action_Review_Detail_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_action_review_detail". */
export type Zerocame_Greenscore_Action_Review_Detail_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evaluation?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  greenscore_action_review?: InputMaybe<Zerocame_Greenscore_Action_Review_Order_By>;
  review_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_action_review_detail */
export type Zerocame_Greenscore_Action_Review_Detail_Pk_Columns_Input = {
  greenscore_action_id: Scalars['String'];
  review_id: Scalars['Int'];
};

/** select columns of table "zerocame.greenscore_action_review_detail" */
export enum Zerocame_Greenscore_Action_Review_Detail_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Evaluation = 'evaluation',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Scalars['Int']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  review_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_stddev_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Stddev_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_stddev_pop_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Stddev_Pop_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_stddev_samp_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Stddev_Samp_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "zerocame_greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Action_Review_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Action_Review_Detail_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Scalars['Int']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  review_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Sum_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_sum_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Int']>;
  review_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Sum_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** update columns of table "zerocame.greenscore_action_review_detail" */
export enum Zerocame_Greenscore_Action_Review_Detail_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Evaluation = 'evaluation',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_Action_Review_Detail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Action_Review_Detail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_var_pop_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Var_Pop_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_var_samp_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Var_Samp_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Action_Review_Detail_Variance_Fields = {
  __typename?: 'zerocame_greenscore_action_review_detail_variance_fields';
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zerocame.greenscore_action_review_detail" */
export type Zerocame_Greenscore_Action_Review_Detail_Variance_Order_By = {
  /** 1,2,3,4,5の5段階評価 */
  evaluation?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Inc_Input = {
  greenscore_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 1：ユーザー操作、2：スコア集計処理 */
  created_by?: InputMaybe<Scalars['String']>;
  greenscore?: InputMaybe<Zerocame_Greenscore_Obj_Rel_Insert_Input>;
  greenscore_action_review_details?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Arr_Rel_Insert_Input>;
  greenscore_calc_actions?: InputMaybe<Zerocame_Greenscore_Calc_Action_Arr_Rel_Insert_Input>;
  /** 0：スコア集計未実施、1：スコア集計中、2：スコア集計完了 */
  greenscore_calc_status?: InputMaybe<Scalars['String']>;
  greenscore_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Zerocame_Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Action_Review_Max_Fields = {
  __typename?: 'zerocame_greenscore_action_review_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 1：ユーザー操作、2：スコア集計処理 */
  created_by?: Maybe<Scalars['String']>;
  /** 0：スコア集計未実施、1：スコア集計中、2：スコア集計完了 */
  greenscore_calc_status?: Maybe<Scalars['String']>;
  greenscore_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Action_Review_Min_Fields = {
  __typename?: 'zerocame_greenscore_action_review_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 1：ユーザー操作、2：スコア集計処理 */
  created_by?: Maybe<Scalars['String']>;
  /** 0：スコア集計未実施、1：スコア集計中、2：スコア集計完了 */
  greenscore_calc_status?: Maybe<Scalars['String']>;
  greenscore_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Mutation_Response = {
  __typename?: 'zerocame_greenscore_action_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Action_Review>;
};

/** input type for inserting object relation for remote table "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Obj_Rel_Insert_Input = {
  data: Zerocame_Greenscore_Action_Review_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Greenscore_Action_Review_On_Conflict>;
};

/** on_conflict condition type for table "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_On_Conflict = {
  constraint: Zerocame_Greenscore_Action_Review_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Action_Review_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Action_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_action_review". */
export type Zerocame_Greenscore_Action_Review_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  greenscore?: InputMaybe<Zerocame_Greenscore_Order_By>;
  greenscore_action_review_details_aggregate?: InputMaybe<Zerocame_Greenscore_Action_Review_Detail_Aggregate_Order_By>;
  greenscore_calc_actions_aggregate?: InputMaybe<Zerocame_Greenscore_Calc_Action_Aggregate_Order_By>;
  greenscore_calc_status?: InputMaybe<Order_By>;
  greenscore_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<Zerocame_Users_Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_action_review */
export type Zerocame_Greenscore_Action_Review_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "zerocame.greenscore_action_review" */
export enum Zerocame_Greenscore_Action_Review_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreCalcStatus = 'greenscore_calc_status',
  /** column name */
  GreenscoreId = 'greenscore_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 1：ユーザー操作、2：スコア集計処理 */
  created_by?: InputMaybe<Scalars['String']>;
  /** 0：スコア集計未実施、1：スコア集計中、2：スコア集計完了 */
  greenscore_calc_status?: InputMaybe<Scalars['String']>;
  greenscore_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Action_Review_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_action_review_stddev_fields';
  greenscore_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Action_Review_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_action_review_stddev_pop_fields';
  greenscore_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Action_Review_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_action_review_stddev_samp_fields';
  greenscore_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_action_review" */
export type Zerocame_Greenscore_Action_Review_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Action_Review_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Action_Review_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 1：ユーザー操作、2：スコア集計処理 */
  created_by?: InputMaybe<Scalars['String']>;
  /** 0：スコア集計未実施、1：スコア集計中、2：スコア集計完了 */
  greenscore_calc_status?: InputMaybe<Scalars['String']>;
  greenscore_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Action_Review_Sum_Fields = {
  __typename?: 'zerocame_greenscore_action_review_sum_fields';
  greenscore_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.greenscore_action_review" */
export enum Zerocame_Greenscore_Action_Review_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreCalcStatus = 'greenscore_calc_status',
  /** column name */
  GreenscoreId = 'greenscore_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_Action_Review_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Action_Review_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Action_Review_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Action_Review_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Action_Review_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_action_review_var_pop_fields';
  greenscore_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Action_Review_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_action_review_var_samp_fields';
  greenscore_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Action_Review_Variance_Fields = {
  __typename?: 'zerocame_greenscore_action_review_variance_fields';
  greenscore_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "zerocame.greenscore" */
export type Zerocame_Greenscore_Aggregate = {
  __typename?: 'zerocame_greenscore_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore>;
};

/** aggregate fields of "zerocame.greenscore" */
export type Zerocame_Greenscore_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore" */
export type Zerocame_Greenscore_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Avg_Fields = {
  __typename?: 'zerocame_greenscore_avg_fields';
  calc_id_action?: Maybe<Scalars['Float']>;
  calc_id_energy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  calc_id_action?: InputMaybe<Int_Comparison_Exp>;
  calc_id_energy?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_saved?: InputMaybe<Boolean_Comparison_Exp>;
  score_sum?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Zerocame_Users_Bool_Exp>;
};

/** グリーンスコア算出 */
export type Zerocame_Greenscore_Calc = {
  __typename?: 'zerocame_greenscore_calc';
  auth0_user_id: Scalars['String'];
  /** 1：アクションスコア、2：エネルギースコア */
  category: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  score_sum?: Maybe<Scalars['Int']>;
  /** 1：集計中、2：集計完了 */
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Zerocame_Users;
};

/** グリーンスコア算出アクション */
export type Zerocame_Greenscore_Calc_Action = {
  __typename?: 'zerocame_greenscore_calc_action';
  action_category: Scalars['String'];
  /** 登録日時 */
  created_at: Scalars['timestamptz'];
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id: Scalars['String'];
  /** An object relationship */
  greenscore_calc: Zerocame_Greenscore_Calc;
  greenscore_calc_id: Scalars['Int'];
  review_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  /** 最終更新日時 */
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  user_action_status: Scalars['String'];
};

/** aggregated selection of "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Aggregate = {
  __typename?: 'zerocame_greenscore_calc_action_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Calc_Action_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Calc_Action>;
};

export type Zerocame_Greenscore_Calc_Action_Aggregate_Bool_Exp = {
  count?: InputMaybe<Zerocame_Greenscore_Calc_Action_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Greenscore_Calc_Action_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Calc_Action_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Calc_Action_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Calc_Action_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Calc_Action_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Calc_Action_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Calc_Action_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Calc_Action_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Calc_Action_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Calc_Action_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Calc_Action_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Aggregate_Order_By = {
  avg?: InputMaybe<Zerocame_Greenscore_Calc_Action_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Greenscore_Calc_Action_Max_Order_By>;
  min?: InputMaybe<Zerocame_Greenscore_Calc_Action_Min_Order_By>;
  stddev?: InputMaybe<Zerocame_Greenscore_Calc_Action_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Zerocame_Greenscore_Calc_Action_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Zerocame_Greenscore_Calc_Action_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Zerocame_Greenscore_Calc_Action_Sum_Order_By>;
  var_pop?: InputMaybe<Zerocame_Greenscore_Calc_Action_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Zerocame_Greenscore_Calc_Action_Var_Samp_Order_By>;
  variance?: InputMaybe<Zerocame_Greenscore_Calc_Action_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Greenscore_Calc_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_Action_On_Conflict>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Calc_Action_Avg_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_avg_fields';
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Avg_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_calc_action". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Calc_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Calc_Action_Bool_Exp>>;
  action_category?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  greenscore_action_id?: InputMaybe<String_Comparison_Exp>;
  greenscore_calc?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
  greenscore_calc_id?: InputMaybe<Int_Comparison_Exp>;
  review_id?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_action_status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_calc_action" */
export enum Zerocame_Greenscore_Calc_Action_Constraint {
  /** unique or primary key constraint on columns "greenscore_calc_id", "greenscore_action_id" */
  GreenscoreCalcActionPkey = 'greenscore_calc_action_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Inc_Input = {
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Insert_Input = {
  action_category?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  greenscore_calc?: InputMaybe<Zerocame_Greenscore_Calc_Obj_Rel_Insert_Input>;
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  user_action_status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Calc_Action_Max_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_max_fields';
  action_category?: Maybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  greenscore_calc_id?: Maybe<Scalars['Int']>;
  review_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  user_action_status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Max_Order_By = {
  action_category?: InputMaybe<Order_By>;
  /** 登録日時 */
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
  user_action_status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Calc_Action_Min_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_min_fields';
  action_category?: Maybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  greenscore_calc_id?: Maybe<Scalars['Int']>;
  review_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  user_action_status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Min_Order_By = {
  action_category?: InputMaybe<Order_By>;
  /** 登録日時 */
  created_at?: InputMaybe<Order_By>;
  /** 登録機能ID */
  created_by?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Order_By>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Order_By>;
  user_action_status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Mutation_Response = {
  __typename?: 'zerocame_greenscore_calc_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Calc_Action>;
};

/** on_conflict condition type for table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_On_Conflict = {
  constraint: Zerocame_Greenscore_Calc_Action_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Calc_Action_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_calc_action". */
export type Zerocame_Greenscore_Calc_Action_Order_By = {
  action_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  greenscore_calc?: InputMaybe<Zerocame_Greenscore_Calc_Order_By>;
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_action_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_calc_action */
export type Zerocame_Greenscore_Calc_Action_Pk_Columns_Input = {
  greenscore_action_id: Scalars['String'];
  greenscore_calc_id: Scalars['Int'];
};

/** select columns of table "zerocame.greenscore_calc_action" */
export enum Zerocame_Greenscore_Calc_Action_Select_Column {
  /** column name */
  ActionCategory = 'action_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  GreenscoreCalcId = 'greenscore_calc_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserActionStatus = 'user_action_status'
}

/** input type for updating data in table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Set_Input = {
  action_category?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  user_action_status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Calc_Action_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_stddev_fields';
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Stddev_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Calc_Action_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_stddev_pop_fields';
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Stddev_Pop_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Calc_Action_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_stddev_samp_fields';
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Stddev_Samp_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "zerocame_greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Calc_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Calc_Action_Stream_Cursor_Value_Input = {
  action_category?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  review_id?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  user_action_status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Calc_Action_Sum_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_sum_fields';
  greenscore_calc_id?: Maybe<Scalars['Int']>;
  review_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Sum_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "zerocame.greenscore_calc_action" */
export enum Zerocame_Greenscore_Calc_Action_Update_Column {
  /** column name */
  ActionCategory = 'action_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  GreenscoreCalcId = 'greenscore_calc_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserActionStatus = 'user_action_status'
}

export type Zerocame_Greenscore_Calc_Action_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Action_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Calc_Action_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Calc_Action_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_var_pop_fields';
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Var_Pop_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Calc_Action_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_var_samp_fields';
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Var_Samp_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Calc_Action_Variance_Fields = {
  __typename?: 'zerocame_greenscore_calc_action_variance_fields';
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zerocame.greenscore_calc_action" */
export type Zerocame_Greenscore_Calc_Action_Variance_Order_By = {
  greenscore_calc_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregated selection of "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Aggregate = {
  __typename?: 'zerocame_greenscore_calc_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Calc_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Calc>;
};

/** aggregate fields of "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_calc_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Calc_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Calc_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Calc_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Calc_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Calc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Calc_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Calc_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Calc_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Calc_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Calc_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Calc_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Calc_Avg_Fields = {
  __typename?: 'zerocame_greenscore_calc_avg_fields';
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_calc". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Calc_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Calc_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Calc_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  score_sum?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Zerocame_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_calc" */
export enum Zerocame_Greenscore_Calc_Constraint {
  /** unique or primary key constraint on columns "id" */
  GreenscoreCalcPkey = 'greenscore_calc_pkey'
}

/** グリーンスコア算出電気 */
export type Zerocame_Greenscore_Calc_Energy = {
  __typename?: 'zerocame_greenscore_calc_energy';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['numeric']>;
  attribute_no?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['numeric']>;
  /** 登録日時 */
  created_at: Scalars['timestamptz'];
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  greenscore_calc: Zerocame_Greenscore_Calc;
  greenscore_calc_id: Scalars['Int'];
  /** 0：初期スコア、1：算出されたスコア */
  is_calculated: Scalars['String'];
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  target_date?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Aggregate = {
  __typename?: 'zerocame_greenscore_calc_energy_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Calc_Energy_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Calc_Energy>;
};

/** aggregate fields of "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Calc_Energy_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Calc_Energy_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Calc_Energy_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Calc_Energy_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Calc_Energy_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Calc_Energy_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Calc_Energy_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Calc_Energy_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Calc_Energy_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Calc_Energy_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Calc_Energy_Avg_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_avg_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['Float']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_calc_energy". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Calc_Energy_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Calc_Energy_Bool_Exp>>;
  all_electrick_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  attribute_no?: InputMaybe<String_Comparison_Exp>;
  benchmark?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  difference_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  electric_usage_sum?: InputMaybe<Numeric_Comparison_Exp>;
  electric_vehicle_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  greenscore_calc?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
  greenscore_calc_id?: InputMaybe<Int_Comparison_Exp>;
  is_calculated?: InputMaybe<String_Comparison_Exp>;
  provisional_score?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  target_date?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_calc_energy" */
export enum Zerocame_Greenscore_Calc_Energy_Constraint {
  /** unique or primary key constraint on columns "greenscore_calc_id" */
  GreenscoreCalcEnergyPkey = 'greenscore_calc_energy_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Inc_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  /** 仮スコア */
  provisional_score?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Insert_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  attribute_no?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  greenscore_calc?: InputMaybe<Zerocame_Greenscore_Calc_Obj_Rel_Insert_Input>;
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  /** 0：初期スコア、1：算出されたスコア */
  is_calculated?: InputMaybe<Scalars['String']>;
  /** 仮スコア */
  provisional_score?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  target_date?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Calc_Energy_Max_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_max_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['numeric']>;
  attribute_no?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['numeric']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['numeric']>;
  greenscore_calc_id?: Maybe<Scalars['Int']>;
  /** 0：初期スコア、1：算出されたスコア */
  is_calculated?: Maybe<Scalars['String']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  target_date?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Calc_Energy_Min_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_min_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['numeric']>;
  attribute_no?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['numeric']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['numeric']>;
  greenscore_calc_id?: Maybe<Scalars['Int']>;
  /** 0：初期スコア、1：算出されたスコア */
  is_calculated?: Maybe<Scalars['String']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  target_date?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Mutation_Response = {
  __typename?: 'zerocame_greenscore_calc_energy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Calc_Energy>;
};

/** on_conflict condition type for table "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_On_Conflict = {
  constraint: Zerocame_Greenscore_Calc_Energy_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Calc_Energy_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_calc_energy". */
export type Zerocame_Greenscore_Calc_Energy_Order_By = {
  all_electrick_coefficient?: InputMaybe<Order_By>;
  attribute_no?: InputMaybe<Order_By>;
  benchmark?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  difference_coefficient?: InputMaybe<Order_By>;
  electric_usage_sum?: InputMaybe<Order_By>;
  electric_vehicle_coefficient?: InputMaybe<Order_By>;
  greenscore_calc?: InputMaybe<Zerocame_Greenscore_Calc_Order_By>;
  greenscore_calc_id?: InputMaybe<Order_By>;
  is_calculated?: InputMaybe<Order_By>;
  provisional_score?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  target_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_calc_energy */
export type Zerocame_Greenscore_Calc_Energy_Pk_Columns_Input = {
  greenscore_calc_id: Scalars['Int'];
};

/** select columns of table "zerocame.greenscore_calc_energy" */
export enum Zerocame_Greenscore_Calc_Energy_Select_Column {
  /** column name */
  AllElectrickCoefficient = 'all_electrick_coefficient',
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DifferenceCoefficient = 'difference_coefficient',
  /** column name */
  ElectricUsageSum = 'electric_usage_sum',
  /** column name */
  ElectricVehicleCoefficient = 'electric_vehicle_coefficient',
  /** column name */
  GreenscoreCalcId = 'greenscore_calc_id',
  /** column name */
  IsCalculated = 'is_calculated',
  /** column name */
  ProvisionalScore = 'provisional_score',
  /** column name */
  Score = 'score',
  /** column name */
  TargetDate = 'target_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Set_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  attribute_no?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  /** 0：初期スコア、1：算出されたスコア */
  is_calculated?: InputMaybe<Scalars['String']>;
  /** 仮スコア */
  provisional_score?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  target_date?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Calc_Energy_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_stddev_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['Float']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Calc_Energy_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_stddev_pop_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['Float']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Calc_Energy_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_stddev_samp_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['Float']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_calc_energy" */
export type Zerocame_Greenscore_Calc_Energy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Calc_Energy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Calc_Energy_Stream_Cursor_Value_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  attribute_no?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  greenscore_calc_id?: InputMaybe<Scalars['Int']>;
  /** 0：初期スコア、1：算出されたスコア */
  is_calculated?: InputMaybe<Scalars['String']>;
  /** 仮スコア */
  provisional_score?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  target_date?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Calc_Energy_Sum_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_sum_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['numeric']>;
  benchmark?: Maybe<Scalars['numeric']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['numeric']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['numeric']>;
  greenscore_calc_id?: Maybe<Scalars['Int']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.greenscore_calc_energy" */
export enum Zerocame_Greenscore_Calc_Energy_Update_Column {
  /** column name */
  AllElectrickCoefficient = 'all_electrick_coefficient',
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DifferenceCoefficient = 'difference_coefficient',
  /** column name */
  ElectricUsageSum = 'electric_usage_sum',
  /** column name */
  ElectricVehicleCoefficient = 'electric_vehicle_coefficient',
  /** column name */
  GreenscoreCalcId = 'greenscore_calc_id',
  /** column name */
  IsCalculated = 'is_calculated',
  /** column name */
  ProvisionalScore = 'provisional_score',
  /** column name */
  Score = 'score',
  /** column name */
  TargetDate = 'target_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_Calc_Energy_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Energy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Calc_Energy_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Calc_Energy_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_var_pop_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['Float']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Calc_Energy_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_var_samp_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['Float']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Calc_Energy_Variance_Fields = {
  __typename?: 'zerocame_greenscore_calc_energy_variance_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** 閾値マスタの係数 */
  difference_coefficient?: Maybe<Scalars['Float']>;
  /** 電力使用量の合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  greenscore_calc_id?: Maybe<Scalars['Float']>;
  /** 仮スコア */
  provisional_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  score_sum?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 1：アクションスコア、2：エネルギースコア */
  category?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  score_sum?: InputMaybe<Scalars['Int']>;
  /** 1：集計中、2：集計完了 */
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Zerocame_Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Calc_Max_Fields = {
  __typename?: 'zerocame_greenscore_calc_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 1：アクションスコア、2：エネルギースコア */
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  score_sum?: Maybe<Scalars['Int']>;
  /** 1：集計中、2：集計完了 */
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Calc_Min_Fields = {
  __typename?: 'zerocame_greenscore_calc_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 1：アクションスコア、2：エネルギースコア */
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  score_sum?: Maybe<Scalars['Int']>;
  /** 1：集計中、2：集計完了 */
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Mutation_Response = {
  __typename?: 'zerocame_greenscore_calc_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Calc>;
};

/** input type for inserting object relation for remote table "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Obj_Rel_Insert_Input = {
  data: Zerocame_Greenscore_Calc_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Greenscore_Calc_On_Conflict>;
};

/** on_conflict condition type for table "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_On_Conflict = {
  constraint: Zerocame_Greenscore_Calc_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Calc_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Calc_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_calc". */
export type Zerocame_Greenscore_Calc_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score_sum?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<Zerocame_Users_Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_calc */
export type Zerocame_Greenscore_Calc_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "zerocame.greenscore_calc" */
export enum Zerocame_Greenscore_Calc_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ScoreSum = 'score_sum',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore_calc" */
export type Zerocame_Greenscore_Calc_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 1：アクションスコア、2：エネルギースコア */
  category?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  score_sum?: InputMaybe<Scalars['Int']>;
  /** 1：集計中、2：集計完了 */
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Calc_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_calc_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Calc_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_calc_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Calc_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_calc_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_calc" */
export type Zerocame_Greenscore_Calc_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Calc_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Calc_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 1：アクションスコア、2：エネルギースコア */
  category?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  score_sum?: InputMaybe<Scalars['Int']>;
  /** 1：集計中、2：集計完了 */
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Calc_Sum_Fields = {
  __typename?: 'zerocame_greenscore_calc_sum_fields';
  id?: Maybe<Scalars['Int']>;
  score_sum?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.greenscore_calc" */
export enum Zerocame_Greenscore_Calc_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ScoreSum = 'score_sum',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_Calc_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Calc_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Calc_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Calc_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Calc_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_calc_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Calc_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_calc_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Calc_Variance_Fields = {
  __typename?: 'zerocame_greenscore_calc_variance_fields';
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** チャレンジ完了コードマスタ */
export type Zerocame_Greenscore_Challenge_Code_Master = {
  __typename?: 'zerocame_greenscore_challenge_code_master';
  /** 入力チャレンジ完了コード */
  campaign_code: Scalars['String'];
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id: Scalars['String'];
  /** レコード作成日時 */
  created_at: Scalars['timestamptz'];
  /** レコード更新日時 */
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "zerocame.greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_Aggregate = {
  __typename?: 'zerocame_greenscore_challenge_code_master_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Challenge_Code_Master_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Challenge_Code_Master>;
};

/** aggregate fields of "zerocame.greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_challenge_code_master_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Challenge_Code_Master_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Challenge_Code_Master_Min_Fields>;
};


/** aggregate fields of "zerocame.greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_challenge_code_master". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>>;
  campaign_code?: InputMaybe<String_Comparison_Exp>;
  challenge_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_challenge_code_master" */
export enum Zerocame_Greenscore_Challenge_Code_Master_Constraint {
  /** unique or primary key constraint on columns "challenge_id" */
  GreenscoreChallengeCodeMasterPkey = 'greenscore_challenge_code_master_pkey'
}

/** input type for inserting data into table "zerocame.greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_Insert_Input = {
  /** 入力チャレンジ完了コード */
  campaign_code?: InputMaybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: InputMaybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** レコード更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Challenge_Code_Master_Max_Fields = {
  __typename?: 'zerocame_greenscore_challenge_code_master_max_fields';
  /** 入力チャレンジ完了コード */
  campaign_code?: Maybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: Maybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** レコード更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Challenge_Code_Master_Min_Fields = {
  __typename?: 'zerocame_greenscore_challenge_code_master_min_fields';
  /** 入力チャレンジ完了コード */
  campaign_code?: Maybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: Maybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: Maybe<Scalars['timestamptz']>;
  /** レコード更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "zerocame.greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_Mutation_Response = {
  __typename?: 'zerocame_greenscore_challenge_code_master_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Challenge_Code_Master>;
};

/** on_conflict condition type for table "zerocame.greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_On_Conflict = {
  constraint: Zerocame_Greenscore_Challenge_Code_Master_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Challenge_Code_Master_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_challenge_code_master". */
export type Zerocame_Greenscore_Challenge_Code_Master_Order_By = {
  campaign_code?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_challenge_code_master */
export type Zerocame_Greenscore_Challenge_Code_Master_Pk_Columns_Input = {
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_challenge_code_master" */
export enum Zerocame_Greenscore_Challenge_Code_Master_Select_Column {
  /** column name */
  CampaignCode = 'campaign_code',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "zerocame.greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_Set_Input = {
  /** 入力チャレンジ完了コード */
  campaign_code?: InputMaybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: InputMaybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** レコード更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "zerocame_greenscore_challenge_code_master" */
export type Zerocame_Greenscore_Challenge_Code_Master_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Challenge_Code_Master_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Challenge_Code_Master_Stream_Cursor_Value_Input = {
  /** 入力チャレンジ完了コード */
  campaign_code?: InputMaybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: InputMaybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** レコード更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "zerocame.greenscore_challenge_code_master" */
export enum Zerocame_Greenscore_Challenge_Code_Master_Update_Column {
  /** column name */
  CampaignCode = 'campaign_code',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Zerocame_Greenscore_Challenge_Code_Master_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Challenge_Code_Master_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Challenge_Code_Master_Bool_Exp;
};

/** CO2排出量削減 */
export type Zerocame_Greenscore_Co2_Emission = {
  __typename?: 'zerocame_greenscore_co2_emission';
  auth0_user_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** アクションによるCO2排出量削減 */
export type Zerocame_Greenscore_Co2_Emission_Action = {
  __typename?: 'zerocame_greenscore_co2_emission_action';
  action_category?: Maybe<Scalars['String']>;
  auth0_user_id: Scalars['String'];
  /** CO2排出削減量 */
  co2_emission_reduction: Scalars['numeric'];
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id: Scalars['String'];
  review_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  user_action_status: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** aggregated selection of "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Aggregate = {
  __typename?: 'zerocame_greenscore_co2_emission_action_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Co2_Emission_Action>;
};

/** aggregate fields of "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Co2_Emission_Action_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Avg_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_avg_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_co2_emission_action". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>>;
  action_category?: InputMaybe<String_Comparison_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  co2_emission_reduction?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  greenscore_action_id?: InputMaybe<String_Comparison_Exp>;
  review_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_action_status?: InputMaybe<String_Comparison_Exp>;
  year_month?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_co2_emission_action" */
export enum Zerocame_Greenscore_Co2_Emission_Action_Constraint {
  /** unique or primary key constraint on columns "year_month", "greenscore_action_id", "auth0_user_id" */
  GreenscoreCo2EmissionActionPkey = 'greenscore_co2_emission_action_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Inc_Input = {
  /** CO2排出削減量 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  review_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Insert_Input = {
  action_category?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** CO2排出削減量 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  review_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  user_action_status?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Max_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_max_fields';
  action_category?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  review_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  user_action_status?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Min_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_min_fields';
  action_category?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  review_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  user_action_status?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Mutation_Response = {
  __typename?: 'zerocame_greenscore_co2_emission_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Co2_Emission_Action>;
};

/** on_conflict condition type for table "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_On_Conflict = {
  constraint: Zerocame_Greenscore_Co2_Emission_Action_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Co2_Emission_Action_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_co2_emission_action". */
export type Zerocame_Greenscore_Co2_Emission_Action_Order_By = {
  action_category?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  co2_emission_reduction?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_action_status?: InputMaybe<Order_By>;
  year_month?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_co2_emission_action */
export type Zerocame_Greenscore_Co2_Emission_Action_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_co2_emission_action" */
export enum Zerocame_Greenscore_Co2_Emission_Action_Select_Column {
  /** column name */
  ActionCategory = 'action_category',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Co2EmissionReduction = 'co2_emission_reduction',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserActionStatus = 'user_action_status',
  /** column name */
  YearMonth = 'year_month'
}

/** input type for updating data in table "zerocame.greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Set_Input = {
  action_category?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** CO2排出削減量 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  review_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  user_action_status?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_stddev_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_stddev_pop_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_stddev_samp_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_co2_emission_action" */
export type Zerocame_Greenscore_Co2_Emission_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Co2_Emission_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Co2_Emission_Action_Stream_Cursor_Value_Input = {
  action_category?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** CO2排出削減量 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  review_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  user_action_status?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Sum_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_sum_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['numeric']>;
  review_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.greenscore_co2_emission_action" */
export enum Zerocame_Greenscore_Co2_Emission_Action_Update_Column {
  /** column name */
  ActionCategory = 'action_category',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Co2EmissionReduction = 'co2_emission_reduction',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserActionStatus = 'user_action_status',
  /** column name */
  YearMonth = 'year_month'
}

export type Zerocame_Greenscore_Co2_Emission_Action_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Co2_Emission_Action_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_var_pop_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_var_samp_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Co2_Emission_Action_Variance_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_action_variance_fields';
  /** CO2排出削減量 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  review_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Aggregate = {
  __typename?: 'zerocame_greenscore_co2_emission_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Co2_Emission_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Co2_Emission>;
};

/** aggregate fields of "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Co2_Emission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Co2_Emission_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Co2_Emission_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Co2_Emission_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Co2_Emission_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Co2_Emission_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Co2_Emission_Avg_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_avg_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_co2_emission". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Co2_Emission_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  total_co2_emission_reduction_dr?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  year_month?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_co2_emission" */
export enum Zerocame_Greenscore_Co2_Emission_Constraint {
  /** unique or primary key constraint on columns "year_month", "auth0_user_id" */
  GreenscoreCo2EmissionPkey = 'greenscore_co2_emission_pkey'
}

/** 【ベンチマーク方式方式】電力使用量によるCO2排出量削減 */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient: Scalars['numeric'];
  attribute_no: Scalars['String'];
  auth0_user_id: Scalars['String'];
  benchmark: Scalars['numeric'];
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient: Scalars['numeric'];
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** aggregated selection of "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Aggregate = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
};

/** aggregate fields of "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Avg_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_avg_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_co2_emission_energy_bm". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>>;
  all_electrick_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  attribute_no?: InputMaybe<String_Comparison_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  benchmark?: InputMaybe<Numeric_Comparison_Exp>;
  co2_emission_benchmark?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  electric_vehicle_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  result_co2_emission_reduction?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  year_month?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_co2_emission_energy_bm" */
export enum Zerocame_Greenscore_Co2_Emission_Energy_Bm_Constraint {
  /** unique or primary key constraint on columns "year_month", "auth0_user_id" */
  GreenscoreCo2EmissionEnergyBmPkey = 'greenscore_co2_emission_energy_bm_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Inc_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Insert_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  attribute_no?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Max_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_max_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['numeric']>;
  attribute_no?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['numeric']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['numeric']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Min_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_min_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['numeric']>;
  attribute_no?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['numeric']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['numeric']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Mutation_Response = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm>;
};

/** on_conflict condition type for table "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_On_Conflict = {
  constraint: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_co2_emission_energy_bm". */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Order_By = {
  all_electrick_coefficient?: InputMaybe<Order_By>;
  attribute_no?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  benchmark?: InputMaybe<Order_By>;
  co2_emission_benchmark?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  electric_vehicle_coefficient?: InputMaybe<Order_By>;
  result_co2_emission_reduction?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  year_month?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_co2_emission_energy_bm */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_co2_emission_energy_bm" */
export enum Zerocame_Greenscore_Co2_Emission_Energy_Bm_Select_Column {
  /** column name */
  AllElectrickCoefficient = 'all_electrick_coefficient',
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  Co2EmissionBenchmark = 'co2_emission_benchmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ElectricVehicleCoefficient = 'electric_vehicle_coefficient',
  /** column name */
  ResultCo2EmissionReduction = 'result_co2_emission_reduction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  YearMonth = 'year_month'
}

/** input type for updating data in table "zerocame.greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Set_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  attribute_no?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_stddev_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_stddev_pop_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_stddev_samp_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_co2_emission_energy_bm" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Stream_Cursor_Value_Input = {
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: InputMaybe<Scalars['numeric']>;
  attribute_no?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: InputMaybe<Scalars['numeric']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Sum_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_sum_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['numeric']>;
  benchmark?: Maybe<Scalars['numeric']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['numeric']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['numeric']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.greenscore_co2_emission_energy_bm" */
export enum Zerocame_Greenscore_Co2_Emission_Energy_Bm_Update_Column {
  /** column name */
  AllElectrickCoefficient = 'all_electrick_coefficient',
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  Co2EmissionBenchmark = 'co2_emission_benchmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ElectricVehicleCoefficient = 'electric_vehicle_coefficient',
  /** column name */
  ResultCo2EmissionReduction = 'result_co2_emission_reduction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  YearMonth = 'year_month'
}

export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Bm_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Co2_Emission_Energy_Bm_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_var_pop_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_var_samp_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Bm_Variance_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_bm_variance_fields';
  /** ベンチマーク係数マスタのオール電化の係数 */
  all_electrick_coefficient?: Maybe<Scalars['Float']>;
  benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマークとユーザーの排出係数から算出したCO2排出量 */
  co2_emission_benchmark?: Maybe<Scalars['Float']>;
  /** ベンチマーク係数マスタの電気自動車の有無の係数 */
  electric_vehicle_coefficient?: Maybe<Scalars['Float']>;
  /** ベンチマーク方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** 【DR方式】電力使用量によるCO2排出量削減 */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr';
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: Maybe<Scalars['String']>;
  auth0_user_id: Scalars['String'];
  /** 前月のCO2排出量削減 */
  co2_emission_reduction: Scalars['numeric'];
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient: Scalars['numeric'];
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction: Scalars['numeric'];
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient: Scalars['numeric'];
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction: Scalars['numeric'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** aggregated selection of "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Aggregate = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
};

/** aggregate fields of "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Avg_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_avg_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['Float']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_co2_emission_energy_dr". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>>;
  all_electric?: InputMaybe<String_Comparison_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  co2_emission_reduction?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  electric_usage_sum?: InputMaybe<Numeric_Comparison_Exp>;
  emission_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  prev_co2_emission_reduction?: InputMaybe<Numeric_Comparison_Exp>;
  prev_electric_usage_sum?: InputMaybe<Numeric_Comparison_Exp>;
  prev_emission_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  result_co2_emission_reduction?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  year_month?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_co2_emission_energy_dr" */
export enum Zerocame_Greenscore_Co2_Emission_Energy_Dr_Constraint {
  /** unique or primary key constraint on columns "year_month", "auth0_user_id" */
  GreenscoreCo2EmissionEnergyDrPkey = 'greenscore_co2_emission_energy_dr_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Inc_Input = {
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Insert_Input = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Max_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_max_fields';
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['numeric']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['numeric']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['numeric']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Min_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_min_fields';
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['numeric']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['numeric']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['numeric']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Mutation_Response = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr>;
};

/** on_conflict condition type for table "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_On_Conflict = {
  constraint: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_co2_emission_energy_dr". */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Order_By = {
  all_electric?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  co2_emission_reduction?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  electric_usage_sum?: InputMaybe<Order_By>;
  emission_coefficient?: InputMaybe<Order_By>;
  prev_co2_emission_reduction?: InputMaybe<Order_By>;
  prev_electric_usage_sum?: InputMaybe<Order_By>;
  prev_emission_coefficient?: InputMaybe<Order_By>;
  result_co2_emission_reduction?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  year_month?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_co2_emission_energy_dr */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_co2_emission_energy_dr" */
export enum Zerocame_Greenscore_Co2_Emission_Energy_Dr_Select_Column {
  /** column name */
  AllElectric = 'all_electric',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Co2EmissionReduction = 'co2_emission_reduction',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ElectricUsageSum = 'electric_usage_sum',
  /** column name */
  EmissionCoefficient = 'emission_coefficient',
  /** column name */
  PrevCo2EmissionReduction = 'prev_co2_emission_reduction',
  /** column name */
  PrevElectricUsageSum = 'prev_electric_usage_sum',
  /** column name */
  PrevEmissionCoefficient = 'prev_emission_coefficient',
  /** column name */
  ResultCo2EmissionReduction = 'result_co2_emission_reduction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  YearMonth = 'year_month'
}

/** input type for updating data in table "zerocame.greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Set_Input = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_stddev_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['Float']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_stddev_pop_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['Float']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_stddev_samp_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['Float']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_co2_emission_energy_dr" */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Stream_Cursor_Value_Input = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: InputMaybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Sum_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_sum_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['numeric']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['numeric']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['numeric']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['numeric']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['numeric']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.greenscore_co2_emission_energy_dr" */
export enum Zerocame_Greenscore_Co2_Emission_Energy_Dr_Update_Column {
  /** column name */
  AllElectric = 'all_electric',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  Co2EmissionReduction = 'co2_emission_reduction',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ElectricUsageSum = 'electric_usage_sum',
  /** column name */
  EmissionCoefficient = 'emission_coefficient',
  /** column name */
  PrevCo2EmissionReduction = 'prev_co2_emission_reduction',
  /** column name */
  PrevElectricUsageSum = 'prev_electric_usage_sum',
  /** column name */
  PrevEmissionCoefficient = 'prev_emission_coefficient',
  /** column name */
  ResultCo2EmissionReduction = 'result_co2_emission_reduction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  YearMonth = 'year_month'
}

export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Energy_Dr_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Co2_Emission_Energy_Dr_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_var_pop_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['Float']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_var_samp_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['Float']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Co2_Emission_Energy_Dr_Variance_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_energy_dr_variance_fields';
  /** 前月のCO2排出量削減 */
  co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前月の電力使用量合計 */
  electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前月の基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
  /** 前年前月のCO2排出量削減 */
  prev_co2_emission_reduction?: Maybe<Scalars['Float']>;
  /** 前年前月の電力使用量の合計 */
  prev_electric_usage_sum?: Maybe<Scalars['Float']>;
  /** 前年前月の基礎排出係数 */
  prev_emission_coefficient?: Maybe<Scalars['Float']>;
  /** DR方式のCO2排出量削減の算出結果（補正済み） */
  result_co2_emission_reduction?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Inc_Input = {
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Co2_Emission_Max_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Co2_Emission_Min_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Mutation_Response = {
  __typename?: 'zerocame_greenscore_co2_emission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Co2_Emission>;
};

/** on_conflict condition type for table "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_On_Conflict = {
  constraint: Zerocame_Greenscore_Co2_Emission_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Co2_Emission_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_co2_emission". */
export type Zerocame_Greenscore_Co2_Emission_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  total_co2_emission_reduction_dr?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year_month?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_co2_emission */
export type Zerocame_Greenscore_Co2_Emission_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_co2_emission" */
export enum Zerocame_Greenscore_Co2_Emission_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TotalCo2EmissionReductionDr = 'total_co2_emission_reduction_dr',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearMonth = 'year_month'
}

/** input type for updating data in table "zerocame.greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Co2_Emission_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_stddev_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_stddev_pop_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_stddev_samp_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_co2_emission" */
export type Zerocame_Greenscore_Co2_Emission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Co2_Emission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Co2_Emission_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Co2_Emission_Sum_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_sum_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.greenscore_co2_emission" */
export enum Zerocame_Greenscore_Co2_Emission_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TotalCo2EmissionReductionDr = 'total_co2_emission_reduction_dr',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearMonth = 'year_month'
}

export type Zerocame_Greenscore_Co2_Emission_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Co2_Emission_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Co2_Emission_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Co2_Emission_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_var_pop_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Co2_Emission_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_var_samp_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Co2_Emission_Variance_Fields = {
  __typename?: 'zerocame_greenscore_co2_emission_variance_fields';
  /** DR方式でのCO2排出量削減の合計 */
  total_co2_emission_reduction_dr?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "zerocame.greenscore" */
export enum Zerocame_Greenscore_Constraint {
  /** unique or primary key constraint on columns "id" */
  GreenscorePkey = 'greenscore_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore" */
export type Zerocame_Greenscore_Inc_Input = {
  calc_id_action?: InputMaybe<Scalars['Int']>;
  calc_id_energy?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  score_sum?: InputMaybe<Scalars['Int']>;
};

/** 初期スコア診断時のヒアリング回答を保持する */
export type Zerocame_Greenscore_Init_Hearing = {
  __typename?: 'zerocame_greenscore_init_hearing';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Int']>;
  /** 回答内容　例）はい、いいえ...など */
  answer_content?: Maybe<Scalars['String']>;
  /** Auth0ユーザーID */
  auth0_user_id: Scalars['String'];
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamp']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** ヒアリングNo（質問No） */
  hearing_no: Scalars['Int'];
  /** ヒアリングタイトル（質問名） */
  hearing_title: Scalars['String'];
  /** 更新日時 */
  updated_at?: Maybe<Scalars['timestamp']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Aggregate = {
  __typename?: 'zerocame_greenscore_init_hearing_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Init_Hearing_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Init_Hearing>;
};

/** aggregate fields of "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_aggregate_fields';
  avg?: Maybe<Zerocame_Greenscore_Init_Hearing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Init_Hearing_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Init_Hearing_Min_Fields>;
  stddev?: Maybe<Zerocame_Greenscore_Init_Hearing_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Greenscore_Init_Hearing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Greenscore_Init_Hearing_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Greenscore_Init_Hearing_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Greenscore_Init_Hearing_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Greenscore_Init_Hearing_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Greenscore_Init_Hearing_Variance_Fields>;
};


/** aggregate fields of "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Greenscore_Init_Hearing_Avg_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_avg_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Float']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_init_hearing". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Init_Hearing_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Init_Hearing_Bool_Exp>>;
  answer_code?: InputMaybe<Int_Comparison_Exp>;
  answer_content?: InputMaybe<String_Comparison_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  hearing_no?: InputMaybe<Int_Comparison_Exp>;
  hearing_title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_init_hearing" */
export enum Zerocame_Greenscore_Init_Hearing_Constraint {
  /** unique or primary key constraint on columns "hearing_no", "auth0_user_id" */
  GreenscoreInitHearingPkey = 'greenscore_init_hearing_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Inc_Input = {
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: InputMaybe<Scalars['Int']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Insert_Input = {
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: InputMaybe<Scalars['Int']>;
  /** 回答内容　例）はい、いいえ...など */
  answer_content?: InputMaybe<Scalars['String']>;
  /** Auth0ユーザーID */
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: InputMaybe<Scalars['Int']>;
  /** ヒアリングタイトル（質問名） */
  hearing_title?: InputMaybe<Scalars['String']>;
  /** 更新日時 */
  updated_at?: InputMaybe<Scalars['timestamp']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Init_Hearing_Max_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_max_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Int']>;
  /** 回答内容　例）はい、いいえ...など */
  answer_content?: Maybe<Scalars['String']>;
  /** Auth0ユーザーID */
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamp']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Int']>;
  /** ヒアリングタイトル（質問名） */
  hearing_title?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updated_at?: Maybe<Scalars['timestamp']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Init_Hearing_Min_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_min_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Int']>;
  /** 回答内容　例）はい、いいえ...など */
  answer_content?: Maybe<Scalars['String']>;
  /** Auth0ユーザーID */
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: Maybe<Scalars['timestamp']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Int']>;
  /** ヒアリングタイトル（質問名） */
  hearing_title?: Maybe<Scalars['String']>;
  /** 更新日時 */
  updated_at?: Maybe<Scalars['timestamp']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Mutation_Response = {
  __typename?: 'zerocame_greenscore_init_hearing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Init_Hearing>;
};

/** on_conflict condition type for table "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_On_Conflict = {
  constraint: Zerocame_Greenscore_Init_Hearing_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Init_Hearing_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_init_hearing". */
export type Zerocame_Greenscore_Init_Hearing_Order_By = {
  answer_code?: InputMaybe<Order_By>;
  answer_content?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  hearing_no?: InputMaybe<Order_By>;
  hearing_title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_init_hearing */
export type Zerocame_Greenscore_Init_Hearing_Pk_Columns_Input = {
  /** Auth0ユーザーID */
  auth0_user_id: Scalars['String'];
  /** ヒアリングNo（質問No） */
  hearing_no: Scalars['Int'];
};

/** select columns of table "zerocame.greenscore_init_hearing" */
export enum Zerocame_Greenscore_Init_Hearing_Select_Column {
  /** column name */
  AnswerCode = 'answer_code',
  /** column name */
  AnswerContent = 'answer_content',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  HearingNo = 'hearing_no',
  /** column name */
  HearingTitle = 'hearing_title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Set_Input = {
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: InputMaybe<Scalars['Int']>;
  /** 回答内容　例）はい、いいえ...など */
  answer_content?: InputMaybe<Scalars['String']>;
  /** Auth0ユーザーID */
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: InputMaybe<Scalars['Int']>;
  /** ヒアリングタイトル（質問名） */
  hearing_title?: InputMaybe<Scalars['String']>;
  /** 更新日時 */
  updated_at?: InputMaybe<Scalars['timestamp']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Init_Hearing_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_stddev_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Float']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Init_Hearing_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_stddev_pop_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Float']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Init_Hearing_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_stddev_samp_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Float']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore_init_hearing" */
export type Zerocame_Greenscore_Init_Hearing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Init_Hearing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Init_Hearing_Stream_Cursor_Value_Input = {
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: InputMaybe<Scalars['Int']>;
  /** 回答内容　例）はい、いいえ...など */
  answer_content?: InputMaybe<Scalars['String']>;
  /** Auth0ユーザーID */
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 登録日時 */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: InputMaybe<Scalars['Int']>;
  /** ヒアリングタイトル（質問名） */
  hearing_title?: InputMaybe<Scalars['String']>;
  /** 更新日時 */
  updated_at?: InputMaybe<Scalars['timestamp']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Init_Hearing_Sum_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_sum_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Int']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.greenscore_init_hearing" */
export enum Zerocame_Greenscore_Init_Hearing_Update_Column {
  /** column name */
  AnswerCode = 'answer_code',
  /** column name */
  AnswerContent = 'answer_content',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  HearingNo = 'hearing_no',
  /** column name */
  HearingTitle = 'hearing_title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_Init_Hearing_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Init_Hearing_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Init_Hearing_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Init_Hearing_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_var_pop_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Float']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Init_Hearing_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_var_samp_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Float']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Init_Hearing_Variance_Fields = {
  __typename?: 'zerocame_greenscore_init_hearing_variance_fields';
  /** 回答コード　例）1:はい、2:いいえ　質問によってコード内容は異なる */
  answer_code?: Maybe<Scalars['Float']>;
  /** ヒアリングNo（質問No） */
  hearing_no?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "zerocame.greenscore" */
export type Zerocame_Greenscore_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  calc_id_action?: InputMaybe<Scalars['Int']>;
  calc_id_energy?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_saved?: InputMaybe<Scalars['Boolean']>;
  score_sum?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Zerocame_Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Max_Fields = {
  __typename?: 'zerocame_greenscore_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  calc_id_action?: Maybe<Scalars['Int']>;
  calc_id_energy?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  score_sum?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Min_Fields = {
  __typename?: 'zerocame_greenscore_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  calc_id_action?: Maybe<Scalars['Int']>;
  calc_id_energy?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  score_sum?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** ミッション達成管理テーブル */
export type Zerocame_Greenscore_Mission_Achievement = {
  __typename?: 'zerocame_greenscore_mission_achievement';
  /** auth0ID */
  auth0_user_id: Scalars['String'];
  /** 入力チャレンジ完了コード */
  challenge_code_input?: Maybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id: Scalars['String'];
  /** 企業・自治体名 */
  company_name: Scalars['String'];
  /** レコード作成日時 */
  created_at: Scalars['timestamp'];
  /** データ種別（記事/クイズ/キャンペーン） */
  data_type: Scalars['String'];
  /** ミッションID（CMSのコンテンツID） */
  mission_id: Scalars['String'];
  /** クイズ1回答 */
  quiz1_input?: Maybe<Scalars['String']>;
  /** クイズ2回答 */
  quiz2_input?: Maybe<Scalars['String']>;
  /** クイズ3回答 */
  quiz3_input?: Maybe<Scalars['String']>;
  /** クイズ4回答 */
  quiz4_input?: Maybe<Scalars['String']>;
  /** クイズ5回答 */
  quiz5_input?: Maybe<Scalars['String']>;
  /** クイズ6回答 */
  quiz6_input?: Maybe<Scalars['String']>;
  /** クイズ7回答 */
  quiz7_input?: Maybe<Scalars['String']>;
  /** クイズ8回答 */
  quiz8_input?: Maybe<Scalars['String']>;
  /** クイズ9回答 */
  quiz9_input?: Maybe<Scalars['String']>;
  /** クイズ10回答 */
  quiz10_input?: Maybe<Scalars['String']>;
  /** レコード更新日時 */
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "zerocame.greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_Aggregate = {
  __typename?: 'zerocame_greenscore_mission_achievement_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Mission_Achievement_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Mission_Achievement>;
};

/** aggregate fields of "zerocame.greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_mission_achievement_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Mission_Achievement_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Mission_Achievement_Min_Fields>;
};


/** aggregate fields of "zerocame.greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_mission_achievement". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Mission_Achievement_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  challenge_code_input?: InputMaybe<String_Comparison_Exp>;
  challenge_id?: InputMaybe<String_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data_type?: InputMaybe<String_Comparison_Exp>;
  mission_id?: InputMaybe<String_Comparison_Exp>;
  quiz1_input?: InputMaybe<String_Comparison_Exp>;
  quiz2_input?: InputMaybe<String_Comparison_Exp>;
  quiz3_input?: InputMaybe<String_Comparison_Exp>;
  quiz4_input?: InputMaybe<String_Comparison_Exp>;
  quiz5_input?: InputMaybe<String_Comparison_Exp>;
  quiz6_input?: InputMaybe<String_Comparison_Exp>;
  quiz7_input?: InputMaybe<String_Comparison_Exp>;
  quiz8_input?: InputMaybe<String_Comparison_Exp>;
  quiz9_input?: InputMaybe<String_Comparison_Exp>;
  quiz10_input?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_mission_achievement" */
export enum Zerocame_Greenscore_Mission_Achievement_Constraint {
  /** unique or primary key constraint on columns "challenge_id", "auth0_user_id", "mission_id" */
  MissionAchievementPkey = 'mission_achievement_pkey'
}

/** input type for inserting data into table "zerocame.greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_Insert_Input = {
  /** auth0ID */
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 入力チャレンジ完了コード */
  challenge_code_input?: InputMaybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: InputMaybe<Scalars['String']>;
  /** 企業・自治体名 */
  company_name?: InputMaybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** データ種別（記事/クイズ/キャンペーン） */
  data_type?: InputMaybe<Scalars['String']>;
  /** ミッションID（CMSのコンテンツID） */
  mission_id?: InputMaybe<Scalars['String']>;
  /** クイズ1回答 */
  quiz1_input?: InputMaybe<Scalars['String']>;
  /** クイズ2回答 */
  quiz2_input?: InputMaybe<Scalars['String']>;
  /** クイズ3回答 */
  quiz3_input?: InputMaybe<Scalars['String']>;
  /** クイズ4回答 */
  quiz4_input?: InputMaybe<Scalars['String']>;
  /** クイズ5回答 */
  quiz5_input?: InputMaybe<Scalars['String']>;
  /** クイズ6回答 */
  quiz6_input?: InputMaybe<Scalars['String']>;
  /** クイズ7回答 */
  quiz7_input?: InputMaybe<Scalars['String']>;
  /** クイズ8回答 */
  quiz8_input?: InputMaybe<Scalars['String']>;
  /** クイズ9回答 */
  quiz9_input?: InputMaybe<Scalars['String']>;
  /** クイズ10回答 */
  quiz10_input?: InputMaybe<Scalars['String']>;
  /** レコード更新日時 */
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Mission_Achievement_Max_Fields = {
  __typename?: 'zerocame_greenscore_mission_achievement_max_fields';
  /** auth0ID */
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 入力チャレンジ完了コード */
  challenge_code_input?: Maybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: Maybe<Scalars['String']>;
  /** 企業・自治体名 */
  company_name?: Maybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: Maybe<Scalars['timestamp']>;
  /** データ種別（記事/クイズ/キャンペーン） */
  data_type?: Maybe<Scalars['String']>;
  /** ミッションID（CMSのコンテンツID） */
  mission_id?: Maybe<Scalars['String']>;
  /** クイズ1回答 */
  quiz1_input?: Maybe<Scalars['String']>;
  /** クイズ2回答 */
  quiz2_input?: Maybe<Scalars['String']>;
  /** クイズ3回答 */
  quiz3_input?: Maybe<Scalars['String']>;
  /** クイズ4回答 */
  quiz4_input?: Maybe<Scalars['String']>;
  /** クイズ5回答 */
  quiz5_input?: Maybe<Scalars['String']>;
  /** クイズ6回答 */
  quiz6_input?: Maybe<Scalars['String']>;
  /** クイズ7回答 */
  quiz7_input?: Maybe<Scalars['String']>;
  /** クイズ8回答 */
  quiz8_input?: Maybe<Scalars['String']>;
  /** クイズ9回答 */
  quiz9_input?: Maybe<Scalars['String']>;
  /** クイズ10回答 */
  quiz10_input?: Maybe<Scalars['String']>;
  /** レコード更新日時 */
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Mission_Achievement_Min_Fields = {
  __typename?: 'zerocame_greenscore_mission_achievement_min_fields';
  /** auth0ID */
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 入力チャレンジ完了コード */
  challenge_code_input?: Maybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: Maybe<Scalars['String']>;
  /** 企業・自治体名 */
  company_name?: Maybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: Maybe<Scalars['timestamp']>;
  /** データ種別（記事/クイズ/キャンペーン） */
  data_type?: Maybe<Scalars['String']>;
  /** ミッションID（CMSのコンテンツID） */
  mission_id?: Maybe<Scalars['String']>;
  /** クイズ1回答 */
  quiz1_input?: Maybe<Scalars['String']>;
  /** クイズ2回答 */
  quiz2_input?: Maybe<Scalars['String']>;
  /** クイズ3回答 */
  quiz3_input?: Maybe<Scalars['String']>;
  /** クイズ4回答 */
  quiz4_input?: Maybe<Scalars['String']>;
  /** クイズ5回答 */
  quiz5_input?: Maybe<Scalars['String']>;
  /** クイズ6回答 */
  quiz6_input?: Maybe<Scalars['String']>;
  /** クイズ7回答 */
  quiz7_input?: Maybe<Scalars['String']>;
  /** クイズ8回答 */
  quiz8_input?: Maybe<Scalars['String']>;
  /** クイズ9回答 */
  quiz9_input?: Maybe<Scalars['String']>;
  /** クイズ10回答 */
  quiz10_input?: Maybe<Scalars['String']>;
  /** レコード更新日時 */
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "zerocame.greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_Mutation_Response = {
  __typename?: 'zerocame_greenscore_mission_achievement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Mission_Achievement>;
};

/** on_conflict condition type for table "zerocame.greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_On_Conflict = {
  constraint: Zerocame_Greenscore_Mission_Achievement_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Mission_Achievement_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_mission_achievement". */
export type Zerocame_Greenscore_Mission_Achievement_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  challenge_code_input?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_type?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
  quiz1_input?: InputMaybe<Order_By>;
  quiz2_input?: InputMaybe<Order_By>;
  quiz3_input?: InputMaybe<Order_By>;
  quiz4_input?: InputMaybe<Order_By>;
  quiz5_input?: InputMaybe<Order_By>;
  quiz6_input?: InputMaybe<Order_By>;
  quiz7_input?: InputMaybe<Order_By>;
  quiz8_input?: InputMaybe<Order_By>;
  quiz9_input?: InputMaybe<Order_By>;
  quiz10_input?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_mission_achievement */
export type Zerocame_Greenscore_Mission_Achievement_Pk_Columns_Input = {
  /** auth0ID */
  auth0_user_id: Scalars['String'];
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id: Scalars['String'];
  /** ミッションID（CMSのコンテンツID） */
  mission_id: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_mission_achievement" */
export enum Zerocame_Greenscore_Mission_Achievement_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ChallengeCodeInput = 'challenge_code_input',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataType = 'data_type',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  Quiz1Input = 'quiz1_input',
  /** column name */
  Quiz2Input = 'quiz2_input',
  /** column name */
  Quiz3Input = 'quiz3_input',
  /** column name */
  Quiz4Input = 'quiz4_input',
  /** column name */
  Quiz5Input = 'quiz5_input',
  /** column name */
  Quiz6Input = 'quiz6_input',
  /** column name */
  Quiz7Input = 'quiz7_input',
  /** column name */
  Quiz8Input = 'quiz8_input',
  /** column name */
  Quiz9Input = 'quiz9_input',
  /** column name */
  Quiz10Input = 'quiz10_input',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "zerocame.greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_Set_Input = {
  /** auth0ID */
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 入力チャレンジ完了コード */
  challenge_code_input?: InputMaybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: InputMaybe<Scalars['String']>;
  /** 企業・自治体名 */
  company_name?: InputMaybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** データ種別（記事/クイズ/キャンペーン） */
  data_type?: InputMaybe<Scalars['String']>;
  /** ミッションID（CMSのコンテンツID） */
  mission_id?: InputMaybe<Scalars['String']>;
  /** クイズ1回答 */
  quiz1_input?: InputMaybe<Scalars['String']>;
  /** クイズ2回答 */
  quiz2_input?: InputMaybe<Scalars['String']>;
  /** クイズ3回答 */
  quiz3_input?: InputMaybe<Scalars['String']>;
  /** クイズ4回答 */
  quiz4_input?: InputMaybe<Scalars['String']>;
  /** クイズ5回答 */
  quiz5_input?: InputMaybe<Scalars['String']>;
  /** クイズ6回答 */
  quiz6_input?: InputMaybe<Scalars['String']>;
  /** クイズ7回答 */
  quiz7_input?: InputMaybe<Scalars['String']>;
  /** クイズ8回答 */
  quiz8_input?: InputMaybe<Scalars['String']>;
  /** クイズ9回答 */
  quiz9_input?: InputMaybe<Scalars['String']>;
  /** クイズ10回答 */
  quiz10_input?: InputMaybe<Scalars['String']>;
  /** レコード更新日時 */
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "zerocame_greenscore_mission_achievement" */
export type Zerocame_Greenscore_Mission_Achievement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Mission_Achievement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Mission_Achievement_Stream_Cursor_Value_Input = {
  /** auth0ID */
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 入力チャレンジ完了コード */
  challenge_code_input?: InputMaybe<Scalars['String']>;
  /** チャレンジID（CMSのコンテンツID） */
  challenge_id?: InputMaybe<Scalars['String']>;
  /** 企業・自治体名 */
  company_name?: InputMaybe<Scalars['String']>;
  /** レコード作成日時 */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** データ種別（記事/クイズ/キャンペーン） */
  data_type?: InputMaybe<Scalars['String']>;
  /** ミッションID（CMSのコンテンツID） */
  mission_id?: InputMaybe<Scalars['String']>;
  /** クイズ1回答 */
  quiz1_input?: InputMaybe<Scalars['String']>;
  /** クイズ2回答 */
  quiz2_input?: InputMaybe<Scalars['String']>;
  /** クイズ3回答 */
  quiz3_input?: InputMaybe<Scalars['String']>;
  /** クイズ4回答 */
  quiz4_input?: InputMaybe<Scalars['String']>;
  /** クイズ5回答 */
  quiz5_input?: InputMaybe<Scalars['String']>;
  /** クイズ6回答 */
  quiz6_input?: InputMaybe<Scalars['String']>;
  /** クイズ7回答 */
  quiz7_input?: InputMaybe<Scalars['String']>;
  /** クイズ8回答 */
  quiz8_input?: InputMaybe<Scalars['String']>;
  /** クイズ9回答 */
  quiz9_input?: InputMaybe<Scalars['String']>;
  /** クイズ10回答 */
  quiz10_input?: InputMaybe<Scalars['String']>;
  /** レコード更新日時 */
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "zerocame.greenscore_mission_achievement" */
export enum Zerocame_Greenscore_Mission_Achievement_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ChallengeCodeInput = 'challenge_code_input',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataType = 'data_type',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  Quiz1Input = 'quiz1_input',
  /** column name */
  Quiz2Input = 'quiz2_input',
  /** column name */
  Quiz3Input = 'quiz3_input',
  /** column name */
  Quiz4Input = 'quiz4_input',
  /** column name */
  Quiz5Input = 'quiz5_input',
  /** column name */
  Quiz6Input = 'quiz6_input',
  /** column name */
  Quiz7Input = 'quiz7_input',
  /** column name */
  Quiz8Input = 'quiz8_input',
  /** column name */
  Quiz9Input = 'quiz9_input',
  /** column name */
  Quiz10Input = 'quiz10_input',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Zerocame_Greenscore_Mission_Achievement_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Mission_Achievement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Mission_Achievement_Bool_Exp;
};

/** response of any mutation on the table "zerocame.greenscore" */
export type Zerocame_Greenscore_Mutation_Response = {
  __typename?: 'zerocame_greenscore_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore>;
};

/** input type for inserting object relation for remote table "zerocame.greenscore" */
export type Zerocame_Greenscore_Obj_Rel_Insert_Input = {
  data: Zerocame_Greenscore_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Greenscore_On_Conflict>;
};

/** on_conflict condition type for table "zerocame.greenscore" */
export type Zerocame_Greenscore_On_Conflict = {
  constraint: Zerocame_Greenscore_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore". */
export type Zerocame_Greenscore_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  calc_id_action?: InputMaybe<Order_By>;
  calc_id_energy?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_saved?: InputMaybe<Order_By>;
  score_sum?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<Zerocame_Users_Order_By>;
};

/** primary key columns input for table: zerocame.greenscore */
export type Zerocame_Greenscore_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** 紹介コード */
export type Zerocame_Greenscore_Referral_Code = {
  __typename?: 'zerocame_greenscore_referral_code';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 企業名 */
  company_name?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  expiration_limit?: Maybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "zerocame.greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_Aggregate = {
  __typename?: 'zerocame_greenscore_referral_code_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Referral_Code_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Referral_Code>;
};

/** aggregate fields of "zerocame.greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_referral_code_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Referral_Code_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Referral_Code_Min_Fields>;
};


/** aggregate fields of "zerocame.greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_referral_code". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Referral_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Referral_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Referral_Code_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiration_limit?: InputMaybe<Timestamptz_Comparison_Exp>;
  referral_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_referral_code" */
export enum Zerocame_Greenscore_Referral_Code_Constraint {
  /** unique or primary key constraint on columns "referral_code" */
  GreenscoreReferralCodePkey = 'greenscore_referral_code_pkey'
}

/** input type for inserting data into table "zerocame.greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 企業名 */
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_limit?: InputMaybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Referral_Code_Max_Fields = {
  __typename?: 'zerocame_greenscore_referral_code_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 企業名 */
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_limit?: Maybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Referral_Code_Min_Fields = {
  __typename?: 'zerocame_greenscore_referral_code_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 企業名 */
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_limit?: Maybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "zerocame.greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_Mutation_Response = {
  __typename?: 'zerocame_greenscore_referral_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Referral_Code>;
};

/** on_conflict condition type for table "zerocame.greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_On_Conflict = {
  constraint: Zerocame_Greenscore_Referral_Code_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Referral_Code_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_referral_code". */
export type Zerocame_Greenscore_Referral_Code_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expiration_limit?: InputMaybe<Order_By>;
  referral_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_referral_code */
export type Zerocame_Greenscore_Referral_Code_Pk_Columns_Input = {
  /** 紹介コード */
  referral_code: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_referral_code" */
export enum Zerocame_Greenscore_Referral_Code_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationLimit = 'expiration_limit',
  /** column name */
  ReferralCode = 'referral_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "zerocame.greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 企業名 */
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_limit?: InputMaybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "zerocame_greenscore_referral_code" */
export type Zerocame_Greenscore_Referral_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Referral_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Referral_Code_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 企業名 */
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_limit?: InputMaybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "zerocame.greenscore_referral_code" */
export enum Zerocame_Greenscore_Referral_Code_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationLimit = 'expiration_limit',
  /** column name */
  ReferralCode = 'referral_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Zerocame_Greenscore_Referral_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Referral_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Referral_Code_Bool_Exp;
};

/** 紹介コード利用 */
export type Zerocame_Greenscore_Referral_Result = {
  __typename?: 'zerocame_greenscore_referral_result';
  created_at: Scalars['timestamptz'];
  /** 紹介コード */
  referral_code: Scalars['String'];
  /** 紹介コードを利用したユーザー */
  referred_auth0_user_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "zerocame.greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_Aggregate = {
  __typename?: 'zerocame_greenscore_referral_result_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Referral_Result_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Referral_Result>;
};

/** aggregate fields of "zerocame.greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_referral_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Referral_Result_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Referral_Result_Min_Fields>;
};


/** aggregate fields of "zerocame.greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_referral_result". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Referral_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Referral_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Referral_Result_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  referral_code?: InputMaybe<String_Comparison_Exp>;
  referred_auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_referral_result" */
export enum Zerocame_Greenscore_Referral_Result_Constraint {
  /** unique or primary key constraint on columns "referred_auth0_user_id" */
  GreenscoreReferralResultPkey = 'greenscore_referral_result_pkey'
}

/** input type for inserting data into table "zerocame.greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: InputMaybe<Scalars['String']>;
  /** 紹介コードを利用したユーザー */
  referred_auth0_user_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Referral_Result_Max_Fields = {
  __typename?: 'zerocame_greenscore_referral_result_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: Maybe<Scalars['String']>;
  /** 紹介コードを利用したユーザー */
  referred_auth0_user_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Referral_Result_Min_Fields = {
  __typename?: 'zerocame_greenscore_referral_result_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: Maybe<Scalars['String']>;
  /** 紹介コードを利用したユーザー */
  referred_auth0_user_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "zerocame.greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_Mutation_Response = {
  __typename?: 'zerocame_greenscore_referral_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Referral_Result>;
};

/** on_conflict condition type for table "zerocame.greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_On_Conflict = {
  constraint: Zerocame_Greenscore_Referral_Result_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Referral_Result_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Referral_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_referral_result". */
export type Zerocame_Greenscore_Referral_Result_Order_By = {
  created_at?: InputMaybe<Order_By>;
  referral_code?: InputMaybe<Order_By>;
  referred_auth0_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_referral_result */
export type Zerocame_Greenscore_Referral_Result_Pk_Columns_Input = {
  /** 紹介コードを利用したユーザー */
  referred_auth0_user_id: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_referral_result" */
export enum Zerocame_Greenscore_Referral_Result_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ReferralCode = 'referral_code',
  /** column name */
  ReferredAuth0UserId = 'referred_auth0_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "zerocame.greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: InputMaybe<Scalars['String']>;
  /** 紹介コードを利用したユーザー */
  referred_auth0_user_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "zerocame_greenscore_referral_result" */
export type Zerocame_Greenscore_Referral_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Referral_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Referral_Result_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 紹介コード */
  referral_code?: InputMaybe<Scalars['String']>;
  /** 紹介コードを利用したユーザー */
  referred_auth0_user_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "zerocame.greenscore_referral_result" */
export enum Zerocame_Greenscore_Referral_Result_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ReferralCode = 'referral_code',
  /** column name */
  ReferredAuth0UserId = 'referred_auth0_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Zerocame_Greenscore_Referral_Result_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Referral_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Referral_Result_Bool_Exp;
};

/** select columns of table "zerocame.greenscore" */
export enum Zerocame_Greenscore_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CalcIdAction = 'calc_id_action',
  /** column name */
  CalcIdEnergy = 'calc_id_energy',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsSaved = 'is_saved',
  /** column name */
  ScoreSum = 'score_sum',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore" */
export type Zerocame_Greenscore_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  calc_id_action?: InputMaybe<Scalars['Int']>;
  calc_id_energy?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_saved?: InputMaybe<Scalars['Boolean']>;
  score_sum?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Greenscore_Stddev_Fields = {
  __typename?: 'zerocame_greenscore_stddev_fields';
  calc_id_action?: Maybe<Scalars['Float']>;
  calc_id_energy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Greenscore_Stddev_Pop_Fields = {
  __typename?: 'zerocame_greenscore_stddev_pop_fields';
  calc_id_action?: Maybe<Scalars['Float']>;
  calc_id_energy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Greenscore_Stddev_Samp_Fields = {
  __typename?: 'zerocame_greenscore_stddev_samp_fields';
  calc_id_action?: Maybe<Scalars['Float']>;
  calc_id_energy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_greenscore" */
export type Zerocame_Greenscore_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  calc_id_action?: InputMaybe<Scalars['Int']>;
  calc_id_energy?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_saved?: InputMaybe<Scalars['Boolean']>;
  score_sum?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Greenscore_Sum_Fields = {
  __typename?: 'zerocame_greenscore_sum_fields';
  calc_id_action?: Maybe<Scalars['Int']>;
  calc_id_energy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  score_sum?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.greenscore" */
export enum Zerocame_Greenscore_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CalcIdAction = 'calc_id_action',
  /** column name */
  CalcIdEnergy = 'calc_id_energy',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsSaved = 'is_saved',
  /** column name */
  ScoreSum = 'score_sum',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Greenscore_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Bool_Exp;
};

/** ユーザーの行動 */
export type Zerocame_Greenscore_User_Actions = {
  __typename?: 'zerocame_greenscore_user_actions';
  auth0_user_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id: Scalars['String'];
  /** 1：取組み中、2：習慣化済み */
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Zerocame_Users;
};

/** aggregated selection of "zerocame.greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_Aggregate = {
  __typename?: 'zerocame_greenscore_user_actions_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_User_Actions_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_User_Actions>;
};

/** aggregate fields of "zerocame.greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_user_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_User_Actions_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_User_Actions_Min_Fields>;
};


/** aggregate fields of "zerocame.greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_user_actions". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_User_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_User_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_User_Actions_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  greenscore_action_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Zerocame_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_user_actions" */
export enum Zerocame_Greenscore_User_Actions_Constraint {
  /** unique or primary key constraint on columns "greenscore_action_id", "auth0_user_id" */
  GreenscoreUserActionsPkey = 'greenscore_user_actions_pkey'
}

/** input type for inserting data into table "zerocame.greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Zerocame_Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_User_Actions_Max_Fields = {
  __typename?: 'zerocame_greenscore_user_actions_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_User_Actions_Min_Fields = {
  __typename?: 'zerocame_greenscore_user_actions_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  greenscore_action_id?: Maybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_Mutation_Response = {
  __typename?: 'zerocame_greenscore_user_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_User_Actions>;
};

/** on_conflict condition type for table "zerocame.greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_On_Conflict = {
  constraint: Zerocame_Greenscore_User_Actions_Constraint;
  update_columns?: Array<Zerocame_Greenscore_User_Actions_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_User_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_user_actions". */
export type Zerocame_Greenscore_User_Actions_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  greenscore_action_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<Zerocame_Users_Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_user_actions */
export type Zerocame_Greenscore_User_Actions_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_user_actions" */
export enum Zerocame_Greenscore_User_Actions_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_greenscore_user_actions" */
export type Zerocame_Greenscore_User_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_User_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_User_Actions_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  /** 1：取組み中、2：習慣化済み */
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.greenscore_user_actions" */
export enum Zerocame_Greenscore_User_Actions_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GreenscoreActionId = 'greenscore_action_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_User_Actions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_User_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_User_Actions_Bool_Exp;
};

/** 行動変容アプリ向けユーザー情報 */
export type Zerocame_Greenscore_Users = {
  __typename?: 'zerocame_greenscore_users';
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric: Scalars['String'];
  auth0_user_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle: Scalars['String'];
  /** 住居形態　1：戸建て、2：集合 */
  housing_type: Scalars['String'];
  /** ニックネーム */
  nick_name: Scalars['String'];
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Aggregate = {
  __typename?: 'zerocame_greenscore_users_aggregate';
  aggregate?: Maybe<Zerocame_Greenscore_Users_Aggregate_Fields>;
  nodes: Array<Zerocame_Greenscore_Users>;
};

export type Zerocame_Greenscore_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Zerocame_Greenscore_Users_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Greenscore_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Aggregate_Fields = {
  __typename?: 'zerocame_greenscore_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Greenscore_Users_Max_Fields>;
  min?: Maybe<Zerocame_Greenscore_Users_Min_Fields>;
};


/** aggregate fields of "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Greenscore_Users_Max_Order_By>;
  min?: InputMaybe<Zerocame_Greenscore_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Greenscore_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Greenscore_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zerocame.greenscore_users". All fields are combined with a logical 'AND'. */
export type Zerocame_Greenscore_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Greenscore_Users_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Greenscore_Users_Bool_Exp>>;
  all_electric?: InputMaybe<String_Comparison_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  electric_vehicle?: InputMaybe<String_Comparison_Exp>;
  housing_type?: InputMaybe<String_Comparison_Exp>;
  nick_name?: InputMaybe<String_Comparison_Exp>;
  num_of_household?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.greenscore_users" */
export enum Zerocame_Greenscore_Users_Constraint {
  /** unique or primary key constraint on columns "auth0_user_id" */
  GreenscoreUsersPkey = 'greenscore_users_pkey'
}

/** input type for inserting data into table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Insert_Input = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle?: InputMaybe<Scalars['String']>;
  /** 住居形態　1：戸建て、2：集合 */
  housing_type?: InputMaybe<Scalars['String']>;
  /** ニックネーム */
  nick_name?: InputMaybe<Scalars['String']>;
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Greenscore_Users_Max_Fields = {
  __typename?: 'zerocame_greenscore_users_max_fields';
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle?: Maybe<Scalars['String']>;
  /** 住居形態　1：戸建て、2：集合 */
  housing_type?: Maybe<Scalars['String']>;
  /** ニックネーム */
  nick_name?: Maybe<Scalars['String']>;
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Max_Order_By = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle?: InputMaybe<Order_By>;
  /** 住居形態　1：戸建て、2：集合 */
  housing_type?: InputMaybe<Order_By>;
  /** ニックネーム */
  nick_name?: InputMaybe<Order_By>;
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Greenscore_Users_Min_Fields = {
  __typename?: 'zerocame_greenscore_users_min_fields';
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle?: Maybe<Scalars['String']>;
  /** 住居形態　1：戸建て、2：集合 */
  housing_type?: Maybe<Scalars['String']>;
  /** ニックネーム */
  nick_name?: Maybe<Scalars['String']>;
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Min_Order_By = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle?: InputMaybe<Order_By>;
  /** 住居形態　1：戸建て、2：集合 */
  housing_type?: InputMaybe<Order_By>;
  /** ニックネーム */
  nick_name?: InputMaybe<Order_By>;
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Mutation_Response = {
  __typename?: 'zerocame_greenscore_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Greenscore_Users>;
};

/** on_conflict condition type for table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_On_Conflict = {
  constraint: Zerocame_Greenscore_Users_Constraint;
  update_columns?: Array<Zerocame_Greenscore_Users_Update_Column>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.greenscore_users". */
export type Zerocame_Greenscore_Users_Order_By = {
  all_electric?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  electric_vehicle?: InputMaybe<Order_By>;
  housing_type?: InputMaybe<Order_By>;
  nick_name?: InputMaybe<Order_By>;
  num_of_household?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.greenscore_users */
export type Zerocame_Greenscore_Users_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
};

/** select columns of table "zerocame.greenscore_users" */
export enum Zerocame_Greenscore_Users_Select_Column {
  /** column name */
  AllElectric = 'all_electric',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ElectricVehicle = 'electric_vehicle',
  /** column name */
  HousingType = 'housing_type',
  /** column name */
  NickName = 'nick_name',
  /** column name */
  NumOfHousehold = 'num_of_household',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.greenscore_users" */
export type Zerocame_Greenscore_Users_Set_Input = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle?: InputMaybe<Scalars['String']>;
  /** 住居形態　1：戸建て、2：集合 */
  housing_type?: InputMaybe<Scalars['String']>;
  /** ニックネーム */
  nick_name?: InputMaybe<Scalars['String']>;
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_greenscore_users" */
export type Zerocame_Greenscore_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Greenscore_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Greenscore_Users_Stream_Cursor_Value_Input = {
  /** オール電化　1：はい、2：いいえ、3：わからない */
  all_electric?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  /** 電気自動車　1：はい、2：いいえ、3：わからない */
  electric_vehicle?: InputMaybe<Scalars['String']>;
  /** 住居形態　1：戸建て、2：集合 */
  housing_type?: InputMaybe<Scalars['String']>;
  /** ニックネーム */
  nick_name?: InputMaybe<Scalars['String']>;
  /** 世帯人数　1：1人、2：2人、3：3人、4：4人以上 */
  num_of_household?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.greenscore_users" */
export enum Zerocame_Greenscore_Users_Update_Column {
  /** column name */
  AllElectric = 'all_electric',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ElectricVehicle = 'electric_vehicle',
  /** column name */
  HousingType = 'housing_type',
  /** column name */
  NickName = 'nick_name',
  /** column name */
  NumOfHousehold = 'num_of_household',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Greenscore_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Greenscore_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Greenscore_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Greenscore_Var_Pop_Fields = {
  __typename?: 'zerocame_greenscore_var_pop_fields';
  calc_id_action?: Maybe<Scalars['Float']>;
  calc_id_energy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Greenscore_Var_Samp_Fields = {
  __typename?: 'zerocame_greenscore_var_samp_fields';
  calc_id_action?: Maybe<Scalars['Float']>;
  calc_id_energy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Greenscore_Variance_Fields = {
  __typename?: 'zerocame_greenscore_variance_fields';
  calc_id_action?: Maybe<Scalars['Float']>;
  calc_id_energy?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score_sum?: Maybe<Scalars['Float']>;
};

/** 気象庁の観測地点を定義するテーブル */
export type Zerocame_Jma_Area_Master = {
  __typename?: 'zerocame_jma_area_master';
  block_code: Scalars['String'];
  jma_area_code?: Maybe<Scalars['String']>;
  jma_area_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  jma_meteorological_dailies: Array<Zerocame_Jma_Meteorological_Daily>;
  /** An aggregate relationship */
  jma_meteorological_dailies_aggregate: Zerocame_Jma_Meteorological_Daily_Aggregate;
  observatory_name: Scalars['String'];
  pref_code?: Maybe<Scalars['String']>;
  pref_name?: Maybe<Scalars['String']>;
};


/** 気象庁の観測地点を定義するテーブル */
export type Zerocame_Jma_Area_MasterJma_Meteorological_DailiesArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};


/** 気象庁の観測地点を定義するテーブル */
export type Zerocame_Jma_Area_MasterJma_Meteorological_Dailies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Order_By>>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};

/** aggregated selection of "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_Aggregate = {
  __typename?: 'zerocame_jma_area_master_aggregate';
  aggregate?: Maybe<Zerocame_Jma_Area_Master_Aggregate_Fields>;
  nodes: Array<Zerocame_Jma_Area_Master>;
};

/** aggregate fields of "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_Aggregate_Fields = {
  __typename?: 'zerocame_jma_area_master_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Jma_Area_Master_Max_Fields>;
  min?: Maybe<Zerocame_Jma_Area_Master_Min_Fields>;
};


/** aggregate fields of "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Jma_Area_Master_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.jma_area_master". All fields are combined with a logical 'AND'. */
export type Zerocame_Jma_Area_Master_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Jma_Area_Master_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Jma_Area_Master_Bool_Exp>>;
  block_code?: InputMaybe<String_Comparison_Exp>;
  jma_area_code?: InputMaybe<String_Comparison_Exp>;
  jma_area_name?: InputMaybe<String_Comparison_Exp>;
  jma_meteorological_dailies?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
  jma_meteorological_dailies_aggregate?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Aggregate_Bool_Exp>;
  observatory_name?: InputMaybe<String_Comparison_Exp>;
  pref_code?: InputMaybe<String_Comparison_Exp>;
  pref_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.jma_area_master" */
export enum Zerocame_Jma_Area_Master_Constraint {
  /** unique or primary key constraint on columns "block_code" */
  JmaAreaMasterPkey = 'jma_area_master_pkey'
}

/** input type for inserting data into table "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_Insert_Input = {
  block_code?: InputMaybe<Scalars['String']>;
  jma_area_code?: InputMaybe<Scalars['String']>;
  jma_area_name?: InputMaybe<Scalars['String']>;
  jma_meteorological_dailies?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Arr_Rel_Insert_Input>;
  observatory_name?: InputMaybe<Scalars['String']>;
  pref_code?: InputMaybe<Scalars['String']>;
  pref_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Jma_Area_Master_Max_Fields = {
  __typename?: 'zerocame_jma_area_master_max_fields';
  block_code?: Maybe<Scalars['String']>;
  jma_area_code?: Maybe<Scalars['String']>;
  jma_area_name?: Maybe<Scalars['String']>;
  observatory_name?: Maybe<Scalars['String']>;
  pref_code?: Maybe<Scalars['String']>;
  pref_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Jma_Area_Master_Min_Fields = {
  __typename?: 'zerocame_jma_area_master_min_fields';
  block_code?: Maybe<Scalars['String']>;
  jma_area_code?: Maybe<Scalars['String']>;
  jma_area_name?: Maybe<Scalars['String']>;
  observatory_name?: Maybe<Scalars['String']>;
  pref_code?: Maybe<Scalars['String']>;
  pref_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_Mutation_Response = {
  __typename?: 'zerocame_jma_area_master_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Jma_Area_Master>;
};

/** input type for inserting object relation for remote table "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_Obj_Rel_Insert_Input = {
  data: Zerocame_Jma_Area_Master_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Jma_Area_Master_On_Conflict>;
};

/** on_conflict condition type for table "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_On_Conflict = {
  constraint: Zerocame_Jma_Area_Master_Constraint;
  update_columns?: Array<Zerocame_Jma_Area_Master_Update_Column>;
  where?: InputMaybe<Zerocame_Jma_Area_Master_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.jma_area_master". */
export type Zerocame_Jma_Area_Master_Order_By = {
  block_code?: InputMaybe<Order_By>;
  jma_area_code?: InputMaybe<Order_By>;
  jma_area_name?: InputMaybe<Order_By>;
  jma_meteorological_dailies_aggregate?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Aggregate_Order_By>;
  observatory_name?: InputMaybe<Order_By>;
  pref_code?: InputMaybe<Order_By>;
  pref_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.jma_area_master */
export type Zerocame_Jma_Area_Master_Pk_Columns_Input = {
  block_code: Scalars['String'];
};

/** select columns of table "zerocame.jma_area_master" */
export enum Zerocame_Jma_Area_Master_Select_Column {
  /** column name */
  BlockCode = 'block_code',
  /** column name */
  JmaAreaCode = 'jma_area_code',
  /** column name */
  JmaAreaName = 'jma_area_name',
  /** column name */
  ObservatoryName = 'observatory_name',
  /** column name */
  PrefCode = 'pref_code',
  /** column name */
  PrefName = 'pref_name'
}

/** input type for updating data in table "zerocame.jma_area_master" */
export type Zerocame_Jma_Area_Master_Set_Input = {
  block_code?: InputMaybe<Scalars['String']>;
  jma_area_code?: InputMaybe<Scalars['String']>;
  jma_area_name?: InputMaybe<Scalars['String']>;
  observatory_name?: InputMaybe<Scalars['String']>;
  pref_code?: InputMaybe<Scalars['String']>;
  pref_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_jma_area_master" */
export type Zerocame_Jma_Area_Master_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Jma_Area_Master_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Jma_Area_Master_Stream_Cursor_Value_Input = {
  block_code?: InputMaybe<Scalars['String']>;
  jma_area_code?: InputMaybe<Scalars['String']>;
  jma_area_name?: InputMaybe<Scalars['String']>;
  observatory_name?: InputMaybe<Scalars['String']>;
  pref_code?: InputMaybe<Scalars['String']>;
  pref_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.jma_area_master" */
export enum Zerocame_Jma_Area_Master_Update_Column {
  /** column name */
  BlockCode = 'block_code',
  /** column name */
  JmaAreaCode = 'jma_area_code',
  /** column name */
  JmaAreaName = 'jma_area_name',
  /** column name */
  ObservatoryName = 'observatory_name',
  /** column name */
  PrefCode = 'pref_code',
  /** column name */
  PrefName = 'pref_name'
}

export type Zerocame_Jma_Area_Master_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Jma_Area_Master_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Jma_Area_Master_Bool_Exp;
};

/** 気象庁観測データ_日次 */
export type Zerocame_Jma_Meteorological_Daily = {
  __typename?: 'zerocame_jma_meteorological_daily';
  air_temperature_max?: Maybe<Scalars['numeric']>;
  air_temperature_min?: Maybe<Scalars['numeric']>;
  block_code: Scalars['String'];
  date: Scalars['String'];
};

/** aggregated selection of "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Aggregate = {
  __typename?: 'zerocame_jma_meteorological_daily_aggregate';
  aggregate?: Maybe<Zerocame_Jma_Meteorological_Daily_Aggregate_Fields>;
  nodes: Array<Zerocame_Jma_Meteorological_Daily>;
};

export type Zerocame_Jma_Meteorological_Daily_Aggregate_Bool_Exp = {
  count?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Aggregate_Bool_Exp_Count>;
};

export type Zerocame_Jma_Meteorological_Daily_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Aggregate_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_aggregate_fields';
  avg?: Maybe<Zerocame_Jma_Meteorological_Daily_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Jma_Meteorological_Daily_Max_Fields>;
  min?: Maybe<Zerocame_Jma_Meteorological_Daily_Min_Fields>;
  stddev?: Maybe<Zerocame_Jma_Meteorological_Daily_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Jma_Meteorological_Daily_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Jma_Meteorological_Daily_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Jma_Meteorological_Daily_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Jma_Meteorological_Daily_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Jma_Meteorological_Daily_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Jma_Meteorological_Daily_Variance_Fields>;
};


/** aggregate fields of "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Aggregate_Order_By = {
  avg?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Max_Order_By>;
  min?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Min_Order_By>;
  stddev?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Sum_Order_By>;
  var_pop?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Var_Samp_Order_By>;
  variance?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Arr_Rel_Insert_Input = {
  data: Array<Zerocame_Jma_Meteorological_Daily_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Jma_Meteorological_Daily_On_Conflict>;
};

/** aggregate avg on columns */
export type Zerocame_Jma_Meteorological_Daily_Avg_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_avg_fields';
  air_temperature_max?: Maybe<Scalars['Float']>;
  air_temperature_min?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Avg_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zerocame.jma_meteorological_daily". All fields are combined with a logical 'AND'. */
export type Zerocame_Jma_Meteorological_Daily_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Jma_Meteorological_Daily_Bool_Exp>>;
  air_temperature_max?: InputMaybe<Numeric_Comparison_Exp>;
  air_temperature_min?: InputMaybe<Numeric_Comparison_Exp>;
  block_code?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.jma_meteorological_daily" */
export enum Zerocame_Jma_Meteorological_Daily_Constraint {
  /** unique or primary key constraint on columns "block_code", "date" */
  JmaMeteorologicalDailyPkey = 'jma_meteorological_daily_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Inc_Input = {
  air_temperature_max?: InputMaybe<Scalars['numeric']>;
  air_temperature_min?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Insert_Input = {
  air_temperature_max?: InputMaybe<Scalars['numeric']>;
  air_temperature_min?: InputMaybe<Scalars['numeric']>;
  block_code?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Jma_Meteorological_Daily_Max_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_max_fields';
  air_temperature_max?: Maybe<Scalars['numeric']>;
  air_temperature_min?: Maybe<Scalars['numeric']>;
  block_code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Max_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
  block_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Zerocame_Jma_Meteorological_Daily_Min_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_min_fields';
  air_temperature_max?: Maybe<Scalars['numeric']>;
  air_temperature_min?: Maybe<Scalars['numeric']>;
  block_code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Min_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
  block_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Mutation_Response = {
  __typename?: 'zerocame_jma_meteorological_daily_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Jma_Meteorological_Daily>;
};

/** on_conflict condition type for table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_On_Conflict = {
  constraint: Zerocame_Jma_Meteorological_Daily_Constraint;
  update_columns?: Array<Zerocame_Jma_Meteorological_Daily_Update_Column>;
  where?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.jma_meteorological_daily". */
export type Zerocame_Jma_Meteorological_Daily_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
  block_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.jma_meteorological_daily */
export type Zerocame_Jma_Meteorological_Daily_Pk_Columns_Input = {
  block_code: Scalars['String'];
  date: Scalars['String'];
};

/** select columns of table "zerocame.jma_meteorological_daily" */
export enum Zerocame_Jma_Meteorological_Daily_Select_Column {
  /** column name */
  AirTemperatureMax = 'air_temperature_max',
  /** column name */
  AirTemperatureMin = 'air_temperature_min',
  /** column name */
  BlockCode = 'block_code',
  /** column name */
  Date = 'date'
}

/** input type for updating data in table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Set_Input = {
  air_temperature_max?: InputMaybe<Scalars['numeric']>;
  air_temperature_min?: InputMaybe<Scalars['numeric']>;
  block_code?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Jma_Meteorological_Daily_Stddev_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_stddev_fields';
  air_temperature_max?: Maybe<Scalars['Float']>;
  air_temperature_min?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Stddev_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Jma_Meteorological_Daily_Stddev_Pop_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_stddev_pop_fields';
  air_temperature_max?: Maybe<Scalars['Float']>;
  air_temperature_min?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Stddev_Pop_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Jma_Meteorological_Daily_Stddev_Samp_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_stddev_samp_fields';
  air_temperature_max?: Maybe<Scalars['Float']>;
  air_temperature_min?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Stddev_Samp_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "zerocame_jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Jma_Meteorological_Daily_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Jma_Meteorological_Daily_Stream_Cursor_Value_Input = {
  air_temperature_max?: InputMaybe<Scalars['numeric']>;
  air_temperature_min?: InputMaybe<Scalars['numeric']>;
  block_code?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Jma_Meteorological_Daily_Sum_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_sum_fields';
  air_temperature_max?: Maybe<Scalars['numeric']>;
  air_temperature_min?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Sum_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** update columns of table "zerocame.jma_meteorological_daily" */
export enum Zerocame_Jma_Meteorological_Daily_Update_Column {
  /** column name */
  AirTemperatureMax = 'air_temperature_max',
  /** column name */
  AirTemperatureMin = 'air_temperature_min',
  /** column name */
  BlockCode = 'block_code',
  /** column name */
  Date = 'date'
}

export type Zerocame_Jma_Meteorological_Daily_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Jma_Meteorological_Daily_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Jma_Meteorological_Daily_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Jma_Meteorological_Daily_Var_Pop_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_var_pop_fields';
  air_temperature_max?: Maybe<Scalars['Float']>;
  air_temperature_min?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Var_Pop_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Zerocame_Jma_Meteorological_Daily_Var_Samp_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_var_samp_fields';
  air_temperature_max?: Maybe<Scalars['Float']>;
  air_temperature_min?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Var_Samp_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Zerocame_Jma_Meteorological_Daily_Variance_Fields = {
  __typename?: 'zerocame_jma_meteorological_daily_variance_fields';
  air_temperature_max?: Maybe<Scalars['Float']>;
  air_temperature_min?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zerocame.jma_meteorological_daily" */
export type Zerocame_Jma_Meteorological_Daily_Variance_Order_By = {
  air_temperature_max?: InputMaybe<Order_By>;
  air_temperature_min?: InputMaybe<Order_By>;
};

/** CO2排出係数マスタ */
export type Zerocame_Mst_Co2_Emission_Coefficient = {
  __typename?: 'zerocame_mst_co2_emission_coefficient';
  /** 基礎排出係数 */
  emission_coefficient: Scalars['numeric'];
  /** 登録番号 */
  regist_no: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** aggregated selection of "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Aggregate = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Co2_Emission_Coefficient>;
};

/** aggregate fields of "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Aggregate_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_aggregate_fields';
  avg?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Min_Fields>;
  stddev?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Mst_Co2_Emission_Coefficient_Variance_Fields>;
};


/** aggregate fields of "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Avg_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_avg_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_co2_emission_coefficient". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>>;
  emission_coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  regist_no?: InputMaybe<String_Comparison_Exp>;
  year_month?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_co2_emission_coefficient" */
export enum Zerocame_Mst_Co2_Emission_Coefficient_Constraint {
  /** unique or primary key constraint on columns "year_month", "regist_no" */
  MstCo2EmissionCoefficientPkey = 'mst_co2_emission_coefficient_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Inc_Input = {
  /** 基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Insert_Input = {
  /** 基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Max_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_max_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['numeric']>;
  /** 登録番号 */
  regist_no?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Min_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_min_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['numeric']>;
  /** 登録番号 */
  regist_no?: Maybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Mutation_Response = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Co2_Emission_Coefficient>;
};

/** on_conflict condition type for table "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_On_Conflict = {
  constraint: Zerocame_Mst_Co2_Emission_Coefficient_Constraint;
  update_columns?: Array<Zerocame_Mst_Co2_Emission_Coefficient_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_co2_emission_coefficient". */
export type Zerocame_Mst_Co2_Emission_Coefficient_Order_By = {
  emission_coefficient?: InputMaybe<Order_By>;
  regist_no?: InputMaybe<Order_By>;
  year_month?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_co2_emission_coefficient */
export type Zerocame_Mst_Co2_Emission_Coefficient_Pk_Columns_Input = {
  /** 登録番号 */
  regist_no: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** select columns of table "zerocame.mst_co2_emission_coefficient" */
export enum Zerocame_Mst_Co2_Emission_Coefficient_Select_Column {
  /** column name */
  EmissionCoefficient = 'emission_coefficient',
  /** column name */
  RegistNo = 'regist_no',
  /** column name */
  YearMonth = 'year_month'
}

/** input type for updating data in table "zerocame.mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Set_Input = {
  /** 基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Stddev_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_stddev_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Stddev_Pop_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_stddev_pop_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Stddev_Samp_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_stddev_samp_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_mst_co2_emission_coefficient" */
export type Zerocame_Mst_Co2_Emission_Coefficient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Co2_Emission_Coefficient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Co2_Emission_Coefficient_Stream_Cursor_Value_Input = {
  /** 基礎排出係数 */
  emission_coefficient?: InputMaybe<Scalars['numeric']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Sum_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_sum_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.mst_co2_emission_coefficient" */
export enum Zerocame_Mst_Co2_Emission_Coefficient_Update_Column {
  /** column name */
  EmissionCoefficient = 'emission_coefficient',
  /** column name */
  RegistNo = 'regist_no',
  /** column name */
  YearMonth = 'year_month'
}

export type Zerocame_Mst_Co2_Emission_Coefficient_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Co2_Emission_Coefficient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Co2_Emission_Coefficient_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Var_Pop_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_var_pop_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Var_Samp_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_var_samp_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Mst_Co2_Emission_Coefficient_Variance_Fields = {
  __typename?: 'zerocame_mst_co2_emission_coefficient_variance_fields';
  /** 基礎排出係数 */
  emission_coefficient?: Maybe<Scalars['Float']>;
};

/** 月別ベンチマークマスタ */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark';
  /** 属性No */
  attribute_no: Scalars['String'];
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark: Scalars['numeric'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** aggregated selection of "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Aggregate = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
};

/** aggregate fields of "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Aggregate_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_aggregate_fields';
  avg?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Min_Fields>;
  stddev?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Variance_Fields>;
};


/** aggregate fields of "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Avg_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_avg_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_co2_emission_monthly_benchmark". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>>;
  attribute_no?: InputMaybe<String_Comparison_Exp>;
  benchmark?: InputMaybe<Numeric_Comparison_Exp>;
  year_month?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_co2_emission_monthly_benchmark" */
export enum Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Constraint {
  /** unique or primary key constraint on columns "attribute_no", "year_month" */
  MstCo2EmissionMonthlyBenchmarkPkey = 'mst_co2_emission_monthly_benchmark_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Inc_Input = {
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Insert_Input = {
  /** 属性No */
  attribute_no?: InputMaybe<Scalars['String']>;
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Max_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_max_fields';
  /** 属性No */
  attribute_no?: Maybe<Scalars['String']>;
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['numeric']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Min_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_min_fields';
  /** 属性No */
  attribute_no?: Maybe<Scalars['String']>;
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['numeric']>;
  /** 年月 yyyyMM */
  year_month?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Mutation_Response = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark>;
};

/** on_conflict condition type for table "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_On_Conflict = {
  constraint: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Constraint;
  update_columns?: Array<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_co2_emission_monthly_benchmark". */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Order_By = {
  attribute_no?: InputMaybe<Order_By>;
  benchmark?: InputMaybe<Order_By>;
  year_month?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_co2_emission_monthly_benchmark */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Pk_Columns_Input = {
  /** 属性No */
  attribute_no: Scalars['String'];
  /** 年月 yyyyMM */
  year_month: Scalars['String'];
};

/** select columns of table "zerocame.mst_co2_emission_monthly_benchmark" */
export enum Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Select_Column {
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  YearMonth = 'year_month'
}

/** input type for updating data in table "zerocame.mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Set_Input = {
  /** 属性No */
  attribute_no?: InputMaybe<Scalars['String']>;
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stddev_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_stddev_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stddev_Pop_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_stddev_pop_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stddev_Samp_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_stddev_samp_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_mst_co2_emission_monthly_benchmark" */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Stream_Cursor_Value_Input = {
  /** 属性No */
  attribute_no?: InputMaybe<Scalars['String']>;
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: InputMaybe<Scalars['numeric']>;
  /** 年月 yyyyMM */
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Sum_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_sum_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.mst_co2_emission_monthly_benchmark" */
export enum Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Update_Column {
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  YearMonth = 'year_month'
}

export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Var_Pop_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_var_pop_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Var_Samp_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_var_samp_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Mst_Co2_Emission_Monthly_Benchmark_Variance_Fields = {
  __typename?: 'zerocame_mst_co2_emission_monthly_benchmark_variance_fields';
  /** ベンチマーク（1か月分の電力使用量） */
  benchmark?: Maybe<Scalars['Float']>;
};

/** 電気事業者マスタ */
export type Zerocame_Mst_Electric_Company = {
  __typename?: 'zerocame_mst_electric_company';
  /** 電気事業者名 */
  electric_company_name: Scalars['String'];
  /** 登録番号 */
  regist_no: Scalars['String'];
};

/** aggregated selection of "zerocame.mst_electric_company" */
export type Zerocame_Mst_Electric_Company_Aggregate = {
  __typename?: 'zerocame_mst_electric_company_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Electric_Company_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Electric_Company>;
};

/** aggregate fields of "zerocame.mst_electric_company" */
export type Zerocame_Mst_Electric_Company_Aggregate_Fields = {
  __typename?: 'zerocame_mst_electric_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Electric_Company_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Electric_Company_Min_Fields>;
};


/** aggregate fields of "zerocame.mst_electric_company" */
export type Zerocame_Mst_Electric_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_electric_company". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Electric_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Electric_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Electric_Company_Bool_Exp>>;
  electric_company_name?: InputMaybe<String_Comparison_Exp>;
  regist_no?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_electric_company" */
export enum Zerocame_Mst_Electric_Company_Constraint {
  /** unique or primary key constraint on columns "regist_no" */
  MstElectricCompanyPkey = 'mst_electric_company_pkey'
}

/** input type for inserting data into table "zerocame.mst_electric_company" */
export type Zerocame_Mst_Electric_Company_Insert_Input = {
  /** 電気事業者名 */
  electric_company_name?: InputMaybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Electric_Company_Max_Fields = {
  __typename?: 'zerocame_mst_electric_company_max_fields';
  /** 電気事業者名 */
  electric_company_name?: Maybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Electric_Company_Min_Fields = {
  __typename?: 'zerocame_mst_electric_company_min_fields';
  /** 電気事業者名 */
  electric_company_name?: Maybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.mst_electric_company" */
export type Zerocame_Mst_Electric_Company_Mutation_Response = {
  __typename?: 'zerocame_mst_electric_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Electric_Company>;
};

/** on_conflict condition type for table "zerocame.mst_electric_company" */
export type Zerocame_Mst_Electric_Company_On_Conflict = {
  constraint: Zerocame_Mst_Electric_Company_Constraint;
  update_columns?: Array<Zerocame_Mst_Electric_Company_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Electric_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_electric_company". */
export type Zerocame_Mst_Electric_Company_Order_By = {
  electric_company_name?: InputMaybe<Order_By>;
  regist_no?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_electric_company */
export type Zerocame_Mst_Electric_Company_Pk_Columns_Input = {
  /** 登録番号 */
  regist_no: Scalars['String'];
};

/** select columns of table "zerocame.mst_electric_company" */
export enum Zerocame_Mst_Electric_Company_Select_Column {
  /** column name */
  ElectricCompanyName = 'electric_company_name',
  /** column name */
  RegistNo = 'regist_no'
}

/** input type for updating data in table "zerocame.mst_electric_company" */
export type Zerocame_Mst_Electric_Company_Set_Input = {
  /** 電気事業者名 */
  electric_company_name?: InputMaybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_mst_electric_company" */
export type Zerocame_Mst_Electric_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Electric_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Electric_Company_Stream_Cursor_Value_Input = {
  /** 電気事業者名 */
  electric_company_name?: InputMaybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.mst_electric_company" */
export enum Zerocame_Mst_Electric_Company_Update_Column {
  /** column name */
  ElectricCompanyName = 'electric_company_name',
  /** column name */
  RegistNo = 'regist_no'
}

export type Zerocame_Mst_Electric_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Electric_Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Electric_Company_Bool_Exp;
};

/** 【エネルギースコア】ベンチマーク係数マスタ */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient';
  /** 1：オール電化、2：電気自動車 */
  attribute_item: Scalars['String'];
  attribute_value: Scalars['String'];
  coefficient: Scalars['numeric'];
};

/** aggregated selection of "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Aggregate = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
};

/** aggregate fields of "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Aggregate_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_aggregate_fields';
  avg?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Min_Fields>;
  stddev?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Variance_Fields>;
};


/** aggregate fields of "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Avg_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_avg_fields';
  coefficient?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_energyscore_benchmark_coefficient". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>>;
  attribute_item?: InputMaybe<String_Comparison_Exp>;
  attribute_value?: InputMaybe<String_Comparison_Exp>;
  coefficient?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_energyscore_benchmark_coefficient" */
export enum Zerocame_Mst_Energyscore_Benchmark_Coefficient_Constraint {
  /** unique or primary key constraint on columns "attribute_item", "attribute_value" */
  MstEnergyscoreBenchmarkCoefficientPkey = 'mst_energyscore_benchmark_coefficient_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Inc_Input = {
  coefficient?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Insert_Input = {
  /** 1：オール電化、2：電気自動車 */
  attribute_item?: InputMaybe<Scalars['String']>;
  attribute_value?: InputMaybe<Scalars['String']>;
  coefficient?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Max_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_max_fields';
  /** 1：オール電化、2：電気自動車 */
  attribute_item?: Maybe<Scalars['String']>;
  attribute_value?: Maybe<Scalars['String']>;
  coefficient?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Min_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_min_fields';
  /** 1：オール電化、2：電気自動車 */
  attribute_item?: Maybe<Scalars['String']>;
  attribute_value?: Maybe<Scalars['String']>;
  coefficient?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Mutation_Response = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient>;
};

/** on_conflict condition type for table "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_On_Conflict = {
  constraint: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Constraint;
  update_columns?: Array<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_energyscore_benchmark_coefficient". */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Order_By = {
  attribute_item?: InputMaybe<Order_By>;
  attribute_value?: InputMaybe<Order_By>;
  coefficient?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_energyscore_benchmark_coefficient */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Pk_Columns_Input = {
  /** 1：オール電化、2：電気自動車 */
  attribute_item: Scalars['String'];
  attribute_value: Scalars['String'];
};

/** select columns of table "zerocame.mst_energyscore_benchmark_coefficient" */
export enum Zerocame_Mst_Energyscore_Benchmark_Coefficient_Select_Column {
  /** column name */
  AttributeItem = 'attribute_item',
  /** column name */
  AttributeValue = 'attribute_value',
  /** column name */
  Coefficient = 'coefficient'
}

/** input type for updating data in table "zerocame.mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Set_Input = {
  /** 1：オール電化、2：電気自動車 */
  attribute_item?: InputMaybe<Scalars['String']>;
  attribute_value?: InputMaybe<Scalars['String']>;
  coefficient?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stddev_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_stddev_fields';
  coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stddev_Pop_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_stddev_pop_fields';
  coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stddev_Samp_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_stddev_samp_fields';
  coefficient?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_mst_energyscore_benchmark_coefficient" */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Stream_Cursor_Value_Input = {
  /** 1：オール電化、2：電気自動車 */
  attribute_item?: InputMaybe<Scalars['String']>;
  attribute_value?: InputMaybe<Scalars['String']>;
  coefficient?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Sum_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_sum_fields';
  coefficient?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.mst_energyscore_benchmark_coefficient" */
export enum Zerocame_Mst_Energyscore_Benchmark_Coefficient_Update_Column {
  /** column name */
  AttributeItem = 'attribute_item',
  /** column name */
  AttributeValue = 'attribute_value',
  /** column name */
  Coefficient = 'coefficient'
}

export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Benchmark_Coefficient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Energyscore_Benchmark_Coefficient_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Var_Pop_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_var_pop_fields';
  coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Var_Samp_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_var_samp_fields';
  coefficient?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Mst_Energyscore_Benchmark_Coefficient_Variance_Fields = {
  __typename?: 'zerocame_mst_energyscore_benchmark_coefficient_variance_fields';
  coefficient?: Maybe<Scalars['Float']>;
};

/** 【エネルギースコア】日別ベンチマークマスタ */
export type Zerocame_Mst_Energyscore_Daily_Benchmark = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark';
  attribute_no: Scalars['String'];
  benchmark: Scalars['numeric'];
  date: Scalars['String'];
};

/** aggregated selection of "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Aggregate = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Energyscore_Daily_Benchmark>;
};

/** aggregate fields of "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Aggregate_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_aggregate_fields';
  avg?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Min_Fields>;
  stddev?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Variance_Fields>;
};


/** aggregate fields of "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Avg_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_avg_fields';
  benchmark?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_energyscore_daily_benchmark". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>>;
  attribute_no?: InputMaybe<String_Comparison_Exp>;
  benchmark?: InputMaybe<Numeric_Comparison_Exp>;
  date?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_energyscore_daily_benchmark" */
export enum Zerocame_Mst_Energyscore_Daily_Benchmark_Constraint {
  /** unique or primary key constraint on columns "attribute_no", "date" */
  MstEnergyscoreDailyBenchmarkPkey = 'mst_energyscore_daily_benchmark_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Inc_Input = {
  benchmark?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Insert_Input = {
  attribute_no?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  date?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Max_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_max_fields';
  attribute_no?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['numeric']>;
  date?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Min_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_min_fields';
  attribute_no?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['numeric']>;
  date?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Mutation_Response = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Energyscore_Daily_Benchmark>;
};

/** on_conflict condition type for table "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_On_Conflict = {
  constraint: Zerocame_Mst_Energyscore_Daily_Benchmark_Constraint;
  update_columns?: Array<Zerocame_Mst_Energyscore_Daily_Benchmark_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_energyscore_daily_benchmark". */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Order_By = {
  attribute_no?: InputMaybe<Order_By>;
  benchmark?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_energyscore_daily_benchmark */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Pk_Columns_Input = {
  attribute_no: Scalars['String'];
  date: Scalars['String'];
};

/** select columns of table "zerocame.mst_energyscore_daily_benchmark" */
export enum Zerocame_Mst_Energyscore_Daily_Benchmark_Select_Column {
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  Date = 'date'
}

/** input type for updating data in table "zerocame.mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Set_Input = {
  attribute_no?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  date?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Stddev_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_stddev_fields';
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Stddev_Pop_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_stddev_pop_fields';
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Stddev_Samp_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_stddev_samp_fields';
  benchmark?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_mst_energyscore_daily_benchmark" */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Energyscore_Daily_Benchmark_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Stream_Cursor_Value_Input = {
  attribute_no?: InputMaybe<Scalars['String']>;
  benchmark?: InputMaybe<Scalars['numeric']>;
  date?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Sum_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_sum_fields';
  benchmark?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.mst_energyscore_daily_benchmark" */
export enum Zerocame_Mst_Energyscore_Daily_Benchmark_Update_Column {
  /** column name */
  AttributeNo = 'attribute_no',
  /** column name */
  Benchmark = 'benchmark',
  /** column name */
  Date = 'date'
}

export type Zerocame_Mst_Energyscore_Daily_Benchmark_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Daily_Benchmark_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Energyscore_Daily_Benchmark_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Var_Pop_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_var_pop_fields';
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Var_Samp_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_var_samp_fields';
  benchmark?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Mst_Energyscore_Daily_Benchmark_Variance_Fields = {
  __typename?: 'zerocame_mst_energyscore_daily_benchmark_variance_fields';
  benchmark?: Maybe<Scalars['Float']>;
};

/** 【エネルギースコア】閾値マスタ */
export type Zerocame_Mst_Energyscore_Difference_Ratio = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio';
  coefficient: Scalars['numeric'];
  max: Scalars['Int'];
  min: Scalars['Int'];
};

/** aggregated selection of "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Aggregate = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Energyscore_Difference_Ratio>;
};

/** aggregate fields of "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Aggregate_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_aggregate_fields';
  avg?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Min_Fields>;
  stddev?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Mst_Energyscore_Difference_Ratio_Variance_Fields>;
};


/** aggregate fields of "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Avg_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_avg_fields';
  coefficient?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_energyscore_difference_ratio". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>>;
  coefficient?: InputMaybe<Numeric_Comparison_Exp>;
  max?: InputMaybe<Int_Comparison_Exp>;
  min?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_energyscore_difference_ratio" */
export enum Zerocame_Mst_Energyscore_Difference_Ratio_Constraint {
  /** unique or primary key constraint on columns "max", "min" */
  MstEnergyscoreDifferenceRatioPkey = 'mst_energyscore_difference_ratio_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Inc_Input = {
  coefficient?: InputMaybe<Scalars['numeric']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Insert_Input = {
  coefficient?: InputMaybe<Scalars['numeric']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Max_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_max_fields';
  coefficient?: Maybe<Scalars['numeric']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Min_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_min_fields';
  coefficient?: Maybe<Scalars['numeric']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Mutation_Response = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Energyscore_Difference_Ratio>;
};

/** on_conflict condition type for table "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_On_Conflict = {
  constraint: Zerocame_Mst_Energyscore_Difference_Ratio_Constraint;
  update_columns?: Array<Zerocame_Mst_Energyscore_Difference_Ratio_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_energyscore_difference_ratio". */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Order_By = {
  coefficient?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_energyscore_difference_ratio */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Pk_Columns_Input = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

/** select columns of table "zerocame.mst_energyscore_difference_ratio" */
export enum Zerocame_Mst_Energyscore_Difference_Ratio_Select_Column {
  /** column name */
  Coefficient = 'coefficient',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min'
}

/** input type for updating data in table "zerocame.mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Set_Input = {
  coefficient?: InputMaybe<Scalars['numeric']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Stddev_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_stddev_fields';
  coefficient?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Stddev_Pop_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_stddev_pop_fields';
  coefficient?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Stddev_Samp_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_stddev_samp_fields';
  coefficient?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_mst_energyscore_difference_ratio" */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Energyscore_Difference_Ratio_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Stream_Cursor_Value_Input = {
  coefficient?: InputMaybe<Scalars['numeric']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Sum_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_sum_fields';
  coefficient?: Maybe<Scalars['numeric']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.mst_energyscore_difference_ratio" */
export enum Zerocame_Mst_Energyscore_Difference_Ratio_Update_Column {
  /** column name */
  Coefficient = 'coefficient',
  /** column name */
  Max = 'max',
  /** column name */
  Min = 'min'
}

export type Zerocame_Mst_Energyscore_Difference_Ratio_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Energyscore_Difference_Ratio_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Energyscore_Difference_Ratio_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Var_Pop_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_var_pop_fields';
  coefficient?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Var_Samp_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_var_samp_fields';
  coefficient?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Mst_Energyscore_Difference_Ratio_Variance_Fields = {
  __typename?: 'zerocame_mst_energyscore_difference_ratio_variance_fields';
  coefficient?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** 【エネルギースコア】属性マスタ */
export type Zerocame_Mst_Energyscore_User_Attribute = {
  __typename?: 'zerocame_mst_energyscore_user_attribute';
  housing_type: Scalars['String'];
  no: Scalars['String'];
  num_of_household: Scalars['String'];
  region: Scalars['String'];
};

/** aggregated selection of "zerocame.mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_Aggregate = {
  __typename?: 'zerocame_mst_energyscore_user_attribute_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Energyscore_User_Attribute_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Energyscore_User_Attribute>;
};

/** aggregate fields of "zerocame.mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_Aggregate_Fields = {
  __typename?: 'zerocame_mst_energyscore_user_attribute_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Energyscore_User_Attribute_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Energyscore_User_Attribute_Min_Fields>;
};


/** aggregate fields of "zerocame.mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_energyscore_user_attribute". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>>;
  housing_type?: InputMaybe<String_Comparison_Exp>;
  no?: InputMaybe<String_Comparison_Exp>;
  num_of_household?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_energyscore_user_attribute" */
export enum Zerocame_Mst_Energyscore_User_Attribute_Constraint {
  /** unique or primary key constraint on columns "region", "num_of_household", "housing_type" */
  MstEnergyscoreUserAttributRegionHousingTypeNumOfHoKey = 'mst_energyscore_user_attribut_region_housing_type_num_of_ho_key',
  /** unique or primary key constraint on columns "no" */
  MstEnergyscoreUserAttributePkey = 'mst_energyscore_user_attribute_pkey'
}

/** input type for inserting data into table "zerocame.mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_Insert_Input = {
  housing_type?: InputMaybe<Scalars['String']>;
  no?: InputMaybe<Scalars['String']>;
  num_of_household?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Energyscore_User_Attribute_Max_Fields = {
  __typename?: 'zerocame_mst_energyscore_user_attribute_max_fields';
  housing_type?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  num_of_household?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Energyscore_User_Attribute_Min_Fields = {
  __typename?: 'zerocame_mst_energyscore_user_attribute_min_fields';
  housing_type?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  num_of_household?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_Mutation_Response = {
  __typename?: 'zerocame_mst_energyscore_user_attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Energyscore_User_Attribute>;
};

/** on_conflict condition type for table "zerocame.mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_On_Conflict = {
  constraint: Zerocame_Mst_Energyscore_User_Attribute_Constraint;
  update_columns?: Array<Zerocame_Mst_Energyscore_User_Attribute_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_energyscore_user_attribute". */
export type Zerocame_Mst_Energyscore_User_Attribute_Order_By = {
  housing_type?: InputMaybe<Order_By>;
  no?: InputMaybe<Order_By>;
  num_of_household?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_energyscore_user_attribute */
export type Zerocame_Mst_Energyscore_User_Attribute_Pk_Columns_Input = {
  no: Scalars['String'];
};

/** select columns of table "zerocame.mst_energyscore_user_attribute" */
export enum Zerocame_Mst_Energyscore_User_Attribute_Select_Column {
  /** column name */
  HousingType = 'housing_type',
  /** column name */
  No = 'no',
  /** column name */
  NumOfHousehold = 'num_of_household',
  /** column name */
  Region = 'region'
}

/** input type for updating data in table "zerocame.mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_Set_Input = {
  housing_type?: InputMaybe<Scalars['String']>;
  no?: InputMaybe<Scalars['String']>;
  num_of_household?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_mst_energyscore_user_attribute" */
export type Zerocame_Mst_Energyscore_User_Attribute_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Energyscore_User_Attribute_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Energyscore_User_Attribute_Stream_Cursor_Value_Input = {
  housing_type?: InputMaybe<Scalars['String']>;
  no?: InputMaybe<Scalars['String']>;
  num_of_household?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.mst_energyscore_user_attribute" */
export enum Zerocame_Mst_Energyscore_User_Attribute_Update_Column {
  /** column name */
  HousingType = 'housing_type',
  /** column name */
  No = 'no',
  /** column name */
  NumOfHousehold = 'num_of_household',
  /** column name */
  Region = 'region'
}

export type Zerocame_Mst_Energyscore_User_Attribute_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Energyscore_User_Attribute_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Energyscore_User_Attribute_Bool_Exp;
};

/** 都道府県別電気事業者マスタ */
export type Zerocame_Mst_Prefecture_Electric_Company = {
  __typename?: 'zerocame_mst_prefecture_electric_company';
  /** 都道府県コード */
  pref_code: Scalars['String'];
  /** 都道府県名 */
  pref_name: Scalars['String'];
  /** 登録番号 */
  regist_no: Scalars['String'];
};

/** aggregated selection of "zerocame.mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_Aggregate = {
  __typename?: 'zerocame_mst_prefecture_electric_company_aggregate';
  aggregate?: Maybe<Zerocame_Mst_Prefecture_Electric_Company_Aggregate_Fields>;
  nodes: Array<Zerocame_Mst_Prefecture_Electric_Company>;
};

/** aggregate fields of "zerocame.mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_Aggregate_Fields = {
  __typename?: 'zerocame_mst_prefecture_electric_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Mst_Prefecture_Electric_Company_Max_Fields>;
  min?: Maybe<Zerocame_Mst_Prefecture_Electric_Company_Min_Fields>;
};


/** aggregate fields of "zerocame.mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.mst_prefecture_electric_company". All fields are combined with a logical 'AND'. */
export type Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>>;
  pref_code?: InputMaybe<String_Comparison_Exp>;
  pref_name?: InputMaybe<String_Comparison_Exp>;
  regist_no?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.mst_prefecture_electric_company" */
export enum Zerocame_Mst_Prefecture_Electric_Company_Constraint {
  /** unique or primary key constraint on columns "pref_code" */
  MstPrefectureElectricCompanyPkey = 'mst_prefecture_electric_company_pkey'
}

/** input type for inserting data into table "zerocame.mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_Insert_Input = {
  /** 都道府県コード */
  pref_code?: InputMaybe<Scalars['String']>;
  /** 都道府県名 */
  pref_name?: InputMaybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Mst_Prefecture_Electric_Company_Max_Fields = {
  __typename?: 'zerocame_mst_prefecture_electric_company_max_fields';
  /** 都道府県コード */
  pref_code?: Maybe<Scalars['String']>;
  /** 都道府県名 */
  pref_name?: Maybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Mst_Prefecture_Electric_Company_Min_Fields = {
  __typename?: 'zerocame_mst_prefecture_electric_company_min_fields';
  /** 都道府県コード */
  pref_code?: Maybe<Scalars['String']>;
  /** 都道府県名 */
  pref_name?: Maybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_Mutation_Response = {
  __typename?: 'zerocame_mst_prefecture_electric_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Mst_Prefecture_Electric_Company>;
};

/** on_conflict condition type for table "zerocame.mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_On_Conflict = {
  constraint: Zerocame_Mst_Prefecture_Electric_Company_Constraint;
  update_columns?: Array<Zerocame_Mst_Prefecture_Electric_Company_Update_Column>;
  where?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.mst_prefecture_electric_company". */
export type Zerocame_Mst_Prefecture_Electric_Company_Order_By = {
  pref_code?: InputMaybe<Order_By>;
  pref_name?: InputMaybe<Order_By>;
  regist_no?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.mst_prefecture_electric_company */
export type Zerocame_Mst_Prefecture_Electric_Company_Pk_Columns_Input = {
  /** 都道府県コード */
  pref_code: Scalars['String'];
};

/** select columns of table "zerocame.mst_prefecture_electric_company" */
export enum Zerocame_Mst_Prefecture_Electric_Company_Select_Column {
  /** column name */
  PrefCode = 'pref_code',
  /** column name */
  PrefName = 'pref_name',
  /** column name */
  RegistNo = 'regist_no'
}

/** input type for updating data in table "zerocame.mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_Set_Input = {
  /** 都道府県コード */
  pref_code?: InputMaybe<Scalars['String']>;
  /** 都道府県名 */
  pref_name?: InputMaybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_mst_prefecture_electric_company" */
export type Zerocame_Mst_Prefecture_Electric_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Mst_Prefecture_Electric_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Mst_Prefecture_Electric_Company_Stream_Cursor_Value_Input = {
  /** 都道府県コード */
  pref_code?: InputMaybe<Scalars['String']>;
  /** 都道府県名 */
  pref_name?: InputMaybe<Scalars['String']>;
  /** 登録番号 */
  regist_no?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.mst_prefecture_electric_company" */
export enum Zerocame_Mst_Prefecture_Electric_Company_Update_Column {
  /** column name */
  PrefCode = 'pref_code',
  /** column name */
  PrefName = 'pref_name',
  /** column name */
  RegistNo = 'regist_no'
}

export type Zerocame_Mst_Prefecture_Electric_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Mst_Prefecture_Electric_Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Mst_Prefecture_Electric_Company_Bool_Exp;
};

/** 個人向け電力量データ（30 分毎） */
export type Zerocame_Personal_Electricity_Data_30_Minuites = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites';
  consumer_spcfc_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  get_ymd: Scalars['String'];
  /** 30分電力量 */
  min_30_energy: Scalars['numeric'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
  time_code: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Aggregate = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_aggregate';
  aggregate?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Aggregate_Fields>;
  nodes: Array<Zerocame_Personal_Electricity_Data_30_Minuites>;
};

/** aggregate fields of "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Aggregate_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_aggregate_fields';
  avg?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Max_Fields>;
  min?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Min_Fields>;
  stddev?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Personal_Electricity_Data_30_Minuites_Variance_Fields>;
};


/** aggregate fields of "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Avg_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_avg_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.personal_electricity_data_30_minuites". All fields are combined with a logical 'AND'. */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>>;
  consumer_spcfc_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  get_ymd?: InputMaybe<String_Comparison_Exp>;
  min_30_energy?: InputMaybe<Numeric_Comparison_Exp>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<String_Comparison_Exp>;
  time_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.personal_electricity_data_30_minuites" */
export enum Zerocame_Personal_Electricity_Data_30_Minuites_Constraint {
  /** unique or primary key constraint on columns "spply_rcvng_pwr_pnt_spcfc_no", "consumer_spcfc_id", "get_ymd", "time_code" */
  PersonalElectricityData_30MinuitesPkey = 'personal_electricity_data_30_minuites_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Inc_Input = {
  /** 30分電力量 */
  min_30_energy?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Insert_Input = {
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  get_ymd?: InputMaybe<Scalars['String']>;
  /** 30分電力量 */
  min_30_energy?: InputMaybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  time_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Max_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_max_fields';
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  get_ymd?: Maybe<Scalars['String']>;
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  time_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Min_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_min_fields';
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  get_ymd?: Maybe<Scalars['String']>;
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  time_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Mutation_Response = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Personal_Electricity_Data_30_Minuites>;
};

/** on_conflict condition type for table "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_On_Conflict = {
  constraint: Zerocame_Personal_Electricity_Data_30_Minuites_Constraint;
  update_columns?: Array<Zerocame_Personal_Electricity_Data_30_Minuites_Update_Column>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.personal_electricity_data_30_minuites". */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Order_By = {
  consumer_spcfc_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  get_ymd?: InputMaybe<Order_By>;
  min_30_energy?: InputMaybe<Order_By>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Order_By>;
  time_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.personal_electricity_data_30_minuites */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Pk_Columns_Input = {
  consumer_spcfc_id: Scalars['String'];
  get_ymd: Scalars['String'];
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
  time_code: Scalars['String'];
};

/** select columns of table "zerocame.personal_electricity_data_30_minuites" */
export enum Zerocame_Personal_Electricity_Data_30_Minuites_Select_Column {
  /** column name */
  ConsumerSpcfcId = 'consumer_spcfc_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GetYmd = 'get_ymd',
  /** column name */
  Min_30Energy = 'min_30_energy',
  /** column name */
  SpplyRcvngPwrPntSpcfcNo = 'spply_rcvng_pwr_pnt_spcfc_no',
  /** column name */
  TimeCode = 'time_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "zerocame.personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Set_Input = {
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  get_ymd?: InputMaybe<Scalars['String']>;
  /** 30分電力量 */
  min_30_energy?: InputMaybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  time_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Stddev_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_stddev_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Stddev_Pop_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_stddev_pop_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Stddev_Samp_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_stddev_samp_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_personal_electricity_data_30_minuites" */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Personal_Electricity_Data_30_Minuites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Stream_Cursor_Value_Input = {
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  get_ymd?: InputMaybe<Scalars['String']>;
  /** 30分電力量 */
  min_30_energy?: InputMaybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  time_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Sum_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_sum_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.personal_electricity_data_30_minuites" */
export enum Zerocame_Personal_Electricity_Data_30_Minuites_Update_Column {
  /** column name */
  ConsumerSpcfcId = 'consumer_spcfc_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GetYmd = 'get_ymd',
  /** column name */
  Min_30Energy = 'min_30_energy',
  /** column name */
  SpplyRcvngPwrPntSpcfcNo = 'spply_rcvng_pwr_pnt_spcfc_no',
  /** column name */
  TimeCode = 'time_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Zerocame_Personal_Electricity_Data_30_Minuites_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Personal_Electricity_Data_30_Minuites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Personal_Electricity_Data_30_Minuites_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Var_Pop_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_var_pop_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Var_Samp_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_var_samp_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Personal_Electricity_Data_30_Minuites_Variance_Fields = {
  __typename?: 'zerocame_personal_electricity_data_30_minuites_variance_fields';
  /** 30分電力量 */
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** 個人向け電力量データ（日次） */
export type Zerocame_Personal_Electricity_Data_Daily = {
  __typename?: 'zerocame_personal_electricity_data_daily';
  /** 需要家特定ID */
  consumer_spcfc_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** データ種別 */
  data_type: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** 取得年月日 */
  get_ymd: Scalars['String'];
  /** 供給/受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** 電圧分類 */
  voltage_classification: Scalars['String'];
};

/** aggregated selection of "zerocame.personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_Aggregate = {
  __typename?: 'zerocame_personal_electricity_data_daily_aggregate';
  aggregate?: Maybe<Zerocame_Personal_Electricity_Data_Daily_Aggregate_Fields>;
  nodes: Array<Zerocame_Personal_Electricity_Data_Daily>;
};

/** aggregate fields of "zerocame.personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_Aggregate_Fields = {
  __typename?: 'zerocame_personal_electricity_data_daily_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Personal_Electricity_Data_Daily_Max_Fields>;
  min?: Maybe<Zerocame_Personal_Electricity_Data_Daily_Min_Fields>;
};


/** aggregate fields of "zerocame.personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.personal_electricity_data_daily". All fields are combined with a logical 'AND'. */
export type Zerocame_Personal_Electricity_Data_Daily_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>>;
  consumer_spcfc_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_type?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  get_ymd?: InputMaybe<String_Comparison_Exp>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  voltage_classification?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.personal_electricity_data_daily" */
export enum Zerocame_Personal_Electricity_Data_Daily_Constraint {
  /** unique or primary key constraint on columns "spply_rcvng_pwr_pnt_spcfc_no", "consumer_spcfc_id", "get_ymd" */
  PersonalElectricityDataDailyPkey = 'personal_electricity_data_daily_pkey'
}

/** input type for inserting data into table "zerocame.personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_Insert_Input = {
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** データ種別 */
  data_type?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** 取得年月日 */
  get_ymd?: InputMaybe<Scalars['String']>;
  /** 供給/受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 電圧分類 */
  voltage_classification?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_Personal_Electricity_Data_Daily_Max_Fields = {
  __typename?: 'zerocame_personal_electricity_data_daily_max_fields';
  /** 需要家特定ID */
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** データ種別 */
  data_type?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** 取得年月日 */
  get_ymd?: Maybe<Scalars['String']>;
  /** 供給/受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 電圧分類 */
  voltage_classification?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Personal_Electricity_Data_Daily_Min_Fields = {
  __typename?: 'zerocame_personal_electricity_data_daily_min_fields';
  /** 需要家特定ID */
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** データ種別 */
  data_type?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** 取得年月日 */
  get_ymd?: Maybe<Scalars['String']>;
  /** 供給/受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 電圧分類 */
  voltage_classification?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_Mutation_Response = {
  __typename?: 'zerocame_personal_electricity_data_daily_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Personal_Electricity_Data_Daily>;
};

/** on_conflict condition type for table "zerocame.personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_On_Conflict = {
  constraint: Zerocame_Personal_Electricity_Data_Daily_Constraint;
  update_columns?: Array<Zerocame_Personal_Electricity_Data_Daily_Update_Column>;
  where?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.personal_electricity_data_daily". */
export type Zerocame_Personal_Electricity_Data_Daily_Order_By = {
  consumer_spcfc_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_type?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  get_ymd?: InputMaybe<Order_By>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  voltage_classification?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.personal_electricity_data_daily */
export type Zerocame_Personal_Electricity_Data_Daily_Pk_Columns_Input = {
  /** 需要家特定ID */
  consumer_spcfc_id: Scalars['String'];
  /** 取得年月日 */
  get_ymd: Scalars['String'];
  /** 供給/受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no: Scalars['String'];
};

/** select columns of table "zerocame.personal_electricity_data_daily" */
export enum Zerocame_Personal_Electricity_Data_Daily_Select_Column {
  /** column name */
  ConsumerSpcfcId = 'consumer_spcfc_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataType = 'data_type',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GetYmd = 'get_ymd',
  /** column name */
  SpplyRcvngPwrPntSpcfcNo = 'spply_rcvng_pwr_pnt_spcfc_no',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoltageClassification = 'voltage_classification'
}

/** input type for updating data in table "zerocame.personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_Set_Input = {
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** データ種別 */
  data_type?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** 取得年月日 */
  get_ymd?: InputMaybe<Scalars['String']>;
  /** 供給/受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 電圧分類 */
  voltage_classification?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_personal_electricity_data_daily" */
export type Zerocame_Personal_Electricity_Data_Daily_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Personal_Electricity_Data_Daily_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Personal_Electricity_Data_Daily_Stream_Cursor_Value_Input = {
  /** 需要家特定ID */
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** データ種別 */
  data_type?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  /** 取得年月日 */
  get_ymd?: InputMaybe<Scalars['String']>;
  /** 供給/受電地点特定番号 */
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 電圧分類 */
  voltage_classification?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.personal_electricity_data_daily" */
export enum Zerocame_Personal_Electricity_Data_Daily_Update_Column {
  /** column name */
  ConsumerSpcfcId = 'consumer_spcfc_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataType = 'data_type',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GetYmd = 'get_ymd',
  /** column name */
  SpplyRcvngPwrPntSpcfcNo = 'spply_rcvng_pwr_pnt_spcfc_no',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoltageClassification = 'voltage_classification'
}

export type Zerocame_Personal_Electricity_Data_Daily_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Personal_Electricity_Data_Daily_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Personal_Electricity_Data_Daily_Bool_Exp;
};

/** グリーンスコアのカテゴリごとのスコア合計（の型定義） */
export type Zerocame_Type_Greenscore_Detail_By_Category = {
  __typename?: 'zerocame_type_greenscore_detail_by_category';
  auth0_user_id: Scalars['String'];
  category_name: Scalars['String'];
  score: Scalars['Int'];
};

export type Zerocame_Type_Greenscore_Detail_By_Category_Aggregate = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_aggregate';
  aggregate?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Aggregate_Fields>;
  nodes: Array<Zerocame_Type_Greenscore_Detail_By_Category>;
};

/** aggregate fields of "zerocame.type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_Aggregate_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_aggregate_fields';
  avg?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Max_Fields>;
  min?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Min_Fields>;
  stddev?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Sum_Fields>;
  var_pop?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_Type_Greenscore_Detail_By_Category_Variance_Fields>;
};


/** aggregate fields of "zerocame.type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Avg_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_avg_fields';
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.type_greenscore_detail_by_category". All fields are combined with a logical 'AND'. */
export type Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  category_name?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.type_greenscore_detail_by_category" */
export enum Zerocame_Type_Greenscore_Detail_By_Category_Constraint {
  /** unique or primary key constraint on columns "category_name" */
  TypeGreenscoreDetailByCategoryPkey = 'type_greenscore_detail_by_category_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_Inc_Input = {
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zerocame.type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  category_name?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Max_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  category_name?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Min_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  category_name?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "zerocame.type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_Mutation_Response = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Type_Greenscore_Detail_By_Category>;
};

/** on_conflict condition type for table "zerocame.type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_On_Conflict = {
  constraint: Zerocame_Type_Greenscore_Detail_By_Category_Constraint;
  update_columns?: Array<Zerocame_Type_Greenscore_Detail_By_Category_Update_Column>;
  where?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.type_greenscore_detail_by_category". */
export type Zerocame_Type_Greenscore_Detail_By_Category_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  category_name?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.type_greenscore_detail_by_category */
export type Zerocame_Type_Greenscore_Detail_By_Category_Pk_Columns_Input = {
  category_name: Scalars['String'];
};

/** select columns of table "zerocame.type_greenscore_detail_by_category" */
export enum Zerocame_Type_Greenscore_Detail_By_Category_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  Score = 'score'
}

/** input type for updating data in table "zerocame.type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  category_name?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Stddev_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_stddev_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Stddev_Pop_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_stddev_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Stddev_Samp_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_stddev_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_type_greenscore_detail_by_category" */
export type Zerocame_Type_Greenscore_Detail_By_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Type_Greenscore_Detail_By_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Type_Greenscore_Detail_By_Category_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  category_name?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Sum_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_sum_fields';
  score?: Maybe<Scalars['Int']>;
};

/** update columns of table "zerocame.type_greenscore_detail_by_category" */
export enum Zerocame_Type_Greenscore_Detail_By_Category_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  Score = 'score'
}

export type Zerocame_Type_Greenscore_Detail_By_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Type_Greenscore_Detail_By_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Type_Greenscore_Detail_By_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Var_Pop_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_var_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Var_Samp_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_var_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_Type_Greenscore_Detail_By_Category_Variance_Fields = {
  __typename?: 'zerocame_type_greenscore_detail_by_category_variance_fields';
  score?: Maybe<Scalars['Float']>;
};

/** ユーザーのアクション達成状況を管理するテーブル */
export type Zerocame_User_Actions = {
  __typename?: 'zerocame_user_actions';
  action_id: Scalars['String'];
  action_text?: Maybe<Scalars['String']>;
  auth0_user_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_at_from_epoch: Scalars['numeric'];
  reduced_co2_emission?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "zerocame.user_actions" */
export type Zerocame_User_Actions_Aggregate = {
  __typename?: 'zerocame_user_actions_aggregate';
  aggregate?: Maybe<Zerocame_User_Actions_Aggregate_Fields>;
  nodes: Array<Zerocame_User_Actions>;
};

/** aggregate fields of "zerocame.user_actions" */
export type Zerocame_User_Actions_Aggregate_Fields = {
  __typename?: 'zerocame_user_actions_aggregate_fields';
  avg?: Maybe<Zerocame_User_Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_User_Actions_Max_Fields>;
  min?: Maybe<Zerocame_User_Actions_Min_Fields>;
  stddev?: Maybe<Zerocame_User_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_User_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_User_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_User_Actions_Sum_Fields>;
  var_pop?: Maybe<Zerocame_User_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_User_Actions_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_User_Actions_Variance_Fields>;
};


/** aggregate fields of "zerocame.user_actions" */
export type Zerocame_User_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_User_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_User_Actions_Avg_Fields = {
  __typename?: 'zerocame_user_actions_avg_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
  reduced_co2_emission?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.user_actions". All fields are combined with a logical 'AND'. */
export type Zerocame_User_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_User_Actions_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_User_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_User_Actions_Bool_Exp>>;
  action_id?: InputMaybe<String_Comparison_Exp>;
  action_text?: InputMaybe<String_Comparison_Exp>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at_from_epoch?: InputMaybe<Numeric_Comparison_Exp>;
  reduced_co2_emission?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.user_actions" */
export enum Zerocame_User_Actions_Constraint {
  /** unique or primary key constraint on columns "action_id", "auth0_user_id" */
  UserActionsPkey = 'user_actions_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.user_actions" */
export type Zerocame_User_Actions_Inc_Input = {
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
  reduced_co2_emission?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.user_actions" */
export type Zerocame_User_Actions_Insert_Input = {
  action_id?: InputMaybe<Scalars['String']>;
  action_text?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
  reduced_co2_emission?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Zerocame_User_Actions_Max_Fields = {
  __typename?: 'zerocame_user_actions_max_fields';
  action_id?: Maybe<Scalars['String']>;
  action_text?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_at_from_epoch?: Maybe<Scalars['numeric']>;
  reduced_co2_emission?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Zerocame_User_Actions_Min_Fields = {
  __typename?: 'zerocame_user_actions_min_fields';
  action_id?: Maybe<Scalars['String']>;
  action_text?: Maybe<Scalars['String']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_at_from_epoch?: Maybe<Scalars['numeric']>;
  reduced_co2_emission?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "zerocame.user_actions" */
export type Zerocame_User_Actions_Mutation_Response = {
  __typename?: 'zerocame_user_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_User_Actions>;
};

/** on_conflict condition type for table "zerocame.user_actions" */
export type Zerocame_User_Actions_On_Conflict = {
  constraint: Zerocame_User_Actions_Constraint;
  update_columns?: Array<Zerocame_User_Actions_Update_Column>;
  where?: InputMaybe<Zerocame_User_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.user_actions". */
export type Zerocame_User_Actions_Order_By = {
  action_id?: InputMaybe<Order_By>;
  action_text?: InputMaybe<Order_By>;
  auth0_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_at_from_epoch?: InputMaybe<Order_By>;
  reduced_co2_emission?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.user_actions */
export type Zerocame_User_Actions_Pk_Columns_Input = {
  action_id: Scalars['String'];
  auth0_user_id: Scalars['String'];
};

/** select columns of table "zerocame.user_actions" */
export enum Zerocame_User_Actions_Select_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  ActionText = 'action_text',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedAtFromEpoch = 'created_at_from_epoch',
  /** column name */
  ReducedCo2Emission = 'reduced_co2_emission'
}

/** input type for updating data in table "zerocame.user_actions" */
export type Zerocame_User_Actions_Set_Input = {
  action_id?: InputMaybe<Scalars['String']>;
  action_text?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
  reduced_co2_emission?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Zerocame_User_Actions_Stddev_Fields = {
  __typename?: 'zerocame_user_actions_stddev_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
  reduced_co2_emission?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_User_Actions_Stddev_Pop_Fields = {
  __typename?: 'zerocame_user_actions_stddev_pop_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
  reduced_co2_emission?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_User_Actions_Stddev_Samp_Fields = {
  __typename?: 'zerocame_user_actions_stddev_samp_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
  reduced_co2_emission?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_user_actions" */
export type Zerocame_User_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_User_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_User_Actions_Stream_Cursor_Value_Input = {
  action_id?: InputMaybe<Scalars['String']>;
  action_text?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_at_from_epoch?: InputMaybe<Scalars['numeric']>;
  reduced_co2_emission?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Zerocame_User_Actions_Sum_Fields = {
  __typename?: 'zerocame_user_actions_sum_fields';
  created_at_from_epoch?: Maybe<Scalars['numeric']>;
  reduced_co2_emission?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.user_actions" */
export enum Zerocame_User_Actions_Update_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  ActionText = 'action_text',
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedAtFromEpoch = 'created_at_from_epoch',
  /** column name */
  ReducedCo2Emission = 'reduced_co2_emission'
}

export type Zerocame_User_Actions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_User_Actions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_User_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_User_Actions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_User_Actions_Var_Pop_Fields = {
  __typename?: 'zerocame_user_actions_var_pop_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
  reduced_co2_emission?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_User_Actions_Var_Samp_Fields = {
  __typename?: 'zerocame_user_actions_var_samp_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
  reduced_co2_emission?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_User_Actions_Variance_Fields = {
  __typename?: 'zerocame_user_actions_variance_fields';
  created_at_from_epoch?: Maybe<Scalars['Float']>;
  reduced_co2_emission?: Maybe<Scalars['Float']>;
};

/** ユーザーの日ごとの電力データ（の型定義） */
export type Zerocame_User_Electricity_Data_Per_Day = {
  __typename?: 'zerocame_user_electricity_data_per_day';
  auth0_user_id: Scalars['String'];
  consent_application_result_id: Scalars['Int'];
  daily_energy: Scalars['numeric'];
  get_ymd: Scalars['String'];
};

export type Zerocame_User_Electricity_Data_Per_Day_Aggregate = {
  __typename?: 'zerocame_user_electricity_data_per_day_aggregate';
  aggregate?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Aggregate_Fields>;
  nodes: Array<Zerocame_User_Electricity_Data_Per_Day>;
};

/** aggregate fields of "zerocame.user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_Aggregate_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_aggregate_fields';
  avg?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Max_Fields>;
  min?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Min_Fields>;
  stddev?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Sum_Fields>;
  var_pop?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_User_Electricity_Data_Per_Day_Variance_Fields>;
};


/** aggregate fields of "zerocame.user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Avg_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_avg_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  daily_energy?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.user_electricity_data_per_day". All fields are combined with a logical 'AND'. */
export type Zerocame_User_Electricity_Data_Per_Day_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  consent_application_result_id?: InputMaybe<Int_Comparison_Exp>;
  daily_energy?: InputMaybe<Numeric_Comparison_Exp>;
  get_ymd?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zerocame.user_electricity_data_per_day" */
export enum Zerocame_User_Electricity_Data_Per_Day_Constraint {
  /** unique or primary key constraint on columns "consent_application_result_id", "get_ymd" */
  UserElectricityDataPerDayPkey = 'user_electricity_data_per_day_pkey'
}

/** input type for incrementing numeric columns in table "zerocame.user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_Inc_Input = {
  consent_application_result_id?: InputMaybe<Scalars['Int']>;
  daily_energy?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "zerocame.user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  consent_application_result_id?: InputMaybe<Scalars['Int']>;
  daily_energy?: InputMaybe<Scalars['numeric']>;
  get_ymd?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Max_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  consent_application_result_id?: Maybe<Scalars['Int']>;
  daily_energy?: Maybe<Scalars['numeric']>;
  get_ymd?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Min_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  consent_application_result_id?: Maybe<Scalars['Int']>;
  daily_energy?: Maybe<Scalars['numeric']>;
  get_ymd?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_Mutation_Response = {
  __typename?: 'zerocame_user_electricity_data_per_day_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_User_Electricity_Data_Per_Day>;
};

/** on_conflict condition type for table "zerocame.user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_On_Conflict = {
  constraint: Zerocame_User_Electricity_Data_Per_Day_Constraint;
  update_columns?: Array<Zerocame_User_Electricity_Data_Per_Day_Update_Column>;
  where?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.user_electricity_data_per_day". */
export type Zerocame_User_Electricity_Data_Per_Day_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  consent_application_result_id?: InputMaybe<Order_By>;
  daily_energy?: InputMaybe<Order_By>;
  get_ymd?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zerocame.user_electricity_data_per_day */
export type Zerocame_User_Electricity_Data_Per_Day_Pk_Columns_Input = {
  consent_application_result_id: Scalars['Int'];
  get_ymd: Scalars['String'];
};

/** select columns of table "zerocame.user_electricity_data_per_day" */
export enum Zerocame_User_Electricity_Data_Per_Day_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ConsentApplicationResultId = 'consent_application_result_id',
  /** column name */
  DailyEnergy = 'daily_energy',
  /** column name */
  GetYmd = 'get_ymd'
}

/** input type for updating data in table "zerocame.user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  consent_application_result_id?: InputMaybe<Scalars['Int']>;
  daily_energy?: InputMaybe<Scalars['numeric']>;
  get_ymd?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Stddev_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_stddev_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  daily_energy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Stddev_Pop_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_stddev_pop_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  daily_energy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Stddev_Samp_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_stddev_samp_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  daily_energy?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_user_electricity_data_per_day" */
export type Zerocame_User_Electricity_Data_Per_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_User_Electricity_Data_Per_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_User_Electricity_Data_Per_Day_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  consent_application_result_id?: InputMaybe<Scalars['Int']>;
  daily_energy?: InputMaybe<Scalars['numeric']>;
  get_ymd?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Sum_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_sum_fields';
  consent_application_result_id?: Maybe<Scalars['Int']>;
  daily_energy?: Maybe<Scalars['numeric']>;
};

/** update columns of table "zerocame.user_electricity_data_per_day" */
export enum Zerocame_User_Electricity_Data_Per_Day_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ConsentApplicationResultId = 'consent_application_result_id',
  /** column name */
  DailyEnergy = 'daily_energy',
  /** column name */
  GetYmd = 'get_ymd'
}

export type Zerocame_User_Electricity_Data_Per_Day_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_User_Electricity_Data_Per_Day_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_User_Electricity_Data_Per_Day_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Var_Pop_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_var_pop_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  daily_energy?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Var_Samp_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_var_samp_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  daily_energy?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_User_Electricity_Data_Per_Day_Variance_Fields = {
  __typename?: 'zerocame_user_electricity_data_per_day_variance_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  daily_energy?: Maybe<Scalars['Float']>;
};

/** ユーザー情報を管理するテーブル */
export type Zerocame_Users = {
  __typename?: 'zerocame_users';
  auth0_user_id: Scalars['String'];
  /** An array relationship */
  bingo_users: Array<Zerocame_Bingo_Users>;
  /** An aggregate relationship */
  bingo_users_aggregate: Zerocame_Bingo_Users_Aggregate;
  /** 同意申込ZeroCa画面状態　null：同意申込未実施、1：同意申込処理中、2：同意申込処理完了、3：同意申込審査結果受領済、4：ユーザーによる取消、5：異動による取消 */
  consent_application_zeroca_status?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  greenscore_users: Array<Zerocame_Greenscore_Users>;
  /** An aggregate relationship */
  greenscore_users_aggregate: Zerocame_Greenscore_Users_Aggregate;
  other_reason_for_delete?: Maybe<Scalars['String']>;
  /** 都道府県コード */
  pref_code?: Maybe<Scalars['String']>;
  reasons_for_delete?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at: Scalars['timestamptz'];
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
  /** An array relationship */
  users_consent_application_inputs: Array<Zerocame_Consent_Application_Input>;
  /** An aggregate relationship */
  users_consent_application_inputs_aggregate: Zerocame_Consent_Application_Input_Aggregate;
  /** An array relationship */
  users_consent_application_results: Array<Zerocame_Consent_Application_Result>;
  /** An aggregate relationship */
  users_consent_application_results_aggregate: Zerocame_Consent_Application_Result_Aggregate;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersBingo_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersBingo_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Bingo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Bingo_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersGreenscore_UsersArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersGreenscore_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Greenscore_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Greenscore_Users_Order_By>>;
  where?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersUsers_Consent_Application_InputsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Input_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersUsers_Consent_Application_Inputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Input_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Input_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersUsers_Consent_Application_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};


/** ユーザー情報を管理するテーブル */
export type Zerocame_UsersUsers_Consent_Application_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zerocame_Consent_Application_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zerocame_Consent_Application_Result_Order_By>>;
  where?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
};

/** aggregated selection of "zerocame.users" */
export type Zerocame_Users_Aggregate = {
  __typename?: 'zerocame_users_aggregate';
  aggregate?: Maybe<Zerocame_Users_Aggregate_Fields>;
  nodes: Array<Zerocame_Users>;
};

/** aggregate fields of "zerocame.users" */
export type Zerocame_Users_Aggregate_Fields = {
  __typename?: 'zerocame_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Zerocame_Users_Max_Fields>;
  min?: Maybe<Zerocame_Users_Min_Fields>;
};


/** aggregate fields of "zerocame.users" */
export type Zerocame_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zerocame.users". All fields are combined with a logical 'AND'. */
export type Zerocame_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_Users_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_Users_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  bingo_users?: InputMaybe<Zerocame_Bingo_Users_Bool_Exp>;
  bingo_users_aggregate?: InputMaybe<Zerocame_Bingo_Users_Aggregate_Bool_Exp>;
  consent_application_zeroca_status?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  greenscore_users?: InputMaybe<Zerocame_Greenscore_Users_Bool_Exp>;
  greenscore_users_aggregate?: InputMaybe<Zerocame_Greenscore_Users_Aggregate_Bool_Exp>;
  other_reason_for_delete?: InputMaybe<String_Comparison_Exp>;
  pref_code?: InputMaybe<String_Comparison_Exp>;
  reasons_for_delete?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  users_consent_application_inputs?: InputMaybe<Zerocame_Consent_Application_Input_Bool_Exp>;
  users_consent_application_inputs_aggregate?: InputMaybe<Zerocame_Consent_Application_Input_Aggregate_Bool_Exp>;
  users_consent_application_results?: InputMaybe<Zerocame_Consent_Application_Result_Bool_Exp>;
  users_consent_application_results_aggregate?: InputMaybe<Zerocame_Consent_Application_Result_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "zerocame.users" */
export enum Zerocame_Users_Constraint {
  /** unique or primary key constraint on columns "auth0_user_id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "zerocame.users" */
export type Zerocame_Users_Insert_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  bingo_users?: InputMaybe<Zerocame_Bingo_Users_Arr_Rel_Insert_Input>;
  /** 同意申込ZeroCa画面状態　null：同意申込未実施、1：同意申込処理中、2：同意申込処理完了、3：同意申込審査結果受領済、4：ユーザーによる取消、5：異動による取消 */
  consent_application_zeroca_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  greenscore_users?: InputMaybe<Zerocame_Greenscore_Users_Arr_Rel_Insert_Input>;
  other_reason_for_delete?: InputMaybe<Scalars['String']>;
  /** 都道府県コード */
  pref_code?: InputMaybe<Scalars['String']>;
  reasons_for_delete?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
  users_consent_application_inputs?: InputMaybe<Zerocame_Consent_Application_Input_Arr_Rel_Insert_Input>;
  users_consent_application_results?: InputMaybe<Zerocame_Consent_Application_Result_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Zerocame_Users_Max_Fields = {
  __typename?: 'zerocame_users_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 同意申込ZeroCa画面状態　null：同意申込未実施、1：同意申込処理中、2：同意申込処理完了、3：同意申込審査結果受領済、4：ユーザーによる取消、5：異動による取消 */
  consent_application_zeroca_status?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  other_reason_for_delete?: Maybe<Scalars['String']>;
  /** 都道府県コード */
  pref_code?: Maybe<Scalars['String']>;
  reasons_for_delete?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_Users_Min_Fields = {
  __typename?: 'zerocame_users_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  /** 同意申込ZeroCa画面状態　null：同意申込未実施、1：同意申込処理中、2：同意申込処理完了、3：同意申込審査結果受領済、4：ユーザーによる取消、5：異動による取消 */
  consent_application_zeroca_status?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  other_reason_for_delete?: Maybe<Scalars['String']>;
  /** 都道府県コード */
  pref_code?: Maybe<Scalars['String']>;
  reasons_for_delete?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zerocame.users" */
export type Zerocame_Users_Mutation_Response = {
  __typename?: 'zerocame_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zerocame_Users>;
};

/** input type for inserting object relation for remote table "zerocame.users" */
export type Zerocame_Users_Obj_Rel_Insert_Input = {
  data: Zerocame_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Zerocame_Users_On_Conflict>;
};

/** on_conflict condition type for table "zerocame.users" */
export type Zerocame_Users_On_Conflict = {
  constraint: Zerocame_Users_Constraint;
  update_columns?: Array<Zerocame_Users_Update_Column>;
  where?: InputMaybe<Zerocame_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "zerocame.users". */
export type Zerocame_Users_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  bingo_users_aggregate?: InputMaybe<Zerocame_Bingo_Users_Aggregate_Order_By>;
  consent_application_zeroca_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  greenscore_users_aggregate?: InputMaybe<Zerocame_Greenscore_Users_Aggregate_Order_By>;
  other_reason_for_delete?: InputMaybe<Order_By>;
  pref_code?: InputMaybe<Order_By>;
  reasons_for_delete?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  users_consent_application_inputs_aggregate?: InputMaybe<Zerocame_Consent_Application_Input_Aggregate_Order_By>;
  users_consent_application_results_aggregate?: InputMaybe<Zerocame_Consent_Application_Result_Aggregate_Order_By>;
};

/** primary key columns input for table: zerocame.users */
export type Zerocame_Users_Pk_Columns_Input = {
  auth0_user_id: Scalars['String'];
};

/** select columns of table "zerocame.users" */
export enum Zerocame_Users_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ConsentApplicationZerocaStatus = 'consent_application_zeroca_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  OtherReasonForDelete = 'other_reason_for_delete',
  /** column name */
  PrefCode = 'pref_code',
  /** column name */
  ReasonsForDelete = 'reasons_for_delete',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "zerocame.users" */
export type Zerocame_Users_Set_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 同意申込ZeroCa画面状態　null：同意申込未実施、1：同意申込処理中、2：同意申込処理完了、3：同意申込審査結果受領済、4：ユーザーによる取消、5：異動による取消 */
  consent_application_zeroca_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  other_reason_for_delete?: InputMaybe<Scalars['String']>;
  /** 都道府県コード */
  pref_code?: InputMaybe<Scalars['String']>;
  reasons_for_delete?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zerocame_users" */
export type Zerocame_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_Users_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  /** 同意申込ZeroCa画面状態　null：同意申込未実施、1：同意申込処理中、2：同意申込処理完了、3：同意申込審査結果受領済、4：ユーザーによる取消、5：異動による取消 */
  consent_application_zeroca_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** 登録機能ID */
  created_by?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  other_reason_for_delete?: InputMaybe<Scalars['String']>;
  /** 都道府県コード */
  pref_code?: InputMaybe<Scalars['String']>;
  reasons_for_delete?: InputMaybe<Scalars['String']>;
  /** 最終更新日時 */
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** 最終更新機能ID */
  updated_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zerocame.users" */
export enum Zerocame_Users_Update_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ConsentApplicationZerocaStatus = 'consent_application_zeroca_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  OtherReasonForDelete = 'other_reason_for_delete',
  /** column name */
  PrefCode = 'pref_code',
  /** column name */
  ReasonsForDelete = 'reasons_for_delete',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Zerocame_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zerocame_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zerocame_Users_Bool_Exp;
};

/** columns and relationships of "zerocame.view_now" */
export type Zerocame_View_Now = {
  __typename?: 'zerocame_view_now';
  epoch?: Maybe<Scalars['float8']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "zerocame.view_now" */
export type Zerocame_View_Now_Aggregate = {
  __typename?: 'zerocame_view_now_aggregate';
  aggregate?: Maybe<Zerocame_View_Now_Aggregate_Fields>;
  nodes: Array<Zerocame_View_Now>;
};

/** aggregate fields of "zerocame.view_now" */
export type Zerocame_View_Now_Aggregate_Fields = {
  __typename?: 'zerocame_view_now_aggregate_fields';
  avg?: Maybe<Zerocame_View_Now_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_View_Now_Max_Fields>;
  min?: Maybe<Zerocame_View_Now_Min_Fields>;
  stddev?: Maybe<Zerocame_View_Now_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_View_Now_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_View_Now_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_View_Now_Sum_Fields>;
  var_pop?: Maybe<Zerocame_View_Now_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_View_Now_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_View_Now_Variance_Fields>;
};


/** aggregate fields of "zerocame.view_now" */
export type Zerocame_View_Now_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_View_Now_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_View_Now_Avg_Fields = {
  __typename?: 'zerocame_view_now_avg_fields';
  epoch?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.view_now". All fields are combined with a logical 'AND'. */
export type Zerocame_View_Now_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_View_Now_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_View_Now_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_View_Now_Bool_Exp>>;
  epoch?: InputMaybe<Float8_Comparison_Exp>;
  now?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Zerocame_View_Now_Max_Fields = {
  __typename?: 'zerocame_view_now_max_fields';
  epoch?: Maybe<Scalars['float8']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Zerocame_View_Now_Min_Fields = {
  __typename?: 'zerocame_view_now_min_fields';
  epoch?: Maybe<Scalars['float8']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "zerocame.view_now". */
export type Zerocame_View_Now_Order_By = {
  epoch?: InputMaybe<Order_By>;
  now?: InputMaybe<Order_By>;
};

/** select columns of table "zerocame.view_now" */
export enum Zerocame_View_Now_Select_Column {
  /** column name */
  Epoch = 'epoch',
  /** column name */
  Now = 'now'
}

/** aggregate stddev on columns */
export type Zerocame_View_Now_Stddev_Fields = {
  __typename?: 'zerocame_view_now_stddev_fields';
  epoch?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_View_Now_Stddev_Pop_Fields = {
  __typename?: 'zerocame_view_now_stddev_pop_fields';
  epoch?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_View_Now_Stddev_Samp_Fields = {
  __typename?: 'zerocame_view_now_stddev_samp_fields';
  epoch?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_view_now" */
export type Zerocame_View_Now_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_View_Now_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_View_Now_Stream_Cursor_Value_Input = {
  epoch?: InputMaybe<Scalars['float8']>;
  now?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Zerocame_View_Now_Sum_Fields = {
  __typename?: 'zerocame_view_now_sum_fields';
  epoch?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Zerocame_View_Now_Var_Pop_Fields = {
  __typename?: 'zerocame_view_now_var_pop_fields';
  epoch?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_View_Now_Var_Samp_Fields = {
  __typename?: 'zerocame_view_now_var_samp_fields';
  epoch?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_View_Now_Variance_Fields = {
  __typename?: 'zerocame_view_now_variance_fields';
  epoch?: Maybe<Scalars['Float']>;
};

/** VIEW_ユーザーの電力データ */
export type Zerocame_View_User_Electricity_Data = {
  __typename?: 'zerocame_view_user_electricity_data';
  auth0_user_id?: Maybe<Scalars['String']>;
  consent_application_result_id?: Maybe<Scalars['Int']>;
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  get_ymd?: Maybe<Scalars['String']>;
  min_30_energy?: Maybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  time_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zerocame.view_user_electricity_data" */
export type Zerocame_View_User_Electricity_Data_Aggregate = {
  __typename?: 'zerocame_view_user_electricity_data_aggregate';
  aggregate?: Maybe<Zerocame_View_User_Electricity_Data_Aggregate_Fields>;
  nodes: Array<Zerocame_View_User_Electricity_Data>;
};

/** aggregate fields of "zerocame.view_user_electricity_data" */
export type Zerocame_View_User_Electricity_Data_Aggregate_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_aggregate_fields';
  avg?: Maybe<Zerocame_View_User_Electricity_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zerocame_View_User_Electricity_Data_Max_Fields>;
  min?: Maybe<Zerocame_View_User_Electricity_Data_Min_Fields>;
  stddev?: Maybe<Zerocame_View_User_Electricity_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Zerocame_View_User_Electricity_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zerocame_View_User_Electricity_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Zerocame_View_User_Electricity_Data_Sum_Fields>;
  var_pop?: Maybe<Zerocame_View_User_Electricity_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Zerocame_View_User_Electricity_Data_Var_Samp_Fields>;
  variance?: Maybe<Zerocame_View_User_Electricity_Data_Variance_Fields>;
};


/** aggregate fields of "zerocame.view_user_electricity_data" */
export type Zerocame_View_User_Electricity_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zerocame_View_User_Electricity_Data_Avg_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_avg_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zerocame.view_user_electricity_data". All fields are combined with a logical 'AND'. */
export type Zerocame_View_User_Electricity_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Bool_Exp>>;
  _not?: InputMaybe<Zerocame_View_User_Electricity_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Zerocame_View_User_Electricity_Data_Bool_Exp>>;
  auth0_user_id?: InputMaybe<String_Comparison_Exp>;
  consent_application_result_id?: InputMaybe<Int_Comparison_Exp>;
  consumer_spcfc_id?: InputMaybe<String_Comparison_Exp>;
  get_ymd?: InputMaybe<String_Comparison_Exp>;
  min_30_energy?: InputMaybe<Numeric_Comparison_Exp>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<String_Comparison_Exp>;
  time_code?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Zerocame_View_User_Electricity_Data_Max_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_max_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  consent_application_result_id?: Maybe<Scalars['Int']>;
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  get_ymd?: Maybe<Scalars['String']>;
  min_30_energy?: Maybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  time_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Zerocame_View_User_Electricity_Data_Min_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_min_fields';
  auth0_user_id?: Maybe<Scalars['String']>;
  consent_application_result_id?: Maybe<Scalars['Int']>;
  consumer_spcfc_id?: Maybe<Scalars['String']>;
  get_ymd?: Maybe<Scalars['String']>;
  min_30_energy?: Maybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: Maybe<Scalars['String']>;
  time_code?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "zerocame.view_user_electricity_data". */
export type Zerocame_View_User_Electricity_Data_Order_By = {
  auth0_user_id?: InputMaybe<Order_By>;
  consent_application_result_id?: InputMaybe<Order_By>;
  consumer_spcfc_id?: InputMaybe<Order_By>;
  get_ymd?: InputMaybe<Order_By>;
  min_30_energy?: InputMaybe<Order_By>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Order_By>;
  time_code?: InputMaybe<Order_By>;
};

/** select columns of table "zerocame.view_user_electricity_data" */
export enum Zerocame_View_User_Electricity_Data_Select_Column {
  /** column name */
  Auth0UserId = 'auth0_user_id',
  /** column name */
  ConsentApplicationResultId = 'consent_application_result_id',
  /** column name */
  ConsumerSpcfcId = 'consumer_spcfc_id',
  /** column name */
  GetYmd = 'get_ymd',
  /** column name */
  Min_30Energy = 'min_30_energy',
  /** column name */
  SpplyRcvngPwrPntSpcfcNo = 'spply_rcvng_pwr_pnt_spcfc_no',
  /** column name */
  TimeCode = 'time_code'
}

/** aggregate stddev on columns */
export type Zerocame_View_User_Electricity_Data_Stddev_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_stddev_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zerocame_View_User_Electricity_Data_Stddev_Pop_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_stddev_pop_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zerocame_View_User_Electricity_Data_Stddev_Samp_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_stddev_samp_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zerocame_view_user_electricity_data" */
export type Zerocame_View_User_Electricity_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zerocame_View_User_Electricity_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zerocame_View_User_Electricity_Data_Stream_Cursor_Value_Input = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  consent_application_result_id?: InputMaybe<Scalars['Int']>;
  consumer_spcfc_id?: InputMaybe<Scalars['String']>;
  get_ymd?: InputMaybe<Scalars['String']>;
  min_30_energy?: InputMaybe<Scalars['numeric']>;
  spply_rcvng_pwr_pnt_spcfc_no?: InputMaybe<Scalars['String']>;
  time_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Zerocame_View_User_Electricity_Data_Sum_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_sum_fields';
  consent_application_result_id?: Maybe<Scalars['Int']>;
  min_30_energy?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Zerocame_View_User_Electricity_Data_Var_Pop_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_var_pop_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zerocame_View_User_Electricity_Data_Var_Samp_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_var_samp_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  min_30_energy?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zerocame_View_User_Electricity_Data_Variance_Fields = {
  __typename?: 'zerocame_view_user_electricity_data_variance_fields';
  consent_application_result_id?: Maybe<Scalars['Float']>;
  min_30_energy?: Maybe<Scalars['Float']>;
};

export type InsertUser_20240226MutationVariables = Exact<{
  auth0_user_id: Scalars['String'];
}>;


export type InsertUser_20240226Mutation = { __typename?: 'mutation_root', insert_zerocame_users?: { __typename?: 'zerocame_users_mutation_response', returning: Array<{ __typename?: 'zerocame_users', auth0_user_id: string }> } | null };

export type InsertGreenscoreAggregationByRetrospective_20240226MutationVariables = Exact<{
  auth0UserId: Scalars['String'];
  details: Array<Zerocame_Greenscore_Action_Review_Detail_Insert_Input> | Zerocame_Greenscore_Action_Review_Detail_Insert_Input;
}>;


export type InsertGreenscoreAggregationByRetrospective_20240226Mutation = { __typename?: 'mutation_root', insert_zerocame_greenscore_action_review?: { __typename?: 'zerocame_greenscore_action_review_mutation_response', returning: Array<{ __typename?: 'zerocame_greenscore_action_review', id: number, greenscore_action_review_details: Array<{ __typename?: 'zerocame_greenscore_action_review_detail', greenscore_action_id: string, evaluation: number }> }> } | null };

export type UpsertGreenscoreUserActions_20240226MutationVariables = Exact<{
  userActions: Array<Zerocame_Greenscore_User_Actions_Insert_Input> | Zerocame_Greenscore_User_Actions_Insert_Input;
}>;


export type UpsertGreenscoreUserActions_20240226Mutation = { __typename?: 'mutation_root', insert_zerocame_greenscore_user_actions?: { __typename?: 'zerocame_greenscore_user_actions_mutation_response', returning: Array<{ __typename?: 'zerocame_greenscore_user_actions', greenscore_action_id: string, status: string }> } | null };

export type DeleteGreenscoreUserAction_20240226MutationVariables = Exact<{
  greenscore_action_id?: InputMaybe<Scalars['String']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
}>;


export type DeleteGreenscoreUserAction_20240226Mutation = { __typename?: 'mutation_root', delete_zerocame_greenscore_user_actions_by_pk?: { __typename?: 'zerocame_greenscore_user_actions', status: string } | null };

export type UpdateProfile_20240226MutationVariables = Exact<{
  auth0_user_id: Scalars['String'];
  nick_name: Scalars['String'];
  sex_code: Scalars['Int'];
  sex_content: Scalars['String'];
  era_code: Scalars['Int'];
  era_content: Scalars['String'];
  pref_code: Scalars['Int'];
  pref_content: Scalars['String'];
  city: Scalars['String'];
  household_size_code: Scalars['Int'];
  household_size_content: Scalars['String'];
  housing_type_code: Scalars['Int'];
  housing_type_content: Scalars['String'];
  housing_energy_code: Scalars['Int'];
  housing_energy_content: Scalars['String'];
  car_type_code: Scalars['Int'];
  car_type_content: Scalars['String'];
  electricity_contract_code: Scalars['Int'];
  electricity_contract_content: Scalars['String'];
  num_of_household: Scalars['String'];
  housing_type: Scalars['String'];
  all_electric: Scalars['String'];
  electric_vehicle: Scalars['String'];
}>;


export type UpdateProfile_20240226Mutation = { __typename?: 'mutation_root', update_zerocame_greenscore_init_hearing_by_pk_1?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_2?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_4?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_5?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_6?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_7?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_8?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_9?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_init_hearing_by_pk_10?: { __typename?: 'zerocame_greenscore_init_hearing', auth0_user_id: string, hearing_no: number } | null, update_zerocame_greenscore_users_by_pk?: { __typename?: 'zerocame_greenscore_users', auth0_user_id: string } | null };

export type InsertGreenscoreMissionAchievement_20240226MutationVariables = Exact<{
  auth0UserId: Scalars['String'];
  challengeId: Scalars['String'];
  missionId: Scalars['String'];
  companyName: Scalars['String'];
}>;


export type InsertGreenscoreMissionAchievement_20240226Mutation = { __typename?: 'mutation_root', insert_zerocame_greenscore_mission_achievement?: { __typename?: 'zerocame_greenscore_mission_achievement_mutation_response', returning: Array<{ __typename?: 'zerocame_greenscore_mission_achievement', auth0_user_id: string, challenge_id: string, mission_id: string }> } | null };

export type InsertQuizMissionAchievement_20240226MutationVariables = Exact<{
  auth0UserId: Scalars['String'];
  challengeId: Scalars['String'];
  missionId: Scalars['String'];
  companyName: Scalars['String'];
  quiz1?: InputMaybe<Scalars['String']>;
  quiz2?: InputMaybe<Scalars['String']>;
  quiz3?: InputMaybe<Scalars['String']>;
  quiz4?: InputMaybe<Scalars['String']>;
  quiz5?: InputMaybe<Scalars['String']>;
  quiz6?: InputMaybe<Scalars['String']>;
  quiz7?: InputMaybe<Scalars['String']>;
  quiz8?: InputMaybe<Scalars['String']>;
  quiz9?: InputMaybe<Scalars['String']>;
  quiz10?: InputMaybe<Scalars['String']>;
}>;


export type InsertQuizMissionAchievement_20240226Mutation = { __typename?: 'mutation_root', insert_zerocame_greenscore_mission_achievement_one?: { __typename?: 'zerocame_greenscore_mission_achievement', auth0_user_id: string, challenge_id: string, mission_id: string } | null };

export type InsertCampaignMissionAchievement_20240226MutationVariables = Exact<{
  auth0UserId: Scalars['String'];
  challengeId: Scalars['String'];
  missionId: Scalars['String'];
  companyName: Scalars['String'];
  challengeCodeInput: Scalars['String'];
}>;


export type InsertCampaignMissionAchievement_20240226Mutation = { __typename?: 'mutation_root', insert_zerocame_greenscore_mission_achievement?: { __typename?: 'zerocame_greenscore_mission_achievement_mutation_response', returning: Array<{ __typename?: 'zerocame_greenscore_mission_achievement', auth0_user_id: string, challenge_id: string, mission_id: string }> } | null };

export type GetNow_20240226QueryVariables = Exact<{ [key: string]: never; }>;


export type GetNow_20240226Query = { __typename?: 'query_root', zerocame_view_now: Array<{ __typename?: 'zerocame_view_now', now?: any | null, epoch?: any | null }> };

export type GetUser_20240226QueryVariables = Exact<{
  auth0_user_id: Scalars['String'];
}>;


export type GetUser_20240226Query = { __typename?: 'query_root', zerocame_users: Array<{ __typename?: 'zerocame_users', auth0_user_id: string, consent_application_zeroca_status?: string | null, pref_code?: string | null, greenscore_users: Array<{ __typename?: 'zerocame_greenscore_users', nick_name: string }> }> };

export type GetUserGreenscoreActions_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;


export type GetUserGreenscoreActions_20240226Query = { __typename?: 'query_root', zerocame_greenscore_user_actions: Array<{ __typename?: 'zerocame_greenscore_user_actions', greenscore_action_id: string, status: string }> };

export type GetUserGreenscoreActionsByStatus_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
  status: Scalars['String'];
}>;


export type GetUserGreenscoreActionsByStatus_20240226Query = { __typename?: 'query_root', zerocame_greenscore_user_actions: Array<{ __typename?: 'zerocame_greenscore_user_actions', greenscore_action_id: string, status: string }> };

export type GetUserGreenscoreReviewLast2_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;


export type GetUserGreenscoreReviewLast2_20240226Query = { __typename?: 'query_root', zerocame_greenscore_action_review: Array<{ __typename?: 'zerocame_greenscore_action_review', id: number, created_at: any, greenscore_action_review_details: Array<{ __typename?: 'zerocame_greenscore_action_review_detail', greenscore_action_id: string, evaluation: number }>, greenscore?: { __typename?: 'zerocame_greenscore', score_sum: number, id: number } | null }> };

export type GetGreenscoreUserActionsByPk_20240226QueryVariables = Exact<{
  auth0_user_id: Scalars['String'];
  greenscore_action_id: Scalars['String'];
}>;


export type GetGreenscoreUserActionsByPk_20240226Query = { __typename?: 'query_root', zerocame_greenscore_user_actions_by_pk?: { __typename?: 'zerocame_greenscore_user_actions', greenscore_action_id: string, auth0_user_id: string, status: string } | null };

export type GetUserActionCountByStatus_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;


export type GetUserActionCountByStatus_20240226Query = { __typename?: 'query_root', active_acions: { __typename?: 'zerocame_greenscore_user_actions_aggregate', aggregate?: { __typename?: 'zerocame_greenscore_user_actions_aggregate_fields', count: number } | null }, habitual_actions: { __typename?: 'zerocame_greenscore_user_actions_aggregate', aggregate?: { __typename?: 'zerocame_greenscore_user_actions_aggregate_fields', count: number } | null } };

export type GetUserGreenscoreLatest_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;


export type GetUserGreenscoreLatest_20240226Query = { __typename?: 'query_root', zerocame_greenscore: Array<{ __typename?: 'zerocame_greenscore', score_sum: number, id: number }> };

export type GetGreenscoreDetail_20240226QueryVariables = Exact<{
  greenscore_id: Scalars['Int'];
}>;


export type GetGreenscoreDetail_20240226Query = { __typename?: 'query_root', zerocame_fn_greenscore_detail_by_category: Array<{ __typename?: 'zerocame_type_greenscore_detail_by_category', category_name: string, score: number }> };

export type GetMyData_20240226QueryVariables = Exact<{
  auth0_user_id: Scalars['String'];
}>;


export type GetMyData_20240226Query = { __typename?: 'query_root', zerocame_fn_current_consent_application_result: Array<{ __typename?: 'zerocame_consent_application_result', id: number, consent_application_status: string }> };

export type SearchInitHearingByUserId_20240226QueryVariables = Exact<{
  auth0_user_id: Scalars['String'];
}>;


export type SearchInitHearingByUserId_20240226Query = { __typename?: 'query_root', zerocame_greenscore_init_hearing: Array<{ __typename?: 'zerocame_greenscore_init_hearing', answer_code?: number | null, answer_content?: string | null, hearing_no: number, hearing_title: string, updated_at?: any | null, created_at?: any | null, auth0_user_id: string }> };

export type GetLastUserElectricityData_20240226QueryVariables = Exact<{
  consentApplicationResultId: Scalars['Int'];
}>;


export type GetLastUserElectricityData_20240226Query = { __typename?: 'query_root', zerocame_view_user_electricity_data: Array<{ __typename?: 'zerocame_view_user_electricity_data', auth0_user_id?: string | null, consent_application_result_id?: number | null, consumer_spcfc_id?: string | null, get_ymd?: string | null, min_30_energy?: any | null, spply_rcvng_pwr_pnt_spcfc_no?: string | null, time_code?: string | null }> };

export type GetGreenscoreReferralCode_20240226QueryVariables = Exact<{
  referral_code: Scalars['String'];
}>;


export type GetGreenscoreReferralCode_20240226Query = { __typename?: 'query_root', zerocame_greenscore_referral_code: Array<{ __typename?: 'zerocame_greenscore_referral_code', referral_code: string, company_name?: string | null, expiration_limit?: any | null }> };

export type GetGreenscoreReferralCodeAuth0UserId_20240226QueryVariables = Exact<{
  auth0_user_id: Scalars['String'];
}>;


export type GetGreenscoreReferralCodeAuth0UserId_20240226Query = { __typename?: 'query_root', zerocame_greenscore_referral_code: Array<{ __typename?: 'zerocame_greenscore_referral_code', referral_code: string }> };

export type GetGreenscoreCo2Emission_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;


export type GetGreenscoreCo2Emission_20240226Query = { __typename?: 'query_root', sum_Co2_Emissions: { __typename?: 'zerocame_greenscore_co2_emission_aggregate', aggregate?: { __typename?: 'zerocame_greenscore_co2_emission_aggregate_fields', sum?: { __typename?: 'zerocame_greenscore_co2_emission_sum_fields', total_co2_emission_reduction_dr?: any | null } | null } | null } };

export type GetMissionAchievement_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
  missionId: Scalars['String'];
  challengeId: Scalars['String'];
}>;


export type GetMissionAchievement_20240226Query = { __typename?: 'query_root', zerocame_greenscore_mission_achievement: Array<{ __typename?: 'zerocame_greenscore_mission_achievement', company_name: string, data_type: string, quiz1_input?: string | null, quiz2_input?: string | null, quiz3_input?: string | null, quiz4_input?: string | null, quiz5_input?: string | null, quiz6_input?: string | null, quiz7_input?: string | null, quiz8_input?: string | null, quiz9_input?: string | null, quiz10_input?: string | null }> };

export type GetDoneMissionAchievement_20240226QueryVariables = Exact<{
  auth0UserId: Scalars['String'];
  mission_id: Scalars['String'];
}>;


export type GetDoneMissionAchievement_20240226Query = { __typename?: 'query_root', zerocame_greenscore_mission_achievement: Array<{ __typename?: 'zerocame_greenscore_mission_achievement', challenge_id: string }> };

export type GetChallengeCodeMaster_20240226QueryVariables = Exact<{
  challenge_id: Scalars['String'];
  campaign_code: Scalars['String'];
}>;


export type GetChallengeCodeMaster_20240226Query = { __typename?: 'query_root', zerocame_greenscore_challenge_code_master: Array<{ __typename?: 'zerocame_greenscore_challenge_code_master', campaign_code: string }> };


export const InsertUser_20240226Document = `
    mutation InsertUser_20240226($auth0_user_id: String!) {
  insert_zerocame_users(objects: {auth0_user_id: $auth0_user_id}) {
    returning {
      auth0_user_id
    }
  }
}
    `;
export const InsertGreenscoreAggregationByRetrospective_20240226Document = `
    mutation InsertGreenscoreAggregationByRetrospective_20240226($auth0UserId: String!, $details: [zerocame_greenscore_action_review_detail_insert_input!]!) {
  insert_zerocame_greenscore_action_review(
    objects: {auth0_user_id: $auth0UserId, greenscore_action_review_details: {data: $details}}
  ) {
    returning {
      id
      greenscore_action_review_details {
        greenscore_action_id
        evaluation
      }
    }
  }
}
    `;
export const UpsertGreenscoreUserActions_20240226Document = `
    mutation UpsertGreenscoreUserActions_20240226($userActions: [zerocame_greenscore_user_actions_insert_input!]!) {
  insert_zerocame_greenscore_user_actions(
    objects: $userActions
    on_conflict: {constraint: greenscore_user_actions_pkey, update_columns: status}
  ) {
    returning {
      greenscore_action_id
      status
    }
  }
}
    `;
export const DeleteGreenscoreUserAction_20240226Document = `
    mutation DeleteGreenscoreUserAction_20240226($greenscore_action_id: String = "", $auth0_user_id: String = "") {
  delete_zerocame_greenscore_user_actions_by_pk(
    auth0_user_id: $auth0_user_id
    greenscore_action_id: $greenscore_action_id
  ) {
    status
  }
}
    `;
export const UpdateProfile_20240226Document = `
    mutation UpdateProfile_20240226($auth0_user_id: String!, $nick_name: String!, $sex_code: Int!, $sex_content: String!, $era_code: Int!, $era_content: String!, $pref_code: Int!, $pref_content: String!, $city: String!, $household_size_code: Int!, $household_size_content: String!, $housing_type_code: Int!, $housing_type_content: String!, $housing_energy_code: Int!, $housing_energy_content: String!, $car_type_code: Int!, $car_type_content: String!, $electricity_contract_code: Int!, $electricity_contract_content: String!, $num_of_household: String!, $housing_type: String!, $all_electric: String!, $electric_vehicle: String!) {
  update_zerocame_greenscore_init_hearing_by_pk_1: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $sex_code, answer_content: $sex_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 1}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_2: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $era_code, answer_content: $era_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 2}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_4: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $pref_code, answer_content: $pref_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 4}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_5: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_content: $city}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 5}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_6: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $household_size_code, answer_content: $household_size_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 6}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_7: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $housing_type_code, answer_content: $housing_type_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 7}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_8: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $housing_energy_code, answer_content: $housing_energy_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 8}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_9: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $car_type_code, answer_content: $car_type_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 9}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_init_hearing_by_pk_10: update_zerocame_greenscore_init_hearing_by_pk(
    _set: {answer_code: $electricity_contract_code, answer_content: $electricity_contract_content}
    pk_columns: {auth0_user_id: $auth0_user_id, hearing_no: 10}
  ) {
    auth0_user_id
    hearing_no
  }
  update_zerocame_greenscore_users_by_pk(
    _set: {nick_name: $nick_name, num_of_household: $num_of_household, housing_type: $housing_type, all_electric: $all_electric, electric_vehicle: $electric_vehicle}
    pk_columns: {auth0_user_id: $auth0_user_id}
  ) {
    auth0_user_id
  }
}
    `;
export const InsertGreenscoreMissionAchievement_20240226Document = `
    mutation InsertGreenscoreMissionAchievement_20240226($auth0UserId: String!, $challengeId: String!, $missionId: String!, $companyName: String!) {
  insert_zerocame_greenscore_mission_achievement(
    objects: {auth0_user_id: $auth0UserId, challenge_id: $challengeId, mission_id: $missionId, company_name: $companyName, data_type: "記事閲読型"}
  ) {
    returning {
      auth0_user_id
      challenge_id
      mission_id
    }
  }
}
    `;
export const InsertQuizMissionAchievement_20240226Document = `
    mutation InsertQuizMissionAchievement_20240226($auth0UserId: String!, $challengeId: String!, $missionId: String!, $companyName: String!, $quiz1: String, $quiz2: String, $quiz3: String, $quiz4: String, $quiz5: String, $quiz6: String, $quiz7: String, $quiz8: String, $quiz9: String, $quiz10: String) {
  insert_zerocame_greenscore_mission_achievement_one(
    object: {auth0_user_id: $auth0UserId, challenge_id: $challengeId, mission_id: $missionId, company_name: $companyName, data_type: "クイズ回答型", quiz1_input: $quiz1, quiz2_input: $quiz2, quiz3_input: $quiz3, quiz4_input: $quiz4, quiz5_input: $quiz5, quiz6_input: $quiz6, quiz7_input: $quiz7, quiz8_input: $quiz8, quiz9_input: $quiz9, quiz10_input: $quiz10}
  ) {
    auth0_user_id
    challenge_id
    mission_id
  }
}
    `;
export const InsertCampaignMissionAchievement_20240226Document = `
    mutation InsertCampaignMissionAchievement_20240226($auth0UserId: String!, $challengeId: String!, $missionId: String!, $companyName: String!, $challengeCodeInput: String!) {
  insert_zerocame_greenscore_mission_achievement(
    objects: {auth0_user_id: $auth0UserId, challenge_id: $challengeId, mission_id: $missionId, company_name: $companyName, data_type: "キャンペーン型", challenge_code_input: $challengeCodeInput}
  ) {
    returning {
      auth0_user_id
      challenge_id
      mission_id
    }
  }
}
    `;
export const GetNow_20240226Document = `
    query GetNow_20240226 {
  zerocame_view_now {
    now
    epoch
  }
}
    `;
export const GetUser_20240226Document = `
    query GetUser_20240226($auth0_user_id: String!) {
  zerocame_users(
    where: {auth0_user_id: {_eq: $auth0_user_id}, _and: {deleted_at: {_is_null: true}}}
  ) {
    auth0_user_id
    consent_application_zeroca_status
    pref_code
    greenscore_users {
      nick_name
    }
  }
}
    `;
export const GetUserGreenscoreActions_20240226Document = `
    query GetUserGreenscoreActions_20240226($auth0UserId: String!) {
  zerocame_greenscore_user_actions(
    where: {auth0_user_id: {_eq: $auth0UserId}, status: {_gte: "1", _lte: "2"}}
  ) {
    greenscore_action_id
    status
  }
}
    `;
export const GetUserGreenscoreActionsByStatus_20240226Document = `
    query GetUserGreenscoreActionsByStatus_20240226($auth0UserId: String!, $status: String!) {
  zerocame_greenscore_user_actions(
    where: {auth0_user_id: {_eq: $auth0UserId}, _and: {status: {_eq: $status}}}
  ) {
    greenscore_action_id
    status
  }
}
    `;
export const GetUserGreenscoreReviewLast2_20240226Document = `
    query GetUserGreenscoreReviewLast2_20240226($auth0UserId: String!) {
  zerocame_greenscore_action_review(
    where: {auth0_user_id: {_eq: $auth0UserId}, _and: {created_by: {_eq: "1"}, _and: {greenscore_calc_status: {_eq: "2"}}}}
    order_by: {created_at: desc}
    limit: 2
  ) {
    id
    created_at
    greenscore_action_review_details {
      greenscore_action_id
      evaluation
    }
    greenscore {
      score_sum
      id
    }
  }
}
    `;
export const GetGreenscoreUserActionsByPk_20240226Document = `
    query GetGreenscoreUserActionsByPk_20240226($auth0_user_id: String!, $greenscore_action_id: String!) {
  zerocame_greenscore_user_actions_by_pk(
    greenscore_action_id: $greenscore_action_id
    auth0_user_id: $auth0_user_id
  ) {
    greenscore_action_id
    auth0_user_id
    status
  }
}
    `;
export const GetUserActionCountByStatus_20240226Document = `
    query GetUserActionCountByStatus_20240226($auth0UserId: String!) {
  active_acions: zerocame_greenscore_user_actions_aggregate(
    where: {auth0_user_id: {_eq: $auth0UserId}, _and: {status: {_eq: "1"}}}
  ) {
    aggregate {
      count(columns: status)
    }
  }
  habitual_actions: zerocame_greenscore_user_actions_aggregate(
    where: {auth0_user_id: {_eq: $auth0UserId}, _and: {status: {_eq: "2"}}}
  ) {
    aggregate {
      count(columns: status)
    }
  }
}
    `;
export const GetUserGreenscoreLatest_20240226Document = `
    query GetUserGreenscoreLatest_20240226($auth0UserId: String!) {
  zerocame_greenscore(
    where: {auth0_user_id: {_eq: $auth0UserId}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    score_sum
    id
  }
}
    `;
export const GetGreenscoreDetail_20240226Document = `
    query GetGreenscoreDetail_20240226($greenscore_id: Int!) {
  zerocame_fn_greenscore_detail_by_category(args: {greenscore_id: $greenscore_id}) {
    category_name
    score
  }
}
    `;
export const GetMyData_20240226Document = `
    query GetMyData_20240226($auth0_user_id: String!) {
  zerocame_fn_current_consent_application_result(args: {user_id: $auth0_user_id}) {
    id
    consent_application_status
  }
}
    `;
export const SearchInitHearingByUserId_20240226Document = `
    query SearchInitHearingByUserId_20240226($auth0_user_id: String!) {
  zerocame_greenscore_init_hearing(where: {auth0_user_id: {_eq: $auth0_user_id}}) {
    answer_code
    answer_content
    hearing_no
    hearing_title
    updated_at
    created_at
    auth0_user_id
  }
}
    `;
export const GetLastUserElectricityData_20240226Document = `
    query GetLastUserElectricityData_20240226($consentApplicationResultId: Int!) {
  zerocame_view_user_electricity_data(
    limit: 1
    where: {consent_application_result_id: {_eq: $consentApplicationResultId}}
    order_by: {get_ymd: desc, time_code: desc}
  ) {
    auth0_user_id
    consent_application_result_id
    consumer_spcfc_id
    get_ymd
    min_30_energy
    spply_rcvng_pwr_pnt_spcfc_no
    time_code
  }
}
    `;
export const GetGreenscoreReferralCode_20240226Document = `
    query GetGreenscoreReferralCode_20240226($referral_code: String!) {
  zerocame_greenscore_referral_code(where: {referral_code: {_eq: $referral_code}}) {
    referral_code
    company_name
    expiration_limit
  }
}
    `;
export const GetGreenscoreReferralCodeAuth0UserId_20240226Document = `
    query GetGreenscoreReferralCodeAuth0UserId_20240226($auth0_user_id: String!) {
  zerocame_greenscore_referral_code(where: {auth0_user_id: {_eq: $auth0_user_id}}) {
    referral_code
  }
}
    `;
export const GetGreenscoreCo2Emission_20240226Document = `
    query GetGreenscoreCo2Emission_20240226($auth0UserId: String!) {
  sum_Co2_Emissions: zerocame_greenscore_co2_emission_aggregate(
    where: {auth0_user_id: {_eq: $auth0UserId}}
  ) {
    aggregate {
      sum {
        total_co2_emission_reduction_dr
      }
    }
  }
}
    `;
export const GetMissionAchievement_20240226Document = `
    query GetMissionAchievement_20240226($auth0UserId: String!, $missionId: String!, $challengeId: String!) {
  zerocame_greenscore_mission_achievement(
    where: {auth0_user_id: {_eq: $auth0UserId}, _and: {mission_id: {_eq: $missionId}, _and: {challenge_id: {_eq: $challengeId}}}}
  ) {
    company_name
    data_type
    quiz1_input
    quiz2_input
    quiz3_input
    quiz4_input
    quiz5_input
    quiz6_input
    quiz7_input
    quiz8_input
    quiz9_input
    quiz10_input
  }
}
    `;
export const GetDoneMissionAchievement_20240226Document = `
    query GetDoneMissionAchievement_20240226($auth0UserId: String!, $mission_id: String!) {
  zerocame_greenscore_mission_achievement(
    where: {auth0_user_id: {_eq: $auth0UserId}, _and: {mission_id: {_eq: $mission_id}}}
  ) {
    challenge_id
  }
}
    `;
export const GetChallengeCodeMaster_20240226Document = `
    query GetChallengeCodeMaster_20240226($challenge_id: String!, $campaign_code: String!) {
  zerocame_greenscore_challenge_code_master(
    where: {challenge_id: {_eq: $challenge_id}, _and: {campaign_code: {_eq: $campaign_code}}}
  ) {
    campaign_code
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    InsertUser_20240226: build.mutation<InsertUser_20240226Mutation, InsertUser_20240226MutationVariables>({
      query: (variables) => ({ document: InsertUser_20240226Document, variables })
    }),
    InsertGreenscoreAggregationByRetrospective_20240226: build.mutation<InsertGreenscoreAggregationByRetrospective_20240226Mutation, InsertGreenscoreAggregationByRetrospective_20240226MutationVariables>({
      query: (variables) => ({ document: InsertGreenscoreAggregationByRetrospective_20240226Document, variables })
    }),
    UpsertGreenscoreUserActions_20240226: build.mutation<UpsertGreenscoreUserActions_20240226Mutation, UpsertGreenscoreUserActions_20240226MutationVariables>({
      query: (variables) => ({ document: UpsertGreenscoreUserActions_20240226Document, variables })
    }),
    DeleteGreenscoreUserAction_20240226: build.mutation<DeleteGreenscoreUserAction_20240226Mutation, DeleteGreenscoreUserAction_20240226MutationVariables | void>({
      query: (variables) => ({ document: DeleteGreenscoreUserAction_20240226Document, variables })
    }),
    UpdateProfile_20240226: build.mutation<UpdateProfile_20240226Mutation, UpdateProfile_20240226MutationVariables>({
      query: (variables) => ({ document: UpdateProfile_20240226Document, variables })
    }),
    InsertGreenscoreMissionAchievement_20240226: build.mutation<InsertGreenscoreMissionAchievement_20240226Mutation, InsertGreenscoreMissionAchievement_20240226MutationVariables>({
      query: (variables) => ({ document: InsertGreenscoreMissionAchievement_20240226Document, variables })
    }),
    InsertQuizMissionAchievement_20240226: build.mutation<InsertQuizMissionAchievement_20240226Mutation, InsertQuizMissionAchievement_20240226MutationVariables>({
      query: (variables) => ({ document: InsertQuizMissionAchievement_20240226Document, variables })
    }),
    InsertCampaignMissionAchievement_20240226: build.mutation<InsertCampaignMissionAchievement_20240226Mutation, InsertCampaignMissionAchievement_20240226MutationVariables>({
      query: (variables) => ({ document: InsertCampaignMissionAchievement_20240226Document, variables })
    }),
    GetNow_20240226: build.query<GetNow_20240226Query, GetNow_20240226QueryVariables | void>({
      query: (variables) => ({ document: GetNow_20240226Document, variables })
    }),
    GetUser_20240226: build.query<GetUser_20240226Query, GetUser_20240226QueryVariables>({
      query: (variables) => ({ document: GetUser_20240226Document, variables })
    }),
    GetUserGreenscoreActions_20240226: build.query<GetUserGreenscoreActions_20240226Query, GetUserGreenscoreActions_20240226QueryVariables>({
      query: (variables) => ({ document: GetUserGreenscoreActions_20240226Document, variables })
    }),
    GetUserGreenscoreActionsByStatus_20240226: build.query<GetUserGreenscoreActionsByStatus_20240226Query, GetUserGreenscoreActionsByStatus_20240226QueryVariables>({
      query: (variables) => ({ document: GetUserGreenscoreActionsByStatus_20240226Document, variables })
    }),
    GetUserGreenscoreReviewLast2_20240226: build.query<GetUserGreenscoreReviewLast2_20240226Query, GetUserGreenscoreReviewLast2_20240226QueryVariables>({
      query: (variables) => ({ document: GetUserGreenscoreReviewLast2_20240226Document, variables })
    }),
    GetGreenscoreUserActionsByPk_20240226: build.query<GetGreenscoreUserActionsByPk_20240226Query, GetGreenscoreUserActionsByPk_20240226QueryVariables>({
      query: (variables) => ({ document: GetGreenscoreUserActionsByPk_20240226Document, variables })
    }),
    GetUserActionCountByStatus_20240226: build.query<GetUserActionCountByStatus_20240226Query, GetUserActionCountByStatus_20240226QueryVariables>({
      query: (variables) => ({ document: GetUserActionCountByStatus_20240226Document, variables })
    }),
    GetUserGreenscoreLatest_20240226: build.query<GetUserGreenscoreLatest_20240226Query, GetUserGreenscoreLatest_20240226QueryVariables>({
      query: (variables) => ({ document: GetUserGreenscoreLatest_20240226Document, variables })
    }),
    GetGreenscoreDetail_20240226: build.query<GetGreenscoreDetail_20240226Query, GetGreenscoreDetail_20240226QueryVariables>({
      query: (variables) => ({ document: GetGreenscoreDetail_20240226Document, variables })
    }),
    GetMyData_20240226: build.query<GetMyData_20240226Query, GetMyData_20240226QueryVariables>({
      query: (variables) => ({ document: GetMyData_20240226Document, variables })
    }),
    SearchInitHearingByUserId_20240226: build.query<SearchInitHearingByUserId_20240226Query, SearchInitHearingByUserId_20240226QueryVariables>({
      query: (variables) => ({ document: SearchInitHearingByUserId_20240226Document, variables })
    }),
    GetLastUserElectricityData_20240226: build.query<GetLastUserElectricityData_20240226Query, GetLastUserElectricityData_20240226QueryVariables>({
      query: (variables) => ({ document: GetLastUserElectricityData_20240226Document, variables })
    }),
    GetGreenscoreReferralCode_20240226: build.query<GetGreenscoreReferralCode_20240226Query, GetGreenscoreReferralCode_20240226QueryVariables>({
      query: (variables) => ({ document: GetGreenscoreReferralCode_20240226Document, variables })
    }),
    GetGreenscoreReferralCodeAuth0UserId_20240226: build.query<GetGreenscoreReferralCodeAuth0UserId_20240226Query, GetGreenscoreReferralCodeAuth0UserId_20240226QueryVariables>({
      query: (variables) => ({ document: GetGreenscoreReferralCodeAuth0UserId_20240226Document, variables })
    }),
    GetGreenscoreCo2Emission_20240226: build.query<GetGreenscoreCo2Emission_20240226Query, GetGreenscoreCo2Emission_20240226QueryVariables>({
      query: (variables) => ({ document: GetGreenscoreCo2Emission_20240226Document, variables })
    }),
    GetMissionAchievement_20240226: build.query<GetMissionAchievement_20240226Query, GetMissionAchievement_20240226QueryVariables>({
      query: (variables) => ({ document: GetMissionAchievement_20240226Document, variables })
    }),
    GetDoneMissionAchievement_20240226: build.query<GetDoneMissionAchievement_20240226Query, GetDoneMissionAchievement_20240226QueryVariables>({
      query: (variables) => ({ document: GetDoneMissionAchievement_20240226Document, variables })
    }),
    GetChallengeCodeMaster_20240226: build.query<GetChallengeCodeMaster_20240226Query, GetChallengeCodeMaster_20240226QueryVariables>({
      query: (variables) => ({ document: GetChallengeCodeMaster_20240226Document, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertUser_20240226Mutation, useInsertGreenscoreAggregationByRetrospective_20240226Mutation, useUpsertGreenscoreUserActions_20240226Mutation, useDeleteGreenscoreUserAction_20240226Mutation, useUpdateProfile_20240226Mutation, useInsertGreenscoreMissionAchievement_20240226Mutation, useInsertQuizMissionAchievement_20240226Mutation, useInsertCampaignMissionAchievement_20240226Mutation, useGetNow_20240226Query, useLazyGetNow_20240226Query, useGetUser_20240226Query, useLazyGetUser_20240226Query, useGetUserGreenscoreActions_20240226Query, useLazyGetUserGreenscoreActions_20240226Query, useGetUserGreenscoreActionsByStatus_20240226Query, useLazyGetUserGreenscoreActionsByStatus_20240226Query, useGetUserGreenscoreReviewLast2_20240226Query, useLazyGetUserGreenscoreReviewLast2_20240226Query, useGetGreenscoreUserActionsByPk_20240226Query, useLazyGetGreenscoreUserActionsByPk_20240226Query, useGetUserActionCountByStatus_20240226Query, useLazyGetUserActionCountByStatus_20240226Query, useGetUserGreenscoreLatest_20240226Query, useLazyGetUserGreenscoreLatest_20240226Query, useGetGreenscoreDetail_20240226Query, useLazyGetGreenscoreDetail_20240226Query, useGetMyData_20240226Query, useLazyGetMyData_20240226Query, useSearchInitHearingByUserId_20240226Query, useLazySearchInitHearingByUserId_20240226Query, useGetLastUserElectricityData_20240226Query, useLazyGetLastUserElectricityData_20240226Query, useGetGreenscoreReferralCode_20240226Query, useLazyGetGreenscoreReferralCode_20240226Query, useGetGreenscoreReferralCodeAuth0UserId_20240226Query, useLazyGetGreenscoreReferralCodeAuth0UserId_20240226Query, useGetGreenscoreCo2Emission_20240226Query, useLazyGetGreenscoreCo2Emission_20240226Query, useGetMissionAchievement_20240226Query, useLazyGetMissionAchievement_20240226Query, useGetDoneMissionAchievement_20240226Query, useLazyGetDoneMissionAchievement_20240226Query, useGetChallengeCodeMaster_20240226Query, useLazyGetChallengeCodeMaster_20240226Query } = injectedRtkApi;

