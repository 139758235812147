import { useEffect, useMemo, useState } from "react";
import {
  useGetNowQuery,
  useGetGreenscoreReferralCodeQuery,
} from "../../services/graphql/enhanceApi";
import { useRouter } from "next/router";
import { useAppDispatch } from "../../services/store";
import { setInvParam } from "../../services/referralCode/slice";

export type InputReferralCodeError =
  | "valid" // エラーなし
  | "errorNotExist" // 存在しないコード
  | "errorExpired" // 有効期限切れのコード
  | "errorTooManyUsed"; // 利用上限を超えているコード TODO 詳細は未決定

export const useReferralCodeCheck = (referralCode: string) => {
  const [errorState, setErrorState] = useState<
    InputReferralCodeError | undefined
  >();

  //現在日時取得
  const { data: viewNows } = useGetNowQuery({}, {});

  // 紹介コードデータを取得
  const {
    currentData: getGreenscoreReferralCodeQuery,
    isSuccess: isGetQuerySuccess,
  } = useGetGreenscoreReferralCodeQuery(
    {
      referral_code: referralCode as string,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: referralCode.length < 1,
    }
  );
  // チェック結果の算出
  const checkResult = useMemo<
    | { code?: string; expirationLimit?: number; companyName?: string }
    | undefined
  >(() => {
    if (isGetQuerySuccess && getGreenscoreReferralCodeQuery) {
      const greenscoreReferralData = getGreenscoreReferralCodeQuery
        ? // getGreenscoreReferralCodeQuery.zerocame_greenscore_referral_code
          getGreenscoreReferralCodeQuery.zerocame_greenscore_referral_code[0]
        : undefined;

      // ミリ秒変換
      const expirationLimit =
        Date.parse(greenscoreReferralData?.expiration_limit) / 1000;

      // console.log(
      //   "test data :" + JSON.stringify(getGreenscoreReferralCodeQuery)
      // );
      // console.log("test data greenscoreReferralData:" + greenscoreReferralData);
      return {
        code: greenscoreReferralData?.referral_code,
        expirationLimit,
        companyName: greenscoreReferralData?.company_name || undefined,
      };
    } else {
      return undefined;
    }
  }, [getGreenscoreReferralCodeQuery, isGetQuerySuccess]);

  // エラーチェック
  useEffect(() => {
    if (referralCode.length === 0 || !viewNows) {
      setErrorState(undefined);
    } else {
      if (checkResult) {
        if (checkResult.code) {
          const UPPER_LIMIT = parseInt(
            process.env.NEXT_PUBLIC_REFERRAL_CODE_LIMIT || ""
          );
          if (UPPER_LIMIT < 1) {
            // TODO 暫定対応
            setErrorState("errorTooManyUsed");
          } else {
            if (checkResult.companyName) {
              if (
                checkResult.expirationLimit &&
                checkResult.expirationLimit <
                  viewNows.zerocame_view_now[0].epoch
              ) {
                setErrorState("errorExpired");
              } else {
                setErrorState("valid");
              }
            } else {
              setErrorState("valid");
            }
          }
        } else {
          setErrorState("errorNotExist");
        }
      } else {
        setErrorState(undefined);
      }
    }
  }, [checkResult, referralCode.length, viewNows]);

  return errorState;
};

const PARAM_NAME_INV = "inv";

export const useGetParamReferralCode = () => {
  const { isReady, query } = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isReady && typeof query[PARAM_NAME_INV] === "string") {
      dispatch(setInvParam(query[PARAM_NAME_INV]));
    }
  }, [dispatch, isReady, query]);
  return isReady;
};
