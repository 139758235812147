import { type } from "os";
import { GRAPHQL_TAG_TYPE } from ".";
import { api as generatedApi } from "./generated";

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    GRAPHQL_TAG_TYPE.VIEW_NOW,
    GRAPHQL_TAG_TYPE.USERS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_STATUS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_PK,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_REVIEW_LAST2,
    GRAPHQL_TAG_TYPE.USER_ACTION_COUNT_BY_STATUS,
    GRAPHQL_TAG_TYPE.USER_GREENSCORE_LATEST,
    GRAPHQL_TAG_TYPE.MY_DATA,
    GRAPHQL_TAG_TYPE.GREENSCORE_REFERRAL_CODE,
    GRAPHQL_TAG_TYPE.GREENSCORE_CO2_EMISSION,
    GRAPHQL_TAG_TYPE.GREENSCORE_DETAIL,
    GRAPHQL_TAG_TYPE.INIT_HEARING,
    GRAPHQL_TAG_TYPE.GREENSCORE_REFERRAL_CODE_BY_USER_ID,
    GRAPHQL_TAG_TYPE.LAST_USER_ELECTRICITY_DATA,
    GRAPHQL_TAG_TYPE.MISSION_ACHIEVEMENT,
    GRAPHQL_TAG_TYPE.CHALLENGE_CODE_MASTER,
  ],
  endpoints: {
    GetNow_20240226: {
      // このタグの名前でキャッシュする
      // 日跨ぎで取りなおすならそのようにロジック書く。
      // 最終チェック時間をlocalで保持しておいて、5時またいだらリセットする
      providesTags: [GRAPHQL_TAG_TYPE.VIEW_NOW],
      keepUnusedDataFor: 3600, // 頻繁な再取得は不要なため、検索結果を1時間キャッシュする
    },
    GetUser_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.USERS],
    },
    InsertUser_20240226: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.USERS],
    },
    GetUserGreenscoreActions_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS],
    },
    GetUserGreenscoreActionsByStatus_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_STATUS],
    },
    GetGreenscoreUserActionsByPk_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_PK],
    },
    GetUserGreenscoreReviewLast2_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_GREENSCORE_REVIEW_LAST2],
    },
    GetUserActionCountByStatus_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_ACTION_COUNT_BY_STATUS],
    },
    GetUserGreenscoreLatest_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_GREENSCORE_LATEST],
    },
    GetGreenscoreDetail_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.GREENSCORE_DETAIL],
    },
    GetMyData_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.MY_DATA],
    },
    InsertGreenscoreAggregationByRetrospective_20240226: {
      invalidatesTags: [
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_REVIEW_LAST2,
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_LATEST,
      ],
    },
    UpsertGreenscoreUserActions_20240226: {
      invalidatesTags: [
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS,
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_STATUS,
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_PK,
      ],
    },
    DeleteGreenscoreUserAction_20240226: {
      invalidatesTags: [
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS,
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_STATUS,
        GRAPHQL_TAG_TYPE.USER_GREENSCORE_ACTIONS_BY_PK,
      ],
    },
    SearchInitHearingByUserId_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.INIT_HEARING],
    },
    GetGreenscoreReferralCode_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.GREENSCORE_REFERRAL_CODE],
    },
    GetGreenscoreReferralCodeAuth0UserId_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.GREENSCORE_REFERRAL_CODE_BY_USER_ID],
    },
    GetGreenscoreCo2Emission_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.GREENSCORE_CO2_EMISSION],
    },
    GetLastUserElectricityData_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.LAST_USER_ELECTRICITY_DATA],
    },
    UpdateProfile_20240226: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.USERS, GRAPHQL_TAG_TYPE.INIT_HEARING],
    },
    GetMissionAchievement_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.MISSION_ACHIEVEMENT],
    },
    GetDoneMissionAchievement_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.DONE_MISSION_ACHIEVEMENT],
    },
    InsertGreenscoreMissionAchievement_20240226: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.MISSION_ACHIEVEMENT],
    },
    InsertQuizMissionAchievement_20240226: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.MISSION_ACHIEVEMENT],
    },
    InsertCampaignMissionAchievement_20240226: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.MISSION_ACHIEVEMENT],
    },
    GetChallengeCodeMaster_20240226: {
      providesTags: [GRAPHQL_TAG_TYPE.CHALLENGE_CODE_MASTER],
    },
  },
});
// 関数名からyyyyMMddを取り除いてexport
export const {
  useGetNow_20240226Query: useGetNowQuery,
  useGetUser_20240226Query: useGetUserQuery,
  useInsertUser_20240226Mutation: useInsertUserMutation,
  useGetUserGreenscoreActions_20240226Query: useGetUserGreenscoreActionsQuery,
  useGetUserGreenscoreActionsByStatus_20240226Query:
    useGetUserGreenscoreActionsByStatusQuery,
  useGetGreenscoreUserActionsByPk_20240226Query:
    useGetGreenscoreUserActionsByPkQuery,
  useInsertGreenscoreAggregationByRetrospective_20240226Mutation:
    useInsertGreenscoreAggregationByRetrospectiveMutation,
  useUpsertGreenscoreUserActions_20240226Mutation:
    useUpsertGreenscoreUserActionsMutation,
  useDeleteGreenscoreUserAction_20240226Mutation:
    useDeleteGreenscoreUserActionMutation,
  useGetUserGreenscoreReviewLast2_20240226Query:
    useGetUserGreenscoreReviewLast2Query,
  useGetUserActionCountByStatus_20240226Query:
    useGetUserActionCountByStatusQuery,
  useGetUserGreenscoreLatest_20240226Query: useGetUserGreenscoreLatestQuery,
  useGetGreenscoreDetail_20240226Query: useGetGreenscoreDetailQuery,
  useGetMyData_20240226Query: useGetMyDataQuery,
  useSearchInitHearingByUserId_20240226Query: useSearchInitHearingByUserIdQuery,
  useGetGreenscoreReferralCode_20240226Query: useGetGreenscoreReferralCodeQuery,
  useGetGreenscoreReferralCodeAuth0UserId_20240226Query:
    useGetGreenscoreReferralCodeAuth0UserIdQuery,
  useGetGreenscoreCo2Emission_20240226Query: useGetGreenscoreCo2EmissionQuery,
  useGetLastUserElectricityData_20240226Query:
    useGetLastUserElectricityDataQuery,
  useUpdateProfile_20240226Mutation: useUpdateProfileMutation,
  useGetMissionAchievement_20240226Query: useGetMissionAchievementQuery,
  useGetDoneMissionAchievement_20240226Query: useGetDoneMissionAchievementQuery,
  useInsertGreenscoreMissionAchievement_20240226Mutation:
    useInsertArticleMissionAchievementMutation,
  useInsertQuizMissionAchievement_20240226Mutation:
    useInsertQuizMissionAchievementMutation,
  useInsertCampaignMissionAchievement_20240226Mutation:
    useInsertCampaignMissionAchievementMutation,
  useGetChallengeCodeMaster_20240226Query: useGetChallengeCodeMasterQuery,
} = api;

// 型定義からyyyyMMddを取り除く
import {
  GetUserGreenscoreReviewLast2_20240226Query,
  GetUserGreenscoreLatest_20240226Query,
  GetGreenscoreDetail_20240226Query,
  InsertGreenscoreAggregationByRetrospective_20240226MutationVariables,
  GetMissionAchievement_20240226Query,
} from "./generated";

export type GetUserGreenscoreReviewLast2Query =
  GetUserGreenscoreReviewLast2_20240226Query;
export type GetUserGreenscoreLatestQuery =
  GetUserGreenscoreLatest_20240226Query;
export type GetGreenscoreDetailQuery = GetGreenscoreDetail_20240226Query;
export type InsertGreenscoreAggregationByRetrospectiveMutationVariables =
  InsertGreenscoreAggregationByRetrospective_20240226MutationVariables;
export type { Zerocame_Greenscore_Action_Review_Detail_Insert_Input } from "./generated";
export type GetMissionAchivementQuery = GetMissionAchievement_20240226Query;